import {Gavel, Home, Restaurant, Store, Work} from "@mui/icons-material";
import React from "react";

export const BPNotifications={
    "residence":{
        "Chs":"住房",
        "icon":<Home/>,
        "keywords":{
            "renewalChanged":{
                "important":true
            },
            "expelled":{
                "important":true
            },
            "guestCheckedIn":{
                "important":true
            },
            "guestCheckedInAsWorker":{
                "important":true
            },
            "invited":{
                "important":true
            },
            "invitedResponded":{
                "important":true
            },
            "guestRenewaled":{
                "important":false
            },
            "guestLeft":{
                "important":false
            },
            "leaseTermEnded":{
                "important":true
            },
            "guestleaseTermEnded":{
                "important":false
            },
            "buildingCollapsed":{
                "important":true
            },
        }
    },
    "market":{
        "Chs":"市场",
        "icon":<Store/>,
        "keywords":{
            "itemsNew":{
                "important":false
            },
            "itemsSold":{
                "important":true
            },
            "itemsTimeUp":{
                "important":true
            },
            "itemsCanceled":{
                "important":false
            },
            "itemsChanged":{
                "important":false
            },
            "buildingsNew":{
                "important":false
            },
            "buildingitemsSold":{
                "important":true
            },
            "buildingitemsTimeUp":{
                "important":true
            },
            "buildingitemsCanceled":{
                "important":false
            },
            "buildingitemsChanged":{
                "important":false
            },
            "needAccounting":{
                "important":true
            },
            "tradingPostCollapsed":{
                "important":true
            },
            "myTradingPostCollapsed":{
                "important":true
            },
            "buildingCollapsed":{
                "important":false
            },

        }
    },
    "restaurant":{
        "Chs":"餐馆",
        "icon":<Restaurant/>,
        "keywords":{
            "needCooking":{
                "important":true
            },
            "needFoodMaterial":{
                "important":true
            },
            "limitMet":{
                "important":false
            },
            "dailySummary":{
                "important":false
            },

        }
    },
    "sueprvisor":{
        "Chs":"监工",
        "icon":<Gavel/>,
        "keywords":{
            "invited":{
                "important":true
            },
            "invitedResponded":{
                "important":true
            },
            "managerFired":{
                "important":true
            },
            "ownershipChangeManager":{
                "important":true
            },
            "combinedManager":{
                "important":true
            },

        }
    }
}
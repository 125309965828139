export const systemSpeeches = () => {
  const   avt = (process.env.PUBLIC_URL + "/img/foodType/meat.webp")
    return [
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1676749883000000000,
            "Content": "可以邀请其他玩家成为建筑管理员，共同管理建筑了。管理员拥有大部分权限，包括修改生产方案、工资和转移仓库物资",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1675303972000000000,
            "Content": " 私人交易页面:自动设置库房为藏身处。修复不能选择索取的建筑。修复不能选择自己的藏身处库房。修复没有选择库房也可以回应交易。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1675109678000000000,
            "Content": "新版私人交易修复：主页面私人交易按钮红点显示逻辑优化，交易列表按照发起时间排序,发起交易支持url跳转。" +
                "更多优化：仓库剩余数量提示，向特定建筑快速发起交易等正在路上。。。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1675059301000000000,
            "Content": "新版私人交易页面上线了。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1671157727000000000,
            "Content": "技能总览页面发布了，在你或者别人的个人页面点击技能大按钮就能显示。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1667894315000000000,
            "Content": "总计调整：\n" +
                "前端食物按钮跳转到寻找餐馆页面（但是空白页面）\n" +
                "前端修复建造界面我有的资源预览和等级无关的问题\n" +
                "前端修复餐馆菜单页面黑屏\n" +
                "\n" +
                "去掉黍的健康减益但是增加快乐减益\n" +
                "去掉q1别墅\n" +
                "修复q1楼房建材，去掉了获取不到的物品\n" +
                "减少q1棚屋开垦和建造产能\n" +
                "没有住房时候每日健康恢复0->0.5\n" +
                "没有住房和棚屋每日快乐减益增加\n" +
                "副业列表增加葡萄",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1667351265000000000,
            "Content": "个人页面增加建筑工人统计；修复技能太高时候个人页面比例失调；部分主要按钮添加了动态效果；前端补充连接部分次要接口。顺带一提9-11月回报奖励已经发放了。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1667339983000000000,
            "Content": "9-11月订阅回报和bug反馈回报已经发放。可以在https://afdian.net/a/CivitasRise进行确认。如果订阅但未收到奖励，请于上述网址（或者其他途径联系管理员）填写玩家信息以接收。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1666802854000000000,
            "Content": "修复了一堆bug。现在可以市场出售建筑了。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1665960990000000000,
            "Content": "新版本已经悄悄上线，然而依旧有成吨bug，部分功能延后，以及新版数值还有待实装。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1660465913000000000,
            "Content": "增加3个小功能：建筑附言（监工页面可以修改），建筑记录建筑师（造完的拿一锤子的人被永久记录），查看别人的个人主页（点别人头像）。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1657350453000000000,
            "Content": "现在建筑可以改名了。现在可以查看工人和辞退工人了。看你的建筑的监工页面。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 1656635286000000000,
            "Content": "今天起更新公告发在这里。而且可以点击下拉按钮查看历史。",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 0,
            "Content": "再测试新的系统公告123…….",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 0,
            "Content": "实装了物品图标",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 0,
            "Content": "@全体成员 5月6号的承诺，到现在就全部兑现了。除了住宅的开发计划被交换了 5月更新的主题是城市结构，一个月时间，我们做了： 大陆-城市-城区郊区-街区-地形的结构关系； 做了玩家建设自己的建筑； 玩家之间私人交易； 仓库之间的物资转移； 新实装了40个物品，51个技能，24个建筑，@鍣哥 提供了设计。 最近几天的工作是查漏补缺：修补bug，补充功能页面，以及给未来画饼。 下一期更新的主题是吃住消费，预计要做的内容： 市场系统+贸易站（玩家私人市场）； 住宿系统+楼房、宅院、别墅； 烹饪系统+餐馆（以完整功能重新介绍餐馆给大家） 副业",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
        {
            "ID": 0,
            "Uid": 0,
            "Uname": "Renewal art studio",
            "Uavatar": avt,
            "Time": 0,
            "Content": "现在可以开关招聘和修改工资了。在自己建筑的监工界面",
            "NumReaction1": "-",
            "NumReaction2": "-",
            "NumReaction3": "-",
            "MyReaction": 0
        },
    ]
}
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'employees',
    initialState: {
        "data": [
            {
                "buildingID": 3,
                "employee": {
                    "uid": 13,
                    "user": {
                        "created_at": -6795364578871345152,
                        "updated_at": -6795364578871345152
                    },
                    "nick_name": "寻找工人……",
                    "avatar": ""
                },
                "employeeID": 13,
                "is_worked": false,
                "quitting": false,
                "firing":false,
            }
        ],
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateEmployeesWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchEmployeesNow: (state) => {
            state.fetchNow += 1;
        },

    },
});
export const { updateEmployeesWPayload, fetchEmployeesNow } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import "./SellBuilding.scss"
import "../../../../components/ListItemBuilding/listItemBuilding.scss"
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ItemSelector from "../../../../components/ItemSelector";
import {itemInfo} from "../../../../components/gadgets";
import {Button, TextField} from "@mui/material";
import {TierToStars} from "../../../../components/mappingTable";
import * as React from "react";
import {useState} from "react";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";

export default function SellBuilding() {
    const {buildingId} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const [buying, setBuying] = useState({
        "id": -1,
        "tier": 1,
        "quantity": 0
    })
    const setBuyingItem = (id: number, tier: number) => {
        let b = {...buying}
        b.id = id
        b.tier = tier
        setBuying(b)
    }
    const setBuyingQuantity = (event:any)  => {
        let b = {...buying}
        b.quantity = parseFloat(event.target.value)
        setBuying(b)
    }


//卖房
    const buyHouse = () => {
        api_with_auth("/api/bazaar/houses/new", "POST", {
            "buildingId": parseInt(buildingId ?? "-1"),
            "buying": buying
        })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "已将该建筑于市场出售。",
                        }))
                        navigate(`/game/bazaar/buildings`)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"sell-building-root"}>
        <div className={"list-item-building list-item-building-storage"}>
            <div className={"right side"}>
                <div className={"starter-text"}>需求</div>
                <ItemSelector mr={0.5} pickItem={(id, tier) => setBuyingItem(id, tier)}/>
                <div className={"two-story-text required-item"}>
                    {(buying.id !== -1) ?
                        <TextField size="small" className={"up price"} label={"售价"} type={"number"}
                                   value={buying.quantity}
                                   onChange={(e) => setBuyingQuantity(e)}
                        /> :
                        <div
                            className={"up " + (buying.id !== -1 ? "" : "emphasis")}>
                            {(itemInfo(buying.id)?.Chs ?? "选择物品") + TierToStars[buying.tier]}
                        </div>
                    }
                    <div className={"down"}>
                        {itemInfo(buying.id)?.Chs ? (itemInfo(buying.id)?.Chs + TierToStars[buying.tier]) : ""} - 运输损耗 0%
                    </div>
                </div>
            </div>
        </div>

        <div className={"execute-btn-container"}>
            <Button variant={"contained"} color={"happiness"}
                    disabled={buying.id === -1}
                    onClick={buyHouse}
            >
                {buying.id === -1 ? "售价未设置" : "出售"}</Button>
        </div>
    </div>
}
import "./overview.scss"
import "./BuildingSelection.scss"
import {api_host, api_with_auth} from "../../../utils/api";
import {Map, ExitToApp,} from '@mui/icons-material';
import * as React from "react";
import {
    Avatar,
    List,
    ListItemButton,
    Divider,
    ListItemAvatar,
    ListItemText,
    Button,
    TextField,
    CircularProgress
} from "@mui/material";
import InLineSVGCollection from "../../../components/inLineSVG";
import {useEffect, useState} from "react";
import {getBPItems, getLoginToken, setBPItems, getBPBuildings, getBPSkills} from "../../../utils/storage";
import {Link, useMatch, useNavigate} from "react-router-dom";
import {
    terrainIdToTerrainChs,
    terrainIdToTerrainEng,
    terrainIdToDescription, TierToStars,
} from "../../../components/mappingTable";
import {ViewQuilt, Close, DriveFileRenameOutline} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {fetchStatusNow} from "../../../appSlice";
import {openSnackbar} from "../../../appSnackbarSlice";
import {constructionHint, FindBuildingCurrentArea} from "../../Building/components/WorkCard/WorkCard";
import {
    BPBTierInfo,
    BPBuildingInfo,
    CityTerrainArea,
    CityTotalArea,
    CityTotalAreaTaken,
    Floor1,
    Floor2,
    itemInfo
} from "../../../components/gadgets";
import {fetchMyBuildingsNow} from "../../../myBuildingsSlice";
import {Item} from "../../../components/IconImg";
import {OverviewBuildingsRender} from "./DistrictBuildingsPreview";
import MethodBullet from "../../Building/components/MethodBullet";
import Filler from "../../../components/Filler";

export default function DistrictOverview(props: {}) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
//    玩家名字
    const playerState = useSelector((state: any) => state.state.data)
//    玩家个人仓库
    const playerHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data.storages)
    const itemQuantityInMyHideOut = (r: any) => {
        if (playerHideOutStorage === null) {
            return 0
        } else {
            return playerHideOutStorage.find((e: any) => e.item_type === r.id && e.item_tier === r.tier)?.item_amount ?? 0
        }
    }
    //今天城市数据
    const gameDay = useSelector((state: any) => state.gameDay.data)
    //昨天的城市数据
    const yesterdayGameDay = useSelector((state: any) => state.twoPreviousGameDays.data[0])
    //前天的城市数据
    const beforeYesterdayGameDay = useSelector((state: any) => state.twoPreviousGameDays.data[1])
    //今日城市数据变化量
    const GameDayDataChange = (props: {
        data: "worked" | "lootTimesNumber" | "loginUserNumbers" | "workChances" | "sideJobWorked" | "totalProductionYield" | "totalTrade" | "takenArea" | "newTakenArea"
    }) => {
        const change = Floor1((beforeYesterdayGameDay[props.data] === 0) ? 0 : (((yesterdayGameDay[props.data] - beforeYesterdayGameDay[props.data]) / beforeYesterdayGameDay[props.data]) ?? 0))
        return <div className={"float " + (change < 0 ? "red" : "green")}>{change < 0 ? "" : "+"}{change * 100}%</div>
    }

    //开关蓝图模式
    const match = useMatch("/game/district/overview/blueprint")
    // const [BPMode, setBPMode] = useState(false)
    const BPMode = match !== null
    // const [BPMode, setBPMode] = useState(true)
    //BP建造模式，建筑选择的单一条目，查看的星级
    const [viewStar, setViewStar] = useState(1)
    //BP蓝图模式，已经选择的建筑和星级
    const [selectedB, setSelectedB] = useState([-1, -1])
    const BSelected = !((selectedB[0] === -1) && (selectedB[1] === -1))
    //BP蓝图模式，已经选择的街区id和地形id、地形type
    const [selectedT, setSelectedT] = useState([-1, -1, 1])
    const [selectedD, setSelectedD] = useState("")
    // const selectedDSubstring = selectedD.substring(0,4);
    const selectedTType: number = selectedT[2]
    const TSelected = !((selectedT[0] === -1) && (selectedT[1] === -1))
    //"开始建造"预览条里的方块点亮：种类、街区、地形、开始建造
    const litBuilding = !BSelected
    const litDistrictAndTera = !TSelected
    //是否准备好建造
    const litStart = (!litBuilding && !litDistrictAndTera)
    //建造中（fetching）
    const [starting, setStarting] = useState(false)
    //新建的建筑名字
    const [BPBName, setBPBName] = useState(((playerState.nick_name === "") ? "我" : playerState.nick_name) + "的建筑")

    //snackbar
    const snackbar = (severity: string, message: string) => {
        dispatch(openSnackbar({
            "severity": severity,
            "snackbarMessage": message,
        }))
    }


    //建造
    function createBuilding() {
        setStarting(true)
        api_with_auth("/api/building/create", "POST", {
            type: selectedB[0],
            tier: selectedB[1],
            terrain_id: selectedT[1],
            name: BPBName,
            avatar: "",
        })
            .then(res => {
                setStarting(false)
                if (res.status === "success") {
                    dispatch(fetchMyBuildingsNow())
                    snackbar("success", "建材已消耗，成功奠基了新的建筑。")
                    //    成功后直接跳转新建的建筑
                    navigate("/game/building/" + res.data)

                } else {
                    snackbar("error", (res.message === undefined) ? "创建新建筑失败。" : res.message)
                }
            });

    }


    //总览表默认值,fetch在app里
    const cityStructure = useSelector((state: any) => state.cityStructure.data)


    //浏览模式，单区域的所有街区街区列表
    function Districts(props: {
        //region：城区0/郊区1
        region: number,
    }) {
        const thisRegionDistricts = cityStructure.districts.filter((district: any) => {
            return district.type === props.region
        });
        return thisRegionDistricts.map((district: any, index: any, array: any) =>
            <div className={"district"} key={district.id}>
                <div className={"banner"}>
                    <div className={"left"}>
                        <div className={"dot"}/>
                        <div className={"avatar"}>
                            <Avatar
                                sx={{
                                    width: "5vw",
                                    height: "5vw",
                                    bgcolor: "grey",
                                }}
                                // src={state.data.avatar}
                                variant="rounded"
                                alt="district_avt">{district.name[0]}
                            </Avatar>
                        </div>
                        <div className={"name-column"}>
                            <div className={"location"}>■
                                中华平原&gt;京兆郡&gt;{cityStructure.name}&gt;{props.region ? "郊区" : "城区"}</div>
                            <div className={"name"}>{district.name}</div>
                        </div>
                        {/*街区三属性*/}
                        {/*<div className={"attributes"}>*/}
                        {/*    <div className={"attribute sanitation"}>*/}
                        {/*        <div className={"text"}>卫生</div>*/}
                        {/*        <div className={"progress-block"} style={{width: `2.6vw`}}/>*/}
                        {/*        <div className={"percentage"}>170%</div>*/}
                        {/*    </div>*/}
                        {/*    <div className={"attribute prosperity"}>*/}
                        {/*        <div className={"text"}>繁荣</div>*/}
                        {/*        <div className={"progress-block"} style={{width: `0.15vw`}}/>*/}
                        {/*        <div className={"percentage"}>-50%</div>*/}
                        {/*    </div>*/}
                        {/*    <div className={"attribute industrialisation"}>*/}
                        {/*        <div className={"text"}>工业</div>*/}
                        {/*        <div className={"progress-block"} style={{width: `1vw`}}/>*/}
                        {/*        <div className={"percentage"}>74%</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className={"right"}>
                        <div className={"land-n-tera"}>
                            <div
                                className={"land"}>{
                                ([...district.terrains].reduce((n: any, {area}: any) => n + area, 0) -
                                    [...district.terrains].reduce((n: any, {area_taken}: any) => n + area_taken, 0)).toFixed(0)
                            }/
                                {[...district.terrains].reduce((n: any, {area}: any) => n + area, 0)}
                                可用
                            </div>
                            <div className={"tera"}>
                                {/*这个街区的地形们按area倒序排列*/}
                                {([...district.terrains].sort((a: any, b: any) => b.area - a.area)).map((terrain: any, index: any, array: any) =>
                                    <div key={terrain.id}>
                                        <InLineSVGCollection SVGName={`tera_` + terrainIdToTerrainEng[terrain.type]}
                                                             opacity={"0.7"}/>
                                        {terrain.area}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={"divider"}/>
                        {BPMode ?
                            <div/> :
                            <div className={"flex-row"}>
                                <div className={"visit"}>造访街区</div>
                                <div className={"visit-icon"}><ExitToApp className={"icon"}/></div>
                            </div>
                        }
                    </div>
                </div>
                <List className={"list"}>
                    {/*建筑条目渲染*/}
                    {BPMode ? <OverviewBPTerrainRender terrains={district.terrains} districtName={district.name}
                                                       districtId={district.id}/> :
                        <OverviewBuildingsRender buildings={district.buildings}/>}
                    <Divider variant={"middle"}/>
                    {BPMode ? <div/> :
                        <ListItemButton className={"list-footnote"}>查看更多建筑</ListItemButton>
                    }
                </List>
            </div>
        )
    }


    //BP蓝图模式fetch建造列表，默认值在storage.ts
    const BPBuildings = React.useState(getBPBuildings())[0];

    const filteredBPB = BPBuildings.filter((B: any) => B.id !== 5)
    //物品，拿名字用，导入了
    // const BPItems = getBPItems()
    // function itemInfo (id:number) {
    //   return  BPItems.find((item:any) => item.id === id )
    // }

    //BP蓝图模式，建筑选择，单枚建筑的4个星级的详细信息 map
    function ToTier(BPB: any) {
        return BPB.tiers.find((e: any) => e.tier === viewStar)
    }

    //BP蓝图模式建造建筑列表
    const BuildingSelection = () => {
        return <div className={"building-selection-container"}>
            <div className={"building-selection-title"}>建筑{BPBuildings.length}种</div>
            {/*做成滚动框*/}
            <div className={"building-selection"}>
                {/*单个建筑选择条子*/}
                {filteredBPB.map((BPB: any, index: any) =>
                    <div className={"selection-bullet"} key={BPB.id}>
                        <div className={"l"}>
                            {[4, 3, 2, 1].map((tier: any, index: any) =>
                                // 4,3,2,1 tier
                                <div
                                    className={"title-container" + (viewStar === tier ? "  selected" : "") + (tier === 1 ? " last" : "")}
                                    onClick={() => setViewStar(tier)} key={tier}>
                                    <div className={"title" + (viewStar === tier ? "  selected" : "")}>
                                        <div className={"up"}>
                                            <div className={"name"}>{BPB.name}</div>
                                            <div className={"star"}>
                                                {(tier === 4) ? "★★★" : ((tier === 3) ? "★★" : ((tier === 2) ? "★" : ""))}
                                            </div>
                                            <Close
                                                className={"no-support" + (viewStar === tier ? "  selected" : "") + ((BPB.tiers.find((e: any) => e.tier === tier) === undefined) ? "  x" : "")}/>
                                            <img
                                                src={require("./assets/BPChosen.webp").default}
                                                alt="chosen"
                                                className={"chosen" + (viewStar === tier ? "  selected" : "") + (((selectedB[0] === BPB.id) && (selectedB[1] === tier)) ? " x" : " ")}/>
                                        </div>
                                        <div className="BPArrow"/>
                                        <div className={"down introduction_container"}>
                                            {(BPB.alert === undefined) ? <div/> :
                                                <div className={"text alert"}>{BPB.alert}</div>
                                            }
                                            <div className={"text introduction"}>{BPB.description}</div>
                                            <div className={"text star-explainer"}>{ToTier(BPB)?.description}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {(ToTier(BPB) === null ||
                            ToTier(BPB) === undefined) ?
                            <div className={"m"}>
                                <Close className={"x_Icon"}/>
                                <div className={"text_big_center"}>该建筑不提供这个星级的版本</div>
                            </div>
                            :
                            <div className={"m"}>
                                <div className={"info text"}>
                                    面积：<b>{ToTier(BPB).designed_area}</b>
                                    ，工位：<b>{ToTier(BPB).worker_slots}</b>
                                    ，生产方案<b>{BPBTierInfo({type: BPB.id, tier: viewStar})?.working_method.length}</b>种如下：
                                </div>
                                <div className={"methods"}>
                                    {BPBTierInfo({type: BPB.id, tier: viewStar})?.working_method.length ?
                                        BPBTierInfo({type: BPB.id, tier: viewStar})?.working_method
                                            .map((method: any, index: number) =>
                                                <MethodBullet method={method} n={index + 1} noTitle key={index}/>
                                            ) :
                                        <Filler height={3} title={"不进行生产活动"}/>
                                    }
                                </div>
                            </div>
                        }
                        {(BPB.tiers.find((e: any) => e.tier === viewStar) === null ||
                            BPB.tiers.find((e: any) => e.tier === viewStar) === undefined) ?
                            <div className={"r"}/>
                            :
                            <div className={"r"}>
                                <div className={"text into"}>需从藏身处支付{ToTier(BPB).cost.resources.length}种建材：</div>
                                <div className={"required-list"}>
                                    {ToTier(BPB).cost.resources.map((resource: any, index: any) =>
                                        <div className={"required-bullet"} key={resource.id}>
                                            <Item id={resource.id} t={resource.tier} mr={0.2}/>
                                            <div className={"amount-and-name"}>
                                                <div
                                                    className={"text amount"}>{Math.floor(itemQuantityInMyHideOut(resource) * 10) / 10}/{resource.quantity}</div>
                                                <div
                                                    className={"text name"}>{itemInfo(resource.id)?.Chs + TierToStars[resource.tier]}</div>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>
                                <div
                                    className={"text to-finish"}>开垦需要 <b>{ToTier(BPB).cost.reclamation}</b> 产能，<br/>建造需要 <b>{ToTier(BPB).cost.building}</b> 产能。
                                </div>
                                <div
                                    className={((selectedB[0] === BPB.id) && (selectedB[1] === viewStar)) ? "submit selected" : "submit"}
                                    onClick={() => {
                                        if ((selectedB[0] === BPB.id) && (selectedB[1] === viewStar)) {
                                            setSelectedB([-1, -1])
                                        } else {
                                            setSelectedB([BPB.id, viewStar])
                                        }
                                    }}
                                >√
                                </div>
                            </div>
                        }
                    </div>
                )}
            </div>
        </div>
    }

    //BP蓝图模式，单个街区里的地形列表
    function OverviewBPTerrainRender(props: {
        terrains: [],
        districtName: string,
        districtId: number,
    }) {
        return <div className={"overview-buildings-render"}>
            <ListItemText
                className={"list-item text"}
            >
                {BSelected ? (`已选择的建筑：${BPBuildingInfo(selectedB[0])?.name + TierToStars[selectedB[1]]}。其受到该街区 产业 属性影响，工作产能变化+0%=(0%*100%) 。占地面积${getBPBuildings().find((e: any) => e.id === selectedB[0]).tiers.find((e: any) => e.tier === selectedB[1]).designed_area}。开垦需要产能=(${getBPBuildings().find((e: any) => e.id === selectedB[0]).tiers.find((e: any) => e.tier === selectedB[1]).cost.reclamation}基础值*建造难度)。`
                ) : "先选择一个想要建造的建筑，以预览它的街区影响和地形适应性。"}
            </ListItemText>
            {props.terrains.map((terrain: any, index: any, array: any) =>
                <ListItemButton key={"districtOverviewBP_" + terrain.id} disableRipple
                                alignItems="flex-start"
                                onClick={() => {
                                }}
                                className={"list-item-building list-item"}
                >
                    <div className={"form-maker"}>{(array.length - 1 === index) ? "└" : "├"}</div>
                    <InLineSVGCollection SVGName={`tera_` + terrainIdToTerrainEng[terrain.type]} size={2}/>
                    <div className={"two-story-text"}>
                        <div className={"up"}>
                            {props.districtName}的{terrainIdToTerrainChs[terrain.type]}
                        </div>
                        <div className={"down"}>
                            {terrainIdToDescription[terrain.type]}
                        </div>
                    </div>
                    <div className={"two-story-text margin-left-auto right"}>
                        <div className={"up"}>
                            {(terrain.area - terrain.area_taken).toFixed(2)}/{terrain.area.toFixed(2)}
                        </div>
                        <div className={"down"}>
                            可用面积
                        </div>
                    </div>
                    <div className={"two-story-text right five-vw"}>
                        <div className={"up"}>
                            {(terrain.area / (terrain.area - terrain.area_taken) * 100).toFixed(0)}%
                        </div>
                        <div className={"down"}>
                            建造难度
                        </div>
                    </div>
                    <div className={"two-story-text right five-vw"}>
                        <div className={"up"}>
                            {/*选定建筑的地形适应性*/}
                            {BSelected ? ((BPBuildings.find((e: any) => e.id === selectedB[0]).tiers.find((e: any) => e.tier === selectedB[1]).terrain_adaptability[terrain.type] * 100).toFixed(0) + "%")
                                : "N/A"
                            }
                        </div>
                        <div className={"down"}>
                            地形适应性
                        </div>
                    </div>
                    <div className={(selectedT[1] === terrain.id) ? "submit terra selected" : "submit terra "}
                         onClick={() => {
                             if (selectedT[1] === terrain.id) {
                                 setSelectedT([-1, -1, 1])
                                 setSelectedD("")
                             } else {
                                 setSelectedT([props.districtId, terrain.id, terrain.type])
                                 setSelectedD(props.districtName)
                             }
                         }}
                    >√
                    </div>
                </ListItemButton>)
            }</div>
    }

    //正文
    return <div className={"district-overview" + (BPMode ? " BPMode" : "")}>
        {/*概括介绍*/}
        <div className={"sum" + (BPMode ? " BPMode" : "")}>
            {BPMode ? "选择建筑种类，然后选择建设低值，来放置一个有待建设的建筑物地基" : "长安是一个 干燥 的 沿河 内陆 东方城市，开创于D1。主流文化: 关中 土地面积:" + Floor1(CityTotalArea(cityStructure))}
        </div>
        {/*banner重要数据一句话*/}
        <div
            className={"big-numbers" + (BPMode ? " BPMode" : "")}>{BPMode ? "建造模式" : `${cityStructure.districts.length}街区，${Floor1(CityTotalArea(cityStructure) - CityTotalAreaTaken(cityStructure))}可用土地`}</div>
        {/*在不在蓝图模式的两种分布*/}
        {/*建造预览条*/}
        {BPMode ? <div className={"build-preview-bar"}>
                {/*左*/}
                <div className={"side"}>
                    <Avatar alt="ReadyToBeBuilt" src="/static/images/avatar/1.jpg"
                            sx={{width: "2.5vw", height: "2.5vw", opacity: "0.7",}}
                            variant="rounded"
                            className={"avt"}
                    />
                    <div className={"two-story-text left"}>
                        <div className={"up"}>
                            <DriveFileRenameOutline style={{display: "inline-block"}}/>
                            <TextField id="BPBName"
                                       className={"BPBName"}
                                       variant="outlined"
                                       value={BPBName}
                                       onChange={(event) => {
                                           setBPBName(event.target.value);
                                       }}
                            />

                        </div>
                        <div className={"down"}>
                            建筑-计划阶段
                        </div>
                    </div>
                </div>
                {/*右*/}
                <div className={"side"}>
                    <div className={"two-story-text" + (litBuilding ? " lit" : "")}>
                        <div className={"up"}>
                            {litBuilding ? "选择建筑" : BPBuildingInfo(selectedB[0])?.name}
                        </div>
                        <div className={"down"}>
                            {litBuilding ? "建筑种类" : (TierToStars[selectedB[1]] + "建筑已选择")}
                        </div>
                    </div>
                    <div
                        className={"two-story-text" + (litDistrictAndTera ? " lit" : "")}>
                        <div className={"up"}>
                            {litDistrictAndTera ? "选择街区" : selectedD}
                        </div>
                        <div className={"down"}>
                            {litDistrictAndTera ? "所在街区" : "街区已选择"}
                        </div>
                    </div>
                    <div
                        className={"two-story-text" + (litDistrictAndTera ? " lit" : "")}>
                        <div className={"up"}>
                            {litDistrictAndTera ? "选择地形" : (<div><InLineSVGCollection
                                SVGName={`tera_` + terrainIdToTerrainEng[selectedTType]}/> {terrainIdToTerrainChs[selectedTType]}
                            </div>)}
                        </div>
                        <div className={"down"}>
                            {litDistrictAndTera ? "所在地形" : "地形已选择"}
                        </div>
                    </div>
                    <div
                        className={"start-building" + (litStart ? " lit" : "")}
                        onClick={() => {
                            if (litStart && (!starting)) {
                                createBuilding()
                            } else if (!starting) {
                                snackbar("info", "选择建筑、街区和地形才能开始建造。")
                            }


                        }}
                    >
                        {starting ? <CircularProgress className={"CircularProgress"} size="1.5vw"
                                                      style={{'color': '#064562'}}/> : "开始建造！"}
                        {/*开始建造！*/}
                    </div>
                </div>
            </div>
            :
            //不在建造模式情况下
            <div>
                {/*日更股市数值*/}
                <div className={"daily-data-line"}>
                    <div className={"daily-data-block"}>
                        <GameDayDataChange data={"worked"}/>
                        <div className={"number"}>{Floor1(yesterdayGameDay.worked)}</div>
                        <div className={"title"}>工作人口</div>
                    </div>
                    <div className={"daily-data-block"}>
                        <GameDayDataChange data={"lootTimesNumber"}/>
                        <div className={"number"}>{Floor1(yesterdayGameDay.lootTimesNumber)}</div>
                        <div className={"title"}>副业劳工</div>
                    </div>
                    <div className={"daily-data-block"}>
                        <GameDayDataChange data={"totalProductionYield"}/>
                        <div className={"number"}>{Floor1(yesterdayGameDay.totalProductionYield)}</div>
                        <div className={"title"}>总产能</div>
                    </div>
                    <div className={"daily-data-block"}>
                        <GameDayDataChange data={"loginUserNumbers"}/>
                        <div className={"number"}>{Floor1(yesterdayGameDay.loginUserNumbers)}</div>
                        <div className={"title"}>活跃人口</div>
                    </div>
                    <div className={"daily-data-block"}>
                        <GameDayDataChange data={"workChances"}/>
                        <div className={"number"}>{Floor1(yesterdayGameDay.workChances)}</div>
                        <div className={"title"}>工作机会</div>
                    </div>
                    <div className={"daily-data-block"}>
                        <GameDayDataChange data={"worked"}/>
                        <div className={"number"}>{Floor1(yesterdayGameDay.worked)}</div>
                        <div className={"title"}>新垦土地</div>
                    </div>
                </div>
                {/*地形分布*/}
                <div className={"tera-sum"}>地形分布&nbsp;&nbsp;
                    <InLineSVGCollection SVGName={"tera_plain"}
                                         opacity={"0.7"}/>{CityTerrainArea(cityStructure, 0)}&nbsp;
                    <InLineSVGCollection SVGName={"tera_forest"}
                                         opacity={"0.7"}/>{CityTerrainArea(cityStructure, 1)}&nbsp;
                    <InLineSVGCollection SVGName={"tera_hill"}
                                         opacity={"0.7"}/>{CityTerrainArea(cityStructure, 2)}&nbsp;
                    <InLineSVGCollection SVGName={"tera_mountain"}
                                         opacity={"0.7"}/>{CityTerrainArea(cityStructure, 3)}&nbsp;
                    <InLineSVGCollection SVGName={"tera_water"}
                                         opacity={"0.7"}/>{CityTerrainArea(cityStructure, 4)}&nbsp;
                    <InLineSVGCollection SVGName={"tera_sea"}
                                         opacity={"0.7"}/>{CityTerrainArea(cityStructure, 5)}&nbsp;
                </div>
                {/*人口省籍*/}
                <div className={"citizen-sum"}>人口 *&nbsp;&nbsp;省籍 *</div>
            </div>
        }
        {BPMode ? BuildingSelection() : <div/>}

        <div className={"blueprint-mode " + (BPMode ? "BPMode" : "")}
             onClick={() => {
                 BPMode ? navigate("/game/district/overview/") : navigate("/game/district/overview/blueprint")
                 setViewStar(1)
             }}
        ><Map className={"blueprint-icon"}/>
        </div>
        {/*</div>*/}
        {/*城区建筑标题*/}
        <div className={"location urban"}>
            <div className={"city-districts-banner"}>
                <div className={"sub-title"}>{BPMode ? "城区位置" : "城区建筑"}</div>
                <img src={require('./assets/CS_illu_east_urban.webp').default} alt={""}/>
            </div>
            {/*渲染城区街区*/}
            <Districts region={0}/>
        </div>

        {/*郊区建筑标题*/}
        <div className={"location urban"}>
            <div className={"city-districts-banner"}>
                <div className={"sub-title"}>{BPMode ? "郊区位置" : "郊区建筑"}</div>
                <img src={require('./assets/CS_illu_east_suburb.webp').default} alt={""}/>
            </div>
            {/*渲染郊区街区*/}
            <Districts region={1}/>
        </div>
    </div>
}

import "./settings.scss"
import React, {useState} from "react";
import {Divider, ListItemButton} from "@mui/material";
import {Navigate, Route, Routes, useMatch, useNavigate, useParams} from "react-router-dom";
import Forum from "../Forum";
import AddPhotoAvt from "../components/AddPhotoAvt";
import {useDispatch, useSelector} from "react-redux";
import store_capsule_small from "./assets/store_capsule_small.png"
import library_logo_transparent2 from "./assets/library_logo_transparent2.png"
import {fetchStatusNow} from "../appSlice";
import {fetchStateNow} from "../appStateSlice";
import AdminSettings from "./AdminSettings";

export default function GameSettings() {
    const dispatch = useDispatch()
    const state = useSelector((state: any) => state.state.data)
    const navigate = useNavigate()
    const tabMap = {
        "character": "角色设置",
        "system": "系统设置",
        "admin": "管理员设置",
        "credits": "制作人员表",
    }

    let UrlPage = "character"
    if (useMatch("/game/settings/character/*")) {
        UrlPage = "character"
    }
    if (useMatch("/game/settings/system/*")) {
        UrlPage = "system"
    }
    if (useMatch("/game/settings/admin/*")) {
        UrlPage = "admin"
    }
    if (useMatch("/game/settings/credits/*")) {
        UrlPage = "credits"
    }

    const TabButton = (props: {
        tab: "character" | "system" | "admin" | "credits"
    }) => {
        return <ListItemButton className="setting-tab"
                               onClick={() => navigate(`/game/settings/` + props.tab, {replace: true})}
                               style={{borderColor: UrlPage === props.tab ? "#ed6c02" : "white"}}
        >
            {tabMap[props.tab]}
        </ListItemButton>
    }
    const Character = () => {
        return <div className="right-content">
            <ListItemButton className="content-bullet" disableRipple>
                <div className="set-ttl">修改角色头像</div>
                <div className="set-content">
                    <div className="pfp-avt">
                        <div style={{width: "5vw"}}/>
                        <AddPhotoAvt type={"player"} src={state.avatar} size={5} onClick={() => {
                        }} onSuccess={() => dispatch(fetchStateNow())} letter={state.nick_name[0]}/>
                        <div className="r">
                            <div>
                                {state.nick_name}
                            </div>
                            <div>
                                点击左侧按钮修改角色形象
                            </div>
                        </div>
                        <div className="stick" style={{left: "-1vw", top: "1vw"}}/>
                        <div className="stick" style={{left: "-3vw", top: "3vw"}}/>
                        <div className="stick" style={{left: "-2vw", top: "5vw"}}/>
                        <div className="stick" style={{right: "0.5vw", top: "0.5vw", width: "2.5vw"}}/>
                    </div>
                </div>
            </ListItemButton>
        </div>
    }
    const System = () => {
        return <div className="right-content">
            <ListItemButton className="content-bullet" disableRipple>
                <div className="set-ttl">暂无系统设置可用</div>
                <div className="set-content">

                </div>
            </ListItemButton>
        </div>
    }
    const Credits = () => {
        return <div className="right-content">
            <ListItemButton className="content-bullet" disableRipple>
                <div className="credits">
                    <img src={library_logo_transparent2} alt={"CIVITAS:RISE"} className={"store_img"}/>
                    <br/><br/><br/>
                    <div className="block">
                        <div className="l">主程序、架构</div>
                        <div className="r">气氛组</div>
                    </div>
                    <div className="block">
                        <div className="l">美术、前端、游戏设计</div>
                        <div className="r">达芬奇</div>
                    </div>
                    <div className="block">
                        <div className="l">后端协力</div>
                        <div className="r">拨雾者<br/>魔笛</div>
                    </div>
                    <div className="block">
                        <div className="l">美术协力</div>
                        <div className="r">YiLan小仙女</div>
                    </div>
                    <br/>
                    <div className="block">
                        <div className="l">玩家赞助致谢</div>
                        <div className="r">egg<br/> BlackWhite<br/> 黎夜蕾<br/> 萌新四<br/> 猫心虎<br/> Op<br/> 小生L公子</div>
                    </div>
                    <br/><br/><br/>
                    <div className="ttl">特别鸣谢</div>
                    <div className="block">
                        <div className="l">数值设计 (星火奠基版本)</div>
                        <div className="r">招哥</div>
                    </div>
                    <div className="block">
                        <div className="l">历史顾问</div>
                        <div className="r">斗鸡走狗刘病己</div>
                    </div>
                    <br/><br/><br/>
                    <div className="ttl">铭记四条眉毛仙人</div>


                </div>
            </ListItemButton>
        </div>
    }

    return <div className="settings-root">
        <div className={"left-container"}>
            <div className={"title"}>
                设置
            </div>
            <Divider className={"title-divider"} variant={"middle"}/>
            <div className="setting-tabs">
                {["character", "system", "admin", "credits"].map((tab, index) =>
                    <TabButton tab={tab as keyof {
                        "character": "角色设置",
                        "system": "系统设置",
                        "admin": "管理员设置",
                        "credits": "制作人员表",
                    }} key={index}/>
                )}
            </div>
        </div>
        <Divider variant={"middle"} orientation={"vertical"} flexItem/>
        <div className={"right-container"}>
            <div className={"title"}>
                {tabMap[UrlPage as keyof {
                    "character": "角色设置",
                    "system": "系统设置",
                    "admin": "管理员设置",
                    "credits": "制作人员表",
                }]}
            </div>
            <Divider className={"title-divider"} variant={"middle"}/>
            <Routes>
                <Route index element={<Navigate to={`/game/settings/character`} replace/>}/>
                <Route path='/character/*' element={<Character/>}/>
                <Route path='/system/*' element={<System/>}/>
                <Route path='/admin/*' element={<AdminSettings/>}/>
                <Route path='/credits/*' element={<Credits/>}/>
            </Routes>
        </div>
    </div>
}
import "../../components/Styles/BazaarAndDistrictFilter/BADFilterStyle.scss"
import "./BazaarBuildings.scss"
import {Navigate, Route, Routes, useMatch, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button, Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {
    BalanceRounded,
    Edit,
    FormatListBulleted,
    History,
    SellOutlined,
    ShoppingBasketOutlined
} from "@mui/icons-material";
import ItemSelector from "../../components/ItemSelector";
import BazaarItemsView from "../BazaarItems/View";
import BazaarItemsEdit from "../BazaarItems/Edit";
import BazaarItemsHistory from "../BazaarItems/History";
import BazaarItemsAgents from "../BazaarItems/Agents";
import * as React from "react";
import BazaarBuildingsView from "./View";
import BazaarBuildingsEdit from "./Edit";
import {api_with_auth} from "../../utils/api";
import {openSnackbar} from "../../appSnackbarSlice";

export default function BazaarBuildings(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

    //页面切换：浏览市场"" 编辑我的订单edit 浏览订单历史history 浏览本城市所有中介agents
    let UrlPage = "view"
    if (useMatch("/game/bazaar/buildings/view/*")) {
        UrlPage = "view"
    }
    if (useMatch("/game/bazaar/buildings/edit/*")) {
        UrlPage = "edit"
    }
    if (useMatch("/game/bazaar/buildings/history/*")) {
        UrlPage = "history"
    }
    if (useMatch("/game/bazaar/buildings/agents/*")) {
        UrlPage = "agents"
    }


    const [page, setPage] = useState(UrlPage)
    const updatePage = (page: "view" | "edit") => {
        setPage(page)
        navigate(`/game/bazaar/buildings/` + page)
    }

//fetch我的出售
    const [bazaarBuildingsEdit, setBazaarBuildingsEdit] = useState<any>({
        "page": 0,
        "pageSize": 20,
        "totalPages": 20,
        "totalResults": 300,
        "content": [
            {
                //建筑出售id
                "id": 1,
                //卖方玩家
                "seller": {
                    "uid": 1,
                    "nick_name": "寻找卖家",
                    "avatar": "",
                    //卖家本城市藏身处的位置,要读取时候实时的，不是建筑出售创建瞬间的状态
                    "belong_city": 0,
                    "belong_districts": 2,
                    "belong_terrain": 7,
                },
                //出售的建筑物，建筑物状态要读取时候实时的，不是建筑出售创建瞬间的状态
                "selling": {
                    //建筑id
                    "id": 1,
                    //建筑名字
                    "name": "获取订单中",
                    //建筑头像
                    "avatar": "",
                    //种类
                    "type": 2,
                    //星级
                    "tier": 1,
                    //所在城市
                    "belong_city": 0,
                    //所在街区
                    "belong_districts": 0,
                    //所在地形
                    "belong_terrain": 0,
                    //建筑阶段
                    "stage": 2,
                    //维护度
                    "percent": 100,
                },
                //交换的货物
                "buying": {
                    "id": 1,
                    "tier": 1,
                    "quantity": 1,
                },
                //建筑出售创建时间戳
                "create_time": 1,
                //建筑出售出售最后编辑时间戳
                "edit_time": 1,
                //建筑出售截止日期。使用游戏日期game_day
                "available_until": 1,
                //建筑出售状态。0进行中1已完售2已取消3已超时
                "status": 0,
            },
        ]
    })
    const buildingEditNumber = bazaarBuildingsEdit.content?.length || 0
    const fetchMySell = () => {
        api_with_auth("/api/bazaar/houses/my", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                    if (res.status === "success") {
                        setBazaarBuildingsEdit(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }
    useEffect(() => {
        fetchMySell()
    }, [])

    return <div className={"bazaar-buildings-root"}>

        {/*商品过滤器*/}
        <div className={"filters-container"}>
            {/*<div className={"filters"}>*/}
            {/*    <FormControl>*/}
            {/*        <InputLabel>订单位置</InputLabel>*/}
            {/*        <Select label="订单位置" value={""} onChange={(e) => {*/}
            {/*        }}>*/}
            {/*            /!*{BPItems.map((item: any, index: any) =>*!/*/}
            {/*            /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
            {/*            /!*)}*!/*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*    <FormControl>*/}
            {/*        <InputLabel>建筑状态</InputLabel>*/}
            {/*        <Select label="建筑状态" value={""} onChange={(e) => {*/}
            {/*        }}>*/}
            {/*            <MenuItem value={2} key={2}>正常运作</MenuItem>*/}
            {/*            <MenuItem value={0} key={0}>开垦中</MenuItem>*/}
            {/*            <MenuItem value={1} key={1}>建造中</MenuItem>*/}
            {/*            <MenuItem value={3} key={3}>已损坏</MenuItem>*/}
            {/*            /!*{BPItems.map((item: any, index: any) =>*!/*/}
            {/*            /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
            {/*            /!*)}*!/*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*    <SellOutlined/>*/}
            {/*    <ItemSelector pickItem={(id, tier) => {*/}
            {/*    }} title={"出售建筑"} ml={0.3} mr={1}/>*/}
            {/*    <ShoppingBasketOutlined/>*/}
            {/*    <ItemSelector pickItem={(id, tier) => {*/}
            {/*    }} title={"所需货物"} ml={0.3} mr={1}/>*/}
            {/*</div>*/}


            <Button variant={page === "view" ? "contained" : "text"} color={"happiness"}
                    style={{
                        margin: "0.5vw 0.5vw 0.5vw auto",
                    }}
                    onClick={() => updatePage("view")
                    }>
                <FormatListBulleted/>
            </Button>
            <Divider className={"middle-divider"} variant={"middle"} orientation={"vertical"} flexItem/>
            <Button variant={page === "edit" ? "contained" : "text"} color={"happiness"}
                    onClick={() => updatePage("edit")}>
                <Edit/>{buildingEditNumber}
            </Button>
        </div>
        <div className={"sort-container"}>
            {{
                "view": "城市建筑市场",
                "edit": "编辑我的订单",
            }[UrlPage]}
        </div>
        <Divider variant="middle"
                 className="middle-divider"
        />
        {/*商品列表*/}
        <div className={"goods-list-container"}>
            <Routes>
                <Route index element={<Navigate
                    to={`/game/bazaar/buildings/view`} replace/>}/>
                <Route path={`/view/*`} element={<BazaarBuildingsView/>}/>
                <Route path={`/edit/*`} element={<BazaarBuildingsEdit/>}/>
            </Routes>
        </div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
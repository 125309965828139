import "./Recipe.scss"
import * as React from "react";
import {Divider, ListItemButton, Switch, Tooltip} from "@mui/material";
import {Floor1, Floor2, skillInfo} from "../../../components/gadgets";
import {Item} from "../../../components/IconImg";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {api_with_auth} from "../../../utils/api";
import {fetchEmployeesNow} from "../Work/workSlice";
import {fetchStatusNow} from "../../../appSlice";
import {openSnackbar} from "../../../appSnackbarSlice";
import {useState} from "react";
import {fetchBuildingSummaryNow} from "../buildingSlice";
import Filler from "../../../components/Filler";
import {InfoOutlined} from "@mui/icons-material";

export const foodTypeImg = (n: number) => {
    return ["cake", "meat", "soup", "wine"][n % 4]
}

//食谱渲染的独立组件
export const RecipeRender = (props: { recipe: any, hideMaterials?: boolean }) => {
    const status = useSelector((state: any) => state.status.data)
    //奢侈度差值奖励
    const luxuryGap = () => {
        const g = props.recipe.luxury - status.luxury
        if (g > 30) {
            return "veryHigh"
        } else if (g > 10) {
            return "high"
        } else if (g > -10) {
            return "normal"
        } else if (g > -30) {
            return "low"
        } else {
            return "veryLow"
        }
    }
    const fontColor = () => {
        const c = {
            "veryHigh": "#73b93c",
            "high": "#53852b",
            "normal": "black",
            "low": "#982f37",
            "veryLow": "#e74652",
        }
        return c[luxuryGap()]
    }
    const arrow = () => {
        return {
            "veryHigh": "↑↑↑",
            "high": "↑",
            "normal": "≈",
            "low": "↓",
            "veryLow": "↓↓↓",
        }[luxuryGap()]
    }
    const description = () => {
        return {
            "veryHigh": "享用高贵的珍馐美味你感到无比幸福，食谱快乐、精力、健康回复+80%（食谱奢侈度高于玩家奢侈度30）",
            "high": "高品味食物使你心情愉悦，食谱快乐、精力、健康回复+25%（食谱奢侈度高于玩家奢侈度10）",
            "normal": "食谱奢侈度在玩家奢侈度+-10之间，无奖励/惩罚",
            "low": "食物格调太低你感到难以下咽，食谱快乐、精力健康回复-25%（食谱奢侈低于玩家奢侈度10）",
            "veryLow": "如此低贱的食物在你看来和泔水无异，食谱快乐、精力健康回复-80%（食谱奢侈低于玩家奢侈度30）",
        }[luxuryGap()]
    }
    const highLux = (((props.recipe.luxury - status.luxury) > -10) && status.luxury > 65)

    const highLuxIcon = () => {
        if (highLux) {
            return "✦"
        } else {
            return ""
        }
    }
    const highLuxDescription = () => {
        if (highLux) {
            return "作为生活奢靡之人享用和身份相衬的食物，食谱快乐、精力、健康回复+15%（玩家奢侈度大于65，食谱奢侈度大于玩家奢侈度-10）"
        } else {
            return ""
        }
    }
    const recipe = props.recipe
    return <div className={"recipe-bullet"}>
        <div className={"recipe-bullet-root"}>
            <img src={process.env.PUBLIC_URL + "/img/foodType/" + foodTypeImg(recipe.id) + ".webp"}
                 className={"food-icon"}
                 alt={"foodIcon"}/>
            <div className={"recipe-bullet-content"}>
                <div className={"title"}>{recipe.name || "  "}</div>
                <div className={"materials"}>配料：好吃的。烹饪方式：高级技术。</div>
                <div className={"status"}>
                    <div className={"double-text"}>
                        <div className={""} style={{color: fontColor()}}>{Floor2(recipe.energy)}</div>
                        <div className={""}>精力变化</div>
                    </div>
                    <div className={"double-text"}>
                        <div className={""} style={{color: fontColor()}}>{Floor2(recipe.happiness)}</div>
                        <div className={""}>快乐变化</div>
                    </div>
                    <div className={"double-text"}>
                        <div className={""} style={{color: fontColor()}}>{Floor2(recipe.health)}</div>
                        <div className={""}>健康变化</div>
                    </div>
                    <div className={"double-text"}>
                        <div className={""} style={{color: "black"}}>{Floor2(recipe.hunger)}</div>
                        <div className={""}>饥饿变化</div>
                    </div>
                    <Tooltip title={
                        <div>
                            <div>{description()}</div>
                            <br/>
                            <div>{highLuxDescription()}</div>
                            <br/>
                            <div>左侧显示了变动前的基础回复值。</div>
                        </div>
                    } arrow disableInteractive>
                        <div className={"double-text"}>
                            {/*↑↓≈*/}
                            <div className={"luxury"}>{Floor2(recipe.luxury)}{arrow()}{highLuxIcon()}</div>
                            <div className={""}>食谱奢侈度<InfoOutlined sx={{fontSize: "0.7vw"}}/></div>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
        {props.hideMaterials?null:
        <div>
            <div className={"cost"}>食材花费：
                {recipe.materials?.map((m: any, index: number) =>
                        <span key={m.id}>
                   {index ? "+" : ""}<Item id={m.id} t={m.tier} size={1}/><b>{m.quantity}</b>
                </span>
                )}
            </div>
            <div className={"cost"}>燃料花费：<Item id={recipe.fuel?.id ?? recipe.fuelId}
                                               t={recipe.fuel?.tier ?? recipe.fuelTier}
                                               size={1}/>
                <b>{recipe.fuel?.quantity ?? recipe.fuelQuantity}</b></div>
            <div className={"cost"}>烹饪花费：<Item id={4} t={recipe.cooking?.tier ?? recipe.cookingTier}
                                               size={1}/><b>{Floor2(recipe.cooking?.quantity ?? recipe.cooking_quantity)}</b>
                ，或者更高星级。
            </div>
        </div>
        }
    </div>
}

export default function Recipe() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const state = useSelector((state: any) => state.state.data)
    const status = useSelector((state: any) => state.status.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const {buildingId} = useParams();
    const bId = parseInt(buildingId ?? "-1")
    //建筑是我管理/拥有
    const IGovern = ((state.uid === buildingSummary.owner.uid) || (state.uid === buildingSummary.manager.uid));

    //食谱公开的相关
    const [recipesPublic, setRecipesPublic] = useState(buildingSummary.recipe?.public)
    const [disabled, setDisabled] = useState(false)
    //切换公开
    const handleToggle = () => {
        Toggle(!recipesPublic)
        setRecipesPublic(!recipesPublic)
        setDisabled(true)
    }

    const Toggle = (RPublic: boolean) => {
        api_with_auth("/api/recipe/building/" + buildingId + "/set_public", "POST", {
            "public": RPublic
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": (RPublic ? "公开了该建筑的食谱。" : "隐藏了该建筑的食谱。") + (res.message ? res.message : ""),
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                    setRecipesPublic(!recipesPublic)
                }
                dispatch(fetchBuildingSummaryNow())
                setDisabled(false)
            },);
    }

    const recipes = buildingSummary.recipe?.recipes
    //新建
    const [newRecipeWaiting, setNewRecipeWaiting] = useState(false)
    const newRecipe = () => {
        setNewRecipeWaiting(true)
        api_with_auth("/api/recipe/new", "POST", {
            "buildingId": bId
        })
            .then(res => {
                setNewRecipeWaiting(false)
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "拟撰了一个新食谱",
                    }))
                    navigate(`/game/building/` + buildingId + "/recipe/edit/" + res.data)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }
    //删除
    const [deleteRecipeWaiting, setDeleteRecipeWaiting] = useState(-1)
    const deleteRecipe = (recipeId: number) => {
        setDeleteRecipeWaiting(recipeId)
        api_with_auth("/api/recipe/" + recipeId + "/delete", "POST")
            .then(res => {
                setDeleteRecipeWaiting(-1)
                if (res.status === "success") {
                    dispatch(fetchBuildingSummaryNow())
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "删除了食谱",
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }

    //编辑，编辑直接跳转url
    const editRecipe = (recipeId: number) => {
        navigate(`/game/building/` + buildingId + "/recipe/edit/" + recipeId)
    }
    //用餐
    const [eatWaiting, setEatWaiting] = useState(-1)
    const eat = (recipeId: number) => {
        setEatWaiting(recipeId)
        api_with_auth("/api/recipe/" + recipeId + "/eat", "POST")
            .then(res => {
                dispatch(fetchStatusNow())
                setEatWaiting(-1)
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "消耗食材后享用了菜肴，属性恢复了",
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }

    return <div className={"recipe-root"}>
        <div className={"recipe-title"}>食谱
            <div className="my-luxury">
                <div className="t">{Floor1(status.luxury)}</div>
                <div className="d">我的奢侈度</div>
            </div>
            <div className="recipe-public">{recipesPublic ? "食谱公开中" : "食谱未公开"}</div>
            {IGovern ?
                <Switch checked={recipesPublic}
                        onChange={handleToggle}
                        disabled={disabled}
                /> : null
            }
            <div className={"white-button button8"}
                 onClick={() => newRecipe()}>{newRecipeWaiting ? "新建中…" : "新建食谱"}</div>
        </div>
        <div className={"recipe-content"}>
            {recipes?.map((recipe: any, index: number) =>
                <div>
                    <RecipeRender recipe={recipe} key={recipe.id}/>
                    <div className={"actions"}>
                        <div className={""}
                             onClick={() => deleteRecipe(recipe.id)}>{deleteRecipeWaiting === recipe.id ? "删除中…" : "删除食谱"}</div>
                        <div className={""} onClick={() => editRecipe(recipe.id)}>编辑食谱</div>
                        <div className={"orange-button button8"}
                             onClick={() => eat(recipe.id)}>{eatWaiting === recipe.id ? "制作菜肴…" : "用餐"}</div>
                    </div>
                    <Divider variant={"middle"}/>
                </div>
            )}
            <Filler/>
        </div>
    </div>
}
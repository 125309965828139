import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'buildingSummary',
    initialState: {
        "data": {
            "id": -1,
            "owner": {
                "uid": 4,
                "nick_name": '加载中',
                "avatar": '',
                "born_city": 0
            },
            "owner_id": 4,
            "manager": {
                "uid": 4,
                "nick_name": '加载中',
                "avatar": '',
                "born_city": 0
            },
            "manager_id": 4,
            "name": '加载中',
            "avatar": '',
            "type": 2,
            "tier": 1,
            "max_work_count": 200,
            "worker_count": 16,
            "is_hiring": true,
            'salary_number': 0,
            "salary_type": 1,
            "salary_tier": 1,
            "storage_capacity": 107.8,
            "max_storage_capacity": 40000,
            "belong_city": 0,
            "belong_districts": 1,
            "belong_terrain": 1,
            "culture": 0,
            "stage": 2,
            "percent": 100,
            "product_type": 2,
            "in_day_product_type": 0,
            "working_method": 0,
            "in_day_working_method": -1,
            "architect": {
                "uid":0,
                "nick_name":"",
                "avatar":"",
                "born_city":0,
            },
            "remarks":"",
        },
        "fetchNow": 0,
    },
    reducers: {
        updateBuildingSummaryWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchBuildingSummaryNow: (state) => {
            state.fetchNow += 1;
        },

    },
});
export const {updateBuildingSummaryWPayload, fetchBuildingSummaryNow} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import "./filler.scss"

export default function Filler(props: {
    title?: string,
    height?: number,
}) {
    const height = props.height || 10
    return <div className={"filler-root"} style={{height: `${height}vw`, minHeight: `${height}vw`}}>
        {props.title}
    </div>
}
export function saveLoginToken(token: string) {
    localStorage.setItem("login_token", token)
}

export function getLoginToken() {
    return localStorage.getItem("login_token")
}


//所有建筑的数值设计
export function setBPBuildings(Buildings: string) {
    localStorage.setItem("bPBuildings", JSON.stringify(Buildings))
}

export function getBPBuildings() {
    return JSON.parse(localStorage.getItem("bPBuildings")
        ||
        JSON.stringify([
            {
                "id": 1,
                "name": "果园",
                "Eng": "orchard",
                "alert": "",
                "description": "种植果树和藤架作物。好吃又管饱。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [1],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 1,
                                "tier_output": 1,
                                "skill": 204,
                                "n": 2
                            },
                            {
                                "input": [58],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 58,
                                "tier_output": 1,
                                "skill": 205,
                                "n": 2
                            },
                            {
                                "input": [26],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 26,
                                "tier_output": 1,
                                "skill": 207,
                                "n": 2
                            },
                            {
                                "input": [18],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 18,
                                "tier_output": 1,
                                "skill": 214,
                                "n": 2
                            },
                            {
                                "input": [17],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 17,
                                "tier_output": 1,
                                "skill": 213,
                                "n": 2
                            },

                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 50
                                }
                            ],
                            "reclamation": 150,
                            "building": 60,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [1],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 1,
                                "tier_output": 2,
                                "skill": 204,
                                "n": 2
                            },
                            {
                                "input": [58],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 58,
                                "tier_output": 2,
                                "skill": 205,
                                "n": 2
                            },
                            {
                                "input": [26],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 26,
                                "tier_output": 2,
                                "skill": 207,
                                "n": 2
                            },
                            {
                                "input": [18],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 18,
                                "tier_output": 2,
                                "skill": 214,
                                "n": 2
                            },
                            {
                                "input": [17],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 17,
                                "tier_output": 2,
                                "skill": 213,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 125
                                }
                            ],
                            "reclamation": 360,
                            "building": 90,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [1],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 1,
                                "tier_output": 3,
                                "skill": 204,
                                "n": 2
                            },
                            {
                                "input": [58],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 58,
                                "tier_output": 3,
                                "skill": 205,
                                "n": 2
                            },
                            {
                                "input": [26],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 26,
                                "tier_output": 3,
                                "skill": 207,
                                "n": 2
                            },
                            {
                                "input": [18],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 18,
                                "tier_output": 3,
                                "skill": 214,
                                "n": 2
                            },
                            {
                                "input": [17],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 17,
                                "tier_output": 3,
                                "skill": 213,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 125
                                }
                            ],
                            "reclamation": 480,
                            "building": 120,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [1],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 1,
                                "tier_output": 4,
                                "skill": 204,
                                "n": 2
                            },
                            {
                                "input": [58],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 58,
                                "tier_output": 4,
                                "skill": 205,
                                "n": 2
                            },
                            {
                                "input": [26],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 26,
                                "tier_output": 4,
                                "skill": 207,
                                "n": 2
                            },
                            {
                                "input": [18],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 18,
                                "tier_output": 4,
                                "skill": 214,
                                "n": 2
                            },
                            {
                                "input": [17],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 17,
                                "tier_output": 4,
                                "skill": 213,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 50
                                }
                            ],
                            "reclamation": 600,
                            "building": 150,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 2,
                "name": "农场",
                "Eng": "farm",
                "alert": "",
                "description": "培育多种粮食和其他农业作物。作物烹饪后可以使用，也可以直接吃。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [1],
                                "input_factor": [1.5],
                                "output": 6,
                                "tier_output": 1,
                                "skill": 201,
                                "n": 2
                            },
                            {
                                "input": [8],
                                "tier_input": [1],
                                "input_factor": [1.5],
                                "output": 8,
                                "tier_output": 1,
                                "skill": 203,
                                "n": 2
                            },
                            {
                                "input": [2],
                                "tier_input": [1],
                                "input_factor": [1.5],
                                "output": 2,
                                "tier_output": 1,
                                "skill": 202,
                                "n": 2
                            },
                            {
                                "input": [55],
                                "tier_input": [1],
                                "input_factor": [1.5],
                                "output": 55,
                                "tier_output": 1,
                                "skill": 210,
                                "n": 2
                            },
                            {
                                "input": [15],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 15,
                                "tier_output": 1,
                                "skill": 211,
                                "n": 2
                            },
                            {
                                "input": [13],
                                "tier_input": [1],
                                "input_factor": [1.5],
                                "output": 13,
                                "tier_output": 1,
                                "skill": 209,
                                "n": 2
                            },
                            {
                                "input": [22],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 22,
                                "tier_output": 1,
                                "skill": 219,
                                "n": 2
                            },
                            {
                                "input": [16],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 16,
                                "tier_output": 1,
                                "skill": 212,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 100
                                }
                            ],
                            "reclamation": 150,
                            "building": 60,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [2],
                                "input_factor": [1.5],
                                "output": 6,
                                "tier_output": 2,
                                "skill": 201,
                                "n": 2
                            },
                            {
                                "input": [8],
                                "tier_input": [2],
                                "input_factor": [1.5],
                                "output": 8,
                                "tier_output": 2,
                                "skill": 203,
                                "n": 2
                            },
                            {
                                "input": [2],
                                "tier_input": [2],
                                "input_factor": [1.5],
                                "output": 2,
                                "tier_output": 2,
                                "skill": 202,
                                "n": 2
                            },
                            {
                                "input": [55],
                                "tier_input": [2],
                                "input_factor": [1.5],
                                "output": 55,
                                "tier_output": 2,
                                "skill": 210,
                                "n": 2
                            },
                            {
                                "input": [15],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 15,
                                "tier_output": 2,
                                "skill": 211,
                                "n": 2
                            },
                            {
                                "input": [13],
                                "tier_input": [2],
                                "input_factor": [1.5],
                                "output": 13,
                                "tier_output": 2,
                                "skill": 209,
                                "n": 2
                            },
                            {
                                "input": [22],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 22,
                                "tier_output": 2,
                                "skill": 219,
                                "n": 2
                            },
                            {
                                "input": [16],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 16,
                                "tier_output": 2,
                                "skill": 212,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 250
                                }
                            ],
                            "reclamation": 360,
                            "building": 90,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [3],
                                "input_factor": [1.5],
                                "output": 6,
                                "tier_output": 3,
                                "skill": 201,
                                "n": 2
                            },
                            {
                                "input": [8],
                                "tier_input": [3],
                                "input_factor": [1.5],
                                "output": 8,
                                "tier_output": 3,
                                "skill": 203,
                                "n": 2
                            },
                            {
                                "input": [2],
                                "tier_input": [3],
                                "input_factor": [1.5],
                                "output": 2,
                                "tier_output": 3,
                                "skill": 202,
                                "n": 2
                            },
                            {
                                "input": [55],
                                "tier_input": [3],
                                "input_factor": [1.5],
                                "output": 55,
                                "tier_output": 3,
                                "skill": 210,
                                "n": 2
                            },
                            {
                                "input": [15],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 15,
                                "tier_output": 3,
                                "skill": 211,
                                "n": 2
                            },
                            {
                                "input": [13],
                                "tier_input": [3],
                                "input_factor": [1.5],
                                "output": 13,
                                "tier_output": 3,
                                "skill": 209,
                                "n": 2
                            },
                            {
                                "input": [22],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 22,
                                "tier_output": 3,
                                "skill": 219,
                                "n": 2
                            },
                            {
                                "input": [16],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 16,
                                "tier_output": 3,
                                "skill": 212,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 250
                                }
                            ],
                            "reclamation": 480,
                            "building": 120,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [1],
                                "input_factor": [1.5],
                                "output": 6,
                                "tier_output": 1,
                                "skill": 201,
                                "n": 2
                            },
                            {
                                "input": [8],
                                "tier_input": [4],
                                "input_factor": [1.5],
                                "output": 8,
                                "tier_output": 4,
                                "skill": 203,
                                "n": 2
                            },
                            {
                                "input": [2],
                                "tier_input": [4],
                                "input_factor": [1.5],
                                "output": 2,
                                "tier_output": 4,
                                "skill": 202,
                                "n": 2
                            },
                            {
                                "input": [55],
                                "tier_input": [4],
                                "input_factor": [1.5],
                                "output": 55,
                                "tier_output": 4,
                                "skill": 210,
                                "n": 2
                            },
                            {
                                "input": [15],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 15,
                                "tier_output": 4,
                                "skill": 211,
                                "n": 2
                            },
                            {
                                "input": [13],
                                "tier_input": [4],
                                "input_factor": [1.5],
                                "output": 13,
                                "tier_output": 4,
                                "skill": 209,
                                "n": 2
                            },
                            {
                                "input": [22],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 22,
                                "tier_output": 4,
                                "skill": 219,
                                "n": 2
                            },
                            {
                                "input": [16],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 16,
                                "tier_output": 4,
                                "skill": 212,
                                "n": 2
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 250
                                }
                            ],
                            "reclamation": 600,
                            "building": 150,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 3,
                "name": "采石场",
                "Eng": "quarry",
                "alert": "",
                "description": "采石场从山体或者地下挖掘石料，大理石材是多种建筑的重要建材之一。",
                "tiers": [

                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 3,
                                "tier_output": 1,
                                "skill": 601,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 100
                                }
                            ],
                            "reclamation": 150,
                            "building": 60,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [31],
                                "tier_input": [1],
                                "input_factor": [1.7],
                                "output": 3,
                                "tier_output": 2,
                                "skill": 603,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 250
                                },
                                {
                                    "id": 31,
                                    "tier": 1,
                                    "quantity": 160
                                },
                            ],
                            "reclamation": 360,
                            "building": 90,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [31],
                                "tier_input": [2],
                                "input_factor": [1.7],
                                "output": 3,
                                "tier_output": 3,
                                "skill": 603,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 31,
                                    "tier": 2,
                                    "quantity": 160
                                },
                            ],
                            "reclamation": 480,
                            "building": 120,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [31],
                                "tier_input": [3],
                                "input_factor": [1.7],
                                "output": 3,
                                "tier_output": 4,
                                "skill": 603,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 250
                                },
                                {
                                    "id": 31,
                                    "tier": 3,
                                    "quantity": 160
                                },
                            ],
                            "reclamation": 600,
                            "building": 150,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 4,
                "name": "餐馆",
                "Eng": "restaurant",
                "alert": "！服务形建筑：售卖餐食",
                "description": "这种小型的酒肆为附近街区的居民和过路的旅人提供菜肴。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "食谱可用食材种类：4。食谱可用烹饪法方式种类：8。",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 4,
                                "tier_output": 1,
                                "skill": 701,
                                "n": 1
                            },
                            {
                                "output": 4,
                                "tier_output": 2,
                                "skill": 701,
                                "n": 0.9
                            },
                            {
                                "output": 4,
                                "tier_output": 3,
                                "skill": 701,
                                "n": 0.8
                            },
                            {
                                "output": 4,
                                "tier_output": 4,
                                "skill": 701,
                                "n": 0.7
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 600
                                },
                            ],
                            "reclamation": 120,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "食谱可用食材种类：5。食谱可用烹饪法方式种类：8。",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 4,
                                "tier_output": 1,
                                "skill": 701,
                                "n": 1
                            },
                            {
                                "output": 4,
                                "tier_output": 2,
                                "skill": 701,
                                "n": 0.9
                            },
                            {
                                "output": 4,
                                "tier_output": 3,
                                "skill": 701,
                                "n": 0.8
                            },
                            {
                                "output": 4,
                                "tier_output": 4,
                                "skill": 701,
                                "n": 0.7
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 1100
                                },
                            ],
                            "reclamation": 180,
                            "building": 360,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "食谱可用食材种类：5。食谱可用烹饪法方式种类：9。",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 4,
                                "tier_output": 1,
                                "skill": 701,
                                "n": 1
                            },
                            {
                                "output": 4,
                                "tier_output": 2,
                                "skill": 701,
                                "n": 0.9
                            },
                            {
                                "output": 4,
                                "tier_output": 3,
                                "skill": 701,
                                "n": 0.8
                            },
                            {
                                "output": 4,
                                "tier_output": 4,
                                "skill": 701,
                                "n": 0.7
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 1100
                                },
                            ],
                            "reclamation": 240,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "食谱可用食材种类：5。食谱可用烹饪法方式种类：9。",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 4,
                                "tier_output": 1,
                                "skill": 701,
                                "n": 1
                            },
                            {
                                "output": 4,
                                "tier_output": 2,
                                "skill": 701,
                                "n": 1
                            },
                            {
                                "output": 4,
                                "tier_output": 3,
                                "skill": 701,
                                "n": 1
                            },
                            {
                                "output": 4,
                                "tier_output": 4,
                                "skill": 701,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 1100
                                },
                            ],
                            "reclamation": 300,
                            "building": 600,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 5,
                "name": "藏身处",
                "Eng": "hideout",
                "description": "",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 800,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            1,
                            1,
                            1,
                            0,
                            0
                        ],
                        "designed_area": 0,
                        "cost": {
                            "resources": [],
                            "reclamation": 30,
                            "building": 60,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    }
                ]
            },
            {
                "id": 6,
                "name": "磨坊",
                "Eng": "mill",
                "alert": "",
                "description": "把稻脱成米，把谷物磨成粉。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 5,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 7,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [2],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 14,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [55],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 56,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [13],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 12,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 300
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 40
                                },
                                {
                                    "id": 32,
                                    "tier": 1,
                                    "quantity": 8
                                },
                                {
                                    "id": 2,
                                    "tier": 1,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 100,
                            "building": 140,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 5,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 7,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [2],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 14,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [55],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 56,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [13],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 12,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 300
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 40
                                },
                                {
                                    "id": 32,
                                    "tier": 2,
                                    "quantity": 8
                                },
                                {
                                    "id": 2,
                                    "tier": 2,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 150,
                            "building": 210,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 5,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 7,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [2],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 14,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [55],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 56,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [13],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 12,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 300
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 40
                                },
                                {
                                    "id": 32,
                                    "tier": 3,
                                    "quantity": 8
                                },
                                {
                                    "id": 2,
                                    "tier": 3,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 200,
                            "building": 280,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [6],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 5,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 7,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [2],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 14,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [55],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 56,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [13],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 12,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 300
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 40
                                },
                                {
                                    "id": 32,
                                    "tier": 4,
                                    "quantity": 8
                                },
                                {
                                    "id": 2,
                                    "tier": 4,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 250,
                            "building": 350,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 7,
                "name": "晒场",
                "Eng": "drying_field",
                "alert": "",
                "description": "曝晒食材使其脱水。脱水后的食材别有风味。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [21],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [20],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 24,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [50],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 30,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [15],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 27,
                                "tier_output": 1,
                                "skill": 704,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 100
                                },
                                {
                                    "id": 39,
                                    "tier": 1,
                                    "quantity": 100
                                },
                            ],
                            "reclamation": 100,
                            "building": 80,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [21],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [20],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 24,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [50],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 30,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [15],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 27,
                                "tier_output": 2,
                                "skill": 704,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 125
                                },
                                {
                                    "id": 39,
                                    "tier": 2,
                                    "quantity": 125
                                },
                            ],
                            "reclamation": 150,
                            "building": 120,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [21],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [20],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 24,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [50],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 30,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [15],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 27,
                                "tier_output": 3,
                                "skill": 704,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 125
                                },
                                {
                                    "id": 39,
                                    "tier": 3,
                                    "quantity": 125
                                },
                            ],
                            "reclamation": 200,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [21],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [20],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 24,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [50],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 30,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [15],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 27,
                                "tier_output": 4,
                                "skill": 704,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 125
                                },
                                {
                                    "id": 39,
                                    "tier": 4,
                                    "quantity": 125
                                },
                            ],
                            "reclamation": 250,
                            "building": 200,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 8,
                "name": "榨油作坊",
                "Eng": "oil_mill",
                "alert": "",
                "description": "压榨原料，直到其中提炼出食用油。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15, 30],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 27,
                                "tier_output": 1,
                                "skill": 703,
                                "n": 1
                            },
                            {
                                "input": [58],
                                "tier_input": [1],
                                "input_factor": [2],
                                "output": 59,
                                "tier_output": 1,
                                "skill": 703,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 100
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 80
                                },
                                {
                                    "id": 67,
                                    "tier": 1,
                                    "quantity": 90
                                },
                                {
                                    "id": 52,
                                    "tier": 1,
                                    "quantity": 20
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15, 30],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 27,
                                "tier_output": 2,
                                "skill": 703,
                                "n": 1
                            },
                            {
                                "input": [58],
                                "tier_input": [2],
                                "input_factor": [2],
                                "output": 59,
                                "tier_output": 2,
                                "skill": 703,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 125
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 100
                                },
                                {
                                    "id": 67,
                                    "tier": 2,
                                    "quantity": 115
                                },
                                {
                                    "id": 52,
                                    "tier": 2,
                                    "quantity": 25
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15, 30],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 27,
                                "tier_output": 3,
                                "skill": 703,
                                "n": 1
                            },
                            {
                                "input": [58],
                                "tier_input": [3],
                                "input_factor": [2],
                                "output": 59,
                                "tier_output": 3,
                                "skill": 703,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 125
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 100
                                },
                                {
                                    "id": 67,
                                    "tier": 3,
                                    "quantity": 115
                                },
                                {
                                    "id": 52,
                                    "tier": 3,
                                    "quantity": 25
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15, 30],
                                "tier_input": [4, 4],
                                "input_factor": [1, 1],
                                "output": 27,
                                "tier_output": 4,
                                "skill": 703,
                                "n": 1
                            },
                            {
                                "input": [58],
                                "tier_input": [4],
                                "input_factor": [2],
                                "output": 59,
                                "tier_output": 4,
                                "skill": 703,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1.1,
                            0,
                            1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 125
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 100
                                },
                                {
                                    "id": 67,
                                    "tier": 4,
                                    "quantity": 115
                                },
                                {
                                    "id": 52,
                                    "tier": 4,
                                    "quantity": 25
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 9,
                "name": "花园",
                "Eng": "garden",
                "alert": "",
                "description": "培育鲜花。将鲜花赠与友人可以提升好友关系。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 60,
                                "tier_output": 1,
                                "skill": 208,
                                "n": 1
                            },
                            {
                                "output": 19,
                                "tier_output": 1,
                                "skill": 215,
                                "n": 1
                            },
                            {
                                "output": 61,
                                "tier_output": 1,
                                "skill": 217,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 160
                                },
                                {
                                    "id": 52,
                                    "tier": 1,
                                    "quantity": 40
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 60,
                                "tier_output": 2,
                                "skill": 208,
                                "n": 1
                            },
                            {
                                "output": 19,
                                "tier_output": 2,
                                "skill": 215,
                                "n": 1
                            },
                            {
                                "output": 61,
                                "tier_output": 2,
                                "skill": 217,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 200
                                },
                                {
                                    "id": 52,
                                    "tier": 2,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 60,
                                "tier_output": 3,
                                "skill": 208,
                                "n": 1
                            },
                            {
                                "output": 19,
                                "tier_output": 3,
                                "skill": 215,
                                "n": 1
                            },
                            {
                                "output": 61,
                                "tier_output": 3,
                                "skill": 217,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 200
                                },
                                {
                                    "id": 52,
                                    "tier": 3,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 60,
                                "tier_output": 4,
                                "skill": 208,
                                "n": 1
                            },
                            {
                                "output": 19,
                                "tier_output": 4,
                                "skill": 215,
                                "n": 1
                            },
                            {
                                "output": 61,
                                "tier_output": 4,
                                "skill": 217,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 200
                                },
                                {
                                    "id": 52,
                                    "tier": 4,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 10,
                "name": "堆肥处",
                "Eng": "compost",
                "alert": "",
                "description": "生产农家肥料，以及提升作物星级。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [10],
                                "tier_input": [1],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 1,
                                "skill": 702,
                                "n": 1
                            },


                            {
                                "input": [25, 1],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 1,
                                "tier_output": 2,
                                "skill": 204,
                                "n": 0.25
                            },
                            {
                                "input": [25, 58],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 58,
                                "tier_output": 2,
                                "skill": 205,
                                "n": 0.25
                            },
                            {
                                "input": [25, 26],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 26,
                                "tier_output": 2,
                                "skill": 207,
                                "n": 0.25
                            },
                            {
                                "input": [25, 18],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 18,
                                "tier_output": 2,
                                "skill": 214,
                                "n": 0.25
                            },
                            {
                                "input": [25, 17],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 17,
                                "tier_output": 2,
                                "skill": 213,
                                "n": 0.25
                            },


                            {
                                "input": [25, 6],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1.5],
                                "output": 6,
                                "tier_output": 2,
                                "skill": 201,
                                "n": 0.25
                            },
                            {
                                "input": [25, 8],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1.5],
                                "output": 8,
                                "tier_output": 2,
                                "skill": 203,
                                "n": 0.25
                            },
                            {
                                "input": [25, 2],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1.5],
                                "output": 2,
                                "tier_output": 2,
                                "skill": 202,
                                "n": 0.25
                            },
                            {
                                "input": [25, 55],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1.5],
                                "output": 55,
                                "tier_output": 2,
                                "skill": 210,
                                "n": 0.25
                            },
                            {
                                "input": [25, 15],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 15,
                                "tier_output": 2,
                                "skill": 211,
                                "n": 0.25
                            },
                            {
                                "input": [25, 13],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1.5],
                                "output": 13,
                                "tier_output": 2,
                                "skill": 209,
                                "n": 0.25
                            },
                            {
                                "input": [25, 22],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 22,
                                "tier_output": 2,
                                "skill": 219,
                                "n": 0.25
                            },
                            {
                                "input": [25, 16],
                                "tier_input": [1, 1],
                                "input_factor": [1, 1],
                                "output": 16,
                                "tier_output": 2,
                                "skill": 212,
                                "n": 0.25
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 100
                                }
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [10],
                                "tier_input": [2],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 2,
                                "skill": 702,
                                "n": 1
                            },


                            {
                                "input": [25, 1],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 1,
                                "tier_output": 3,
                                "skill": 204,
                                "n": 0.25
                            },
                            {
                                "input": [25, 58],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 58,
                                "tier_output": 3,
                                "skill": 205,
                                "n": 0.25
                            },
                            {
                                "input": [25, 26],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 26,
                                "tier_output": 3,
                                "skill": 207,
                                "n": 0.25
                            },
                            {
                                "input": [25, 18],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 18,
                                "tier_output": 3,
                                "skill": 214,
                                "n": 0.25
                            },
                            {
                                "input": [25, 17],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 17,
                                "tier_output": 3,
                                "skill": 213,
                                "n": 0.25
                            },


                            {
                                "input": [25, 6],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1.5],
                                "output": 6,
                                "tier_output": 3,
                                "skill": 201,
                                "n": 0.25
                            },
                            {
                                "input": [25, 8],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1.5],
                                "output": 8,
                                "tier_output": 3,
                                "skill": 203,
                                "n": 0.25
                            },
                            {
                                "input": [25, 2],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1.5],
                                "output": 2,
                                "tier_output": 3,
                                "skill": 202,
                                "n": 0.25
                            },
                            {
                                "input": [25, 55],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1.5],
                                "output": 55,
                                "tier_output": 3,
                                "skill": 210,
                                "n": 0.25
                            },
                            {
                                "input": [25, 15],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 15,
                                "tier_output": 3,
                                "skill": 211,
                                "n": 0.25
                            },
                            {
                                "input": [25, 13],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1.5],
                                "output": 13,
                                "tier_output": 3,
                                "skill": 209,
                                "n": 0.25
                            },
                            {
                                "input": [25, 22],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 22,
                                "tier_output": 3,
                                "skill": 219,
                                "n": 0.25
                            },
                            {
                                "input": [25, 16],
                                "tier_input": [2, 2],
                                "input_factor": [1, 1],
                                "output": 16,
                                "tier_output": 3,
                                "skill": 212,
                                "n": 0.25
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 250
                                },
                                {
                                    "id": 31,
                                    "tier": 1,
                                    "quantity": 160
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [10],
                                "tier_input": [3],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 3,
                                "skill": 702,
                                "n": 1
                            },


                            {
                                "input": [25, 1],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 1,
                                "tier_output": 4,
                                "skill": 204,
                                "n": 0.25
                            },
                            {
                                "input": [25, 58],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 58,
                                "tier_output": 4,
                                "skill": 205,
                                "n": 0.25
                            },
                            {
                                "input": [25, 26],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 26,
                                "tier_output": 4,
                                "skill": 207,
                                "n": 0.25
                            },
                            {
                                "input": [25, 18],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 18,
                                "tier_output": 4,
                                "skill": 214,
                                "n": 0.25
                            },
                            {
                                "input": [25, 17],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 17,
                                "tier_output": 4,
                                "skill": 213,
                                "n": 0.25
                            },


                            {
                                "input": [25, 6],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1.5],
                                "output": 6,
                                "tier_output": 4,
                                "skill": 201,
                                "n": 0.25
                            },
                            {
                                "input": [25, 8],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1.5],
                                "output": 8,
                                "tier_output": 4,
                                "skill": 203,
                                "n": 0.25
                            },
                            {
                                "input": [25, 2],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1.5],
                                "output": 2,
                                "tier_output": 4,
                                "skill": 202,
                                "n": 0.25
                            },
                            {
                                "input": [25, 55],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1.5],
                                "output": 55,
                                "tier_output": 4,
                                "skill": 210,
                                "n": 0.25
                            },
                            {
                                "input": [25, 15],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 15,
                                "tier_output": 4,
                                "skill": 211,
                                "n": 0.25
                            },
                            {
                                "input": [25, 13],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1.5],
                                "output": 13,
                                "tier_output": 4,
                                "skill": 209,
                                "n": 0.25
                            },
                            {
                                "input": [25, 22],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 22,
                                "tier_output": 4,
                                "skill": 219,
                                "n": 0.25
                            },
                            {
                                "input": [25, 16],
                                "tier_input": [3, 3],
                                "input_factor": [1, 1],
                                "output": 16,
                                "tier_output": 4,
                                "skill": 212,
                                "n": 0.25
                            },


                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 31,
                                    "tier": 2,
                                    "quantity": 160
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [15],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                            {
                                "input": [10],
                                "tier_input": [4],
                                "input_factor": [3],
                                "output": 25,
                                "tier_output": 4,
                                "skill": 702,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 250
                                },
                                {
                                    "id": 31,
                                    "tier": 3,
                                    "quantity": 160
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 11,
                "name": "书局",
                "Eng": "bookstore",
                "alert": "",
                "description": "储存，售卖，发行抄本。暂未实现功能",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 54,
                                "tier_output": 1,
                                "skill": 1202,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 66,
                                    "tier": 1,
                                    "quantity": 280
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 250
                                },
                                {
                                    "id": 36,
                                    "tier": 1,
                                    "quantity": 60
                                },
                                {
                                    "id": 76,
                                    "tier": 1,
                                    "quantity": 60
                                },
                            ],
                            "reclamation": 100,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 54,
                                "tier_output": 1,
                                "skill": 1202,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 66,
                                    "tier": 2,
                                    "quantity": 350
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 315
                                },
                                {
                                    "id": 36,
                                    "tier": 2,
                                    "quantity": 75
                                },
                                {
                                    "id": 76,
                                    "tier": 2,
                                    "quantity": 75
                                },
                            ],
                            "reclamation": 150,
                            "building": 360,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 54,
                                "tier_output": 1,
                                "skill": 1202,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 66,
                                    "tier": 3,
                                    "quantity": 350
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 315
                                },
                                {
                                    "id": 36,
                                    "tier": 3,
                                    "quantity": 75
                                },
                                {
                                    "id": 76,
                                    "tier": 3,
                                    "quantity": 75
                                },
                            ],
                            "reclamation": 200,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 54,
                                "tier_output": 1,
                                "skill": 1202,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 66,
                                    "tier": 4,
                                    "quantity": 350
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 315
                                },
                                {
                                    "id": 36,
                                    "tier": 4,
                                    "quantity": 75
                                },
                                {
                                    "id": 76,
                                    "tier": 4,
                                    "quantity": 75
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 12,
                "name": "私塾",
                "Eng": "school",
                "alert": "",
                "description": "教育新手玩家，或者从资深玩家学习技能。暂未实现功能",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 266
                                },
                                {
                                    "id": 64,
                                    "tier": 1,
                                    "quantity": 7
                                },
                                {
                                    "id": 69,
                                    "tier": 1,
                                    "quantity": 160
                                },
                                {
                                    "id": 36,
                                    "tier": 1,
                                    "quantity": 120
                                },
                            ],
                            "reclamation": 100,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 335
                                },
                                {
                                    "id": 64,
                                    "tier": 2,
                                    "quantity": 9
                                },
                                {
                                    "id": 69,
                                    "tier": 2,
                                    "quantity": 200
                                },
                                {
                                    "id": 36,
                                    "tier": 2,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 150,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 335
                                },
                                {
                                    "id": 64,
                                    "tier": 3,
                                    "quantity": 9
                                },
                                {
                                    "id": 69,
                                    "tier": 3,
                                    "quantity": 200
                                },
                                {
                                    "id": 36,
                                    "tier": 3,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 200,
                            "building": 640,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 335
                                },
                                {
                                    "id": 64,
                                    "tier": 4,
                                    "quantity": 9
                                },
                                {
                                    "id": 69,
                                    "tier": 4,
                                    "quantity": 200
                                },
                                {
                                    "id": 36,
                                    "tier": 4,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 250,
                            "building": 800,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 13,
                "name": "酿酒作坊",
                "Eng": "winery",
                "alert": "",
                "description": "在蒸馏技术还没普及之前，用发酵方法生产酒。何以解忧，唯有杜康,酒回复较多快乐。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [14],
                                "tier_input": [1],
                                "input_factor": [7.5],
                                "output": 29,
                                "tier_output": 1,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [56],
                                "tier_input": [1],
                                "input_factor": [7.5],
                                "output": 57,
                                "tier_output": 1,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [1],
                                "input_factor": [7.5],
                                "output": 9,
                                "tier_output": 1,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [12],
                                "tier_input": [1],
                                "input_factor": [7.5],
                                "output": 28,
                                "tier_output": 1,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [1],
                                "tier_input": [1],
                                "input_factor": [5],
                                "output": 23,
                                "tier_output": 1,
                                "skill": 705,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 106
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 126
                                },
                                {
                                    "id": 42,
                                    "tier": 1,
                                    "quantity": 24
                                },
                                {
                                    "id": 52,
                                    "tier": 1,
                                    "quantity": 20
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [14],
                                "tier_input": [2],
                                "input_factor": [7.5],
                                "output": 29,
                                "tier_output": 2,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [56],
                                "tier_input": [2],
                                "input_factor": [7.5],
                                "output": 57,
                                "tier_output": 2,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [2],
                                "input_factor": [7.5],
                                "output": 9,
                                "tier_output": 2,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [12],
                                "tier_input": [2],
                                "input_factor": [7.5],
                                "output": 28,
                                "tier_output": 2,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [1],
                                "tier_input": [2],
                                "input_factor": [5],
                                "output": 23,
                                "tier_output": 2,
                                "skill": 705,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 133
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 157
                                },
                                {
                                    "id": 42,
                                    "tier": 2,
                                    "quantity": 30
                                },
                                {
                                    "id": 52,
                                    "tier": 2,
                                    "quantity": 25
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [14],
                                "tier_input": [3],
                                "input_factor": [7.5],
                                "output": 29,
                                "tier_output": 3,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [56],
                                "tier_input": [3],
                                "input_factor": [7.5],
                                "output": 57,
                                "tier_output": 3,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [3],
                                "input_factor": [7.5],
                                "output": 9,
                                "tier_output": 3,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [12],
                                "tier_input": [3],
                                "input_factor": [7.5],
                                "output": 28,
                                "tier_output": 3,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [1],
                                "tier_input": [3],
                                "input_factor": [5],
                                "output": 23,
                                "tier_output": 3,
                                "skill": 705,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 133
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 157
                                },
                                {
                                    "id": 42,
                                    "tier": 3,
                                    "quantity": 30
                                },
                                {
                                    "id": 52,
                                    "tier": 3,
                                    "quantity": 25
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [14],
                                "tier_input": [4],
                                "input_factor": [7.5],
                                "output": 29,
                                "tier_output": 4,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [56],
                                "tier_input": [4],
                                "input_factor": [7.5],
                                "output": 57,
                                "tier_output": 4,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [8],
                                "tier_input": [4],
                                "input_factor": [7.5],
                                "output": 9,
                                "tier_output": 4,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [12],
                                "tier_input": [4],
                                "input_factor": [7.5],
                                "output": 28,
                                "tier_output": 4,
                                "skill": 705,
                                "n": 1
                            },
                            {
                                "input": [1],
                                "tier_input": [4],
                                "input_factor": [5],
                                "output": 23,
                                "tier_output": 4,
                                "skill": 705,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 133
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 157
                                },
                                {
                                    "id": 42,
                                    "tier": 4,
                                    "quantity": 30
                                },
                                {
                                    "id": 52,
                                    "tier": 4,
                                    "quantity": 25
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 14,
                "name": "瓷窑",
                "Eng": "pottery_workshop",
                "alert": "",
                "description": "烧制陶、瓷器皿。使用大量的原木作为燃料。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33, 3],
                                "tier_input": [1, 1],
                                "input_factor": [10, 0.67],
                                "output": 52,
                                "tier_output": 1,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [33, 3],
                                "tier_input": [1, 1],
                                "input_factor": [5, 0.75],
                                "output": 63,
                                "tier_output": 1,
                                "skill": 603,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 500
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 390
                                },
                                {
                                    "id": 32,
                                    "tier": 1,
                                    "quantity": 4
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33, 3],
                                "tier_input": [2, 2],
                                "input_factor": [10, 0.75],
                                "output": 52,
                                "tier_output": 2,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [33, 3],
                                "tier_input": [2, 2],
                                "input_factor": [5, 0.75],
                                "output": 63,
                                "tier_output": 2,
                                "skill": 603,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 625
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 485
                                },
                                {
                                    "id": 32,
                                    "tier": 2,
                                    "quantity": 5
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33, 3],
                                "tier_input": [3, 3],
                                "input_factor": [10, 0.75],
                                "output": 52,
                                "tier_output": 3,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [33, 3],
                                "tier_input": [3, 3],
                                "input_factor": [5, 0.75],
                                "output": 63,
                                "tier_output": 3,
                                "skill": 603,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 625
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 485
                                },
                                {
                                    "id": 32,
                                    "tier": 3,
                                    "quantity": 5
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33, 3],
                                "tier_input": [4, 4],
                                "input_factor": [10, 0.75],
                                "output": 52,
                                "tier_output": 4,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [33, 3],
                                "tier_input": [4, 4],
                                "input_factor": [5, 0.75],
                                "output": 63,
                                "tier_output": 4,
                                "skill": 603,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 625
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 485
                                },
                                {
                                    "id": 32,
                                    "tier": 4,
                                    "quantity": 5
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },

            {
                "id": 16,
                "name": "石工小屋",
                "Eng": "masonry",
                "description": "切割打磨石材，作为高级建材使用",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [3],
                                "tier_input": [1],
                                "input_factor": [0.6],
                                "output": 62,
                                "tier_output": 1,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [31, 62],
                                "tier_input": [1, 1],
                                "input_factor": [6.67, 6.67],
                                "output": 65,
                                "tier_output": 1,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [62, 52],
                                "tier_input": [1, 1],
                                "input_factor": [4, 0.4],
                                "output": 66,
                                "tier_output": 1,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [34, 4],
                                "tier_input": [1, 1],
                                "input_factor": [0.7, 0.25],
                                "output": 32,
                                "tier_output": 1,
                                "skill": 806,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 240
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 36,
                                    "tier": 1,
                                    "quantity": 24
                                },
                            ],
                            "reclamation": 100,
                            "building": 140,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [3],
                                "tier_input": [2],
                                "input_factor": [0.6],
                                "output": 62,
                                "tier_output": 2,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [31, 62],
                                "tier_input": [2, 2],
                                "input_factor": [6.67, 6.67],
                                "output": 65,
                                "tier_output": 2,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [62, 52],
                                "tier_input": [2, 2],
                                "input_factor": [4, 0.4],
                                "output": 66,
                                "tier_output": 2,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [34, 4],
                                "tier_input": [2, 2],
                                "input_factor": [0.7, 0.25],
                                "output": 32,
                                "tier_output": 2,
                                "skill": 806,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 300
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 36,
                                    "tier": 2,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 150,
                            "building": 210,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [3],
                                "tier_input": [3],
                                "input_factor": [0.6],
                                "output": 62,
                                "tier_output": 3,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [31, 62],
                                "tier_input": [3, 3],
                                "input_factor": [6.67, 6.67],
                                "output": 65,
                                "tier_output": 3,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [62, 52],
                                "tier_input": [3, 3],
                                "input_factor": [4, 0.4],
                                "output": 66,
                                "tier_output": 3,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [34, 4],
                                "tier_input": [3, 3],
                                "input_factor": [0.7, 0.25],
                                "output": 32,
                                "tier_output": 3,
                                "skill": 806,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 300
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 36,
                                    "tier": 3,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 200,
                            "building": 280,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [3],
                                "tier_input": [4],
                                "input_factor": [0.6],
                                "output": 62,
                                "tier_output": 4,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [31, 62],
                                "tier_input": [4, 4],
                                "input_factor": [6.67, 6.67],
                                "output": 65,
                                "tier_output": 4,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [62, 52],
                                "tier_input": [4, 4],
                                "input_factor": [4, 0.4],
                                "output": 66,
                                "tier_output": 4,
                                "skill": 806,
                                "n": 1
                            },
                            {
                                "input": [34, 4],
                                "tier_input": [4, 4],
                                "input_factor": [0.7, 0.25],
                                "output": 32,
                                "tier_output": 4,
                                "skill": 806,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 300
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 36,
                                    "tier": 4,
                                    "quantity": 30
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 17,
                "name": "伐木场",
                "Eng": "sawmill",
                "description": "砍倒大树，生产原木。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 33,
                                "tier_output": 1,
                                "skill": 501,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            1.2,
                            0.7,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 100
                                }
                            ],
                            "reclamation": 150,
                            "building": 60,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [32],
                                "tier_input": [1],
                                "input_factor": [0.01],
                                "output": 33,
                                "tier_output": 2,
                                "skill": 501,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            1.2,
                            0.7,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 190
                                },
                                {
                                    "id": 32,
                                    "tier": 1,
                                    "quantity": 2.5
                                },
                            ],
                            "reclamation": 360,
                            "building": 90,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [32],
                                "tier_input": [2],
                                "input_factor": [0.01],
                                "output": 33,
                                "tier_output": 3,
                                "skill": 501,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            1.2,
                            0.7,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 190
                                },
                                {
                                    "id": 32,
                                    "tier": 2,
                                    "quantity": 2.5
                                },
                            ],
                            "reclamation": 480,
                            "building": 120,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [32],
                                "tier_input": [3],
                                "input_factor": [0.01],
                                "output": 33,
                                "tier_output": 4,
                                "skill": 501,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            1.2,
                            0.7,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 50,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 190
                                },
                                {
                                    "id": 32,
                                    "tier": 3,
                                    "quantity": 2.5
                                },
                            ],
                            "reclamation": 600,
                            "building": 150,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 18,
                "name": "木工作坊",
                "Eng": "wood_workshop",
                "description": "切割原木，拼合木材，制作各种木器。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [1],
                                "input_factor": [4],
                                "output": 34,
                                "tier_output": 1,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [33],
                                "tier_input": [1],
                                "input_factor": [6],
                                "output": 31,
                                "tier_output": 1,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 44, 63],
                                "tier_input": [1, 1, 1],
                                "input_factor": [5, 3, 0.75],
                                "output": 64,
                                "tier_output": 1,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 31],
                                "tier_input": [1, 1],
                                "input_factor": [4, 2],
                                "output": 33,
                                "tier_output": 1,
                                "skill": 502,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            1.1,
                            0.9,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 120,
                            "building": 180,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [4],
                                "output": 34,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [6],
                                "output": 31,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 44, 63],
                                "tier_input": [2, 2, 2],
                                "input_factor": [5, 3, 0.75],
                                "output": 64,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 31],
                                "tier_input": [2, 2],
                                "input_factor": [4, 2],
                                "output": 33,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            1.1,
                            0.9,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 500
                                },
                            ],
                            "reclamation": 180,
                            "building": 270,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [4],
                                "output": 34,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [6],
                                "output": 31,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 44, 63],
                                "tier_input": [2, 2, 2],
                                "input_factor": [5, 3, 0.75],
                                "output": 64,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 31],
                                "tier_input": [2, 2],
                                "input_factor": [4, 2],
                                "output": 33,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            1.1,
                            0.9,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 500
                                },
                            ],
                            "reclamation": 240,
                            "building": 360,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [4],
                                "output": 34,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [6],
                                "output": 31,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 44, 63],
                                "tier_input": [2, 2, 2],
                                "input_factor": [5, 3, 0.75],
                                "output": 64,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                            {
                                "input": [34, 31],
                                "tier_input": [2, 2],
                                "input_factor": [4, 2],
                                "output": 33,
                                "tier_output": 2,
                                "skill": 502,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            1.1,
                            0.9,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 500
                                },
                            ],
                            "reclamation": 300,
                            "building": 450,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 19,
                "name": "铁矿",
                "Eng": "iron_mine",
                "alert": "",
                "description": "开采铁矿石",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "output": 39,
                                "tier_output": 1,
                                "skill": 302,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 39,
                                "tier_output": 2,
                                "skill": 302,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 300,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 39,
                                "tier_output": 3,
                                "skill": 302,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 400,
                            "building": 640,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 39,
                                "tier_output": 4,
                                "skill": 302,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 500,
                            "building": 800,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 20,
                "name": "金矿",
                "Eng": "gold_mine",
                "alert": "",
                "description": "开采金矿石",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "output": 40,
                                "tier_output": 1,
                                "skill": 304,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 40,
                                "tier_output": 2,
                                "skill": 304,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 300,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 40,
                                "tier_output": 3,
                                "skill": 304,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 400,
                            "building": 640,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 40,
                                "tier_output": 4,
                                "skill": 304,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 500,
                            "building": 800,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 21,
                "name": "银矿",
                "Eng": "silver_mine",
                "alert": "",
                "description": "生产银矿石",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "output": 41,
                                "tier_output": 1,
                                "skill": 303,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 41,
                                "tier_output": 2,
                                "skill": 303,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 300,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 41,
                                "tier_output": 3,
                                "skill": 303,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 400,
                            "building": 640,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [68],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 41,
                                "tier_output": 4,
                                "skill": 303,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                            ],
                            "reclamation": 500,
                            "building": 800,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 22,
                "name": "冶炼作坊",
                "Eng": "smelting_workshop",
                "description": "熔化矿石，炼制金属。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [51, 39],
                                "tier_input": [1, 1],
                                "input_factor": [2.67, 0.67],
                                "output": 42,
                                "tier_output": 1,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 41],
                                "tier_input": [1, 1],
                                "input_factor": [2, 0.8],
                                "output": 44,
                                "tier_output": 1,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 40],
                                "tier_input": [1, 1],
                                "input_factor": [2, 0.6],
                                "output": 43,
                                "tier_output": 1,
                                "skill": 803,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 250
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 39,
                                    "tier": 1,
                                    "quantity": 40
                                },
                                {
                                    "id": 32,
                                    "tier": 1,
                                    "quantity": 8
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [51, 39],
                                "tier_input": [2, 2],
                                "input_factor": [2.67, 0.67],
                                "output": 42,
                                "tier_output": 2,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 41],
                                "tier_input": [2, 2],
                                "input_factor": [2, 0.8],
                                "output": 44,
                                "tier_output": 2,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 40],
                                "tier_input": [2, 2],
                                "input_factor": [2, 0.6],
                                "output": 43,
                                "tier_output": 2,
                                "skill": 803,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 310
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 39,
                                    "tier": 2,
                                    "quantity": 50
                                },
                                {
                                    "id": 32,
                                    "tier": 2,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [51, 39],
                                "tier_input": [3, 3],
                                "input_factor": [2.67, 0.67],
                                "output": 42,
                                "tier_output": 3,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 41],
                                "tier_input": [3, 3],
                                "input_factor": [2, 0.8],
                                "output": 44,
                                "tier_output": 3,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 40],
                                "tier_input": [3, 3],
                                "input_factor": [2, 0.6],
                                "output": 43,
                                "tier_output": 3,
                                "skill": 803,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 310
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 39,
                                    "tier": 3,
                                    "quantity": 50
                                },
                                {
                                    "id": 32,
                                    "tier": 3,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [51, 39],
                                "tier_input": [4, 4],
                                "input_factor": [2.67, 0.67],
                                "output": 42,
                                "tier_output": 4,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 41],
                                "tier_input": [4, 4],
                                "input_factor": [2, 0.8],
                                "output": 44,
                                "tier_output": 4,
                                "skill": 803,
                                "n": 1
                            },
                            {
                                "input": [51, 40],
                                "tier_input": [4, 4],
                                "input_factor": [2, 0.6],
                                "output": 43,
                                "tier_output": 4,
                                "skill": 803,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 310
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 39,
                                    "tier": 4,
                                    "quantity": 50
                                },
                                {
                                    "id": 32,
                                    "tier": 4,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 23,
                "name": "铸造作坊",
                "Eng": "foundry",
                "description": "将金属加工成各种工具。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [42],
                                "tier_input": [1],
                                "input_factor": [0.3],
                                "output": 68,
                                "tier_output": 1,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [70, 31, 42],
                                "tier_input": [1, 1, 1],
                                "input_factor": [1, 6, 0.6],
                                "output": 75,
                                "tier_output": 1,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [73, 74, 75],
                                "tier_input": [1, 1, 1],
                                "input_factor": [0.07, 0.08, 1],
                                "output": 35,
                                "tier_output": 1,
                                "skill": 804,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 550
                                },
                                {
                                    "id": 42,
                                    "tier": 1,
                                    "quantity": 20
                                },
                                {
                                    "id": 32,
                                    "tier": 1,
                                    "quantity": 8
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [42],
                                "tier_input": [2],
                                "input_factor": [0.3],
                                "output": 68,
                                "tier_output": 2,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [70, 31, 42],
                                "tier_input": [2, 2, 2],
                                "input_factor": [1, 6, 0.6],
                                "output": 75,
                                "tier_output": 2,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [73, 74, 75],
                                "tier_input": [2, 2, 2],
                                "input_factor": [0.07, 0.08, 1],
                                "output": 35,
                                "tier_output": 2,
                                "skill": 804,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 690
                                },
                                {
                                    "id": 42,
                                    "tier": 2,
                                    "quantity": 25
                                },
                                {
                                    "id": 32,
                                    "tier": 2,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [42],
                                "tier_input": [3],
                                "input_factor": [0.3],
                                "output": 68,
                                "tier_output": 3,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [70, 31, 42],
                                "tier_input": [3, 3, 3],
                                "input_factor": [1, 6, 0.6],
                                "output": 75,
                                "tier_output": 3,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [73, 74, 75],
                                "tier_input": [3, 3, 3],
                                "input_factor": [0.07, 0.08, 1],
                                "output": 35,
                                "tier_output": 3,
                                "skill": 804,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 690
                                },
                                {
                                    "id": 42,
                                    "tier": 3,
                                    "quantity": 25
                                },
                                {
                                    "id": 32,
                                    "tier": 3,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [42],
                                "tier_input": [4],
                                "input_factor": [0.3],
                                "output": 68,
                                "tier_output": 4,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [70, 31, 42],
                                "tier_input": [4, 4, 4],
                                "input_factor": [1, 6, 0.6],
                                "output": 75,
                                "tier_output": 4,
                                "skill": 804,
                                "n": 1
                            },
                            {
                                "input": [73, 74, 75],
                                "tier_input": [4, 4, 4],
                                "input_factor": [0.07, 0.08, 1],
                                "output": 35,
                                "tier_output": 4,
                                "skill": 804,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 690
                                },
                                {
                                    "id": 42,
                                    "tier": 4,
                                    "quantity": 25
                                },
                                {
                                    "id": 32,
                                    "tier": 4,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 24,
                "name": "海水渔场",
                "Eng": "sea_fishery",
                "description": "于近海设置竹笼，撒网捕鱼。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 20,
                                "tier_output": 1,
                                "skill": 902,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            1
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 68,
                                    "tier": 1,
                                    "quantity": 400
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 48,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 120
                                },
                            ],
                            "reclamation": 160,
                            "building": 80,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 20,
                                "tier_output": 2,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            1
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 68,
                                    "tier": 2,
                                    "quantity": 500
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 48,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 240,
                            "building": 120,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 20,
                                "tier_output": 3,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            1
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 68,
                                    "tier": 3,
                                    "quantity": 500
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 48,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 320,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 20,
                                "tier_output": 4,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            0,
                            1
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 68,
                                    "tier": 4,
                                    "quantity": 500
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 48,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 400,
                            "building": 200,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 25,
                "name": "淡水渔场",
                "Eng": "freshwater_fishery",
                "description": "于河水和内陆湖设置的养鱼场，淡水鱼好吃。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 21,
                                "tier_output": 1,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 120
                                },
                            ],
                            "reclamation": 160,
                            "building": 40,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 21,
                                "tier_output": 2,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 240,
                            "building": 60,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 21,
                                "tier_output": 3,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 3,
                                    "tier": 3,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 320,
                            "building": 80,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 21,
                                "tier_output": 4,
                                "skill": 902,
                                "n": 1
                            },],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 3,
                                    "tier": 4,
                                    "quantity": 150
                                },
                            ],
                            "reclamation": 400,
                            "building": 100,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 26,
                "name": "银楼",
                "Eng": "silver_workshop",
                "alert": "",
                "description": "家庭作坊式的珠宝加工站。",
                "tiers": [
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [44],
                                "tier_input": [2],
                                "input_factor": [0.4],
                                "output": 47,
                                "tier_output": 2,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [43],
                                "tier_input": [2],
                                "input_factor": [0.5],
                                "output": 46,
                                "tier_output": 2,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [36, 42],
                                "tier_input": [2, 2],
                                "input_factor": [0.75, 0.94],
                                "output": 67,
                                "tier_output": 2,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [67, 47],
                                "tier_input": [2, 2],
                                "input_factor": [3.2, 0.8],
                                "output": 70,
                                "tier_output": 2,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [36, 46],
                                "tier_input": [2, 2],
                                "input_factor": [0.75, 0.05],
                                "output": 69,
                                "tier_output": 2,
                                "skill": 503,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 63,
                                    "tier": 2,
                                    "quantity": 45
                                },
                                {
                                    "id": 52,
                                    "tier": 2,
                                    "quantity": 13
                                },
                                {
                                    "id": 64,
                                    "tier": 2,
                                    "quantity": 17
                                },
                                {
                                    "id": 66,
                                    "tier": 2,
                                    "quantity": 19
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [44],
                                "tier_input": [3],
                                "input_factor": [0.4],
                                "output": 47,
                                "tier_output": 3,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [43],
                                "tier_input": [3],
                                "input_factor": [0.5],
                                "output": 46,
                                "tier_output": 3,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [36, 42],
                                "tier_input": [3, 3],
                                "input_factor": [0.75, 0.94],
                                "output": 67,
                                "tier_output": 3,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [67, 47],
                                "tier_input": [3, 3],
                                "input_factor": [3.2, 0.8],
                                "output": 70,
                                "tier_output": 3,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [36, 46],
                                "tier_input": [3, 3],
                                "input_factor": [0.75, 0.05],
                                "output": 69,
                                "tier_output": 3,
                                "skill": 503,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 63,
                                    "tier": 3,
                                    "quantity": 45
                                },
                                {
                                    "id": 52,
                                    "tier": 3,
                                    "quantity": 13
                                },
                                {
                                    "id": 64,
                                    "tier": 3,
                                    "quantity": 17
                                },
                                {
                                    "id": 66,
                                    "tier": 3,
                                    "quantity": 19
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [44],
                                "tier_input": [4],
                                "input_factor": [0.4],
                                "output": 47,
                                "tier_output": 4,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [43],
                                "tier_input": [4],
                                "input_factor": [0.5],
                                "output": 46,
                                "tier_output": 4,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [36, 42],
                                "tier_input": [4, 4],
                                "input_factor": [0.75, 0.94],
                                "output": 67,
                                "tier_output": 4,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [67, 47],
                                "tier_input": [4, 4],
                                "input_factor": [3.2, 0.8],
                                "output": 70,
                                "tier_output": 4,
                                "skill": 807,
                                "n": 1
                            },
                            {
                                "input": [36, 46],
                                "tier_input": [4, 4],
                                "input_factor": [0.75, 0.05],
                                "output": 69,
                                "tier_output": 4,
                                "skill": 503,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 63,
                                    "tier": 4,
                                    "quantity": 45
                                },
                                {
                                    "id": 52,
                                    "tier": 4,
                                    "quantity": 13
                                },
                                {
                                    "id": 64,
                                    "tier": 4,
                                    "quantity": 17
                                },
                                {
                                    "id": 66,
                                    "tier": 4,
                                    "quantity": 19
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 27,
                "name": "纺织作坊",
                "Eng": "textile_workshop",
                "description": "搓绳织布，量体裁衣。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [10],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 48,
                                "tier_output": 1,
                                "skill": 1001,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 480
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 400
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 10,
                                    "tier": 1,
                                    "quantity": 40
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [10],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 48,
                                "tier_output": 2,
                                "skill": 1001,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 600
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 10,
                                    "tier": 2,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [10],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 48,
                                "tier_output": 3,
                                "skill": 1001,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 600
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 10,
                                    "tier": 3,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [10],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 48,
                                "tier_output": 4,
                                "skill": 1001,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 600
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 500
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 10,
                                    "tier": 4,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 28,
                "name": "精雕作坊",
                "Eng": "carving_workshop",
                "alert": "",
                "description": "当今最高手工技术的体现，蛮夷无法理解的工业神迹。",
                "tiers": [
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [69, 44],
                                "tier_input": [3, 3],
                                "input_factor": [8, 0.2],
                                "output": 73,
                                "tier_output": 3,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [64, 32],
                                "tier_input": [3, 3],
                                "input_factor": [0.64, 0.24],
                                "output": 71,
                                "tier_output": 3,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [66, 70],
                                "tier_input": [3, 3],
                                "input_factor": [4, 2],
                                "output": 74,
                                "tier_output": 3,
                                "skill": 604,
                                "n": 1
                            },
                            {
                                "input": [65, 68],
                                "tier_input": [3, 3],
                                "input_factor": [0.5, 60],
                                "output": 72,
                                "tier_output": 3,
                                "skill": 604,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 200
                                },
                                {
                                    "id": 66,
                                    "tier": 3,
                                    "quantity": 87
                                },
                                {
                                    "id": 70,
                                    "tier": 3,
                                    "quantity": 25
                                },
                                {
                                    "id": 75,
                                    "tier": 3,
                                    "quantity": 8
                                },
                            ],
                            "reclamation": 200,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "input": [69, 44],
                                "tier_input": [4, 4],
                                "input_factor": [8, 0.2],
                                "output": 73,
                                "tier_output": 4,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [64, 32],
                                "tier_input": [4, 4],
                                "input_factor": [0.64, 0.24],
                                "output": 71,
                                "tier_output": 4,
                                "skill": 503,
                                "n": 1
                            },
                            {
                                "input": [66, 70],
                                "tier_input": [4, 4],
                                "input_factor": [4, 2],
                                "output": 74,
                                "tier_output": 4,
                                "skill": 604,
                                "n": 1
                            },
                            {
                                "input": [65, 68],
                                "tier_input": [4, 4],
                                "input_factor": [0.5, 60],
                                "output": 72,
                                "tier_output": 4,
                                "skill": 604,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 200
                                },
                                {
                                    "id": 66,
                                    "tier": 4,
                                    "quantity": 87
                                },
                                {
                                    "id": 70,
                                    "tier": 4,
                                    "quantity": 25
                                },
                                {
                                    "id": 75,
                                    "tier": 4,
                                    "quantity": 8
                                },
                            ],
                            "reclamation": 250,
                            "building": 500,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 29,
                "name": "盐田",
                "Eng": "salt_field",
                "alert": "",
                "description": "利用太阳能产盐。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 50,
                                "tier_output": 1,
                                "skill": 1102,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 200
                                },
                                {
                                    "id": 3,
                                    "tier": 1,
                                    "quantity": 100
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 350
                                },
                            ],
                            "reclamation": 100,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 50,
                                "tier_output": 2,
                                "skill": 1102,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 125
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 440
                                },
                            ],
                            "reclamation": 150,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 50,
                                "tier_output": 3,
                                "skill": 1102,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 125
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 440
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 50,
                                "tier_output": 4,
                                "skill": 1102,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 3,
                                    "tier": 2,
                                    "quantity": 125
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 440
                                },
                            ],
                            "reclamation": 250,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 30,
                "name": "炭窑",
                "Eng": "charcoal_kiln",
                "description": "将原木烧成木炭，供食谱或冶炼作燃料。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [1],
                                "input_factor": [1],
                                "output": 51,
                                "tier_output": 1,
                                "skill": 805,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 31,
                                    "tier": 1,
                                    "quantity": 300
                                },
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 250
                                },
                                {
                                    "id": 32,
                                    "tier": 1,
                                    "quantity": 8.8
                                },
                            ],
                            "reclamation": 200,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [2],
                                "input_factor": [1],
                                "output": 51,
                                "tier_output": 2,
                                "skill": 805,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 31,
                                    "tier": 2,
                                    "quantity": 375
                                },
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 325
                                },
                                {
                                    "id": 32,
                                    "tier": 2,
                                    "quantity": 11
                                },
                            ],
                            "reclamation": 300,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [3],
                                "input_factor": [1],
                                "output": 51,
                                "tier_output": 3,
                                "skill": 805,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 31,
                                    "tier": 3,
                                    "quantity": 375
                                },
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 325
                                },
                                {
                                    "id": 32,
                                    "tier": 3,
                                    "quantity": 11
                                },
                            ],
                            "reclamation": 400,
                            "building": 640,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 2,
                        "working_method": [
                            {
                                "input": [33],
                                "tier_input": [4],
                                "input_factor": [1],
                                "output": 51,
                                "tier_output": 4,
                                "skill": 805,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1.1,
                            0.7,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 31,
                                    "tier": 4,
                                    "quantity": 375
                                },
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 325
                                },
                                {
                                    "id": 32,
                                    "tier": 4,
                                    "quantity": 11
                                },
                            ],
                            "reclamation": 500,
                            "building": 800,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 31,
                "name": "空地贮藏",
                "Eng": "open_storage",
                "description": "简单标注了边界的一大片空地。除了堆放货物之外别无他用。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 8000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 40
                                },
                            ],
                            "reclamation": 240,
                            "building": 100,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 8000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 360,
                            "building": 150,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 8000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 480,
                            "building": 200,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 8000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            0,
                            0,
                            0,
                            0,
                            1,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 50
                                },
                            ],
                            "reclamation": 600,
                            "building": 250,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },

            {
                "id": 36,
                "name": "别墅",
                "Eng": "villa",
                "alert": "",
                "description": "极致奢华的二人之家。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 71,
                                    "tier": 1,
                                    "quantity": 10
                                },
                                {
                                    "id": 72,
                                    "tier": 1,
                                    "quantity": 8
                                },
                                {
                                    "id": 73,
                                    "tier": 1,
                                    "quantity": 3
                                },
                                {
                                    "id": 74,
                                    "tier": 1,
                                    "quantity": 8
                                },
                                {
                                    "id": 67,
                                    "tier": 1,
                                    "quantity": 36
                                },
                                {
                                    "id": 69,
                                    "tier": 1,
                                    "quantity": 76
                                },
                            ],
                            "reclamation": 100,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 71,
                                    "tier": 2,
                                    "quantity": 13
                                },
                                {
                                    "id": 72,
                                    "tier": 2,
                                    "quantity": 10
                                },
                                {
                                    "id": 73,
                                    "tier": 2,
                                    "quantity": 4
                                },
                                {
                                    "id": 74,
                                    "tier": 2,
                                    "quantity": 10
                                },
                                {
                                    "id": 67,
                                    "tier": 2,
                                    "quantity": 45
                                },
                                {
                                    "id": 69,
                                    "tier": 2,
                                    "quantity": 95
                                },
                            ],
                            "reclamation": 150,
                            "building": 600,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 71,
                                    "tier": 2,
                                    "quantity": 13
                                },
                                {
                                    "id": 72,
                                    "tier": 2,
                                    "quantity": 10
                                },
                                {
                                    "id": 73,
                                    "tier": 2,
                                    "quantity": 4
                                },
                                {
                                    "id": 74,
                                    "tier": 2,
                                    "quantity": 10
                                },
                                {
                                    "id": 67,
                                    "tier": 2,
                                    "quantity": 45
                                },
                                {
                                    "id": 69,
                                    "tier": 2,
                                    "quantity": 95
                                },
                            ],
                            "reclamation": 200,
                            "building": 800,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 71,
                                    "tier": 2,
                                    "quantity": 13
                                },
                                {
                                    "id": 72,
                                    "tier": 2,
                                    "quantity": 10
                                },
                                {
                                    "id": 73,
                                    "tier": 2,
                                    "quantity": 4
                                },
                                {
                                    "id": 74,
                                    "tier": 2,
                                    "quantity": 10
                                },
                                {
                                    "id": 67,
                                    "tier": 2,
                                    "quantity": 45
                                },
                                {
                                    "id": 69,
                                    "tier": 2,
                                    "quantity": 95
                                },
                            ],
                            "reclamation": 250,
                            "building": 1000,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 37,
                "name": "楼房",
                "Eng": "apartment",
                "description": "提供8个床位的较高密集住房，有助恢复住客的身体状态。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 80
                                },
                                {
                                    "id": 64,
                                    "tier": 1,
                                    "quantity": 28
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 200
                                },
                                {
                                    "id": 63,
                                    "tier": 1,
                                    "quantity": 310
                                },
                            ],
                            "reclamation": 100,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 100
                                },
                                {
                                    "id": 64,
                                    "tier": 2,
                                    "quantity": 35
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 63,
                                    "tier": 2,
                                    "quantity": 390
                                },
                            ],
                            "reclamation": 150,
                            "building": 360,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 100
                                },
                                {
                                    "id": 64,
                                    "tier": 3,
                                    "quantity": 35
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 250
                                },
                                {
                                    "id": 63,
                                    "tier": 3,
                                    "quantity": 390
                                },
                            ],
                            "reclamation": 200,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 100
                                },
                                {
                                    "id": 64,
                                    "tier": 4,
                                    "quantity": 35
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 250
                                },
                                {
                                    "id": 63,
                                    "tier": 4,
                                    "quantity": 390
                                },
                            ],
                            "reclamation": 250,
                            "building": 600,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 38,
                "name": "宅院",
                "Eng": "house",
                "description": "低矮舒适的低密集住房，提供两个床位。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 1,
                                    "quantity": 120
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 104
                                },
                                {
                                    "id": 36,
                                    "tier": 1,
                                    "quantity": 68
                                },
                            ],
                            "reclamation": 160,
                            "building": 200,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 2,
                                    "quantity": 150
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 130
                                },
                                {
                                    "id": 36,
                                    "tier": 2,
                                    "quantity": 85
                                },
                            ],
                            "reclamation": 240,
                            "building": 300,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 3,
                                    "quantity": 150
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 130
                                },
                                {
                                    "id": 36,
                                    "tier": 3,
                                    "quantity": 85
                                },
                            ],
                            "reclamation": 320,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 33,
                                    "tier": 4,
                                    "quantity": 150
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 130
                                },
                                {
                                    "id": 36,
                                    "tier": 4,
                                    "quantity": 85
                                },
                            ],
                            "reclamation": 400,
                            "building": 500,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 39,
                "name": "棚屋",
                "Eng": "hut",
                "alert": "",
                "description": "仅供一人使用的临时住所。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 80
                                },
                                {
                                    "id": 64,
                                    "tier": 1,
                                    "quantity": 28
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 200
                                },
                                {
                                    "id": 63,
                                    "tier": 1,
                                    "quantity": 310
                                },
                            ],
                            "reclamation": 100,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 100
                                },
                                {
                                    "id": 64,
                                    "tier": 2,
                                    "quantity": 35
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 250
                                },
                                {
                                    "id": 63,
                                    "tier": 2,
                                    "quantity": 390
                                },
                            ],
                            "reclamation": 150,
                            "building": 360,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 100
                                },
                                {
                                    "id": 64,
                                    "tier": 3,
                                    "quantity": 35
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 250
                                },
                                {
                                    "id": 63,
                                    "tier": 3,
                                    "quantity": 390
                                },
                            ],
                            "reclamation": 200,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 1000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 10,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 100
                                },
                                {
                                    "id": 64,
                                    "tier": 4,
                                    "quantity": 35
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 250
                                },
                                {
                                    "id": 63,
                                    "tier": 4,
                                    "quantity": 390
                                },
                            ],
                            "reclamation": 250,
                            "building": 600,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 40,
                "name": "仓库",
                "Eng": "warehouse",
                "description": "遮风挡雨的仓储建筑。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 11000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 160
                                },
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 150
                                },
                                {
                                    "id": 52,
                                    "tier": 1,
                                    "quantity": 52
                                },
                                {
                                    "id": 10,
                                    "tier": 1,
                                    "quantity": 80
                                },
                            ],
                            "reclamation": 240,
                            "building": 160,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 16000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 200
                                },
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 185
                                },
                                {
                                    "id": 52,
                                    "tier": 2,
                                    "quantity": 65
                                },
                                {
                                    "id": 10,
                                    "tier": 2,
                                    "quantity": 100
                                },
                            ],
                            "reclamation": 360,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 28000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 200
                                },
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 185
                                },
                                {
                                    "id": 52,
                                    "tier": 3,
                                    "quantity": 65
                                },
                                {
                                    "id": 10,
                                    "tier": 3,
                                    "quantity": 100
                                },

                            ],
                            "reclamation": 480,
                            "building": 320,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "",
                        "storage_capacity": 40000,
                        "worker_slots": 0,
                        "working_method": [],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0,
                            1,
                            0,
                            0,
                            0
                        ],
                        "designed_area": 80,
                        "cost": {
                            "resources": [
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 200
                                },
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 185
                                },
                                {
                                    "id": 52,
                                    "tier": 4,
                                    "quantity": 65
                                },
                                {
                                    "id": 10,
                                    "tier": 4,
                                    "quantity": 100
                                },
                            ],
                            "reclamation": 600,
                            "building": 400,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            },
            {
                "id": 41,
                "name": "贸易站",
                "Eng": "trading_post",
                "alert": "！服务形建筑：寄售物资",
                "description": "寄售物资以减免交易损耗。主人可以从寄售的交易中抽税。",
                "tiers": [
                    {
                        "tier": 1,
                        "available_only": -1,
                        "description": "寄售总重量上限50,000",
                        "storage_capacity": 2000,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 53,
                                "tier_output": 1,
                                "skill": 1201,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0.8,
                            1,
                            0.4,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 1,
                                    "quantity": 500
                                },
                                {
                                    "id": 34,
                                    "tier": 1,
                                    "quantity": 320
                                },
                                {
                                    "id": 36,
                                    "tier": 1,
                                    "quantity": 40
                                },
                                {
                                    "id": 52,
                                    "tier": 1,
                                    "quantity": 20
                                },
                                {
                                    "id": 44,
                                    "tier": 1,
                                    "quantity": 10
                                },
                            ],
                            "reclamation": 80,
                            "building": 240,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 2,
                        "available_only": -1,
                        "description": "寄售总重量上限200,000",
                        "storage_capacity": 2400,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 53,
                                "tier_output": 2,
                                "skill": 1201,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0.8,
                            1,
                            0.4,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 2,
                                    "quantity": 625
                                },
                                {
                                    "id": 34,
                                    "tier": 2,
                                    "quantity": 400
                                },
                                {
                                    "id": 36,
                                    "tier": 2,
                                    "quantity": 50
                                },
                                {
                                    "id": 52,
                                    "tier": 2,
                                    "quantity": 25
                                },
                                {
                                    "id": 44,
                                    "tier": 2,
                                    "quantity": 13
                                },
                            ],
                            "reclamation": 120,
                            "building": 360,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 3,
                        "available_only": -1,
                        "description": "寄售总重量上限800,000",
                        "storage_capacity": 2800,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 53,
                                "tier_output": 3,
                                "skill": 1201,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0.8,
                            1,
                            0.4,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 3,
                                    "quantity": 625
                                },
                                {
                                    "id": 34,
                                    "tier": 3,
                                    "quantity": 400
                                },
                                {
                                    "id": 36,
                                    "tier": 3,
                                    "quantity": 50
                                },
                                {
                                    "id": 52,
                                    "tier": 3,
                                    "quantity": 25
                                },
                                {
                                    "id": 44,
                                    "tier": 3,
                                    "quantity": 13
                                },
                            ],
                            "reclamation": 160,
                            "building": 480,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                    {
                        "tier": 4,
                        "available_only": -1,
                        "description": "寄售总重量上限2,000,000",
                        "storage_capacity": 3200,
                        "worker_slots": 1,
                        "working_method": [
                            {
                                "output": 53,
                                "tier_output": 4,
                                "skill": 1201,
                                "n": 1
                            },
                        ],
                        "district_affected": [
                            0,
                            0,
                            0
                        ],
                        "district_affect": [
                            0,
                            0,
                            0
                        ],
                        "terrain_adaptability": [
                            1,
                            0.8,
                            1,
                            0.4,
                            0,
                            0
                        ],
                        "designed_area": 20,
                        "cost": {
                            "resources": [
                                {
                                    "id": 62,
                                    "tier": 4,
                                    "quantity": 625
                                },
                                {
                                    "id": 34,
                                    "tier": 4,
                                    "quantity": 400
                                },
                                {
                                    "id": 36,
                                    "tier": 4,
                                    "quantity": 50
                                },
                                {
                                    "id": 52,
                                    "tier": 4,
                                    "quantity": 25
                                },
                                {
                                    "id": 44,
                                    "tier": 4,
                                    "quantity": 13
                                },
                            ],
                            "reclamation": 200,
                            "building": 600,
                            "building_skill": [
                                402,
                                403
                            ]
                        }
                    },
                ]
            }
        ])
    )
}

//所有物品的数值设计
export function setBPItems(Items: string) {
    localStorage.setItem("BPItems", JSON.stringify(Items))
}

export function getBPItems() {
    return JSON.parse(localStorage.getItem("BPItems") ||
        JSON.stringify([
            {
                "id": 1,
                "Chs": "葡萄",
                "Eng": "grape",
                "factor": 1,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 2,
                "Chs": "黍",
                "Eng": "millet",
                "factor": 1.5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 3,
                "Chs": "石料",
                "Eng": "stone",
                "factor": 1,
                "weight": 5,
                "tradable": true
            },
            {
                "id": 4,
                "Chs": "烹饪",
                "Eng": "cooking",
                "factor": 20,
                "weight": 0.01,
                "tradable": false
            },
            {
                "id": 5,
                "Chs": "小麦粉",
                "Eng": "wheat_flour",
                "factor": 4.5,
                "weight": 0.5,
                "tradable": true
            },
            {
                "id": 6,
                "Chs": "小麦",
                "Eng": "wheat",
                "factor": 1.5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 7,
                "Chs": "大麦粉",
                "Eng": "barley_flour",
                "factor": 4.5,
                "weight": 0.5,
                "tradable": true
            },
            {
                "id": 8,
                "Chs": "大麦",
                "Eng": "barley",
                "factor": 1.5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 9,
                "Chs": "啤酒",
                "Eng": "beer",
                "factor": 9,
                "weight": 3,
                "tradable": true
            },
            {
                "id": 10,
                "Chs": "麻",
                "Eng": "hemp",
                "factor": 2.5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 11,
                "Chs": "堆肥",
                "Eng": "fertilizer",
                "factor": 1,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 12,
                "Chs": "稻米",
                "Eng": "paddy_rice",
                "factor": 4.5,
                "weight": 0.8,
                "tradable": true
            },
            {
                "id": 13,
                "Chs": "稻",
                "Eng": "paddy",
                "factor": 1.5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 14,
                "Chs": "黍米",
                "Eng": "millet_rice",
                "factor": 4.5,
                "weight": 0.8,
                "tradable": true
            },
            {
                "id": 15,
                "Chs": "大豆",
                "Eng": "soybean",
                "factor": 1,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 16,
                "Chs": "葵菜",
                "Eng": "mallow",
                "factor": 1,
                "weight": 0.7,
                "tradable": true
            },
            {
                "id": 17,
                "Chs": "桃",
                "Eng": "peach",
                "factor": 1,
                "weight": 1.3,
                "tradable": true
            },
            {
                "id": 18,
                "Chs": "柑橘",
                "Eng": "tangerine",
                "factor": 1,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 19,
                "Chs": "芍药",
                "Eng": "paeonia",
                "factor": 0.2,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 20,
                "Chs": "海水鱼",
                "Eng": "sea_fish",
                "factor": 0.5,
                "weight": 1.8,
                "tradable": true
            },
            {
                "id": 21,
                "Chs": "淡水鱼",
                "Eng": "freshwater_fish",
                "factor": 0.5,
                "weight": 1.8,
                "tradable": true
            },
            {
                "id": 22,
                "Chs": "卷心菜",
                "Eng": "cabbage",
                "factor": 1,
                "weight": 0.8,
                "tradable": true
            },
            {
                "id": 23,
                "Chs": "葡萄酒",
                "Eng": "grape_wine",
                "factor": 3,
                "weight": 3,
                "tradable": true
            },
            {
                "id": 24,
                "Chs": "海水鱼干",
                "Eng": "dried_sea_fish",
                "factor": 1.5,
                "weight": 1.5,
                "tradable": true
            },
            {
                "id": 25,
                "Chs": "淡水鱼干",
                "Eng": "dried_freshwater_fish",
                "factor": 1.5,
                "weight": 1.5,
                "tradable": true
            },
            {
                "id": 26,
                "Chs": "无花果",
                "Eng": "fig",
                "factor": 1,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 27,
                "Chs": "豆酱",
                "Eng": "bean_paste",
                "factor": 1.5,
                "weight": 0.2,
                "tradable": true
            },
            {
                "id": 28,
                "Chs": "稻酒",
                "Eng": "rice_wine",
                "factor": 3,
                "weight": 3,
                "tradable": true
            },
            {
                "id": 29,
                "Chs": "黍酒",
                "Eng": "millet_wine",
                "factor": 9,
                "weight": 3.2,
                "tradable": true
            },
            {
                "id": 30,
                "Chs": "盐卤",
                "Eng": "bittern",
                "factor": 1,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 31,
                "Chs": "楔",
                "Eng": "wedge",
                "factor": 20,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 32,
                "Chs": "石器",
                "Eng": "stone_tool",
                "factor": 0.1,
                "weight": 5,
                "tradable": true
            },
            {
                "id": 33,
                "Chs": "原木",
                "Eng": "log",
                "factor": 2,
                "weight": 5,
                "tradable": true
            },
            {
                "id": 34,
                "Chs": "木材",
                "Eng": "wooden_plank",
                "factor": 8,
                "weight": 2,
                "tradable": true
            },
            {
                "id": 35,
                "Chs": "机械",
                "Eng": "mechanical",
                "factor": 0.5,
                "weight": 7,
                "tradable": true
            },
            {
                "id": 36,
                "Chs": "家具",
                "Eng": "furniture",
                "factor": 0.6,
                "weight": 10,
                "tradable": true
            },
            {
                "id": 37,
                "Chs": "车",
                "Eng": "cart",
                "factor": 0.16,
                "weight": 100,
                "tradable": true
            },
            {
                "id": 38,
                "Chs": "船",
                "Eng": "ship",
                "factor": 0.08,
                "weight": 200,
                "tradable": true
            },
            {
                "id": 39,
                "Chs": "铁矿石",
                "Eng": "iron_ore",
                "factor": 0.5,
                "weight": 15,
                "tradable": true
            },
            {
                "id": 40,
                "Chs": "金矿石",
                "Eng": "gold_ore",
                "factor": 0.1,
                "weight": 40,
                "tradable": true
            },
            {
                "id": 41,
                "Chs": "银矿石",
                "Eng": "silver_ore",
                "factor": 0.2,
                "weight": 20,
                "tradable": true
            },
            {
                "id": 42,
                "Chs": "铁",
                "Eng": "iron",
                "factor": 0.5,
                "weight": 7.87,
                "tradable": true
            },
            {
                "id": 43,
                "Chs": "金",
                "Eng": "gold",
                "factor": 0.1,
                "weight": 19.32,
                "tradable": true
            },
            {
                "id": 44,
                "Chs": "银",
                "Eng": "silver",
                "factor": 0.2,
                "weight": 10.49,
                "tradable": true
            },
            {
                "id": 45,
                "Chs": "工具",
                "Eng": "tool",
                "factor": 5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 46,
                "Chs": "金饰",
                "Eng": "gold_jewellery",
                "factor": 0.4,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 47,
                "Chs": "银饰",
                "Eng": "silver_jewellery",
                "factor": 0.8,
                "weight": 0.5,
                "tradable": true
            },
            {
                "id": 48,
                "Chs": "麻绳",
                "Eng": "hemp_rope",
                "factor": 1,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 49,
                "Chs": "麻布",
                "Eng": "linen",
                "factor": 5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 50,
                "Chs": "盐",
                "Eng": "salt",
                "factor": 0.5,
                "weight": 0.1,
                "tradable": true
            },
            {
                "id": 51,
                "Chs": "木炭",
                "Eng": "charcoal",
                "factor": 1,
                "weight": 4.5,
                "tradable": true
            },
            {
                "id": 52,
                "Chs": "陶器",
                "Eng": "pottery",
                "factor": 1,
                "weight": 5.6,
                "tradable": true
            },
            {
                "id": 53,
                "Chs": "会计",
                "Eng": "accounting",
                "factor": 40,
                "weight": 0.01,
                "tradable": false
            },
            {
                "id": 54,
                "Chs": "抄录",
                "Eng": "transcribe",
                "factor": 20,
                "weight": 0.01,
                "tradable": true
            },
            {
                "id": 55,
                "Chs": "粟",
                "Eng": "foxtail_millet",
                "factor": 1.5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 56,
                "Chs": "粟米",
                "Eng": "foxtail_millet_rice",
                "factor": 4.5,
                "weight": 0.5,
                "tradable": true
            },
            {
                "id": 57,
                "Chs": "粟酒",
                "Eng": "foxtail_millet_wine",
                "factor": 1,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 58,
                "Chs": "橄榄",
                "Eng": "olive",
                "factor": 1,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 59,
                "Chs": "橄榄油",
                "Eng": "olive_oil",
                "factor": 0.6,
                "weight": 3,
                "tradable": true
            },
            {
                "id": 60,
                "Chs": "鸢尾",
                "Eng": "iris",
                "factor": 0.2,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 61,
                "Chs": "风信子",
                "Eng": "hyacinth",
                "factor": 0.2,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 62,
                "Chs": "石砖",
                "Eng": "stone_brick",
                "factor": 4,
                "weight": 1.2,
                "tradable": true
            },
            {
                "id": 63,
                "Chs": "瓦",
                "Eng": "tile",
                "factor": 3,
                "weight": 4,
                "tradable": true
            },
            {
                "id": 64,
                "Chs": "梁",
                "Eng": "beam",
                "factor": 0.4,
                "weight": 21,
                "tradable": true
            },
            {
                "id": 65,
                "Chs": "柱",
                "Eng": "pillar",
                "factor": 0.5,
                "weight": 20,
                "tradable": true
            },
            {
                "id": 66,
                "Chs": "画像砖",
                "Eng": "painted_brick",
                "factor": 4,
                "weight": 2,
                "tradable": true
            },
            {
                "id": 67,
                "Chs": "日用品",
                "Eng": "necessities",
                "factor": 4,
                "weight": 3,
                "tradable": true
            },
            {
                "id": 68,
                "Chs": "钉",
                "Eng": "nail",
                "factor": 12,
                "weight": 0.2,
                "tradable": true
            },
            {
                "id": 69,
                "Chs": "摆件",
                "Eng": "ornament",
                "factor": 4,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 70,
                "Chs": "银器",
                "Eng": "silverware",
                "factor": 2,
                "weight": 2,
                "tradable": true
            },
            {
                "id": 71,
                "Chs": "雕梁",
                "Eng": "carved_beam",
                "factor": 0.5,
                "weight": 25,
                "tradable": true
            },
            {
                "id": 72,
                "Chs": "画栋",
                "Eng": "painted_pillar",
                "factor": 0.5,
                "weight": 20,
                "tradable": true
            },
            {
                "id": 73,
                "Chs": "雕刻砌木",
                "Eng": "carved_wood_block",
                "factor": 0.5,
                "weight": 10,
                "tradable": true
            },
            {
                "id": 74,
                "Chs": "雕刻砌石",
                "Eng": "carved_stone_block",
                "factor": 0.5,
                "weight": 10,
                "tradable": true
            },
            {
                "id": 75,
                "Chs": "零件",
                "Eng": "component",
                "factor": 3,
                "weight": 2,
                "tradable": true
            },
            {
                "id": 76,
                "Chs": "纸",
                "Eng": "paper",
                "factor": 5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 77,
                "Chs": "抄本",
                "Eng": "transcript",
                "factor": 5,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 78,
                "Chs": "豆腐",
                "Eng": "tofu",
                "factor": 3,
                "weight": 0.6,
                "tradable": true
            },
            {
                "id": 79,
                "Chs": "糠",
                "Eng": "bran",
                "factor": 6,
                "weight": 1,
                "tradable": true
            },
            {
                "id": 80,
                "Chs": "巧克力",
                "Eng": "chocolate",
                "factor": 1,
                "weight": 0.5,
                "tradable": true
            },
        ])
    )
}

//所有食材的数值设计
export function setBPFoodMaterials(FoodMaterials: string) {
    localStorage.setItem("BPFoodMaterials", JSON.stringify(FoodMaterials))
}

export function getBPFoodMaterials() {
    return JSON.parse(localStorage.getItem("BPFoodMaterials")
        ||
        JSON.stringify([
            {
                "id": 1,
                "Chs": "葡萄",
                "Eng": "grape",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.6,
                        "happiness": 0.35,
                        "health": 0.1,
                        "energy": 0.1,
                        "luxury": 40,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.6,
                        "happiness": 0.45,
                        "health": 0.14,
                        "energy": 0.13,
                        "luxury": 45,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.6,
                        "happiness": 0.55,
                        "health": 0.18,
                        "energy": 0.16,
                        "luxury": 50,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.6,
                        "happiness": 0.65,
                        "health": 0.22,
                        "energy": 0.2,
                        "luxury": 55,
                    },
                ]
            },
            {
                "id": 2,
                "Chs": "黍",
                "Eng": "millet",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 3.3,
                        "happiness": -0.13,
                        "health": -0.12,
                        "energy": 0,
                        "luxury": 5,
                    },
                    {
                        "tier": 2,
                        "hungry": 3.3,
                        "happiness": -0.09,
                        "health": -0.08,
                        "energy": 0,
                        "luxury": 10,
                    },
                    {
                        "tier": 3,
                        "hungry": 3.3,
                        "happiness": -0.05,
                        "health": -0.04,
                        "energy": 0,
                        "luxury": 25,
                    },
                    {
                        "tier": 4,
                        "hungry": 3.3,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 20,
                    },
                ]
            },
            {
                "id": 5,
                "Chs": "小麦粉",
                "Eng": "wheat_flour",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.3,
                        "happiness": 0.05,
                        "health": 0.2,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.3,
                        "happiness": 0.07,
                        "health": 0.26,
                        "energy": 0,
                        "luxury": 35,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.3,
                        "happiness": 0.09,
                        "health": 0.32,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.3,
                        "happiness": 0.11,
                        "health": 0.38,
                        "energy": 0,
                        "luxury": 45,
                    },
                ]
            },
            {
                "id": 6,
                "Chs": "小麦",
                "Eng": "wheat",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2.3,
                        "happiness": -0.07,
                        "health": 0.1,
                        "energy": 0,
                        "luxury": 5,
                    },
                    {
                        "tier": 2,
                        "hungry": 2.3,
                        "happiness": -0.05,
                        "health": 0.14,
                        "energy": 0,
                        "luxury": 10,
                    },
                    {
                        "tier": 3,
                        "hungry": 2.3,
                        "happiness": -0.03,
                        "health": 0.2,
                        "energy": 0,
                        "luxury": 15,
                    },
                    {
                        "tier": 4,
                        "hungry": 2.3,
                        "happiness": 0,
                        "health": 0.24,
                        "energy": 0,
                        "luxury": 20,
                    },
                ]
            },
            {
                "id": 7,
                "Chs": "大麦粉",
                "Eng": "barley_flour",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.1,
                        "happiness": 0.05,
                        "health": 0.2,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.1,
                        "happiness": 0.07,
                        "health": 0.26,
                        "energy": 0,
                        "luxury": 35,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.1,
                        "happiness": 0.09,
                        "health": 0.32,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.1,
                        "happiness": 0.11,
                        "health": 0.4,
                        "energy": 0,
                        "luxury": 45,
                    },
                ]
            },
            {
                "id": 8,
                "Chs": "大麦",
                "Eng": "barley",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2,
                        "happiness": -0.06,
                        "health": 0.14,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 2,
                        "hungry": 2,
                        "happiness": -0.04,
                        "health": 0.18,
                        "energy": 0,
                        "luxury": 35,
                    },
                    {
                        "tier": 3,
                        "hungry": 2,
                        "happiness": -0.02,
                        "health": 0.22,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 4,
                        "hungry": 2,
                        "happiness": 0,
                        "health": 0.26,
                        "energy": 0,
                        "luxury": 45,
                    },
                ]
            },
            {
                "id": 9,
                "Chs": "啤酒",
                "Eng": "beer",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.4,
                        "happiness": 0.6,
                        "health": -0.12,
                        "energy": 0.3,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.4,
                        "happiness": 0.96,
                        "health": -0.12,
                        "energy": 0.35,
                        "luxury": 55,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.4,
                        "happiness": 1.42,
                        "health": -0.12,
                        "energy": 0.4,
                        "luxury": 60,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.4,
                        "happiness": 1.78,
                        "health": -0.12,
                        "energy": 0.45,
                        "luxury": 65,
                    },
                ]
            },
            {
                "id": 12,
                "Chs": "稻米",
                "Eng": "paddy_rice",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.6,
                        "happiness": 0.1,
                        "health": 0.06,
                        "energy": 0,
                        "luxury": 20,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.6,
                        "happiness": 0.16,
                        "health": 0.1,
                        "energy": 0,
                        "luxury": 25,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.6,
                        "happiness": 0.22,
                        "health": 0.14,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.6,
                        "happiness": 0.28,
                        "health": 0.18,
                        "energy": 0,
                        "luxury": 35,
                    },
                ]
            },
            {
                "id": 13,
                "Chs": "稻",
                "Eng": "paddy",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2.4,
                        "happiness": -0.02,
                        "health": 0,
                        "energy": 0,
                        "luxury": 5,
                    },
                    {
                        "tier": 2,
                        "hungry": 2.4,
                        "happiness": -0.01,
                        "health": 0,
                        "energy": 0,
                        "luxury": 10,
                    },
                    {
                        "tier": 3,
                        "hungry": 2.4,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 15,
                    },
                    {
                        "tier": 4,
                        "hungry": 2.4,
                        "happiness": 0.01,
                        "health": 0,
                        "energy": 0,
                        "luxury": 20,
                    },
                ]
            },
            {
                "id": 14,
                "Chs": "黍米",
                "Eng": "millet_rice",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2.7,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 5,
                    },
                    {
                        "tier": 2,
                        "hungry": 2.7,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 10,
                    },
                    {
                        "tier": 3,
                        "hungry": 2.7,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 15,
                    },
                    {
                        "tier": 4,
                        "hungry": 2.7,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 20,
                    },
                ]
            },
            {
                "id": 15,
                "Chs": "大豆",
                "Eng": "soybean",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.8,
                        "happiness": 0,
                        "health": 0.4,
                        "energy": 0,
                        "luxury": 35,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.8,
                        "happiness": 0,
                        "health": 0.64,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.8,
                        "happiness": 0,
                        "health": 0.88,
                        "energy": 0,
                        "luxury": 50,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.8,
                        "happiness": 0,
                        "health": 1.12,
                        "energy": 0,
                        "luxury": 60,
                    },
                ]
            },
            {
                "id": 16,
                "Chs": "葵菜",
                "Eng": "mallow",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2.4,
                        "happiness": 0,
                        "health": 0.26,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 2,
                        "hungry": 2.4,
                        "happiness": 0,
                        "health": 0.34,
                        "energy": 0,
                        "luxury": 35,
                    },
                    {
                        "tier": 3,
                        "hungry": 2.4,
                        "happiness": 0,
                        "health": 0.42,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 4,
                        "hungry": 2.4,
                        "happiness": 0,
                        "health": 0.5,
                        "energy": 0,
                        "luxury": 45,
                    },
                ]
            },
            {
                "id": 17,
                "Chs": "桃",
                "Eng": "peach",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.2,
                        "happiness": 0.25,
                        "health": 0.3,
                        "energy": 0,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.2,
                        "happiness": 0.33,
                        "health": 0.4,
                        "energy": 0,
                        "luxury": 55,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.2,
                        "happiness": 0.41,
                        "health": 0.5,
                        "energy": 0,
                        "luxury": 60,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.2,
                        "happiness": 0.5,
                        "health": 0.6,
                        "energy": 0,
                        "luxury": 65,
                    },
                ]
            },
            {
                "id": 18,
                "Chs": "柑橘",
                "Eng": "tangerine",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.2,
                        "happiness": 0.3,
                        "health": 0.26,
                        "energy": 0,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.2,
                        "happiness": 0.4,
                        "health": 0.34,
                        "energy": 0,
                        "luxury": 55,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.2,
                        "happiness": 0.5,
                        "health": 0.42,
                        "energy": 0,
                        "luxury": 60,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.2,
                        "happiness": 0.6,
                        "health": 0.5,
                        "energy": 0,
                        "luxury": 65,
                    },
                ]
            },
            {
                "id": 20,
                "Chs": "海水鱼",
                "Eng": "sea_fish",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.2,
                        "happiness": 0.4,
                        "health": 1,
                        "energy": 0.3,
                        "luxury": 60,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.2,
                        "happiness": 0.5,
                        "health": 1.4,
                        "energy": 0.4,
                        "luxury": 65,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.2,
                        "happiness": 0.6,
                        "health": 1.8,
                        "energy": 0.5,
                        "luxury": 70,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.2,
                        "happiness": 0.7,
                        "health": 2.2,
                        "energy": 0.6,
                        "luxury": 75,
                    },
                ]
            },
            {
                "id": 21,
                "Chs": "淡水鱼",
                "Eng": "freshwater_fish",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.4,
                        "happiness": 0.5,
                        "health": 1.1,
                        "energy": 0.4,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.4,
                        "happiness": 0.7,
                        "health": 1.42,
                        "energy": 0.52,
                        "luxury": 55,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.4,
                        "happiness": 0.5,
                        "health": 1.78,
                        "energy": 0.64,
                        "luxury": 60,
                    },
                    {
                        "tier": 4,
                        "hungry": 4,
                        "happiness": 0.5,
                        "health": 2.12,
                        "energy": 1.76,
                        "luxury": 65,
                    },
                ]
            },
            {
                "id": 22,
                "Chs": "卷心菜",
                "Eng": "cabbage",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2,
                        "happiness": 0,
                        "health": 0.36,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 2,
                        "hungry": 2,
                        "happiness": 0,
                        "health": 0.46,
                        "energy": 0,
                        "luxury": 35,
                    },
                    {
                        "tier": 3,
                        "hungry": 2,
                        "happiness": 0,
                        "health": 0.56,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 4,
                        "hungry": 2,
                        "happiness": 0,
                        "health": 0.64,
                        "energy": 0,
                        "luxury": 45,
                    },
                ]
            },
            {
                "id": 23,
                "Chs": "葡萄酒",
                "Eng": "grape_wine",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.4,
                        "happiness": 2,
                        "health": -0.4,
                        "energy": 1.5,
                        "luxury": 80,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.4,
                        "happiness": 3.2,
                        "health": -0.4,
                        "energy": 1.95,
                        "luxury": 85,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.4,
                        "happiness": 4.4,
                        "health": -0.4,
                        "energy": 2.4,
                        "luxury": 90,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.4,
                        "happiness": 5.6,
                        "health": -0.4,
                        "energy": 2.85,
                        "luxury": 95,
                    },
                ]
            },
            {
                "id": 24,
                "Chs": "海水鱼干",
                "Eng": "dried_sea_fish",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.4,
                        "happiness": 0.4,
                        "health": 1.2,
                        "energy": 0,
                        "luxury": 70,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.4,
                        "happiness": 0.52,
                        "health": 1.56,
                        "energy": 0,
                        "luxury": 75,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.4,
                        "happiness": 0.64,
                        "health": 1.92,
                        "energy": 0,
                        "luxury": 80,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.4,
                        "happiness": 0.76,
                        "health": 2.28,
                        "energy": 0,
                        "luxury": 85,
                    },
                ]
            },
            {
                "id": 25,
                "Chs": "淡水鱼干",
                "Eng": "dried_freshwater_fish",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2,
                        "happiness": 0.4,
                        "health": 1,
                        "energy": 0.55,
                        "luxury": 70,
                    },
                    {
                        "tier": 2,
                        "hungry": 2,
                        "happiness": 0.52,
                        "health": 1.3,
                        "energy": 0.72,
                        "luxury": 75,
                    },
                    {
                        "tier": 3,
                        "hungry": 2,
                        "happiness": 0.64,
                        "health": 1.6,
                        "energy": 0.89,
                        "luxury": 80,
                    },
                    {
                        "tier": 4,
                        "hungry": 2,
                        "happiness": 0.76,
                        "health": 1.9,
                        "energy": 1.06,
                        "luxury": 85,
                    },
                ]
            },
            {
                "id": 26,
                "Chs": "无花果",
                "Eng": "fig",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.2,
                        "happiness": 0.35,
                        "health": 0.2,
                        "energy": 0.1,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.2,
                        "happiness": 0.45,
                        "health": 0.26,
                        "energy": 0.13,
                        "luxury": 60,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.2,
                        "happiness": 0.55,
                        "health": 0.32,
                        "energy": 0.16,
                        "luxury": 65,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.2,
                        "happiness": 0.65,
                        "health": 0.4,
                        "energy": 0.2,
                        "luxury": 70,
                    },
                ]
            },
            {
                "id": 27,
                "Chs": "豆腐",
                "Eng": "tofu",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.6,
                        "happiness": 0.15,
                        "health": 1.4,
                        "energy": 0,
                        "luxury": 70,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.6,
                        "happiness": 0.2,
                        "health": 1.8,
                        "energy": 0,
                        "luxury": 75,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.6,
                        "happiness": 0.25,
                        "health": 2.2,
                        "energy": 0,
                        "luxury": 80,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.6,
                        "happiness": 0.3,
                        "health": 2.6,
                        "energy": 0,
                        "luxury": 85,
                    },
                ]
            },
            {
                "id": 28,
                "Chs": "稻酒",
                "Eng": "rice_wine",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.8,
                        "happiness": 1.9,
                        "health": -0.4,
                        "energy": 1.4,
                        "luxury": 70,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.8,
                        "happiness": 3,
                        "health": -0.4,
                        "energy": 1.8,
                        "luxury": 75,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.8,
                        "happiness": 4.1,
                        "health": -0.4,
                        "energy": 2.2,
                        "luxury": 80,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.8,
                        "happiness": 5.2,
                        "health": -0.4,
                        "energy": 2.6,
                        "luxury": 85,
                    },
                ]
            },
            {
                "id": 29,
                "Chs": "黍酒",
                "Eng": "millet_wine",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.8,
                        "happiness": 0.5,
                        "health": -0.14,
                        "energy": 0.3,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.8,
                        "happiness": 0.65,
                        "health": -0.1,
                        "energy": 0.4,
                        "luxury": 55,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.8,
                        "happiness": 0.8,
                        "health": -0.6,
                        "energy": 0.5,
                        "luxury": 60,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.95,
                        "happiness": 0.5,
                        "health": 0,
                        "energy": 0.6,
                        "luxury": 65,
                    },
                ]
            },
            {
                "id": 50,
                "Chs": "盐",
                "Eng": "salt",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 55,
                    },
                    {
                        "tier": 2,
                        "hungry": 0,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 60,
                    },
                    {
                        "tier": 3,
                        "hungry": 0,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 65,
                    },
                    {
                        "tier": 4,
                        "hungry": 0,
                        "happiness": 0,
                        "health": 0,
                        "energy": 0,
                        "luxury": 70,
                    },
                ]
            },


            {
                "id": 55,
                "Chs": "粟",
                "Eng": "foxtail_millet",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 2.8,
                        "happiness": -0.07,
                        "health": 0.08,
                        "energy": 0,
                        "luxury": 7,
                    },
                    {
                        "tier": 2,
                        "hungry": 2.8,
                        "happiness": -0.05,
                        "health": 0.1,
                        "energy": 0,
                        "luxury": 12,
                    },
                    {
                        "tier": 3,
                        "hungry": 2.8,
                        "happiness": -0.03,
                        "health": 0.12,
                        "energy": 0,
                        "luxury": 17,
                    },
                    {
                        "tier": 4,
                        "hungry": 2.8,
                        "happiness": 0,
                        "health": 0.14,
                        "energy": 0,
                        "luxury": 22,
                    },
                ]
            },
            {
                "id": 56,
                "Chs": "粟米",
                "Eng": "foxtail_millet_rice",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.5,
                        "happiness": 0.07,
                        "health": 0.14,
                        "energy": 0,
                        "luxury": 20,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.5,
                        "happiness": 0.09,
                        "health": 0.18,
                        "energy": 0,
                        "luxury": 25,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.5,
                        "happiness": 0.11,
                        "health": 0.22,
                        "energy": 0,
                        "luxury": 30,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.5,
                        "happiness": 0.13,
                        "health": 0.26,
                        "energy": 0,
                        "luxury": 35,
                    },
                ]
            },
            {
                "id": 57,
                "Chs": "粟酒",
                "Eng": "foxtail_millet_wine",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.6,
                        "happiness": 1.77,
                        "health": -0.4,
                        "energy": 1.4,
                        "luxury": 55,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.6,
                        "happiness": 2.77,
                        "health": -0.4,
                        "energy": 1.8,
                        "luxury": 60,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.6,
                        "happiness": 3.77,
                        "health": -0.4,
                        "energy": 2.2,
                        "luxury": 65,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.6,
                        "happiness": 4.77,
                        "health": -0.4,
                        "energy": 2.6,
                        "luxury": 70,
                    },
                ]
            },
            {
                "id": 58,
                "Chs": "橄榄",
                "Eng": "olive",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1.5,
                        "happiness": 0.08,
                        "health": 2,
                        "energy": 0,
                        "luxury": 40,
                    },
                    {
                        "tier": 2,
                        "hungry": 1.5,
                        "happiness": 0.1,
                        "health": 3.2,
                        "energy": 0,
                        "luxury": 45,
                    },
                    {
                        "tier": 3,
                        "hungry": 1.5,
                        "happiness": 0.12,
                        "health": 4.4,
                        "energy": 0,
                        "luxury": 50,
                    },
                    {
                        "tier": 4,
                        "hungry": 1.5,
                        "happiness": 0.14,
                        "health": 5.6,
                        "energy": 0,
                        "luxury": 55,
                    },
                ]
            },
            {
                "id": 59,
                "Chs": "橄榄油",
                "Eng": "olive_oil",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 1,
                        "happiness": 0,
                        "health": 3,
                        "energy": 0,
                        "luxury": 80,
                    },
                    {
                        "tier": 2,
                        "hungry": 1,
                        "happiness": 0,
                        "health": 4.8,
                        "energy": 0,
                        "luxury": 85,
                    },
                    {
                        "tier": 3,
                        "hungry": 1,
                        "happiness": 0,
                        "health": 6.6,
                        "energy": 0,
                        "luxury": 90,
                    },
                    {
                        "tier": 4,
                        "hungry": 1,
                        "happiness": 0,
                        "health": 8,
                        "energy": 0,
                        "luxury": 95,
                    },
                ]
            },
            {
                "id": 78,
                "Chs": "豆腐",
                "Eng": "tofu",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.4,
                        "happiness": 0.1,
                        "health": 1.5,
                        "energy": 0,
                        "luxury": 80,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.4,
                        "happiness": 0.1,
                        "health": 2.4,
                        "energy": 0,
                        "luxury": 85,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.4,
                        "happiness": 0.1,
                        "health": 3.3,
                        "energy": 0,
                        "luxury": 90,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.4,
                        "happiness": 0.1,
                        "health": 4.2,
                        "energy": 0,
                        "luxury": 95,
                    },
                ]
            },
            {
                "id": 79,
                "Chs": "糠",
                "Eng": "bran",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 3.3,
                        "happiness": -1,
                        "health": -0.2,
                        "energy": 0,
                        "luxury": 0,
                    },
                    {
                        "tier": 2,
                        "hungry": 3.3,
                        "happiness": -1,
                        "health": -0.14,
                        "energy": 0,
                        "luxury": 0,
                    },
                    {
                        "tier": 3,
                        "hungry": 3.3,
                        "happiness": -1,
                        "health": -0.08,
                        "energy": 0,
                        "luxury": 0,
                    },
                    {
                        "tier": 4,
                        "hungry": 3.3,
                        "happiness": -1,
                        "health": 0,
                        "energy": 0,
                        "luxury": 0,
                    },
                ]
            },
            {
                "id": 80,
                "Chs": "巧克力",
                "Eng": "chocolate",
                "tiers": [
                    {
                        "tier": 1,
                        "hungry": 0.2,
                        "happiness": 6,
                        "health": 2,
                        "energy": 3,
                        "luxury": 80,
                    },
                    {
                        "tier": 2,
                        "hungry": 0.2,
                        "happiness": 7,
                        "health": 3,
                        "energy": 4,
                        "luxury": 85,
                    },
                    {
                        "tier": 3,
                        "hungry": 0.2,
                        "happiness": 8,
                        "health": 4,
                        "energy": 5,
                        "luxury": 90,
                    },
                    {
                        "tier": 4,
                        "hungry": 0.2,
                        "happiness": 9,
                        "health": 5,
                        "energy": 6,
                        "luxury": 95,
                    },
                ]
            },
        ])
    )
}


//食材-调味料
export function setBPSpices(Spices: string) {
    localStorage.setItem("BPSpices", JSON.stringify(Spices))
}

export function getBPSpices() {
    return JSON.parse(localStorage.getItem("BPSpices")
        ||
        JSON.stringify([
            {
                //调味料的物品id
                "id": 27,
                "Eng": "bean_paste",
                "Chs": "豆酱",
                "tiers": [
                    {
                        "tier": 1,
                        "multiply": 1.1,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 1, 1, 0],
                            [0, 1, 1, 0],
                            [0, 0, 0, 0],
                        ],
                    },
                    {
                        "tier": 2,
                        "multiply": 1.2,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 1, 1, 0],
                            [0, 1, 1, 0],
                            [0, 0, 0, 0],
                        ],
                    },
                    {
                        "tier": 3,
                        "multiply": 1.3,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 1, 1, 0],
                            [0, 1, 1, 0],
                            [0, 0, 0, 0],
                        ],
                    },
                    {
                        "tier": 4,
                        "multiply": 1.4,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 1, 1, 0],
                            [0, 1, 1, 0],
                            [0, 0, 0, 0],
                        ],
                    },
                ]

            },
            {
                "id": 50,
                "Eng": "salt",
                "Chs": "盐",
                "tiers": [
                    {
                        "tier": 1,
                        "multiply": 1.1,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [1, 1, 0, 0],
                            [1, 1, 0, 0],
                        ],
                    },
                    {
                        "tier": 2,
                        "multiply": 1.2,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [1, 1, 0, 0],
                            [1, 1, 0, 0],
                        ],
                    },
                    {
                        "tier": 3,
                        "multiply": 1.3,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [1, 1, 0, 0],
                            [1, 1, 0, 0],
                        ],
                    },
                    {
                        "tier": 4,
                        "multiply": 1.4,
                        "matrix": [
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [0, 0, 0, 0],
                            [1, 1, 0, 0],
                            [1, 1, 0, 0],
                        ],
                    },
                ]

            },
        ])
    )
}


//所有燃料的数值设计
export function setBPFuels(Fuels: string) {
    localStorage.setItem("BPFuels", JSON.stringify(Fuels))
}

export function getBPFuels() {
    return JSON.parse(localStorage.getItem("BPFuels")
        ||
        JSON.stringify([
            {
                "id": 33,
                "Chs": "原木",
                "Eng": "log",
                "tiers": [
                    {
                        "tier": 1,
                        "fuel": 10
                    },
                    {
                        "tier": 2,
                        "fuel": 15
                    },
                    {
                        "tier": 3,
                        "fuel": 20
                    },
                    {
                        "tier": 4,
                        "fuel": 25
                    },
                ]
            },
            {
                "id": 34,
                "Chs": "木板",
                "Eng": "wooden_plank",
                "tiers": [
                    {
                        "tier": 1,
                        "fuel": 2
                    },
                    {
                        "tier": 2,
                        "fuel": 3
                    },
                    {
                        "tier": 3,
                        "fuel": 4
                    },
                    {
                        "tier": 4,
                        "fuel": 5
                    },
                ]
            },
            {
                "id": 36,
                "Chs": "家具",
                "Eng": "furniture",
                "tiers": [
                    {
                        "tier": 1,
                        "fuel": 2
                    },
                    {
                        "tier": 2,
                        "fuel": 2
                    },
                    {
                        "tier": 3,
                        "fuel": 2
                    },
                    {
                        "tier": 4,
                        "fuel": 2
                    },
                ]
            },
            {
                "id": 49,
                "Chs": "麻布",
                "Eng": "linen",
                "tiers": [
                    {
                        "tier": 1,
                        "fuel": 1
                    },
                    {
                        "tier": 2,
                        "fuel": 1.5
                    },
                    {
                        "tier": 3,
                        "fuel": 2
                    },
                    {
                        "tier": 4,
                        "fuel": 2.5
                    },
                ]
            },
            {
                "id": 51,
                "Chs": "木炭",
                "Eng": "charcoal",
                "tiers": [
                    {
                        "tier": 1,
                        "fuel": 10
                    },
                    {
                        "tier": 2,
                        "fuel": 15
                    },
                    {
                        "tier": 3,
                        "fuel": 20
                    },
                    {
                        "tier": 4,
                        "fuel": 25
                    },
                ]
            },

        ])
    )
}

//所有技能的数值设计
export function setBPSkills(Skills: string) {
    localStorage.setItem("BPSkills", JSON.stringify(Skills))
}

export function getBPSkills() {
    return JSON.parse(localStorage.getItem("BPSkills")
        ||
        JSON.stringify([
            {
                "id": 100,
                "Chs": "演讲",
                "Eng": "speech",
                "n": 1500,
            },
            {
                "id": 200,
                "Chs": "种植",
                "Eng": "plant",
                "n": 300,
            },
            {
                "id": 201,
                "Chs": "小麦种植",
                "Eng": "wheat_plant",
                "n": 600,
            },
        ])
    )
}

//副业的选项
export function setBPSideJobLoots(SideJobLoots: string) {
    localStorage.setItem("BPSideJobLoots", JSON.stringify(SideJobLoots))
}

export function getBPSideJobLoots() {
    return JSON.parse(localStorage.getItem("BPSideJobLoots")
        ||
        JSON.stringify({
            "gathering": [
                //地形：0平原1森林2丘陵3高山4淡水5海洋
                {
                    "id": 0,
                    "Chs": "平原拾荒",
                    "Eng": "plain",
                    "description": "出发前往 平原 进行采集。主要产出农作物和食材",
                    "description2": "你来到一片平原，广阔的草地被风吹的像浪。野生的粮食作物吸引了你的注意，它们些许能派上用场。",
                    "skill": 216,
                    "loots": {
                        "guarantee": [
                            {
                                //黍
                                "id": 2,
                                "tier": 1,
                                //副业产出此物品时候的产量修正。多半是1。
                                "capacityFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                        "chance": [
                            {
                                //水稻-东方
                                "id": 13,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": true,
                                "civilizationSpecialty": 0,
                                "citySpecialty": [],
                            },
                            {
                                //大豆
                                "id": 15,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                            {
                                //葵菜
                                "id": 16,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                            {
                                //小麦-西方
                                "id": 6,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": true,
                                "civilizationSpecialty": 1,
                                "citySpecialty": [],
                            },
                            {
                                //大麦-城市
                                "id": 8,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": true,
                                "civilizationSpecialty": null,
                                "citySpecialty": [2],
                            },
                            {
                                //卷心菜-城市
                                "id": 22,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": true,
                                "civilizationSpecialty": null,
                                "citySpecialty": [1],
                            },
                            {
                                //粟
                                "id": 55,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ]
                    }
                },
                {
                    "id": 1,
                    "Chs": "森林拾荒",
                    "Eng": "forest",
                    "description": "出发前往 森林 进行采集。主要产出原木，次要产出水果。",
                    "description2": " ",
                    "skill": 501,
                    "loots": {
                        "guarantee": [
                            {
                                //原木
                                "id": 33,
                                "tier": 1,
                                "capacityFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                        "chance": [
                            {
                                //桃
                                "id": 17,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": true,
                                "civilizationSpecialty": null,
                                "citySpecialty": [0],
                            },
                            {
                                //无花果
                                "id": 55,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                            {
                                //橄榄
                                "id": 58,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                    },
                },
                {
                    "id": 2,
                    "Chs": "丘陵拾荒",
                    "Eng": "hill",
                    "description": "出发前往 丘陵 进行采集。主要产出非粮作物，辅助产出石料。",
                    "description2": " ",
                    "skill": 216,
                    "loots": {
                        "guarantee": [
                            {
                                //麻
                                "id": 10,
                                "tier": 1,
                                "capacityFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                        "chance": [
                            {
                                //石料
                                "id": 3,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                    },
                },
                {
                    "id": 3,
                    "Chs": "高山拾荒",
                    "Eng": "mountain",
                    "description": "出发前往 高山 进行采集。主要产出石料，次要产出矿物。",
                    "description2": " ",
                    "skill": 301,
                    "loots": {
                        "guarantee": [
                            {
                                //石料
                                "id": 3,
                                "tier": 1,
                                "capacityFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                        "chance": [
                            {
                                //铁矿石
                                "id": 39,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                            {
                                //盐
                                "id": 50,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },

                        ],
                    },
                },
                {
                    "id": 4,
                    "Chs": "淡水打捞",
                    "Eng": "water",
                    "description": "出发前往 淡水 进行采集。主要产出淡水鱼。",
                    "description2": "",
                    "skill": 902,
                    "loots": {
                        "guarantee": [
                            {
                                //淡水鱼
                                "id": 21,
                                "tier": 1,
                                "capacityFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                        "chance": [],
                    },
                },
                {
                    "id": 5,
                    "Chs": "海洋打捞",
                    "Eng": "sea",
                    "description": "出发前往 平原 进行采集。主要产出农作物和食材",
                    "description2": "",
                    "skill": 902,
                    "loots": {
                        "guarantee": [
                            {
                                //海水鱼
                                "id": 20,
                                "tier": 1,
                                "capacityFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                        "chance": [
                            {
                                //盐
                                "id": 50,
                                "tier": 1,
                                "capacityFactor": 1,
                                "chanceFactor": 1,
                                "specialtyRestricted": false,
                                "civilizationSpecialty": null,
                                "citySpecialty": [],
                            },
                        ],
                    },
                },
            ],
            "crafting": [
                {
                    "id": 0,
                    "Chs": "居家烹饪",
                    "Eng": "cooking",
                    "description": "手搓一点烹饪。自家下厨开火用。",
                    "description2": "",
                    "skill": 701,
                    "input": [],
                    "output":
                        {
                            //烹饪
                            "id": 4,
                            "tier": 1,
                            "capacityFactor": 1,
                        },
                },
                {
                    "id": 1,
                    "Chs": "居家烹饪★",
                    "Eng": "cooking",
                    "description": "手搓一点烹饪★。自家下厨开火用。",
                    "description2": "",
                    "skill": 701,
                    "input": [],
                    "output":
                        {
                            //烹饪
                            "id": 4,
                            "tier": 2,
                            "capacityFactor": 1,
                        },
                },

                {
                    "id": 2,
                    "Chs": "居家烹饪★★",
                    "Eng": "cooking",
                    "description": "手搓一点烹饪★★。自家下厨开火用。",
                    "description2": "",
                    "skill": 701,
                    "input": [],
                    "output":
                        {
                            //烹饪
                            "id": 4,
                            "tier": 3,
                            "capacityFactor": 0.5,
                        },
                },
                {
                    "id": 3,
                    "Chs": "手搓木板",
                    "Eng": "wooden_plank",
                    "description": "手搓一点木板。",
                    "description2": "",
                    "skill": 502,
                    "input": [
                        {
                            //原木
                            "id": 33,
                            "tier": 1,
                            "capacityFactor": 1,
                        },
                    ],
                    "output":
                        {
                            //木板
                            "id": 34,
                            "tier": 1,
                            "capacityFactor": 1,
                        },
                },
            ],
        }))
}

// 烹饪方法
export function setBPCookingMethods(CookingMethods: string) {
    localStorage.setItem("BPCookingMethods", JSON.stringify(CookingMethods))
}

export function getBPCookingMethods() {
    return JSON.parse(localStorage.getItem("BPCookingMethods")
        ||
        JSON.stringify([
            {
                "id": 0,
                "Eng": "salad",
                "Chs": "拌",
                "description": "拌是简单是把食材混合在一次，兴许也搅拌几下。原汁原味。",
                "description2": "拌不提供任何加成或减益。",
                "fuelFactor": 0,
                "cookingFactor": 0,
                "matrix": [
                    [1, 1, 1, 1],
                    [1, 1, 1, 1],
                    [1, 1, 1, 1],
                    [1, 1, 1, 1],
                    [1, 1, 1, 1],
                ]
            },
            {
                "id": 1,
                "Eng": "salad",
                "Chs": "蒸",
                "description": "蒸是把经过调味后的食品原料放在器皿中，再置入蒸笼利用蒸汽使其成熟。",
                "description2": "蒸为副食提供稍高健康加成。",
                "fuelFactor": 1,
                "cookingFactor": 1,
                "matrix": [
                    [1.4, 1.4, 1.8, 1.2],
                    [1.3, 1.3, 1.6, 1.2],
                    [1.2, 1.2, 1.4, 1.1],
                    [1.1, 1.1, 1.2, 1.1],
                    [1, 1, 1, 1],
                ]
            },
            {
                "id": 2,
                "Eng": "grill",
                "Chs": "烤",
                "description": "烤是一种把食物放在铁架子上，用火加热的方式。比起水煮，烤的食物熟的更快。",
                "description2": "烤需要更少的燃料。",
                "fuelFactor": 0.8,
                "cookingFactor": 0.8,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.3],
                    [1.3, 1.3, 1.3, 1.2],
                    [1.3, 1.3, 1.2, 1.1],
                    [1.1, 1.1, 1.1, 1.1],
                    [1, 1, 1, 1],
                ]
            },
            {
                "id": 3,
                "Eng": "a",
                "Chs": "煎",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 4,
                "Eng": "b",
                "Chs": "炸",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 5,
                "Eng": "c",
                "Chs": "煮",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 6,
                "Eng": "d",
                "Chs": "炒",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 7,
                "Eng": "e",
                "Chs": "卤",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 8,
                "Eng": "f",
                "Chs": "炖",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 9,
                "Eng": "g",
                "Chs": "炮",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 10,
                "Eng": "h",
                "Chs": "腌",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
            {
                "id": 11,
                "Eng": "i",
                "Chs": "酵",
                "description": "炸是把食物放入高温油中浸泡加热。这种烹饪方式据说是从希腊传入。",
                "description2": "炸制主食恢复显著更高的饥饿。吃起来很快乐。",
                "fuelFactor": 1.1,
                "cookingFactor": 1.1,
                "matrix": [
                    [1.4, 1.4, 1.3, 1.2],
                    [1.3, 1.3, 1.1, 1.2],
                    [1.2, 1.2, 0.9, 1.3],
                    [1.1, 1.2, 0.8, 1.4],
                    [1, 1.1, 0.7, 1.5],
                ]
            },
        ])
    )
}


// 有食谱的建筑
export function setBPBuildingsHaveRecipe(BuildingsHaveRecipe: string) {
    localStorage.setItem("BPBuildingsHaveRecipe", JSON.stringify(BuildingsHaveRecipe))
}

export function getBPBuildingsHaveRecipe() {
    return JSON.parse(localStorage.getItem("BPBuildingsHaveRecipe")
        ||
        JSON.stringify([
            {
                //建筑种类id
                "id": 5,
                "Eng": "hideout",
                "Chs": "藏身处",
                //建筑星级
                "tiers": [
                    {
                        "tier": 1,
                        //可用的食材槽位数
                        "materialSlots": 3,
                        //可用的烹饪方法
                        "methodAllowed": [0, 1, 2, 3, 4]
                    }
                ]
            },
            {
                "id": 4,
                "Eng": "restaurant",
                "Chs": "餐馆",
                "tiers": [
                    {
                        "tier": 1,
                        "materialSlots": 4,
                        "methodAllowed": [0, 1, 2, 3, 4, 5, 6, 7]
                    },
                    {
                        "tier": 2,
                        "materialSlots": 5,
                        "methodAllowed": [0, 1, 2, 3, 4, 5, 6, 7]
                    },
                    {
                        "tier": 3,
                        "materialSlots": 5,
                        "methodAllowed": [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    },
                    {
                        "tier": 4,
                        "materialSlots": 5,
                        "methodAllowed": [0, 1, 2, 3, 4, 5, 6, 7, 8]
                    },
                ]
            },
        ])
    )
}


//住房建筑
export function setBPResidence(Residence: string) {
    localStorage.setItem("BPResidence", JSON.stringify(Residence))
}

export function getBPResidence() {
    return JSON.parse(localStorage.getItem("BPResidence")
        ||
        JSON.stringify([ //0棚屋1楼房2宅院3没有住房
            {
                //住房的配置文件id
                "id": 0,
                //这种住房对应的建筑种类id，39是棚屋
                "buildingId": 39,
                //住房的可容纳房客数量
                "guestSlots": 1,
                "tiers": [
                    {
                        //住房的星级
                        "tier": 1,
                        //每日属性恢复
                        "recover": {
                            "energy": 30,
                            "happiness": 3,
                            "health": 2,
                            "hunger": 0,
                        },
                        //这个恢复可以达到的上限
                        "recoverCap": {
                            "energy": 70,
                            "happiness": 65,
                            "health": 80,
                            "hunger": 0,
                        },
                        //使用这个住房后玩家在本城市的个人库存容量
                        "storage": 1600,
                        //住房的奢侈度倾向
                        "luxury": 10,
                    },
                    {
                        "tier": 2,
                        "recover": {
                            "energy": 32.5,
                            "happiness": 3,
                            "health": 2.5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 80,
                            "happiness": 65,
                            "health": 82.5,
                            "hunger": 0,
                        },
                        "storage": 1700,
                        "luxury": 15,
                    },
                    {
                        "tier": 3,
                        "recover": {
                            "energy": 35,
                            "happiness": 3,
                            "health": 3,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 90,
                            "happiness": 65,
                            "health": 85,
                            "hunger": 0,
                        },
                        "storage": 1800,
                        "luxury": 20,
                    },
                    {
                        "tier": 4,
                        "recover": {
                            "energy": 37.5,
                            "happiness": 3,
                            "health": 3.5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 100,
                            "happiness": 65,
                            "health": 87.5,
                            "hunger": 0,
                        },
                        "storage": 1900,
                        "luxury": 25,
                    },

                ]

            },

            {
                //楼房
                "id": 1,
                "buildingId": 37,
                "guestSlots": 8,
                "tiers": [
                    {
                        "tier": 1,
                        "recover": {
                            "energy": 32.5,
                            "happiness": 10,
                            "health": 5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 80,
                            "happiness": 77.5,
                            "health": 85,
                            "hunger": 0,
                        },
                        "storage": 3200,
                        "luxury": 30,
                    },
                    {
                        "tier": 2,
                        "recover": {
                            "energy": 35,
                            "happiness": 12,
                            "health": 3.5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 90,
                            "happiness": 80,
                            "health": 90,
                            "hunger": 0,
                        },
                        "storage": 3300,
                        "luxury": 35,
                    },
                    {
                        "tier": 3,
                        "recover": {
                            "energy": 37.5,
                            "happiness": 14,
                            "health": 7,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 100,
                            "happiness": 85,
                            "health": 95,
                            "hunger": 0,
                        },
                        "storage": 3400,
                        "luxury": 40,
                    },
                    {
                        "tier": 4,
                        "recover": {
                            "energy": 40,
                            "happiness": 16,
                            "health": 4.5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 100,
                            "happiness": 95,
                            "health": 100,
                            "hunger": 0,
                        },
                        "storage": 3500,
                        "luxury": 45,
                    },
                ]
            },
            {
                //宅院
                "id": 2,
                "buildingId": 38,
                "guestSlots": 2,
                "tiers": [
                    {
                        "tier": 1,
                        "recover": {
                            "energy": 35,
                            "happiness": 14,
                            "health": 4,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 90,
                            "happiness": 80,
                            "health": 85,
                            "hunger": 0,
                        },
                        "storage": 6400,
                        "luxury": 50,
                    },
                    {
                        "tier": 2,
                        "recover": {
                            "energy": 37.5,
                            "happiness": 16,
                            "health": 4.5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 100,
                            "happiness": 85,
                            "health": 90,
                            "hunger": 0,
                        },
                        "storage": 6500,
                        "luxury": 55,
                    },
                    {
                        "tier": 3,
                        "recover": {
                            "energy": 40,
                            "happiness": 18,
                            "health": 5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 100,
                            "happiness": 90,
                            "health": 95,
                            "hunger": 0,
                        },
                        "storage": 6600,
                        "luxury": 60,
                    },
                    {
                        "tier": 4,
                        "recover": {
                            "energy": 42.5,
                            "happiness": 20,
                            "health": 5.5,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 100,
                            "happiness": 95,
                            "health": 100,
                            "hunger": 0,
                        },
                        "storage": 6700,
                        "luxury": 65,
                    },


                ]

            },
            {
                //3没有住房
                "id": 3,
                "buildingId": 5,
                "guestSlots": 1,
                "tiers": [
                    {
                        "tier": 1,
                        "recover": {
                            "energy": 30,
                            "happiness": 1.5,
                            "health": 0,
                            "hunger": 0,
                        },
                        "recoverCap": {
                            "energy": 70,
                            "happiness": 50,
                            "health": 50,
                            "hunger": 0,
                        },
                        "storage": 800,
                        "luxury": 0,
                    }
                ]
            }])
    )
}

// 模板备用
// export function setBPFoodMaterials(FoodMaterials: string) {
//     localStorage.setFoodMaterials("BPFoodMaterials", JSON.stringify(FoodMaterials))
// }
//
// export function getBPFoodMaterials() {
//     return JSON.parse(localStorage.getItem("BPFoodMaterials")
//         ||
//         JSON.stringify([])
//     )
// }

// import "statusBar.scss";
import "./statusBar.scss";
import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Floor1} from "../gadgets";


export function PBar(props: {
    color: string,
    //0-100
    value: number,
    text: string,
    hoverText: string,
    length?: number,
}) {
    //默认长度，可覆盖
    const defaultLength = 5
    const value = props.value
    const [displayText, setDisplayText] = useState(props.text)
    //hover文本变换
    const mouseEnter = () => {
        setDisplayText(props.hoverText)
    };
    const mouseLeave = () => {
        setDisplayText(props.text)
    }

    return <div className={"status-bar"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <div className={"progress-container"}
             style={{
                 backgroundColor: "#f2f2f2",
                 width: (props.length) ? `${props.length}vw` : `${defaultLength}vw`
             }}>
            <div className={"progress-bar"} style={{
                backgroundColor: props.color,
                left: `${(value - 100)}%`,
            }}/>
            <div className={"text"}
                 style={{
                     left: `${((value > 30) ? (value - 35) : (value + 6))}%`,
                     color: `${
                         ((value > 30) ? "#eaeaea" : props.color)
                     }`
                 }}>{displayText}</div>
        </div>
    </div>
}

//TODO:输入改变值加tofixed，整体加minwidth
export default function StatusBar(props: {
    //种类：energy,happiness,health,hunger
    type: string,
    length?: number,
    parentHovering?: boolean,
    //下面俩功能没写
    hideIcon?: boolean,
    maxValue?: number,
    valueIfNotStatus?: number,
    changingValue?: number,
}) {
    const changingValue1 = props.changingValue ? props.changingValue : 0;
    const changingValueOrZero = props.changingValue ? (props.changingValue) : 0;
    const changingValueDisplay = (props.changingValue ? (props.changingValue) : 0).toFixed(1)
    const status = useSelector((state: any) => state.status)
    //api名称差异转换,因为api传来的，名字不一样。。。
    const nameConvert: { [key: string]: string } = {
        energy: "energy",
        happiness: "happiness",
        health: "health",
        hungry: "hunger",
    };
    const statusValue = (status.status === "success") ? status.data[nameConvert[props.type]] : 0
    const value = ["energy", "happiness", "health", "hungry"].includes(props.type) ? statusValue : props.valueIfNotStatus


    const valueDisplay = `${Floor1(value)}`
    // const valueDisplay = `${value.toFixed(0)}/${props.maxValue ? props.maxValue : 100}`
    // const [valueDisplay, setValueDisplay] = useState(value)
    //预设长度（条的长度）
    const defaultLength = 6.5
    //预设种类
    const types: { [key: string]: any } = {
        energy: {
            backgroundColor: "#b9e3f6",
            color: "#46b4e6",
            text: "精力",
            img: "statusEnergy",
        },
        happiness: {
            backgroundColor: "#ffd3b9",
            color: "#ff8842",
            text: "快乐",
            img: "statusHappiness",
        },
        health: {
            backgroundColor: "#cbe5b6",
            color: "#73b93c",
            text: "健康",
            img: "statusHealth",
        },
        hungry: {
            backgroundColor: "#f6bbbf",
            color: "#e74652",
            text: "饥饿",
            img: "statusHungry",
        },
        storage: {
            backgroundColor: "#b38460",
            color: "#b38460",
            text: "库存",
            img: "statusHungry",
        }
    };

    const [displayText, setDisplayText] = useState((changingValueOrZero) ? changingValueDisplay : types[props.type].text)
    useEffect(() => {
        setDisplayText((changingValueOrZero) ? changingValueDisplay : types[props.type].text)
    }, [changingValueOrZero])
    //hover文本变换
    const mouseEnter = () => {
        if (props.parentHovering === undefined) {
            setDisplayText(valueDisplay)
        }
    };
    const mouseLeave = () => {
        if (props.parentHovering === undefined) {
            setDisplayText((changingValueOrZero) ? (changingValueOrZero < 0 ? changingValueDisplay : "+" + changingValueDisplay) : types[props.type].text)
        }
    };

    //被parent覆盖时的hover文本变换
    useEffect(() => {
        if (props.parentHovering === true) {
            setDisplayText(valueDisplay)
        } else {
            setDisplayText((changingValueOrZero) ? (changingValueOrZero < 0 ? changingValueDisplay : "+" + changingValueDisplay) : types[props.type].text)
        }
    }, [props.parentHovering])
    // const indicatorLeft = 1.3 + (value + changingValueOrZero) / (props.maxValue ? props.maxValue : 100) * (props.length ? props.length : defaultLength)
    // const indicatorTrimed = indicatorLeft < 1.3 ? 1.3 : (indicatorLeft > (1.3 + (props.length ? props.length : defaultLength)) ? (1.3 + (props.length ? props.length : defaultLength)) : indicatorLeft)
    const indicatorLeft = (value + changingValueOrZero) / (props.maxValue ? props.maxValue : 100) * (props.length ? props.length : defaultLength)
    const indicatorTrimed = (indicatorLeft > ((props.length ? props.length : defaultLength)) ? ((props.length ? props.length : defaultLength)) : indicatorLeft)


    return <div className={"status-bar"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        {props.hideIcon ? null :
            <img src={process.env.PUBLIC_URL + `/img/status/${types[props.type].img}.svg`}
                 className={"type-icon"}
                 alt={"type-icon"}/>
        }
        <div className={"progress-container"}
             style={{
                 backgroundColor: types[props.type].backgroundColor,
                 width: (props.length) ? `${props.length}vw` : `${defaultLength}vw`
             }}>
            <div className={"progress-bar"} style={{
                backgroundColor: types[props.type].color,
                left: `${(value - 100)}%`,
            }}/>
            <div className={"changingPreview"} style={{
                backgroundColor: (changingValueOrZero > 0) ? "#54ef74" : "#737373",
                left: (changingValueOrZero > 0) ? `${value}%` : `${value + changingValueOrZero}%`,
                width: (changingValueOrZero) ? `${Math.abs(changingValueOrZero) / ((props.maxValue) ? props.maxValue : 100) * 100}%` : "0"
            }}/>
            <div className={"text"}
                 style={(value + (changingValueOrZero ?? 0) > 20) ?
                     {
                         right: `${((100 - value) + "%")}`,
                         color: `${
                             (changingValueOrZero === undefined) ?
                                 ((value > 20) ? "#eaeaea" : types[props.type].color)
                                 : ((value + changingValueOrZero > 20) ?
                                     "#eaeaea" : types[props.type].color)
                         }`
                     } :
                     {
                         left: `${(value + "%")}`,
                         color: `${
                             (changingValueOrZero === undefined) ?
                                 ((value > 20) ? "#eaeaea" : types[props.type].color)
                                 : ((changingValueOrZero > 0) ?
                                     "#eaeaea" :
                                     types[props.type].color)
                         }`
                     }

                 }>{displayText}</div>
            <div className={"change-indicator"}
                 style={!changingValueOrZero ? ({visibility: "hidden"}) : ({
                     visibility: "visible",
                     left: `${indicatorTrimed}vw`
                 })}/>
        </div>
    </div>
}


export function StatusBar2x2(props: {
    // length?: string,
    changingValueEnergy?: number,
    changingValueHappiness?: number,
    changingValueHealth?: number,
    changingValueHungry?: number,
}) {
    const [hovering, setHovering] = useState(false)
    const mouseEnter = () => {
        setHovering(true)
    };
    const mouseLeave = () => {
        setHovering(false)
    };
    return <div className={"status-bar2x2"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <div className={"_2x2column"}>
            <div className={"_2x2single"}><StatusBar type={"energy"} parentHovering={hovering}
                                                     changingValue={props.changingValueEnergy}/></div>
            <div className={"_2x2single"}><StatusBar type={"happiness"} parentHovering={hovering}
                                                     changingValue={props.changingValueHappiness}/></div>
        </div>
        <div className={"_2x2column"}>
            <div className={"_2x2single"}><StatusBar type={"health"} parentHovering={hovering}
                                                     changingValue={props.changingValueHealth}/></div>
            <div className={"_2x2single"}><StatusBar type={"hungry"} parentHovering={hovering}
                                                     changingValue={props.changingValueHungry}/></div>
        </div>
    </div>
}

export function StatusBar1x4(props: {
    barLength?: number,
    changingValueEnergy?: number,
    changingValueHappiness?: number,
    changingValueHealth?: number,
    changingValueHungry?: number,
}) {
    const [hovering, setHovering] = useState(false)
    const mouseEnter = () => {
        setHovering(true)
    };
    const mouseLeave = () => {
        setHovering(false)
    };
    return <div className={"status-bar1x4"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <div className={"_1x4single"}><StatusBar type={"energy"} parentHovering={hovering}
                                                 length={props.barLength} changingValue={props.changingValueEnergy}/>
        </div>
        <div className={"_1x4single"}><StatusBar type={"happiness"} parentHovering={hovering}
                                                 length={props.barLength}
                                                 changingValue={props.changingValueHappiness}/></div>
        <div className={"_1x4single"}><StatusBar type={"health"} parentHovering={hovering}
                                                 length={props.barLength} changingValue={props.changingValueHealth}/>
        </div>
        <div className={"_1x4single"}><StatusBar type={"hungry"} parentHovering={hovering}
                                                 length={props.barLength} changingValue={props.changingValueHungry}/>
        </div>
    </div>
}
import "./Notifications.scss"
import {Avatar, Button, Divider, IconButton, Tab, tabClasses, Tabs} from "@mui/material";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Circle, Flag, PriorityHigh, Work} from "@mui/icons-material";
import {Item} from "../components/IconImg";
import NotifContentText from "./ContentText";
import NotifActions from "./Actions";
import {BPNotifications} from "./BPNotifications";
import {api_with_auth} from "../utils/api";
import {openSnackbar} from "../appSnackbarSlice";
import {useDispatch, useSelector} from "react-redux";
import {fetchStatusNow} from "../appSlice";

export const formattedTime = (timeStamp: number) => {
    let date = new Date(timeStamp)
    return date.toLocaleTimeString()
    let hours = date.getHours()
    let minutes = "0" + date.getMinutes()
    return hours + ":" + minutes.substring(-2)
}

export const notifIsImportant = (notif: any) => {
    return (BPNotifications as any)[notif.type]?.keywords[notif.keyword]?.important
}

//从string提取种类（玩家/建筑）和id
export const getHrefFromStringifyJson = (content: string) => {
    const jsonEnd = content.indexOf("]")
    const openingTagEnd = content.indexOf("' >")
    const substrTag = content.substring(0, openingTagEnd)
    const personalIndex = substrTag.indexOf("game/personal")
    const buildingIndex = substrTag.indexOf("game/building")
    const idStartingIndex = (personalIndex === -1) ? (buildingIndex + 8) : (personalIndex + 8)
    const type = (personalIndex === -1) ? "building" : "personal"
    const id = content.substring(idStartingIndex + 6, openingTagEnd)
    return {
        "type": type, "id": id
    }
}

export default function Notifications(props: {}) {
    const dispatch = useDispatch()
    const [notifs, setNotifs] = useState({
        "page": 0,
        "pageSize": 20,
        "totalPages": 20,
        "totalResults": 300,
        "content": [
            {
                //通知id
                "id": 3,
                //通知发布时候的游戏日，使用game_day功能
                "gameDay": 172,
                //通知发布时候的时间戳
                "timestamp": 123131313,
                //type和keyword标志种类，指示前端如何显示通知
                "type": "restaurant",
                "keyword": "needFoodMaterial",
                //用户手动添加标记的通知。不被超时清除
                "flagged": false,
                //重要的通知。和通知类型有关，具体每个通知会说明是不是重要的
                "important": false,
                //是否回复。有些通知须要玩家处理、响应，则有这个条目。此时false是还没回复，true是已经回复了
                //玩家会用拿通知里的数据，用别处接口处理这个事情。后端判断已经回复了就改为true
                "responded": false,
                "content": "[{\"avatar\":\"\"}]<a></a>"
            },
        ]
    })
    const status = useSelector((state: any) => state.status.data)
    //最后已读时间
    const notificationLastReadTime = status.notificationLastReadTime

    //fetch通知
    const fetchNotifs = (filter: "important" | "flagged" | "all") => {
        api_with_auth("/api/notifications", "POST", {
            "page": 0,
            "pageSize": 20,
            "filter": filter
        })
            .then(res => {
                if (res.status === "success") {
                    setNotifs(res.data)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }

    useEffect(() => {
        fetchNotifs("important")
    }, [])
    useEffect(() => {
        return () => {
            // componentwillunmount in functional component.
            // Anything in here is fired on component unmount.
            api_with_auth("/api/notifications/refresh_last_read_time", "POST")
                .then(res => {
                    if (res.status === "success") {
                        dispatch(fetchStatusNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                })
        }
    }, [])


    //设置星标
    const flagNotif = (notifId: number, flagged: boolean) => {
        api_with_auth("/api/notifications/" + notifId, "POST", {
            "flagged": flagged
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "星标了通知",
                    }))
                    fetchNotifs(tab)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }

    //content Json，蛋疼无比的string里面包json
    const contentJson = (content: string) => {
        const jsonEnd = content.indexOf("]")
        const substr = content.substring(1, jsonEnd)
        return JSON.parse(substr)
    }
    //content text
    const contentText = (content: string) => {
        const jsonEnd = content.indexOf("]")
        const substr = content.substring(jsonEnd + 1, content.length)
        return substr
    }


    //头像位置显示图像
    const InfoDisplayed = (props: {
        notif: any
    }) => {
        const params = props.notif.params
        return <Avatar alt={"notif_avt"} src={contentJson(props.notif.content)?.avatar}
                       sx={{width: "2.5vw", height: "2.5vw"}}
                       variant={(getHrefFromStringifyJson(props.notif.content).type === "building") ? "rounded" : "circular"}
        >{""}</Avatar>
    }


    const NotifsRender = (props: {
        tab: "all" | "important" | "flagged"
    }) => {
        return <div className={"notifications-container-root"}>
            {notifs.content?.map((notif: any) =>
                <div className={"notification-container"} key={notif.id}>
                    <div className={"icons"}>
                        <Circle style={{color: (notificationLastReadTime > notif.timestamp ? "white" : "#ed6c02")}}
                                className={"circle"}/>
                        <PriorityHigh className={"priority-high"} style={
                            notifIsImportant(notif) ? {color: "#ed6c02"} : {color: "white"}
                        }/>
                        <IconButton onClick={() => {
                            flagNotif(notif.id, !notif.flagged)
                        }}>
                            <Flag className={"flag"} style={{color: notif.flagged ? "#ed6c02" : "#eaeaea"}}/>
                        </IconButton>
                    </div>
                    <div className={"pfp"}>
                        <InfoDisplayed notif={notif}/>
                    </div>
                    <div className={"content-container"}>
                        <div className={"content"} style={
                            notifIsImportant(notif) ? {fontWeight: "700"} : {}}
                             dangerouslySetInnerHTML={{__html: contentText(notif.content)}}
                        >
                            {/*{}*/}
                            {/*<NotifContentText notif={notif}/>*/}
                        </div>
                        <div className={"info"}>
                            {BPNotifications[notif.type as keyof typeof BPNotifications]?.icon ?? null}
                            {BPNotifications[notif.type as keyof typeof BPNotifications]?.Chs ?? ''}
                            消息，推送于{notif.gameDay}日，
                            {formattedTime(notif.timestamp / 1000000000)}
                            {/*12:13*/}
                            。
                        </div>
                    </div>
                    <div className={"actions"}>
                        <NotifActions notif={notif}/>
                    </div>
                </div>
            )}
        </div>
    }

    const [tab, setTab] = useState<"all" | "important" | "flagged">("important")
    const changeTab = (event: SyntheticEvent, newValue: "all" | "important" | "flagged") => {
        setTab(newValue);
        fetchNotifs(newValue)
    };

    return <div className={"notifications-root"}>
        <div className={"left-container"}>
            <div className={"title"}>
                我的通知
            </div>
            <TabContext value={tab}>
                <TabList className={"tab-buttons"} value={""} onChange={changeTab} centered
                    // indicatorColor={"blueGrey"} textColor={"blueGrey"}
                >
                    <Tab label={"重要"} value={"important"}/>
                    <Tab label={"全部"} value={"all"}/>
                    <Tab label={"已星标"} value={"flagged"}/>
                </TabList>
                <Divider variant={"middle"}/>
                <TabPanel value="all">
                    <NotifsRender tab={"all"}/>
                </TabPanel>
                <TabPanel value="important">
                    <NotifsRender tab={"important"}/>
                </TabPanel>
                <TabPanel value="flagged">
                    <NotifsRender tab={"flagged"}/>
                </TabPanel>
            </TabContext>

        </div>
        <Divider variant={"middle"} orientation={"vertical"} flexItem/>
        <div className={"right-container"}>
            <img alt={"notif-icon"} src={require("./assets/bg.webp").default}/>
            <div className={""}></div>
        </div>
    </div>
}
import "./Sell.scss"
import "../../../../components/GenericModalStyle/GenericModalStyle.scss"
import TransitModal from "../Transit";
import {useDispatch, useSelector} from "react-redux";
import {Close} from "@mui/icons-material";
import {StorageBullet} from "../../components/BuildingBulletRender";
import {
    Avatar,
    Backdrop,
    Button,
    Checkbox, Fade,
    InputAdornment,
    MenuItem,
    Modal,
    Radio,
    Select,
    TextField
} from "@mui/material";
import {Floor1, ifNumberFloor2, itemInfo} from "../../../../components/gadgets";
import {TierToStars} from "../../../../components/mappingTable";
import {LoadingButton} from "@mui/lab";
import * as React from "react";
import {Item} from "../../../../components/IconImg";
import ItemSelector from "../../../../components/ItemSelector";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {current} from "@reduxjs/toolkit";
import {useNavigate, useParams} from "react-router-dom";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {api_with_auth} from "../../../../utils/api";
import {StatusBar2x2} from "../../../../components/statusBar";

export default function SellModal(props: {
    open: boolean
    onClose: () => void
    storageCheckBox: any[]
    storage: any[]
    // onWorkDone: (status: any, message: any) => void
}) {
    const stateRef: any = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {buildingId} = useParams()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const city = useSelector((state: any) => state.cityStructure.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    const myBuildings = useSelector((state: any) => state.myBuildings.data)

    //checkbox里面true的index的array。内容是storage物品arr的部分index。
    const trueArr = props.storageCheckBox.reduce(
        (out, bool, index) => bool ? out.concat(index) : out,
        []
    )


    //出售物品arr
    const [sellItems, setSellItems] = useState([{
        "id": -1,
        "tier": 1,
        "quantity": 0
    }])

    //需求物品arr，和trueArr条目对应
    const [buyItems, setBuyItems] = useState<any>(new Array(trueArr.length).fill({
        "id": -1,
        "tier": 1,
        "quantityPerItem": 0
    }))

    //初始化出售和需求物品arr
    useEffect(() => {
        setBuyItems(new Array(trueArr.length).fill({
            "id": -1,
            "tier": 1,
            "quantityPerItem": 1
        }))
        //卖
        let arr = new Array(trueArr.length).fill({
            "id": -1,
            "tier": 1,
            "quantity": 0
        })
        arr = arr.map((item: any, index: number) => {
                return {
                    "id": props.storage[trueArr[index]]?.item_type,
                    "tier": props.storage[trueArr[index]]?.item_tire,
                    "quantity": ifNumberFloor2(props.storage[trueArr[index]]?.item_in_storage)
                }
            }
        )
        setSellItems(arr)
    }, [props.storageCheckBox])

    //调整提供总量
    const setSellTotal = (event: any, index: number) => {
        let arr = JSON.parse(JSON.stringify(sellItems))
        arr[index].quantity = parseFloat(event.target.value)
        setSellItems(arr)
    }

    //选择需求物品
    const pickItem = (id: number, tier: number, indexInReturn: number) => {
        setBuyItems((current: any) =>
            current.map((item: any, indexInTrueArr: number) => {
                if (indexInTrueArr === indexInReturn) {
                    return {
                        "id": id,
                        "tier": tier,
                        "quantityPerItem": item.quantityPerItem
                    }
                } else {
                    return item
                }
            }))
    }

    //调整需求单价
    const setBuyPrice = (event: any, index: number) => {
        let arr = JSON.parse(JSON.stringify(buyItems))
        arr[index].quantityPerItem = parseFloat(event.target.value)
        setBuyItems(arr)
    }

    //全城市贸易站
    const [agents, setAgent] = useState([{
        "id": 163,
        "owner": {
            "uid": 0,
            "nick_name": "",
            "avatar": "",
            "born_city": 0
        },
        "owner_id": 111,
        "manager": {
            "uid": 0,
            "nick_name": "",
            "avatar": "",
            "born_city": 0
        },
        "manager_id": 111,
        "name": "获取贸易站中",
        "avatar": "",
        "type": 41,
        "tier": 1,
        "is_hiring": false,
        "salary_number": 0,
        "salary_type": 0,
        "salary_tier": 0,
        "storage_capacity": 20263.231828296215,
        "max_storage_capacity": 800,
        "belong_city": 0,
        "belong_districts": 0,
        "belong_terrain": 0,
        "culture": 0,
        "stage": 2,
        "percent": 100,
        "product_type": 0,
        "in_day_product_type": 0,
        "working_method": -1,
        "in_day_working_method": -1,
        "architect": {
            "uid": 0,
            "nick_name": "",
            "avatar": "",
            "born_city": 0
        },
        "remarks": "",
        "public": true,
        "multiply": 1,
        "del_flag": 0,
        "is_selling": true,
        "agent": {
            "open": true,
            "same_district_only": false,
            "capacity": 5000,
            "capacity_taken": 0,
            "yesterday_sale": 0,
            "total_sale": 0,
            "tax": 0.02
        }
    },])
    //无贸易站就跳过
    const skipAgentStyle = {display: agents.length === 0 ? "none" : "block"}
    const fetchAgents = () => {
        api_with_auth("/api/bazaar/agents", "GET")
            .then(res => {
                if (res.status === "success") {
                    setAgent(res.data)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })

    }
    useEffect(() => {
        fetchAgents()
    }, [])
    const [pickedAgent, setPickedAgent] = useState(0)

    //提交到市场
    const [applySuccess, setApplySuccess] = useState(0)
    // stateRef.applySuccess = applySuccess
    const apply = (useAgent: boolean) => {
        let promises = sellItems?.map((sell: any, index) => {
            api_with_auth("/api/bazaar/items/new", "POST", {
                "buildingId": parseInt(buildingId ?? "0"),
                "agent": useAgent ? pickedAgent : 0,
                "selling": {
                    "id": sell.id,
                    "tier": sell.tier,
                    "quantity": sell.quantity
                },
                "buying": {
                    "id": buyItems[index].id,
                    "tier": buyItems[index].tier,
                    "quantityPerItem": buyItems[index].quantityPerItem
                }
            })
                .then(res => {
                    if (res.status === "success") {
                        setApplySuccess(applySuccess + 1)
                        dispatch(openSnackbar({
                            "severity": !applySuccess ? "error" : (applySuccess === sellItems.length ? "success" : "info"),
                            "snackbarMessage": "发布了物品在市场上出售。",
                        }))
                        navigate("/game/bazaar")
                    } else {
                    }
                })
        })
        // Promise.all(promises).then(function (results) {
        //     dispatch(openSnackbar({
        //         "severity": !applySuccess ? "error" : (applySuccess === sellItems.length ? "success" : "info"),
        //         "snackbarMessage": "发布了" + applySuccess + "项物品在市场上出售。",
        //     }))
        //     if (applySuccess) {
        //         navigate("/game/bazaar")
        //     }
        //     setApplySuccess(0)
        // })

    }

    const AgentsContainer = () => {
        return <div className={"agents-container"} style={skipAgentStyle}>
            {agents?.map((agent: any, index) =>
                <div className={"agent-container list-item-building"} key={agent.id}>
                    <Radio checked={pickedAgent === agent.id} onChange={() => {
                        setPickedAgent(agent.id)
                    }}/>
                    <Avatar alt={""} src={agent.avatar}
                            sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                            variant="rounded"
                    >{agent.name?.[0]}</Avatar>
                    <div className={"two-story-text"}>
                        <div className={"up"}>
                            {agent.name}
                        </div>
                        <div className={"down"}>
                            {"贸易站 - 距离0"}
                        </div>
                    </div>
                    <div className={"two-story-text right margin-left-auto"}>
                        <div className={"up"}>
                            {agent.agent?.capacity - agent.agent?.capacity_taken}
                        </div>
                        <div className={"down"}>
                            剩余寄售额度
                        </div>
                    </div>
                    <div className={"two-story-text right four-vw"}>
                        <div className={"up"}>
                            {Floor1(agent.agent?.tax * 100)}%
                        </div>
                        <div className={"down"}>
                            税率
                        </div>
                    </div>
                </div>
            )}

        </div>
    }

    const sellInitiatedSnackbar = () => {
        dispatch(openSnackbar({
            "severity": "success",
            "snackbarMessage": "成功把货物放在城市市场售卖了",
        }))
    }

    return <Modal
        open={props.open}
        onClose={() => {
            props.onClose()
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        className={"generic-modal-root"}
    >

        <Fade in={props.open}>
            <div className={"SellModal modal-content-root width-override"}>

                <div className={"title-image title-image1"}/>
                <div className={"title-image title-image3"}/>
                <div className={"title"}>市场出售</div>

                <div className={"content-container"}>
                    <div className={"sub-title"}>出发建筑（此处）：</div>
                    {/*<StorageBullet building={buildingSummary}/>*/}
                    <div className={"building-line"}>
                        <StorageBullet building={buildingSummary}/>
                    </div>
                    <div className={"sub-title"}>交易物品：</div>
                    {trueArr.map((trueIndex: any, index: number) =>
                        <div className={"list-item-building list-item-building-storage"}
                             key={props.storage[trueIndex]?.item_type + "1" + props.storage[trueIndex]?.item_tire}>
                            <div className={"left side"}>
                                <div className={"starter-text"}>提供</div>
                                <Item id={props.storage[trueIndex]?.item_type} t={props.storage[trueIndex]?.item_tire}
                                      mr={0.5}/>
                                <div className={"two-story-text"}>
                                    <div className={"up black provide-item"}>
                                        <TextField size="small" label={"提供总量"}
                                                   value={(sellItems[index]?.quantity)}
                                                   onChange={event => setSellTotal(event, index)}
                                                   type={"number"}
                                                   InputProps={{
                                                       endAdornment: <InputAdornment position="end">
                                                           /{props.storage[trueIndex]?.item_in_storage ? (ifNumberFloor2(props.storage[trueIndex]?.item_in_storage)) : ""}
                                                       </InputAdornment>,
                                                   }}
                                        />
                                    </div>
                                    <div className={"down"}>
                                        {itemInfo(props.storage[trueIndex]?.item_type)?.Chs + TierToStars[props.storage[trueIndex]?.item_tire]} -
                                        运输损耗 0%
                                    </div>
                                </div>

                            </div>
                            <div className={"right side"}>
                                <div className={"starter-text"}>需求</div>
                                <ItemSelector mr={0.5} pickItem={(id, tier) => pickItem(id, tier, index)}/>
                                <div className={"two-story-text required-item"}>
                                    {(itemInfo(buyItems[index]?.id)?.Chs) ?
                                        <TextField size="small" className={"up price"} label={"单价"} type={"number"}
                                                   value={buyItems[index]?.quantityPerItem}
                                                   onChange={event => setBuyPrice(event, index)}
                                        /> :
                                        <div
                                            className={"up " + (itemInfo(buyItems[index]?.id)?.Chs ? "" : "emphasis")}>
                                            {(itemInfo(buyItems[index]?.id)?.Chs ?? "选择物品") + TierToStars[buyItems[index]?.tier]}
                                        </div>
                                    }
                                    <div className={"down"}>
                                        {itemInfo(buyItems[index]?.id)?.Chs ? (itemInfo(buyItems[index]?.id)?.Chs + TierToStars[buyItems[index]?.tier]) : ""} -
                                        运输损耗 0%
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <StatusBar2x2 changingValueEnergy={-1.5} changingValueHappiness={-0.1} changingValueHealth={-0.1}/>
                    <div className={"buttons"}>
                        <Button variant="outlined" className={"modify-order"} color={"happiness"}
                                style={{
                                    marginTop: "0.25vw",
                                    padding: 0,
                                    maxWidth: "7vw",
                                    minWidth: "6vw",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    fontSize: '0.8vw',
                                }}
                                onClick={() => props.onClose()}
                        >
                            取消
                        </Button>
                        <LoadingButton variant="contained" className={"modify-order"}
                                       loading={false} color={"happiness"}
                                       style={{
                                           marginTop: "0.25vw",
                                           padding: 0,
                                           maxWidth: "7vw",
                                           minWidth: "6vw",
                                           maxHeight: '1.8vw',
                                           minHeight: '2vw',
                                           fontSize: '0.8vw',
                                       }}
                                       onClick={() => {
                                           apply(false)
                                       }}
                        >
                            提交到市场
                        </LoadingButton>
                    </div>
                    <div className={"sub-title"} style={skipAgentStyle}>通过贸易站寄售？
                        <div className={"description"}>贸易站的专业会计帮你的交易降低大部分运输损耗。</div>
                    </div>
                    <AgentsContainer/>

                    <StatusBar2x2 changingValueEnergy={-1.5} changingValueHappiness={-0.1} changingValueHealth={-0.1}/>
                    <div className={"buttons"} style={{display: agents.length === 0 ? "none" : "flex"}}>
                        <Button variant="outlined" className={"modify-order"} color={"happiness"}
                                style={{
                                    marginTop: "0.25vw",
                                    padding: 0,
                                    maxWidth: "7vw",
                                    minWidth: "6vw",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    fontSize: '0.8vw',
                                }}
                                onClick={props.onClose}
                        >
                            取消
                        </Button>

                        <LoadingButton variant="contained" className={"modify-order"}
                                       loading={false} color={"happiness"}
                                       style={{
                                           marginTop: "0.25vw",
                                           padding: 0,
                                           maxWidth: "7vw",
                                           minWidth: "6vw",
                                           maxHeight: '1.8vw',
                                           minHeight: '2vw',
                                           fontSize: '0.8vw',
                                       }}
                                       onClick={() => {
                                           apply(true)
                                       }}
                        >
                            寄售并提交
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </Fade>
    </Modal>

}
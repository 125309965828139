import "./Upgrade.scss"
import "../../../../components/GenericModalStyle/GenericModalStyle.scss"
import * as React from "react";
import {SPVSCheckMark} from "../component/CheckMark";
import {Backdrop, Button, Divider, Fade, Modal} from "@mui/material";
import {Insights} from "@mui/icons-material";
import {ProgressDots} from "../../../../components/ProgressDots";
import {useState} from "react";
import {StatusBar2x2} from "../../../../components/statusBar";
import {useDispatch, useSelector} from "react-redux";
import {BPBuildings, itemInfo} from "../../../../components/gadgets";
import {Item} from "../../../../components/IconImg";
import {BuildingSpecialType, TierToStars} from "../../../../components/mappingTable";
import {useParams} from "react-router-dom";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";

export default function UpgradeBuilding(props: {}) {
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const {buildingId} = useParams()
    const dispatch = useDispatch()
    const state = useSelector((state: any) => state.state.data)
    const [open, setOpen] = useState(false)
    const ImOwner = buildingSummary.owner.uid === state.uid

    //建筑为正常运作阶段
    const BFunctioning = buildingSummary.stage === 2
    //建筑不是合并后的
    const noMultiplied = (!buildingSummary.multiply) || buildingSummary.multiply === 1
    //建筑没有工人
    const noWorkers = !buildingSummary.worker_count
    //建筑没有房客
    const noGuests = BuildingSpecialType(buildingSummary) !== "housing" ||
        buildingSummary.residence?.guests?.length === 0
    //更高一级的建筑信息（如有）
    const upgradedBuilding = BPBuildings.find((b: any) => b.id === buildingSummary.type)?.tiers.find((t: any) => t.tier === (buildingSummary.tier + 1))
    //满足的条件数量
    const fulfilledCount = [BFunctioning, noMultiplied, noWorkers, noGuests, upgradedBuilding].filter(Boolean).length
    //条件总数
    const fulfillNeeded = 5
    //全部满足
    const fulfilledAll = fulfilledCount === fulfillNeeded


    //当前建筑花费的建材
    const resourcesThis = BPBuildings.find((b: any) => b.id === buildingSummary.type)?.tiers.find((t: any) => t.tier === buildingSummary.tier)?.cost.resources
    //升级后建筑建材
    const resourcesUpgraded = upgradedBuilding?.cost.resources ?? []
    //去掉完全抵消的建材
    const resourcesTrimmed = [...resourcesUpgraded]?.filter((rUpgraded: any) => !(
        resourcesThis.find((rThis: any) =>
            rUpgraded.id === rThis.id &&
            rUpgraded.tier === rThis.tier &&
            rUpgraded.quantity <= rThis.quantity
        ))) ?? []
    //最终升级建材 (更新后直接用高级的建筑的建材)
    const resourcesFinal = resourcesUpgraded
    const resourcesFinal1 = [...resourcesTrimmed]?.map((rUpgraded: any, index) => {
            //当前建筑同种同级的数量
            const sameTierQuantity = resourcesThis?.find((rThis: any) =>
                rUpgraded.id === rThis.id &&
                rUpgraded.tier === rThis.tier
            )?.quantity ?? 0
            //当前建筑同种低一级的数量
            const lowerTierQuantity = resourcesThis?.find((rThis: any) =>
                rUpgraded.id === rThis.id &&
                rUpgraded.tier === rThis.tier + 1
            )?.quantity ?? 0
            return {
                "id": rUpgraded.id,
                "tier": rUpgraded.tier,
                "quantity": rUpgraded.quantity - sameTierQuantity - 0.25 * lowerTierQuantity
            }
        }
    )

    const upgrade = () => {

        api_with_auth("/api/building/" + buildingId + "/supervisor/upgrade", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "花费建材后建筑已升级！",
                        }))
                        setOpen(false)
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"upgrade-building-root"}>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={BFunctioning ? "check" : "cross"}/>建筑处在正常运作阶段。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noMultiplied ? "check" : "cross"}/>建筑不是一座合并后的建筑。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noWorkers ? "check" : "cross"}/>建筑没有工人。</div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noGuests ? "check" : "cross"}/>如果建筑是一座住房，没有房客。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={upgradedBuilding ? "check" : "cross"}/>建筑有更高一级的版本。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>投入建筑材料，种类相同的部分享受减扣。</div>

        <div className={"execute-btn-container"}>
            <Button variant={"contained"} color={"happiness"}
                    disabled={!ImOwner}
                    onClick={() => {
                        setOpen(true)
                    }}
            >
                {ImOwner ? "详情" : "仅限所有者"}</Button>
        </div>
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={"generic-modal-root"}
        >
            <Fade in={open}>
                <div className={"modal-content-root"}>
                    <div className={"title-image title-image1"}/>
                    <div className={"title-image title-image2"}/>
                    <div className={"title"}>升级建筑</div>
                    <div className={"content-container"}>
                        <Divider variant={"middle"} flexItem>条件（{fulfilledCount}/{fulfillNeeded}）</Divider>
                        <div className={"conditions-container " + (fulfilledAll ? "yes" : "no")}>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={BFunctioning ? "check" : "cross"}/>建筑处在正常运作阶段。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noMultiplied ? "check" : "cross"}/>建筑不是一座合并后的建筑。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noWorkers ? "check" : "cross"}/>建筑没有工人。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noGuests ? "check" : "cross"}/>如果建筑是一座住房，没有房客。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={upgradedBuilding ? "check" : "cross"}/>建筑有更高一级的版本。
                            </div>
                        </div>
                        <Divider variant={"middle"} flexItem>结果</Divider>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>建筑星级+1。</div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>建筑回到建设阶段。</div>
                        <Divider variant={"middle"} flexItem>消耗</Divider>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>从藏身处支付升级后建筑的全额建筑材料。
                        </div>
                        {/*<div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>与当前建筑相比，种类和星级都相同的建筑材料完全减免。*/}
                        {/*</div>*/}
                        {/*<div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>与当前建筑相比，星级更高一级的同种建筑材料减免25%。*/}
                        {/*</div>*/}

                        <div className={"resources-container"}>
                            {resourcesFinal.length !== 0 ? (resourcesFinal.map((resource: any, index: number) =>
                                <div className={"resource"}>
                                    <Item id={resource.id} t={resource.tier} mr={0.7}/>
                                    <div className={"resource-text"}>
                                        <div className={"up"}>
                                            {resource.quantity}
                                        </div>
                                        <div className={"down"}>
                                            {itemInfo(resource.id)?.Chs}{TierToStars[resource.tier]}
                                        </div>
                                    </div>
                                </div>
                            )) : <div/>}
                        </div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>消耗属性来开始建筑升级。</div>
                        <StatusBar2x2 changingValueEnergy={-17} changingValueHappiness={-2} changingValueHealth={-2}
                                      changingValueHungry={-0}/>
                        <Button variant={"contained"} color={"happiness"} disabled={!fulfilledAll}
                                onClick={upgrade}
                        >
                            {fulfilledAll ? "升级建筑" : "条件未满足"}</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    </div>
}
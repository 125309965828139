import "./Housing.scss"
import "../../../components/Styles/BazaarAndDistrictFilter/BADFilterStyle.scss"
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    InputLabel,
    ListItemButton,
    MenuItem,
    Select,
    Tooltip
} from "@mui/material";
import {SellOutlined, ShoppingBasketOutlined} from "@mui/icons-material";
import ItemSelector from "../../../components/ItemSelector";
import * as React from "react";
import {BPBuildingInfo, districtIdToName, itemInfo} from "../../../components/gadgets";
import {buildingStageToChs, TierToStars} from "../../../components/mappingTable";
import {shippingLossTip} from "../../../components/ToolTips";
import {BuildingInList, Item} from "../../../components/IconImg";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {api_with_auth} from "../../../utils/api";
import {openSnackbar} from "../../../appSnackbarSlice";
import {useNavigate} from "react-router-dom";

export default function DistrictHousing(props: {}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

    //https://api.civitasrise.com/api/buildings/leasing_market
    const [leasingMarket, setLeasingMarket] = useState({
        "page": 0,
        "pageSize": 20,
        "totalPages": 20,
        "totalResults": 300,
        "content": [
            {
                //合同id
                "id": 1,
                //建筑id
                "buildingId": 1,
                //建筑名字
                "name": "寻找住房",
                //建筑头像
                "avatar": "",
                "belong_city": 0,
                "belong_districts": 1,
                "belong_terrain": 1,
                //租金
                "rent": {
                    // 种类
                    "type": 1,
                    //租金数量
                    "quantity": 1,
                    //租金星级
                    "tier": 1,
                },
                //单次租房周期（天）
                "duration": 7,
                //是否允许租客续租
                "allowRenewal": true,
            },
        ]
    })


    useEffect(() => {
        api_with_auth("/api/buildings/leasing_market", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                    if (res.status === "success") {
                        setLeasingMarket(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }, [])

    const LeasingBulletRender = () => {
        return <div className={"leasing-bullet-render-root"}>
            {leasingMarket.content?.map((leasing: any) =>
                <ListItemButton className={"leasing-bullet-root"} key={leasing.id} disableRipple
                onClick={()=>navigate(`/game/building/` + leasing.buildingId)}
                >
                    <div className={"list-item-building"}>
                        <Avatar alt={leasing.name} src={leasing.avatar}
                                sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                                variant="rounded"
                        >{leasing.name?.[0]}</Avatar>

                        <div className={"two-story-text"}>
                            <div className={"up"}>
                                {leasing.name}
                            </div>
                            <div className={"down"}>
                                {/*{BPBuildingInfo(leasing.selling.type)?.name}{TierToStars[leasing.selling.tier]}{" - "}*/}
                                {/*{districtIdToName(leasing.selling.belong_districts, cityStructure)}*/}
                                {/*<Tooltip title={shippingLossTip} arrow*/}
                                {/*         disableInteractive><u>🛈</u></Tooltip>）*/}
                                住房种类-{BPBuildingInfo(leasing.type)?.name}
                            </div>
                        </div>
                        <div className={"two-story-text right margin-left-auto"}>
                            <div className={"up"}>
                                &nbsp;
                            </div>
                            <div className={"down"}>
                                租金
                            </div>
                        </div>
                        <Item id={leasing.rent.type} t={leasing.rent.tier} ml={0.3} mr={0.3}/>
                        <div className={"two-story-text four-vw"}>
                            <div className={"up"}>
                                {(leasing.rent.quantity).toFixed(2)}
                            </div>
                            <div className={"down"}>
                                {itemInfo(leasing.rent.type).Chs}{TierToStars[leasing.rent.tier]}
                            </div>
                        </div>
                        <div className={"two-story-text right four-vw"}>
                            <div className={"up"}>
                                {leasing.duration}天
                            </div>
                            <div className={"down"}>
                                租房周期
                            </div>
                        </div>
                        <div className={"two-story-text right seven-vw"}>
                            <div className={"up"}>
                                {leasing.allowRenewal ? "可续租" : "单期租住"}
                            </div>
                            <div className={"down"}>
                                允许续租?
                            </div>
                        </div>
                        <BuildingInList id={38} t={1}/>
                        <Button variant={"text"} color={"happiness"}
                                style={{
                                    margin: "0.8vw 1.5vw 0.8vw 0.5vw",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    maxWidth: "4vw",
                                    minWidth: "4vw",
                                    fontSize: '0.8vw',
                                    padding: 0,
                                }}>造访</Button>
                    </div>
                </ListItemButton>
            )}

        </div>
    }


    return <div className={"district-housing-root"}>

        {/*过滤器*/}
        <div className={"filters-container"}>
            {/*    <div className={"filters"}>*/}
            {/*        <FormControl>*/}
            {/*            <InputLabel>住房位置</InputLabel>*/}
            {/*            <Select label="订单位置" value={""} onChange={(e) => {*/}
            {/*            }}>*/}
            {/*                /!*{BPItems.map((item: any, index: any) =>*!/*/}
            {/*                /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
            {/*                /!*)}*!/*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*        <FormControl>*/}
            {/*            <InputLabel>住房种类</InputLabel>*/}
            {/*            <Select label="建筑状态" value={""} onChange={(e) => {*/}
            {/*            }}>*/}
            {/*                <MenuItem value={2} key={2}>棚屋</MenuItem>*/}
            {/*                <MenuItem value={0} key={0}>宅院</MenuItem>*/}
            {/*                <MenuItem value={1} key={1}>楼房</MenuItem>*/}
            {/*                <MenuItem value={3} key={3}>别墅</MenuItem>*/}
            {/*                /!*{BPItems.map((item: any, index: any) =>*!/*/}
            {/*                /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
            {/*                /!*)}*!/*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*        /!*<SellOutlined/>*!/*/}
            {/*        /!*<ItemSelector pickItem={(id, tier) => {*!/*/}
            {/*        /!*}} title={"租金种类"} ml={0.3} mr={1}/>*!/*/}
            {/*        <ShoppingBasketOutlined/>*/}
            {/*        <ItemSelector pickItem={(id, tier) => {*/}
            {/*        }} title={"租金种类"} ml={0.3} mr={1}/>*/}
            {/*        /!*<ItemSelector pickItem={(id, tier) => {*!/*/}
            {/*        /!*}} title={"所需货物"} ml={0.3} mr={1}/>*!/*/}
            {/*    </div>*/}
        </div>
        <Divider variant="middle"
                 className="middle-divider"
        />
        <LeasingBulletRender/>
        <div className={""}></div>
        <div className={""}></div>
    </div>

}
import "./WorkingResults.scss"
import "../../extraGlobalStyle.scss"
import {Fade, Modal, Backdrop, Avatar, Divider} from "@mui/material";
import {useState} from "react";
import Category, {ArrowForward, Insights, Man} from '@mui/icons-material';
import {useSelector} from "react-redux";
import {id2color} from "../../utils/id2color";
import {StatusBar1x4} from "../statusBar";
import {BPSkills, FindPlayerSkillProgress, FindSkillLevel, Floor1, Floor2, itemInfo, skillInfo} from "../gadgets";
import * as React from "react";
import {Item, Skill} from "../IconImg";
import {ProgressDots} from "../ProgressDots";
import {capacityBuff, TierToStars} from "../mappingTable";
import {ConstructionMethodBullet} from "../../Buildings/Building/components/MethodBullet";


export default function WorkingResults(props: {
    type: "working" | "sideJob"
    result: any,
    open: boolean,
    close: () => void,
}) {
    const typeTitle = {
        "working": {
            "title": "工作",
            "pages": 3,
        },
        "sideJob": {
            "title": "副业",
            "pages": 2,
        }
    }
    const status = useSelector((state: any) => state.status.data)
    const state = useSelector((state: any) => state.state.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)


    const open = props.open
    // const [open, setOpen] = useState(true)

    //0自身属性变化 1产能计算和产物 2薪水（如有）
    const [page, setPage] = useState(0)

    //基础产能计算
    const baseCapacity = () => {
        const major = props.result.skills.find((skill: any) =>
            Number.isInteger(skill?.id / 100)
        )?.before
        const minor = props.result.skills.find((skill: any) =>
            !Number.isInteger(skill?.id / 100)
        )?.before
        // return minor +6.4
        return 5 + (major / 6) + (minor / 3)
    }

    //最终产能计算
    const finalCapacity = () => {
        if (props.result.buffs === null) {
            return baseCapacity()
        } else {
            return Object.keys(props.result.buffs).reduce(function (previous, key) {
                return previous * props.result.buffs[key];
            }, baseCapacity());
        }
    }

    //产物展示，按一下多一个
    const [outputDisplayedCount, setOutputDisplayedCount] = useState(1)
    const displayMore = () => {
        if (outputDisplayedCount < (props.result.output.length ?? 0)) {
            setOutputDisplayedCount(outputDisplayedCount + 1)
        } else {

        }
    }

    const outputDisplayed = !props.result.output ? [] : [...props.result?.output].slice(0, outputDisplayedCount)

    //重置
    const reset = () => {
        props.close()
        setPage(0)
        setOutputDisplayedCount(1)
    }

    //产物结算后的按钮onClick
    const page1Click = () => {
        switch (props.type) {
            case "working":
                setPage(2)
                break
            case "sideJob":
                reset()
                break
            default :
                reset()
        }
    }

    const Content = () => {
        switch (page) {
            case 0:
                return <div className={"working-results-content page0"}>
                    <div className={"status-Characteristics-container"}>
                        <div className={"status"}>
                            <Avatar
                                sx={{width: "3vw", height: "3vw", bgcolor: id2color(state.uid)}}
                                src={state.avatar}
                                alt={state.nick_name}
                            >{state.nick_name ? state.nick_name[0] : ""}
                            </Avatar>
                            <StatusBar1x4/>
                        </div>
                        <Divider orientation={"vertical"} variant={"middle"} flexItem/>
                        <div className={"characteristics"}>
                            <div className={""}>这件工作没有改变< br/>你的性格习惯。</div>
                        </div>
                    </div>
                    <Divider variant={"middle"} flexItem/>
                    <div className={"skills-container"}>
                        {props.result?.skills?.map((skill: any) =>
                            <div className={"skill"} key={skill.id}>
                                <Skill id={skill.id} playerProgress={FindPlayerSkillProgress(status, skill.id)}/>
                                <div className="value-info">
                                    <div className="value">+{Floor2(skill.after - skill.before)}↑</div>
                                    <div className="name">技能提升</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={"help-text"}>技能决定工作效率。工作提升技能水平。</div>
                    {props.type === "sideJob" ? <div className={"help-text"}>副业活动提升技能水平效率为18%。</div> : null}

                    <div className={"orange-button button8"} onClick={() => setPage(1)}>属性已变化</div>
                </div>
            case 1:
                return <div className={"working-results-content page1"}>
                    <div className={"skills-capacity-container"}>
                        <div className={"skills"}>
                            {props.result.skills?.map((skill: any, index: number) =>
                                <div className={"skill"} key={skill.id}>
                                    {index === 0 ? null : <div className={"text"}>&</div>}
                                    <Skill id={skill.id} playerProgress={FindPlayerSkillProgress(status, skill.id)}/>
                                    <div className="value-info">
                                        <div className="value">{Floor1(FindSkillLevel(status, skill.id))}</div>
                                        <div className="name">{skillInfo(skill.id).Chs}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <ArrowForward/>
                        <div className="value-info">
                            <div className="value">{Floor1(baseCapacity())}</div>
                            <div className="name">基础产能</div>
                        </div>
                    </div>
                    <Divider variant={"middle"} flexItem/>
                    <div className={"buffs-container"}>
                        <div className={"buffs"}>
                            <div className={"sub-title"}>产能修改器</div>
                            {props.result.buffs === null ?
                                <div className={"buff"}>一切如常：产能没有受到调整</div> :
                                Object.keys(props.result.buffs).map((buff: any, index) =>
                                    <div className={"buff"} key={buff}
                                    >{capacityBuff[buff]}：{Math.floor(props.result.buffs[buff] * 100)}%</div>
                                )}
                        </div>
                        <ArrowForward/>
                        <div className="value-info">
                            <div className="value">{Floor1(finalCapacity())}</div>
                            <div className="name">最终产能</div>
                        </div>
                    </div>
                    <Divider variant={"middle"} flexItem/>
                    <div className={"output-container"}>
                        <div className={"sub-title"}>消耗物资</div>
                        {props.result.input === null ?
                            <div className={"description"}>没有消耗资源</div>
                            : props.result?.input?.map((input: any, index: number) =>
                                <div className={"item"} key={input.id + "" + input.tier}>
                                    <Item id={input.id} t={input.tier}/>
                                    <div className={"text"}>{Floor2(input.quantity)}{itemInfo(input.id).Chs}</div>
                                </div>
                            )}
                    </div>
                    <Divider variant={"middle"} flexItem/>
                    <div className={"output-container"}>
                        <div className={"sub-title"}>产出物资</div>
                        {(props.type === "working" && (buildingSummary.stage === 0 || buildingSummary.stage === 1)) ?
                            <ConstructionMethodBullet buildingSummary={buildingSummary}/> : null}

                        {props.result.output === null ?
                            <div className={"description"}>没有产出实物资源</div>
                            : outputDisplayed.map((output: any, index: number) =>
                                <div className={"item"} key={output.id + "" + output.tier}>
                                    <Item id={output.id} t={output.tier}/>
                                    <div className={"text"}>{Floor2(output.quantity)}{itemInfo(output.id).Chs}</div>
                                </div>
                            )
                        }

                        {(outputDisplayedCount < (props.result.output?.length ?? 0)) ?
                            <div className={"orange-button button8"} onClick={displayMore}>以及更多！
                            </div> : null
                        }
                    </div>
                    <div
                        className={"orange-button button8 " + ((outputDisplayedCount < (props.result.output?.length ?? 0)) ? "disabled" : "")}
                        onClick={page1Click}
                    >产出物资已进入本地仓库
                    </div>
                </div>
            case 2:
                return <div className={"working-results-content page2"}>
                    <div className={"sub-title"}>薪水</div>
                    <div className={"salary-container"}>
                        {props.result.salary.quantity === 0 ?
                            <div className={"item"}>
                                <div className={"text"}>
                                    志愿工作
                                </div>
                            </div> :
                            <div className={"item"}>
                                <Item id={props.result.salary.id} t={props.result.salary.tier}/>
                                <div
                                    className={"text"}>{Floor2(props.result.salary.quantity)}{itemInfo(props.result.salary.id)?.Chs}{TierToStars[props.result.salary.tier]}</div>
                            </div>
                        }
                    </div>
                    <div className={"description"}>薪水=单位产能薪水×工人基础产能</div>
                    <div className={"orange-button button8"} onClick={reset}>已从雇主收到薪水，在你的私人库房
                    </div>
                </div>
            default:
                return <div className={"working-results-content"}>错误的页码</div>
        }
    }
    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={reset}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        className={"working-results-modal-root"}
    >
        <Fade in={open}>
            <div className={"working-results-root"}>
                <div className={"title-image title-image1"}/>
                <div className={"title-image title-image2"}><Insights/></div>
                <div className={"title"}>{typeTitle[props.type].title}结算</div>
                <Content/>
                <ProgressDots currentStep={page} totalSteps={typeTitle[props.type].pages}/>
                <div className={""}></div>
                <div className={""}></div>
            </div>
        </Fade>
    </Modal>
}
import {useSelector} from "react-redux";
import {api_host, api_with_auth} from "../../../utils/api";
import "./recruit.scss"
import {useEffect, useState} from "react";
import {getLoginToken} from "../../../utils/storage";
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    InputLabel, List,
    ListItemAvatar,
    ListItemButton,
    ListItemText, MenuItem, Select
} from "@mui/material";
import {Link} from "react-router-dom";
import {DistrictTabButtons} from "../../../components/districtAndBazaarTabs";
import * as React from "react";
import {
    constructionHint, FindBuildingDistrictName,
} from "../../Building/components/WorkCard/WorkCard";
import {
    BaseCapacity,
    BPBuildingInfo,
    FindBuildingSkillLevel,
    FindBuildingSkillName,
    itemInfo
} from "../../../components/gadgets";
import {TierToStars} from "../../../components/mappingTable";
import {RecruitBullet} from "../../../components/ListItemBuilding";

export default function Recruit() {
    //自己
    //自己
    const status = useSelector((state: any) => state.status.data)
    const state = useSelector((state: any) => state.state.data)
    const cityStructure = useSelector((state: any) => state.cityStructure.data)


// 招聘的建筑表默认值
    const [hiringBuildings, setHiringBuildings] = useState<any>({
        "data": [
            {
                "id": 1,
                "owner": {
                    "uid": 4,
                    "nick_name": '加载中',
                    "avatar": '',
                    "born_city": 0
                },
                "owner_id": 4,
                "manager": {
                    "uid": 4,
                    "nick_name": '加载中',
                    "avatar": '',
                    "born_city": 0
                },
                "manager_id": 4,
                "name": '加载中',
                "avatar": '',
                "type": 2,
                "tier": 1,
                "max_work_count": 200,
                "worker_count": 16,
                "is_hiring": true,
                'salary_number': 0.4,
                "salary_type": 5,
                "salary_tier": 1,
                "storage_capacity": 107.8,
                "max_storage_capacity": 40000,
                "belong_city": 0,
                "belong_districts": 1,
                "belong_terrain": 1,
                "culture": 0,
                "stage": 2,
                "percent": 100,
                "product_type": 2,
                "in_day_product_type": 0,
                "working_method": 0,
                "in_day_working_method": -1,
                "architect": 4
            }
        ],
        "status": "success"
    })

//获取招聘建筑列表
    useEffect(() => {
            api_with_auth("/api/buildings/hiring", "GET", )
                .then(res => {
                    if (!(res.data === undefined) && !(res.data === null)) {
                        setHiringBuildings(res)
                    } else {

                    }
                })
        }
        , [])

    //生成招聘建筑列表
    const hiringBuildingsRender = hiringBuildings.data.map((building: any, index: any) =>
        // <div>
            <ListItemButton className={"RC-bullet-container"} key={building.id}  component={Link} to={"/game/building/"+building.id}>
            <RecruitBullet  building={building} />
            </ListItemButton>
        // </div>
    )

    return (

            <div className={"district-recruit-root"}>

                {/*以下商品过滤器*/}

                <div className={"selectors-container"}>
                    {/*<div className={"selectors-container-container"}>*/}
                    {/*    <FormControl sx={{m: "0.3vw", minWidth: "10vw"}}>*/}
                    {/*        <InputLabel htmlFor="grouped-native-select" sx={{fontSize: "0.8vw"}}>功能</InputLabel>*/}
                    {/*        <Select id="grouped-native-select" label="功能" defaultValue={1} sx={{fontSize: "0.8vw"}}>*/}
                    {/*            <MenuItem value={1}>应聘</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*    <FormControl sx={{m: "0.3vw", minWidth: "10vw"}}>*/}
                    {/*        <InputLabel htmlFor="grouped-native-select" sx={{fontSize: "0.8vw"}}>设施</InputLabel>*/}
                    {/*        <Select id="grouped-native-select" label="设施" defaultValue={1} sx={{fontSize: "0.8vw"}}>*/}
                    {/*            <MenuItem value={1}>所有种类</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*    <FormControl sx={{m: "0.3vw", minWidth: "10vw"}}>*/}
                    {/*        <InputLabel htmlFor="grouped-native-select" sx={{fontSize: "0.8vw"}}>报酬货币</InputLabel>*/}
                    {/*        <Select id="grouped-native-select" label="报酬货币" defaultValue={1} sx={{fontSize: "0.8vw"}}>*/}
                    {/*            <MenuItem value={1}>所有种类</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</div>*/}
                </div>


                <div className={"filters"}>
                    <div className={"filter"}>技能等级</div>
                    <div className={"filter"}>单位薪水</div>
                    <div className={"filter"}>预计薪水</div>
                </div>
                <Divider variant="middle"
                         className="middle-divider"
                />

                {/*以下商品列表*/}

                <div className={"goods-list-container"}>
                    <List sx={{
                        width: '100%',
                        maxWidth: "100%",
                        padding: "0",
                        // backgroundColor: 'gray'
                    }}>
                        {hiringBuildingsRender}
                    </List>
                </div>
            </div>

    )
}


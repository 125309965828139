import * as React from 'react'
import {api_host, api_with_auth} from "../utils/api";
import "./personal.scss"
import {
    Avatar,
    Button,
    Divider, List, Typography,
} from '@mui/material';
import {useEffect, useState} from "react";
import {getLoginToken} from "../utils/storage";
import {
    MoreHoriz,
    EmailOutlined,
    RedeemOutlined,
    ThumbUpOutlined,
    ThumbDownOutlined,
    Book, Work, Hotel,
    Home, KeyboardArrowDown, KeyboardArrowUp, SportsMartialArts,
} from '@mui/icons-material';
import {id2color} from "../utils/id2color";
import {useDispatch, useSelector} from "react-redux";
import {useAppDispatch} from "../store";
import TimeToNow from "../components/timeToNow";
import SpeechBullet from "../Forum/components/speechBullet";
import {ListItemButton} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import jobless from "../Buildings/assets/building_seal.webp";
import {openSnackbar} from "../appSnackbarSlice";
import {ListItemBuildingPersonal} from "../components/ListItemBuilding";
import {BPBuildingInfo, skillInfo, SkillsSum} from "../components/gadgets";
import blank from "../assets/blank.webp"
import {stringify} from "querystring";
import {
    fetchOtherUserStatusNow, fetchUserById,
    updateOtherUserStatusWPayload
} from "../components/Fetch/otherUserStatusSlice";
import Traits from "./traits";
import Filler from "../components/Filler";
import SkillsModal from "./Skills";

export const FetchOtherUserStatus1 = (uid: number) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    api_with_auth("/api/user/" + uid + "/status", "GET")
        .then(res => {
                if (res.status === "success") {
                    dispatch(updateOtherUserStatusWPayload(res.data))
                }
            },
        );
}

export function Personal() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    //我的信息
    const state = useSelector((state: any) => state.state.data)
    const status = useSelector((state: any) => state.status.data)
    //其他玩家的信息（url参数获取）
    const otherUserStatus = useSelector((state: any) => state.otherUserStatus.data)
    const fetchOtherUserStatusNowIn = useSelector((state: any) => state.otherUserStatus.fetchNow)

    const {UId} = useParams()
    //是我？
    const me = (UId ? parseInt(UId) : UId) === state.uid


    //本页不是我就发出fetch信号，fetch玩家信息
    useEffect(() => {
        if (!me) {
            // dispatch(fetchOtherUserStatusNow())
            dispatch(fetchUserById(parseInt(UId ?? "-1")))
        }
    }, [UId])

    // useEffect(() => {
    //     //收到fetch信号，本页面不是我，而且fetch标号不为0
    //     if (!me && fetchOtherUserStatusNowIn !== 0) {
    //         FetchOtherUserStatus1(parseInt(UId ?? "-1"))
    //     }
    // }, [fetchOtherUserStatusNowIn])

    //本页显示的status信息
    const statusDisplayed = [{...state, ...status}, otherUserStatus][me ? 0 : 1]

    //菜单栏互动按钮呼出snackbar
    const testSnackbar = () => {
        dispatch(openSnackbar({
            "severity": "info",
            "snackbarMessage": "没有准备好。",
        }))
    }


    //技能总和
    const skillsSum = SkillsSum(statusDisplayed)
    //技能总和显示，位数太多就四舍五入，保证总长合适
    const skillsSumDisplay = (skillsSum > 99) ? skillsSum.toFixed(0) : skillsSum.toFixed(1);

    //技能按等级倒序
    const skillsSort = Object.values(statusDisplayed.skills).sort(function (a: any, b: any) {
        return b.level - a.level
    });

    //头条显示的最佳技能和次佳技能
    const bestSkill: any = skillsSort[0];
    const bestSkillImg = "/img/skills/" + skillInfo(bestSkill?.type)?.Eng + ".webp";
    const secondBestSkill: any = skillsSort[1];
    const secondBestSkillImg = "/img/skills/" + skillInfo(secondBestSkill?.type)?.Eng + ".webp";


    //工作地点的 名字、种类、插画（如有）
    const workBtnTo = (statusDisplayed.employee && statusDisplayed.employee.length) ?
        `/game/building/${statusDisplayed.employee[0].buildingID}/works` :
        `/game/district`
    //工作地址名字种类暂无api，只有id
    const [fetchedWorkPlaceInfo, setFetchedWorkPlaceInfo] = useState({
        "id": 1,
        "owner": {
            "uid": 4,
            "nick_name": '加载中',
            "avatar": '',
            "born_city": 0
        },
        "owner_id": 4,
        "manager": {
            "uid": 4,
            "nick_name": '加载中',
            "avatar": '',
            "born_city": 0
        },
        "manager_id": 4,
        "name": '加载中',
        "avatar": '',
        "type": 2,
        "tier": 1,
        "max_work_count": 200,
        "worker_count": 16,
        "is_hiring": true,
        'salary_number': 0.4,
        "salary_type": 5,
        "salary_tier": 1,
        "storage_capacity": 107.8,
        "max_storage_capacity": 40000,
        "belong_city": 0,
        "belong_districts": 1,
        "belong_terrain": 1,
        "culture": 0,
        "stage": 2,
        "percent": 100,
        "product_type": 2,
        "in_day_product_type": 0,
        "working_method": 0,
        "in_day_working_method": -1,
        "architect": 4
    })
    const workPlaceName = (statusDisplayed.employee && statusDisplayed.employee.length) ?
        fetchedWorkPlaceInfo.name : "没有工作"
    const workPlaceType = (statusDisplayed.employee && statusDisplayed.employee.length) ?
        BPBuildingInfo(fetchedWorkPlaceInfo.type)?.name : "去找一个"

    const bgSrc = "/img/buildings/" + BPBuildingInfo(fetchedWorkPlaceInfo.type)?.Eng + "_" + ((fetchedWorkPlaceInfo.culture === 0) ? "east" : "west") + ".webp"
    const workPlaceBgImage = (statusDisplayed.employee && statusDisplayed.employee.length) ? bgSrc : jobless;
    const hideoutBgImage = "/img/buildings/hideout_east.webp"


    //显示的人在本城市的藏身处
    const meHideout = useSelector((state: any) => state.myHideOutStorage.data.id)
    const displayedHideout = me ? meHideout : statusDisplayed.hideout.find((h: any) => h.belong_city === status.now_city)?.id


    //自己工作地址
    //TODO：整个这俩卡片，逻辑混乱，迟早重写
    const workBuildingId = (statusDisplayed.employee && statusDisplayed.employee.length) ? statusDisplayed.employee[0].buildingID : 1;
    // const buildingId =  1;
    // const [hideout, setHideout] = useState(1)
    useEffect(() => {
        api_with_auth("/api/building/" + workBuildingId, "GET",)
            .then(res => {
                if (res.status === "success") {
                    setFetchedWorkPlaceInfo(res.data)
                } else {
                }
            })

    }, [workBtnTo])

    //技能modal弹窗
    const [skillsModal, setSkillsModal] = useState(false)


    //我的建筑。因为fetch里提前拿好，这里直接看，保留他，把别人的建筑分开写发，判断展示。
    const myBuildings = useSelector((state: any) => state.myBuildings.data)

    //玩家id获得玩家拥有的建筑
    const [playerOwnedBuildings, setPlayerOwnedBuildings] = useState<any>({"buildings": [], "count": 0})
    useEffect(() => {
            api_with_auth("/api/buildings/user/" + UId, "GET",)
                .then(res => {
                    if (res.status === "success") {
                        setPlayerOwnedBuildings(res.data)
                    }
                })
        }
        , [])

//假的书列表
    const bookList = {
        data: [
            {
                id: 1,
                name: "布衣",
                authorId: 1,
                authorName: "匿名",
                description: "注释内容",
                material: "锦帛",
                durability: 0.45,
                timeWrote: 1234567,
                isOrigin: true,
            },
            {
                id: 2,
                name: "公共餐饮福利计划",
                authorId: 1,
                authorName: "匿名",
                description: "这是抄本…",
                material: "锦帛",
                durability: 0.45,
                timeWrote: 1234567,
                isOrigin: false,
            },
        ],

    }
//渲染书列表
    const bookListRender = bookList.data.map((book: any, index: any) =>
        <ListItemButton key={book.id} className="text-list-bullet book-list-bullet">
            <div className="left">
                <div className="title">
                    《{book.name}》
                </div>
                - {book.authorName} 著，{book.description}
            </div>
            <div className="right">
                {book.material}材质{book.isOrigin ? "原本" : "副本"}，耐久{(book.durability * 100)?.toFixed(1)}%，著于第16日
            </div>
        </ListItemButton>
    )

    //过去的演讲
    const [fetchPastSpeechNow, setFetchPastSpeechNow] = useState(0)
    const [pastSpeech, setPastSpeech] = useState([{
        "ID": 90,
        "Uid": 13,
        "Uname": "加载中",
        "Uavatar": "",
        "Time": 1645702105965125000,
        "Content": "",
        "NumReaction1": 0,
        "NumReaction2": 0,
        "NumReaction3": 0,
        "MyReaction": 0
    }])
    useEffect(() => {
            api_with_auth("/api/speech/past_speech?userId=" + UId + "&pageSize=20", "GET",)
                .then(res => {
                        if (res.status === "success") {
                            setPastSpeech(res.data)
                        }
                    },
                );
        }
        , [fetchPastSpeechNow, UId])

//假的演讲列表
    const PastSpeechListRender = () => {
        return <List sx={{width: '100%', maxWidth: "28.5vw", m: "0 0.6vw", p: "0"}}>
            {pastSpeech ?
                (pastSpeech?.map((speech: any, index: number) =>
                    <div key={speech.ID}>
                        <SpeechBullet
                            speech={speech}
                            updateSpeech={() => setFetchPastSpeechNow(fetchPastSpeechNow + 1)}/>
                        <Divider variant={"middle"}/>
                    </div>
                )) : <Filler title={"不曾发表过演讲"}/>}
        </List>
    }
//正文
    return (
        <div className="personalRoot">
            <div className={"left-container"}>
                <div className="menu-bar">
                    <div className="buttons">
                        <Button className="button" onClick={testSnackbar}><EmailOutlined/></Button>
                        <Button className="button" onClick={testSnackbar}><RedeemOutlined/></Button>
                        <Button className="button" onClick={testSnackbar}><ThumbUpOutlined/></Button>
                        <Button className="button" onClick={testSnackbar}><ThumbDownOutlined/></Button>
                        <Button className="button" onClick={testSnackbar}><MoreHoriz/></Button>
                    </div>
                </div>
                <div className="banner-container">
                    <div className="banner">
                        <div className="ribbons">
                            <div className="ribbon ribbon1"></div>
                            <div className="ribbon ribbon2">中原民族，中原文化认同</div>
                            <div className="ribbon ribbon3">技能水平成就（施工中）</div>
                        </div>
                        <div className="pfp">
                            <Avatar alt="banner-pfp" src={statusDisplayed.avatar}
                                    sx={{
                                        width: "5.5vw",
                                        height: "5.5vw",
                                        marginRight: "0vw",
                                        bgcolor: id2color(me ? statusDisplayed.uid : statusDisplayed.user_id)

                                    }}
                                    style={{fontSize: "2.4vw"}}>{statusDisplayed.nick_name ? statusDisplayed.nick_name[0] : ""}</Avatar>
                        </div>
                        <div className="name">
                            <div className="name-text">
                                {statusDisplayed.nick_name ? statusDisplayed.nick_name : "正在寻人"}
                            </div>
                            <div className="description">
                                {TimeToNow(statusDisplayed.user.created_at)}前来到长安
                            </div>
                        </div>
                    </div>
                    <div className="tagIcon">抢鲜体验</div>
                </div>


                <div className="relation-skill">
                    {/*人际关系*/}
                    <div className="relation RS-card">
                        <Button variant="text"
                                color="warning"
                                style={{
                                    position: "absolute",
                                    zIndex: "3",
                                    height: "9.7vw",
                                    width: "95%",
                                    top: "1.8vw",
                                    left: "0.5vw",
                                    color: "#ed6c02",
                                    borderColor: "#ed6c02",
                                }}
                            // onClick={handleClick}
                        />
                        <div className="title">人际关系</div>
                        <div className="content">
                            <div className="sum">
                                <div className={"sum-number " + ((0 >= 100) ? "fancyLargeNumber" : "")}>/0</div>
                                <div className="sum-title">关系总和</div>
                            </div>
                            <div className="examples">
                                <div className="example">
                                    <Avatar alt="example-pfp"
                                            sx={{
                                                width: "2.15vw",
                                                height: "2.15vw",
                                                marginRight: "0vw",
                                                bgcolor: id2color(1)
                                            }}>P</Avatar>
                                    <div className="example-text">
                                        <div className="example-text-up">0</div>
                                        <div className="example-text-down">好友</div>
                                    </div>
                                </div>
                                <div className="example">
                                    <Avatar alt="example-pfp"
                                            sx={{
                                                width: "2.15vw",
                                                height: "2.15vw",
                                                marginRight: "0vw",
                                                bgcolor: id2color(1)
                                            }}>P</Avatar>
                                    <div className="example-text">
                                        <div className="example-text-up">0</div>
                                        <div className="example-text-down">仇敌</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*技能水平*/}
                    <div className="skill RS-card" onClick={() => {setSkillsModal(true)}}>
                        <Button variant="text"
                                color="warning"
                                style={{
                                    position: "absolute",
                                    zIndex: "3",
                                    height: "9.7vw",
                                    width: "95%",
                                    top: "1.8vw",
                                    left: "0.5vw",
                                    color: "#ed6c02",
                                    borderColor: "#ed6c02",
                                }}
                            // onClick={handleClick}
                        />
                        <div className="title">技能水平</div>
                        <div className="content">
                            <div className="sum">
                                <div
                                    className={"sum-number " + ((skillsSum >= 1000) ? "fancyLargeNumber" : "")}>{skillsSumDisplay}</div>
                                <div className="sum-title">技能总和</div>
                            </div>
                            <div className="examples">
                                <div className="example">
                                    {bestSkill === undefined ? <div/> :
                                        <img src={process.env.PUBLIC_URL + bestSkillImg}
                                             onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                 event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                                                 event.currentTarget.onerror = null;
                                             }}
                                             className={"example-img"}
                                             alt={"最佳技能"}/>}
                                    <div className="example-text">
                                        <div
                                            className="example-text-up">{bestSkill === undefined ? "" : bestSkill.level?.toFixed(1)}</div>
                                        <div
                                            className="example-text-down">{bestSkill === undefined ? "" : skillInfo(bestSkill.type)?.Chs}</div>
                                    </div>
                                </div>
                                <div className="example">
                                    {secondBestSkill === undefined ? <div/> :
                                        <img src={process.env.PUBLIC_URL + secondBestSkillImg}
                                             onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                 event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                                                 event.currentTarget.onerror = null;
                                             }}
                                             className={"example-img"}
                                             alt={"第二技能"}/>}
                                    <div className="example-text">
                                        <div
                                            className="example-text-up">{secondBestSkill === undefined ? "" : secondBestSkill.level.toFixed(1)}</div>
                                        <div
                                            className="example-text-down">{secondBestSkill === undefined ? "" : skillInfo(secondBestSkill.type)?.Chs}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*性格*/}
                <div className="books">
                    <div className="title-line expandable-title-line">
                        <div className="title-line-text">
                            <SportsMartialArts/>
                            {me ? "我" : "此人"}的性格习惯
                        </div>
                    </div>
                    <Traits statusDisplayed={statusDisplayed}/>
                </div>
                <Divider variant="middle"/>

                {/*藏书*/}
                {/*<div className="books">*/}
                {/*    <div className="title-line expandable-title-line">*/}
                {/*        <div className="title-line-text">*/}
                {/*            <Book/>*/}
                {/*            {me ? "我" : "此人"}的2部藏书(施工中)*/}
                {/*        </div>*/}
                {/*        <KeyboardArrowDown/>*/}
                {/*    </div>*/}
                {/*    {bookListRender}*/}
                {/*</div>*/}
                {/*<Divider variant="middle"/>*/}

                {/*工作和住所*/}
                <div className="work-n-residence">
                    {/*工作*/}
                    <div className="work">
                        <div className="title-line">
                            <div className="title-line-text">
                                <Work/>
                                {me ? "我" : "此人"}的工作地点
                            </div>
                        </div>
                        <Link className="work-card-container" to={workBtnTo}
                              style={{
                                  backgroundImage: `linear-gradient(to right, rgba(248,241,225,1) 35%, rgba(248,241,225,0) 55%),url(${workPlaceBgImage})`
                              }}>
                            <div className="title">
                                {workPlaceName}
                            </div>
                            {workPlaceType}
                        </Link>
                    </div>
                    {/*住所*/}
                    <div className="residence">
                        <div className="title-line">
                            <div className="title-line-text">
                                <Hotel/>
                                {me ? "我" : "此人"}的住所
                            </div>
                        </div>
                        {/*<Link className={"residence-card-container " + (me ? "" : "disabled-link")}*/}
                        <Link className={"residence-card-container "}
                              to={`/game/building/${displayedHideout}/storage`}
                              style={{
                                  backgroundImage: `linear-gradient(to right, rgba(248, 241, 225, 1) 35%, rgba(248, 241, 225, 0) 55%),url("/img/buildings/hideout_east.webp")`
                              }}>
                            {/*TODO:住房类型写死了。做住宅的时候需要本城市的藏身处的info接口，可以单独出来接口而不是修改，以免破坏性*/}
                            <div className="title">
                                {me ? "我的藏身陋室" : "此人的住所"}
                            </div>
                            藏身处
                        </Link>
                    </div>
                </div>
                <Divider variant="middle"/>
                <div className="my-buildings">
                    <div className="title-line expandable-title-line">
                        <div className="title-line-text">
                            <Home/>
                            {me ? "我" : "此人"}的{me ? myBuildings.count : playerOwnedBuildings.count}处不动产
                        </div>
                        <KeyboardArrowDown/>
                    </div>
                    {

                        (me ? (myBuildings.count === 0) : (playerOwnedBuildings.count === 0)) ?
                            <div className="absence-description">暂未拥有或者管理任何不动产。</div>
                            :
                            (
                                (me ? myBuildings : playerOwnedBuildings).buildings.map((building: any, index: any, array: any) =>
                                    <ListItemButton key={"personalOwnedBuildings_" + building.id} disableRipple
                                                    alignItems="flex-start"
                                                    onClick={() => {
                                                    }}
                                                    component={Link}
                                                    className={"list-item"}
                                                    to={`/game/building/${building.id}`}
                                    >
                                        <ListItemBuildingPersonal building={building}/>
                                    </ListItemButton>
                                ))
                    }
                    <Button variant={"outlined"} color={"luxury"} sx={{margin: "0.5vw auto", fontWeight: "500"}}
                            onClick={() => navigate(`/game/district/overview/blueprint`)}
                    >奠基一座新建筑</Button>
                </div>
                <Divider variant="middle"/>

            </div>
            <Divider orientation="vertical"
                     variant="middle"
                     className="middle-divider"
                     component="div"
            />

            {/*右侧面板*/}
            <div className={"right-container"}>
                <header><h2 className={"local-speech-container-text social"}>社交活动（施工中）</h2></header>
                <div className="recent-visits social-container">
                    <div className="heads-container">
                        <div style={{color: "#5f6e7b"}}>门可罗雀……</div>
                        {/*<div>*/}
                        {/*    <Avatar alt="visitor-pfp"*/}
                        {/*            sx={{*/}
                        {/*                width: "2.5vw",*/}
                        {/*                height: "2.5vw",*/}
                        {/*                mr: "0.3vw",*/}
                        {/*                bgcolor: id2color(2)*/}
                        {/*            }}>招</Avatar>*/}
                        {/*    <Avatar alt="visitor-pfp"*/}
                        {/*            sx={{*/}
                        {/*                width: "2.5vw",*/}
                        {/*                height: "2.5vw",*/}
                        {/*                mr: "0.3vw",*/}
                        {/*                bgcolor: id2color(6)*/}
                        {/*            }}>牛</Avatar>*/}
                        {/*    <Avatar alt="visitor-pfp"*/}
                        {/*            sx={{*/}
                        {/*                width: "2.5vw",*/}
                        {/*                height: "2.5vw",*/}
                        {/*                mr: "0.3vw",*/}
                        {/*                bgcolor: id2color(5)*/}
                        {/*            }}>气</Avatar>*/}
                        {/*    <Avatar alt="visitor-pfp"*/}
                        {/*            sx={{*/}
                        {/*                width: "2.5vw",*/}
                        {/*                height: "2.5vw",*/}
                        {/*                mr: "0.3vw",*/}
                        {/*                bgcolor: id2color(9)*/}
                        {/*            }}>土</Avatar>*/}
                        {/*</div>*/}
                    </div>
                    <div className="visitor-counter">
                        <div className="bold">
                            0
                        </div>
                        &nbsp;最近来访
                    </div>
                </div>
                {/*<div className="recent-interactions social-container">*/}
                {/*    <div className="interactions-top-container">*/}
                {/*        <div className="interactions-bullet">*/}
                {/*            <div className="up">*/}
                {/*                <Avatar alt="visitor-pfp"*/}
                {/*                        sx={{*/}
                {/*                            width: "3vw",*/}
                {/*                            height: "3vw",*/}
                {/*                            bgcolor: id2color(me ? statusDisplayed.uid : statusDisplayed.user_id)*/}
                {/*                        }}>{statusDisplayed.nick_name ? statusDisplayed.nick_name[0] : ""}</Avatar>*/}
                {/*                <ThumbUpOutlined style={{margin: "0 0.1vw", color: "#5f6e7b"}}/>*/}
                {/*                <Avatar alt="visitor-pfp"*/}
                {/*                        sx={{*/}
                {/*                            width: "3vw",*/}
                {/*                            height: "3vw",*/}
                {/*                            bgcolor: id2color(5)*/}
                {/*                        }}>气</Avatar>*/}
                {/*            </div>*/}
                {/*            <div className="down">*/}
                {/*                第23日，15:28*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="interactions-bullet">*/}
                {/*            <div className="up">*/}
                {/*                <Avatar alt="visitor-pfp"*/}
                {/*                        sx={{*/}
                {/*                            width: "3vw",*/}
                {/*                            height: "3vw",*/}
                {/*                            bgcolor: id2color(me ? statusDisplayed.uid : statusDisplayed.user_id)*/}
                {/*                        }}>{statusDisplayed.nick_name ? statusDisplayed.nick_name[0] : ""}</Avatar>*/}
                {/*                <RedeemOutlined style={{margin: "0 0.1vw", color: "#5f6e7b"}}/>*/}
                {/*                <Avatar alt="visitor-pfp"*/}
                {/*                        sx={{*/}
                {/*                            width: "3vw",*/}
                {/*                            height: "3vw",*/}
                {/*                            bgcolor: id2color(2)*/}
                {/*                        }}>招</Avatar>*/}
                {/*            </div>*/}
                {/*            <div className="down">*/}
                {/*                第23日，15:28*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="interactions-bullet">*/}
                {/*            <div className="up">*/}
                {/*                <Avatar alt="visitor-pfp"*/}
                {/*                        sx={{*/}
                {/*                            width: "3vw",*/}
                {/*                            height: "3vw",*/}
                {/*                            bgcolor: id2color(6)*/}
                {/*                        }}>牛</Avatar>*/}

                {/*                <ThumbDownOutlined style={{margin: "0 0.1vw", color: "#5f6e7b"}}/>*/}
                {/*                <Avatar alt="visitor-pfp"*/}
                {/*                        sx={{*/}
                {/*                            width: "3vw",*/}
                {/*                            height: "3vw",*/}
                {/*                            bgcolor: id2color(me ? statusDisplayed.uid : statusDisplayed.user_id)*/}
                {/*                        }}>{statusDisplayed.nick_name ? statusDisplayed.nick_name[0] : ""}</Avatar>*/}
                {/*            </div>*/}
                {/*            <div className="down">*/}
                {/*                第23日，15:28*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="interaction-scroll-bar">*/}

                {/*    </div>*/}
                {/*</div>*/}
                <Divider variant="middle"/>
                <header><h2 className={"local-speech-container-text"}>历史发言</h2></header>
                <PastSpeechListRender/>
            </div>
            <SkillsModal open={skillsModal} onClose={() => {setSkillsModal(false)}} status={statusDisplayed}/>
        </div>
    )
}


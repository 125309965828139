import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getLoginToken} from "../../utils/storage";
import {api_host, api_with_auth} from "../../utils/api";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {updateGameDayWPayload} from "./gameDaySlice";
import {AppDispatch, useAppDispatch} from "../../store";

// First, create the thunk
export const fetchUserById = createAsyncThunk(
    'users/fetchByIdStatus',
    async (userId: number, thunkAPI) => {
        return api_with_auth("/api/user/" + userId + "/status","GET")
    }
)

const slice = createSlice({
    name: 'otherUserStatus',
    initialState: {
        "data": {
            "user_id": -1,
            "user": {
                "created_at": 1641827285125785000
            },
            "nick_name": "寻找玩家中",
            "avatar": "",
            "born_city": 0,
            //是否和我同一城市。true时返回skills，employee，hideout
            "same_city": true,
            "skills": {
                "100": {
                    "breakthrough": 14,
                    "level": 1,
                    "saved_level": 0,
                    "type": 100
                },
            },
            "employee": [
                {
                    "buildingID": 114,
                    "employee": null,
                    "employeeID": 4,
                    "city": 0,
                    "is_worked": false,
                    "quitting": false,
                    "firing": false
                }
            ],
            "hideout": [
                {
                    "id": 6,
                    "name": "寻找住址",
                    "avatar": "",
                    "belong_city": 0
                }
            ]
        },
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateOtherUserStatusWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchOtherUserStatusNow: (state) => {
            state.fetchNow += 1;
        },
    },
    extraReducers:(builder)=>{
        //Add reducers for additional action types here,and handle loading state as needed
      builder.addCase(fetchUserById.pending,(state, action) => {
      // builder.addCase(fetchUserById.fulfilled,(state, action) => {
          // state.entities.push(action.payload)
          state.status = "pending"
              // state.data = action.payload
      })
          .addCase(fetchUserById.fulfilled,(state, action:any) => {
              state.status = "fulfilled"
              state.data = action.payload.data
          })
    }

});

export const {updateOtherUserStatusWPayload, fetchOtherUserStatusNow} = slice.actions;


export default slice.reducer;

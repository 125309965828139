import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

//建筑阶段映射阶段名字
export const buildingStageToChs: { [key: number]: string } = {
    0: "开垦中",
    1: "建设中",
    2: "",
    3: "已损毁",
}


//建筑种类映射indexRoute默认子元素路径
export const buildingTypeToIndexRoute: { [key: number]: string } = {
    1: "works",
    2: "works",
    3: "works",
    4: "OrderFood",
    5: "Storage",
    6: "works",
}

//建筑特殊功能类型
export function BuildingSpecialType(building:any) {
    // const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    let type = "normal"
    switch (building.type) {
        case 4:
            //餐馆
            type = "restaurant"
            break;
        case 5:
            //藏身处
            type = "hideout"
            break;
        case 37:
        case 38:
        case 39:
            //宅院/楼房/棚屋？我别墅呢。藏身处不算
            type = "housing"
            break;
        case 41:
            //贸易站
            type = "agent"
            break;
        //所有普通生产建筑
        default:
            type = "normal"
            break;
    }
    return type
}

//考虑建筑阶段的特殊功能区分。建造时候算作是普通建筑
export function BuildingSpecialTypeWStage(building:any) {
    // const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    return (building.stage === 2) ? BuildingSpecialType(building) : "normal"
}


//建筑默认路径
export function BuildingDefaultRoute(building:any) {
    const {buildingId} = useParams();
    let route = "works"
    switch (BuildingSpecialTypeWStage(building)) {
        case "restaurant":
            //餐馆
            route = "OrderFood"
            break;
        case "hideout":
            //藏身处
            route = "residence"
            break;
        case "housing":
            //宅院/楼房/棚屋？我别墅呢。藏身处不算
            route = "guestRooms"
            break;
        //所有普通生产建筑
        default:
            route = "works"
            break;
    }

    return `/game/building/${buildingId}/${route}`
}


//建筑种类映射介绍
export const buildingTypeToIntroduction: { [key: number]: string } = {
    1: "汉朝虽然是在推翻秦朝的基础上建立的，但仍然与秦朝一样是一个官僚政治的社会。汉朝统治者继续尽可能地抑制社会上各种集团的影响，来削减他们对国家政权的威胁。到武帝统治时期(公元前140-前87年)，朝廷已经采取了许多措施，如对重要商品的专营、发展官营工商业、歧视性征税及其他财政与货币措施，将国家直接控制的范围延伸到了经济领域。这些措施与使用政治手段来实现对整个社会的完全控制是一致的。不过，影响最深远的，还是汉政府繁荣农业经济，为官僚制国家奠定基础而进行的持续努力。",
    2: "汉朝虽然是在推翻秦朝的基础上建立的，但仍然与秦朝一样是一个官僚政治的社会。汉朝统治者继续尽可能地抑制社会上各种集团的影响，来削减他们对国家政权的威胁。到武帝统治时期(公元前140-前87年)，朝廷已经采取了许多措施，如对重要商品的专营、发展官营工商业、歧视性征税及其他财政与货币措施，将国家直接控制的范围延伸到了经济领域。这些措施与使用政治手段来实现对整个社会的完全控制是一致的。不过，影响最深远的，还是汉政府繁荣农业经济，为官僚制国家奠定基础而进行的持续努力。",
    3: "徐州汉代采石场遗址位于徐州市云龙区云龙山北坡，徐州博物馆西侧，占地约四十亩，和汉代楚王墓葬群连成一体。2004年5月在施工过程中被发现，同年6月至9月由徐州博物馆进行抢救性发掘。2006年被列入第六批全国重点文物保护单位，同时归入第四批全国重点文物保护单位汉楚王墓群。\n" +
        "\n" +
        "该遗址南北向约长200米，东西向约长150米，在这将近三万平方米的面积内，一共有采石坑68处被发掘清理，其中开采空坑63处，石坯坑5处，石渣坑一处，另有墓葬两座，刻字一处。该遗址还发掘出大量采石工具和建材、陶器等。",
    4: "酒肆，又称酒店、酒舍、酒家、酒楼、酒馆，是以卖酒为主的商业性店铺，同时一兼营酒的生产并提供下酒菜。《韩非子》中一记载，“宋人有沽酒者，升概甚平，遇客甚谨，为酒甚美，悬炽甚高”。早在春秋战国的时候，宋人开了一个小酒馆，量酒的容器相当精准，从不缺斤短两;接待客人温文尔雅，从不无礼怠慢;酒色纯正气味飘香，从不掺杂次品;店门酒旗高挂，招揽顾客。可以看出，当时酒肆就以公平交易、优质服务、酒质纯美、竖旗揽客为标准，进行经营管理，为后世的经营者提供了范本。",
    5: "汉承秦制，西汉初期汉高祖刘邦采取了一系列重农抑商、开荒屯田，打击富豪土地兼并等重要措施，使农业生产迅速恢复和发展，粮食积累日益增多，西汉政权开始把储粮备战备荒提高到治国之本的高度，强调： “夫积贮者，天下之大命也，苟粟多而财有余，何为而不 成”。于是全国各地大肆修建粮仓，出现了长安城的京师仓和太仓、各县的县仓和常平 仓 等，特 别 是 建 立 在 陕 西 华 阴 的 汉 华 仓（见图３），规模很大，储粮数十万石。",
    6: "缺省功能",
}

//建筑等级映射星级显示
export const TierToStars: { [key: number]: string } = {
    1: "",
    2: "★",
    3: "★★",
    4: "★★★",
    5: "★★★★",
}

//地形id映射地形种类名字
export const terrainIdToTerrainChs: { [key: number]: string } = {
    0: "平原",
    1: "森林",
    2: "丘陵",
    3: "山脉",
    4: "河流",
    5: "海洋",
}
//地形id映射地形种类英文名（拿图标用）
export const terrainIdToTerrainEng: { [key: number]: string } = {
    0: "plain",
    1: "forest",
    2: "hill",
    3: "mountain",
    4: "water",
    5: "sea",
}
//地形id映射地形介绍
export const terrainIdToDescription: { [key: number]: string } = {
    0: "广阔的平原适合各种建筑和耕地。",
    1: "茂密的木本植物比你更早数百年占据了这片区域。",
    2: "地壳的缓慢运动造就了这些起伏。",
    3: "山脉蕴藏着丰富的矿物资源。",
    4: "在这里你可以打鱼喝水，或者引流灌溉。",
    5: "看不到边际的海洋。你无法想象海的尽头。",
}

//城市id映射城市中文名
export const cityIdToName: { [key: number]: string } = {
    0: "长安",
    1: "蜀郡",
}

//产能buff
export const capacityBuff:{ [key: string]: string }={
    "bigTerrain": "广阔的地形面积",
    "smallTerrain":"过小的地形面积",
    "sideJob":"副业",
    "dailyGather":"每日第一次副业采集",
    "chanceGather":"副业采集随机获取",
    "random":"产能随机浮动",
    "drained":"资源暂时性枯竭",

}

//集市市场物品订单订单交易status状态 0进行中1已完售2已取消3已超时
export const bazaarItemStatus: { [key: number]: string } = {
    0: "进行中",
    1: "已完售",
    2: "已取消",
    3: "已超时",
}

//技能阶段映射阶段名字
export const skillStageToChs: { [key: number]: string } = {
    1: "懵懂",
    2: "学徒",
    3: "匠人",
    4: "匠师",
    5: "专家",
    6: "大师",
    7: "大宗师",
    8: "大宗师",
    9: "大宗师",
    10: "大宗师",
    11: "大宗师",
    12: "大宗师",
}

import "./BuildingInfo.scss"
import {Avatar, Button, TextField} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AddPhotoAlternate} from "@mui/icons-material";
import AddPhotoAvt from "../../../../components/AddPhotoAvt";
import {useEffect, useState} from "react";
import {api_host, api_with_auth} from "../../../../utils/api";
import {getLoginToken} from "../../../../utils/storage";
import {fetchStatusNow} from "../../../../appSlice";
import {useParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";

export default function BuildingInfo() {
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const dispatch = useDispatch();
    const [newName, setNewName] = useState(buildingSummary.name)
    const [newRemarks, setNewRemarks] = useState(buildingSummary.remarks)

    const [disabled, setDisabled] = useState(false)
    const {buildingId} = useParams();
    //fetch好建筑后更新名字显示
    useEffect(() => {
        setNewName(buildingSummary.name)
        setNewRemarks(buildingSummary.remarks)
    }, [buildingSummary])

    //提交修改按钮
    const apply = () => {
        setBuildingName()
        setBuildingRemarks()
    }

    const setBuildingName = () => {
        setDisabled(true)
        api_with_auth("/api/building/" + buildingId + "/set_name",  "POST",
            {
                "newName": newName
            })
            .then(res => {
                    setDisabled(false)
                    if (res.status === "success") {
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "成功修改了建筑名字",
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))

                    }
                },
            );
    }

    const setBuildingRemarks = () => {
        setDisabled(true)
        api_with_auth("/api/building/" + buildingId + "/set_remarks", "POST", {
                "id": buildingId,
                "newRemarks": newRemarks
            } )
            .then(res => {
                    setDisabled(false)
                    if (res.status === "success") {
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "成功修改了建筑附言",
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))

                    }
                },
            );
    }

    return <div className={"building-info-root"}>
        <AddPhotoAvt type={"building"} src={buildingSummary.avatar} onClick={() => {
        }} letter={buildingSummary.name[0]}/>
        <TextField label="建筑命名" fullWidth
                   className={"name"}
                   value={newName}
                   onChange={(e) => {
                       setNewName(e.target.value);
                   }}
        />
        <TextField label="建筑附言" fullWidth multiline rows={2}
                   className={"remarks"}
                   value={newRemarks}
                   onChange={(e) => {
                       setNewRemarks(e.target.value);
                   }}
        />
        <div className={"flex-end"}>
            <LoadingButton variant="contained" className={"apply-btn"}  color={"happiness"}
                           onClick={apply} loading={disabled}
            >
                应用修改
            </LoadingButton>
        </div>
    </div>
}
// 如果是数字就fixed小数点后2位
import {
    getBPBuildings, getBPBuildingsHaveRecipe,
    getBPCookingMethods,
    getBPFoodMaterials,
    getBPFuels,
    getBPItems,
    getBPSkills, getBPSpices,
    getLoginToken
} from "../utils/storage";
import * as React from "react";
import {api_host, api_with_auth} from "../utils/api";
import {TierToStars} from "./mappingTable";

export const ifNumberToFix2 = (input: any) => {
    if (typeof input === 'number') {
        return input.toFixed(2)
    } else {
        return input
    }
}
//如果是数字就floor到最多小数点2位
export const ifNumberFloor2 = (input: any) => {
    if (typeof input === 'number') {
        return Math.floor(input * 100) / 100
    } else {
        return input
    }
}
//floor最多小数点2位
export const Floor2 = (n: number) => {
    return Math.floor(n * 100) / 100
}
//floor最多小数点1位
export const Floor1 = (n: number) => {
    return Math.floor(n * 10) / 10
}
//TODO:这么写法，好像只拿一次。比fetch慢所以第一次打开是拿的默认值。overview有正确的写法但是套用就报错
//local storage拿物品
export const BPItems = getBPItems()
export const BPFoodMaterials = getBPFoodMaterials()
export const BPFuels = getBPFuels()

//物品info
export function itemInfo(id: number) {
    return getBPItems().find((item: any) => item.id === id)
}

//食材info
export function foodMaterialInfo(id: number, tier: number) {
    return BPFoodMaterials.find((f: any) => f.id === id)?.tiers.find((t: any) => t.tier === tier)
}

//local storage拿技能
export const BPSkills = getBPSkills()

export function skillInfo(id: number) {
    return getBPSkills().find((skill: any) => skill.id === id)
}

//local storage拿建筑
export const BPBuildings = getBPBuildings()

export function BPBuildingInfo(id: number) {
    return getBPBuildings().find((building: any) => building.id === id)
}

//拿特定种类星级建筑的信息。格式为buildingSummary
export function BPBTierInfo(buildingSummary: any) {
    return BPBuildingInfo(buildingSummary.type)?.tiers.find((tier: any) => tier.tier === buildingSummary.tier)
}

//建筑种类星级的中文显示(“农场★★★”)
export  function BuildingTypeTierToChs(buildingSummary: any) {
    return BPBuildingInfo(buildingSummary?.type)?.name + TierToStars[buildingSummary?.tier]
}
//序号数字
const noNumbers = ["❶", "❷", "❸", "❹", "❺", "❻", "❼", "❽", "❾", "❿", "⓫", "⓬", "⓭", "⓮", "⓯", "⓰", "⓱", "⓲", "⓳", "⓴"]
export const no = (n: number) => {
    return noNumbers[n - 1]
}
//罗马数字
const romanNumbers = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX"]
export const roman = (n: number) => {
    return romanNumbers[n - 1]
}

//小技能序号找大技能
export const majorSkillId = (minor: number) => {
    return (Math.floor(minor / 100) * 100)
}

//找指定建筑使用的大/小技能id(0大技能1小技能)
export function FindBuildingSkill(building: any, majorOrMinor: number) {
    //这个建筑的BPB星级详情
    const BPBTierInfo = BPBuildingInfo(building.type).tiers.find((e: any) => e.tier === building.tier)
    //这个建筑的建设阶段使用的技能，返回402/403
    const thisBuildingBuildingSkill = BPBTierInfo.cost.building_skill[building.culture]
    //从没有方案的建筑转到有方案的建筑时候，这个值会为undefined
    const crrMethodInfo = BPBTierInfo.working_method[building.working_method]
    //开垦
    if (building.stage === 0) {
        return majorOrMinor ? 401 : 400
        //    建设
    } else if (building.stage === 1) {
        return majorOrMinor ? thisBuildingBuildingSkill : 400
    } else {
        if (majorOrMinor) {
            //大技能
            return skillInfo(Math.floor(crrMethodInfo?.skill / 100) * 100)?.id
        } else {
            //小技能
            return skillInfo(crrMethodInfo?.skill)?.id
        }
    }

}


//找指定建筑使用的大/小技能名字
export function FindBuildingSkillName(building: any, majorOrMinor: number) {
    return skillInfo(FindBuildingSkill(building, majorOrMinor))?.Chs
}

//找指定玩家在指定建筑的大/小技能等级
export function FindBuildingSkillLevel(playerStatus: any, building: any, majorOrMinor: number) {
    const level = playerStatus?.skills?.[FindBuildingSkill(building, majorOrMinor)]?.level
    if (level === undefined) {
        return 0
    } else {
        return level
    }
}

//找指定玩家的指定技能等级
export function FindSkillLevel(playerStatus: any, skill: number) {
    const level = playerStatus?.skills?.[skill]?.level
    if (level === undefined) {
        return 0
    } else {
        return level
    }

}

//找指定玩家的指定技能info
export function FindPlayerSkillProgress(playerStatus: any, skill: number) {
    const info = playerStatus?.skills?.[skill]
    if (info === undefined) {
        return {
            "type": 200,
            "level": 0,
            "saved_level": 0,
            "breakthrough": 14,
        }
    } else {
        return info
    }

}

//给status返回技能总和
export  const SkillsSum =(playerStatus: any)=>{
    return Object.values(playerStatus.skills).reduce((total: number, skill: any) => total + skill.level, 0) ?? 0 as number;
}

//基础产能算法
export const BaseCapacity = (major: number, minor: number) => (major / 6 + minor / 3) + 5

//街区id找街区信息（仅限传入城市）
export const districtInfo = (city: any, districtId: number) => {
    return city.districts.find((district: any) => district.id === districtId)?.name ?? ""
}

//城市地形面积。没有该地形则返回0
export const CityTerrainArea = (city: any, terrain: number) => {
    return city.districts.reduce(
        (total: any, district: any) => total + (district.terrains.find((terrain2: any) => terrain2.type === terrain)?.area ?? 0)
        , 0)
}

//城市总面积
export const CityTotalArea = (city: any) => {
    return city.districts?.reduce(
        // ( total:any,district:any) => total + 1
        (total: any, district: any) => total + district.terrains?.reduce((total2: any, terrain: any) => total2 + terrain.area, 0)
        , 0)
}

//城市总已开发面积
export const CityTotalAreaTaken = (city: any) => {
    return city.districts?.reduce(
        (total: any, district: any) => total + district.terrains?.reduce((total2: any, terrain: any) => total2 + terrain.area_taken, 0)
        , 0)
}

//街区id到街区名字
export function districtIdToName(givenId: number,city:any) {
    const districts =city.districts
    const match = districts.find((e: any) => e.id === givenId)
    if (match === null || match === undefined) {
        return "寻找街区中"
    } else {
        return match.name
    }
}

//烹饪
//有食谱的建筑，拿本地建筑的配置信息
export const BPBWRInfo = (buildingSummary: any) => {
    return getBPBuildingsHaveRecipe().find((building: any) =>
        building.id === buildingSummary.type)?.tiers.find((tier: any) =>
        tier.tier === buildingSummary.tier)
}

//调料信息
export const BPSpicesInfo = (id: number, tier: number) => {
    return getBPSpices().find((spice: any) =>
        spice.id === id)?.tiers.find((BPTier: any) =>
        BPTier.tier === tier)
}

//烹饪方法信息
export const cookingMethodInfo = (id: number) => {
    return getBPCookingMethods().find((method: any) => method.id === id)
}

//燃料信息
export const fuelInfo = (id: number,tier:number) => {
    return getBPFuels().find((fuel: any) => fuel.id === id)?.tiers.find((tier2: any) => tier2.tier === tier)
}
//调味料信息。拿不要tier的名字，直接用物品的接口
export const spiceInfo = (id: number,tier:number) => {
    return getBPSpices().find((spice: any) => spice.id === id)?.tiers.find((tier2: any) => tier2.tier === tier)
}


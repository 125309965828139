import "./BuildingSelector.scss"
import {Avatar, Divider, ListItemButton, Popover} from "@mui/material";
import {Item} from "../IconImg";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {api_with_auth} from "../../utils/api";
import {HomeOutlined, PlaceOutlined, WarehouseOutlined} from "@mui/icons-material";
import * as React from "react";

//建筑选择器弹出列表
function PickBuildingList(props: {
    pickBuilding: ({}) => void
    pool: any[],
}) {
    //建筑池（建筑详细信息，不光id）
    const pool = props.pool
    console.log(pool)
    const pick = (building: any) => {
        props.pickBuilding(building)
    }
    //空选择
    const blankBuilding = {
        "id": -1,
        "owner": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "owner_id": -1,
        "manager": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "manager_id": -1,
        "name": "未选择建筑",
        "avatar": "",
        "type": 5,
        "tier": 1,
        "storage_capacity": 0,
        "max_storage_capacity": 1,
        "remarks": "",
        "multiply": 1,
    }
    return <div className={"pick-building-list-root"}>
        <div className={"title"}>选择一个建筑</div>
        <Divider/>
        <div className={"list"}>
            <div className={"row"}>
                <div className={"name"}>取消选择</div>
                <div className={"pointer"} onClick={() => pick(blankBuilding)}>
                    <Item id={-1} t={1} Eng={"null"} title={"取消选择"} mr={0.5}/>
                </div>
            </div>
            {(pool.length) ? pool.map((b: any, index: number) =>
                <ListItemButton className={"row"} key={b.id} onClick={() => pick(b)}>
                {/*<div className={"row"} key={b.id} onClick={() => pick(b)}>*/}
                    {<BuildingInfoCard buildingInfo={b}/>}
                </ListItemButton>
            ) : null
            }
        </div>
    </div>
}

//库房建筑选择器
export function BuildingSelector(props: {
    //往出传选了的建筑详细信息
    pickBuilding?: (building: any) => void
    //覆盖按钮显示文字
    title?: string,
    secondTitle?: string,
    //建筑池，玩家id 他拥有的建筑
    poolOwnedByPlayerId?: number,
    //默认选择的建筑信息
    defaultInfo?: any,
    //默认选择的建筑,通过id查询
    defaultId?: any,
}) {
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    //空选择
    const blankBuilding = {
        "id": -1,
        "owner": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "owner_id": -1,
        "manager": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "manager_id": -1,
        "name": "未选择建筑",
        "avatar": "",
        "type": 5,
        "tier": 1,
        "storage_capacity": 0,
        "max_storage_capacity": 1,
        "remarks": "",
        "multiply": 1,
        "belong_districts": 0,
        "belong_city": 0
    }
    //显示的建筑信息（不止数字id）
    const [selectedBuilding, setSelectedBuilding] = useState(blankBuilding)
    const selectedNone = selectedBuilding.id === -1

    const textStyle = {display: ((!selectedNone) ? "flex" : "none")}
    //现在能选（加载好了）
    // let [canPick, setCanPick] = useState(true)
    let [canPick, setCanPick] = useState(false)
    let [buildingPool, setBuildingPool] = useState([])
    const [myHideOutInfo, setMyHideOutInfo] = useState(blankBuilding)
    const finalBuildingPool = () => {
        if (myHideOutInfo.id !== -1) {
            return [
                myHideOutInfo,
                ...buildingPool.slice(0)
            ]
        } else {
            return [...buildingPool]
        }
    }

    //加载建筑池
    useEffect(() => {
        //建筑池，不传id默认是我的建筑池
        if (!props.poolOwnedByPlayerId) {
            api_with_auth("/api/buildings/my", "GET")
                .then(res => {
                        if (res.status === "success") {
                            setBuildingPool(res.data.buildings)
                            setCanPick(true)
                        }
                    },
                );
        } else {
            //其他玩家的建筑池
            api_with_auth("/api/buildings/user/" + props.poolOwnedByPlayerId, "GET")
                .then(res => {
                        if (res.status === "success") {
                            setBuildingPool(res.data.buildings)
                            setCanPick(true)
                        }
                    },
                );
        }
    }, [])


    //如果是我的池，额外拿取一个我的藏身处放在池子最上面
    useEffect(() => {
        //如果是我的建筑池，而且藏身处不为-1已经拿到了
        if (!props.poolOwnedByPlayerId) {
            api_with_auth("/api/building/" + myHideOutStorage.id, "GET")
                .then(res => {
                        if (res.status === "success") {
                            setMyHideOutInfo(res.data)
                        }
                    },
                );

        }
    }, [myHideOutStorage.id])

    useEffect(() => {
        //如果有按建筑id的默认建筑，查询建筑信息显示。 -1是通用的空的默认值
        if (props.defaultId && props.defaultId !== -1) {
            api_with_auth("/api/building/" + props.defaultId, "GET")
                .then(res => {
                        if (res.status === "success") {
                            setSelectedBuilding(res.data)
                        }
                    },
                );
        }
    }, [props.defaultId])

    //popover的相关。太tm多了
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event: any) => {
        if (canPick && !open) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'building-selector-popover' : undefined;
    //弹出列表点选了建筑
    const setBuilding = (building: any) => {
        setSelectedBuilding(building)
        props.pickBuilding?.(building)
        handleClose()
    }

    const districtName = cityStructure.districts.find((e: any) => e.id === selectedBuilding.belong_districts)?.name
    const isSuburb = (cityStructure.districts.find((e: any) => e.id === selectedBuilding.belong_districts)?.type) ? "郊区" : "城区"
    const cityName = (cityStructure.id === selectedBuilding.belong_city) ? cityStructure.name : "非本地城市"

    return <div
        className={"storage-building selector " + (selectedNone ? "" : "selected")}
        ref={anchorEl}
        onClick={handleClick}
    >
        <Avatar alt={"1"} src={selectedBuilding.avatar} variant={"rounded"}
                sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
        >选</Avatar>
        <div className="building-infos">
            <div className="name">{selectedNone ? (
                canPick ? (props.title ?? "选择一个建筑") : "在加载请稍等"
            ) : selectedBuilding.name}</div>
            <div className="text" style={textStyle}>{props.secondTitle}</div>
            <div className="text" style={textStyle}><HomeOutlined/>
                农场 <PlaceOutlined/>{isSuburb + ">" + districtName}</div>
            <div className="text" style={textStyle}><WarehouseOutlined/>
                {selectedBuilding.storage_capacity.toFixed(1)}/{selectedBuilding.max_storage_capacity.toFixed(0)}
            </div>
        </div>

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <PickBuildingList pickBuilding={setBuilding} pool={finalBuildingPool()}/>
        </Popover>
    </div>
}


//库房建筑显示卡片
export function BuildingInfoCard(props: {
    //覆盖显示文字
    title?: string,
    secondTitle?: string,
    //通过传入建筑信息显示
    buildingInfo?: any,
    //通过建筑id显示建筑
    buildingId?: number,
}) {
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

    //占位显示
    const defaultBuilding = {
        "id": -1,
        "owner": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "owner_id": -1,
        "manager": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "manager_id": -1,
        "name": "加载中",
        "avatar": "",
        "type": 5,
        "tier": 1,
        "storage_capacity": 0,
        "max_storage_capacity": 1,
        "remarks": "",
        "multiply": 1,
        "belong_districts": 0,
        "belong_city": 0
    }

    //显示的建筑信息,未传入就显示占位
    const [selectedBuilding, setSelectedBuilding] = useState(props.buildingInfo ?? defaultBuilding)

    //通过建筑id显示
    useEffect(() => {
        if (props.buildingId) {
            api_with_auth("/api/building/" + props.buildingId, "GET")
                .then(res => {
                    if (res.status === "success") {
                        setSelectedBuilding(res.data)
                    }
                })
        }
    }, [props.buildingId])


    const textStyle = {display: "flex"}

    const districtName = cityStructure.districts.find((e: any) => e.id === selectedBuilding.belong_districts)?.name
    const isSuburb = (cityStructure.districts.find((e: any) => e.id === selectedBuilding.belong_districts)?.type) ? "郊区" : "城区"
    const cityName = (cityStructure.id === selectedBuilding.belong_city) ? cityStructure.name : "非本地城市"

    return <div className={"storage-building"}>
        <Avatar alt={"1"} src={selectedBuilding.avatar} variant={"rounded"}
                sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
        >{selectedBuilding.name?.[0] ?? "房"}</Avatar>
        <div className="building-infos">
            <div className="name">{props.title ?? selectedBuilding.name}</div>
            <div className="text" style={textStyle}>{props.secondTitle}</div>
            <div className="text" style={textStyle}><HomeOutlined/>
                农场 <PlaceOutlined/>{isSuburb + ">" + districtName}</div>
            <div className="text" style={textStyle}><WarehouseOutlined/>
                {selectedBuilding.storage_capacity.toFixed(1)}/{selectedBuilding.max_storage_capacity.toFixed(0)}
            </div>
        </div>

    </div>
}

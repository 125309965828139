import "./Restaurant.scss"
import "../../../components/Styles/BazaarAndDistrictFilter/BADFilterStyle.scss"
import {Divider, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {SellOutlined, ShoppingBasketOutlined} from "@mui/icons-material";
import ItemSelector from "../../../components/ItemSelector";
import * as React from "react";
import Filler from "../../../components/Filler";

export default function DistrictRestaurants(props: {}) {
    return <div className={"district-restaurants-root"}>

        {/*过滤器*/}
        <div className={"filters-container"}>
            <div className={"filters"}>
                {/*<FormControl>*/}
                {/*    <InputLabel>餐馆位置</InputLabel>*/}
                {/*    <Select label="餐馆位置" value={""} onChange={(e) => {*/}
                {/*    }}>*/}
                {/*        /!*{BPItems.map((item: any, index: any) =>*!/*/}
                {/*        /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
                {/*        /!*)}*!/*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*<FormControl>*/}
                {/*    <InputLabel>建筑状态</InputLabel>*/}
                {/*    <Select label="建筑状态" value={""} onChange={(e) => {*/}
                {/*    }}>*/}
                {/*        <MenuItem value={2} key={2}>正常运作</MenuItem>*/}
                {/*        <MenuItem value={0} key={0}>开垦中</MenuItem>*/}
                {/*        <MenuItem value={1} key={1}>建造中</MenuItem>*/}
                {/*        <MenuItem value={3} key={3}>已损坏</MenuItem>*/}
                {/*        /!*{BPItems.map((item: any, index: any) =>*!/*/}
                {/*        /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
                {/*        /!*)}*!/*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*<SellOutlined/>*/}
                {/*<ItemSelector pickItem={(id, tier) => {*/}
                {/*}} title={"出售建筑"} ml={0.3} mr={1}/>*/}
                {/*<ShoppingBasketOutlined/>*/}
                {/*<ItemSelector pickItem={(id, tier) => {*/}
                {/*}} title={"所需货物"} ml={0.3} mr={1}/>*/}
            </div>
        </div>
        <Divider variant="middle"
                 className="middle-divider"
        />
        <Filler title={"目前无法浏览所有餐馆，请手动寻找"}/>
        <div className={""}></div>

    </div>

}
import {createSlice} from '@reduxjs/toolkit';
import {getLoginToken} from "../../../utils/storage";
import {api_host} from "../../../utils/api";

const slice = createSlice({
    name: 'storage',
    initialState: {
        "data": [
            {
                "id": 1,
                "item_type": 1,
                "item_tire": 1,
                // "item_in_storage": "大量",
                // "fresh_item_in_storage": "少量",
                // "item_soling": "少量",
                // "fresh_item_soling": "微量",
                // "total_weight": "大量"
            },
        ],
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateStorageWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchStorageNow: (state) => {
            state.fetchNow += 1;
        },
    },
});
export const {updateStorageWPayload, fetchStorageNow,} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import {getLoginToken} from "./storage";

//本地
// let host = "http://localhost:8080"
//正式服務器
let host = "https://api.civitasrise.com"
//主域名的测试服
// let host = "https://testapi.civitasrise.com"
//李松霖的测试服務器
// let host = "http://isilent.me:15765"
//TravorZhu的测试服务器
// let host = "http://139.162.114.56:80"
//李琦的测试服务器
// let host = "http://120.79.29.180:15765"


export function api_host(): string {
    return host;
}

export async function api_without_auth(method: string, path: string, body: any) {
    let res = await fetch(host + path, {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    return res.json()
}

export async function api_with_auth(path: string, method: "GET" | "POST" | "PATCH", body?: any) {
    let res = await fetch(host + path, {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getLoginToken(),
            },
            body: JSON.stringify(body)
        })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = "/#/login"
                }
                return res
            })
    ;
    return res.json()
}


import "./interface.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Avatar, Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import {Clear, HourglassEmpty, NavigateBefore, NavigateNext, PlaceOutlined, Schedule} from "@mui/icons-material";
import itemIcon from "./assets/item.webp";
import buildingIcon from "./assets/building.webp";
import {ProgressDots} from "../../components/ProgressDots";
import * as React from "react";
import {TransactionStatusToChs} from "../index";
import {TimeSlot} from "../../components/timeToNow";
import ItemSelector from "../../components/ItemSelector";
import {itemInfo} from "../../components/gadgets";
import {TierToStars} from "../../components/mappingTable";
import Filler from "../../components/Filler";
import {LoadingButton} from "@mui/lab";
import {Item} from "../../components/IconImg";
import {stringify} from "querystring";
import {BuildingInfoCard, BuildingSelector} from "../../components/BuildingSelectorAndDisplay";
import {api_with_auth} from "../../utils/api";
import {openSnackbar} from "../../appSnackbarSlice";
import {fetchMyTransactionsNow} from "../myTransactionsSlice";


//浏览交易 和创建交易共享css
export default function ViewTransactionInterface(props: {
    //交易信息
    transaction?: any,
}) {
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const state = useSelector((state: any) => state.state.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    //思路：既通过props传入订单信息；也通过url的交易订单号，fetch订单信息。同时保留一个回退显示用的默认交易信息。
    //这样如果是从列表进入页面，可以瞬间显示内容免去等待，同时也支持输入url跳转到页面。

    //父组件点击列表时更新props传入订单信息。渲染模块时，如果props订单号和url订单号一致，则显示props传入的信息，同时fetch详细信息以更新。
    //如果props订单号和url订单号不一致，或者props undefined，则显示回退信息，同时用url号fetch订单信息。

    //url来的交易id
    const txnId = 1

    const transactionInfoDefault = {
        "id": 1,
        //0未决1已完成2取消3被拒4超时
        "status": 0,
        "city": 0,
        "initiate_time": 1652802087074262000,
        "expire_time": 1652888487074262000,
        "text_message": "",
        "initiator": {
            "id": 4,
            "name": "加载中",
            "export_building": 6,
            "import_building": 6,
            "fresh_only": false,
            "pay": {
                "items": [
                    {
                        "type": 5,
                        "tier": 1,
                        "quantity": 0
                    }
                ],
                "buildings": [
                    71,
                    75
                ]
            }
        },
        "recipient": {
            "id": 4,
            "name": "加载中",
            //没接的时候为0，交易成功后后端会更新
            "export_building": 0,
            "import_building": 0,
            "fresh_only": false,
            "pay": {
                "items": [
                    {
                        "type": 5,
                        "tier": 1,
                        "quantity": 0
                    }
                ],
                "buildings": []
            }
        },
        //交易中涉及的所有建筑
        "buildings": {
            "-2": {
                "id": 6,
                "owner": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "owner_id": 4,
                "manager": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "manager_id": 4,
                "name": "加载中",
                "avatar": "",
                "type": 5,
                "tier": 1,
                "storage_capacity": 451,
                "max_storage_capacity": 100000,
                "belong_city": 0,
                "belong_districts": 1,
                "belong_terrain": 1,
                "culture": 0,
                "stage": 2,
                "percent": 100,
                "product_type": 0,
                "architect": 4
            },
            "-1": {
                "id": 72,
                "owner": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "owner_id": 4,
                "manager": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "manager_id": 4,
                "name": "加载中",
                "avatar": "",
                "type": 3,
                "tier": 2,
                "max_work_count": 1,
                "is_hiring": true,
                "salary_number": 0.4,
                "salary_type": 5,
                "salary_tier": 1,
                "storage_capacity": 0,
                "max_storage_capacity": 400,
                "belong_city": 0,
                "belong_districts": 3,
                "belong_terrain": 3,
                "culture": 0,
                "stage": 0,
                "percent": 0,
                "product_type": 3,
                "architect": 0
            }
        }
    }
    const [transactionFetched, setTransactionFetched] = useState(transactionInfoDefault)
    // const txn =  transactionFetched
    const txn = props.transaction ?? transactionFetched

    const fetchTransaction = () => {
        api_with_auth("/api/transaction/" + txnId, "GET")
            .then(res => {
                if (res.status === "success") {
                    //成功时重拿交易列表
                    setTransactionFetched(res.data)
                }
            });
    }
    useEffect(() => {
        fetchTransaction()
    }, [])


    //接收方回应交易
    const [respondJson, setRespondJson] = useState({
        "export_building": 0,
        "import_building": 0
    })

    //拿我的藏身处，如果还没选库房，自动设定选择的库房为藏身处
    useEffect(() => {
        let n = {...respondJson}
        if (n.export_building === 0 && myHideOutStorage.id !== -1) {
            n.export_building = myHideOutStorage.id
        }
        if (n.import_building === 0 && myHideOutStorage.id !== -1) {
            n.import_building = myHideOutStorage.id
        }
        setRespondJson(n)
    }, [myHideOutStorage.id])


    const respondTransaction = (respond: boolean) => {
        api_with_auth("/api/transaction/" + txn.id + "/respond", "POST", {
            "respond": respond,
            "export_building": respondJson.export_building,
            "import_building": respondJson.import_building
        })
            .then(res => {
                dispatch(openSnackbar({
                    "severity": (res.status === "success" ? "success" : "error"),
                    "snackbarMessage": (res.status === "success" ? ("成功" + (respond ? "接受" : "回决") + "交易") : res.message),
                }))
                if (res.status === "success") {
                    //成功时重拿交易列表
                    dispatch(fetchMyTransactionsNow())
                }
            });
    }

    //发起方取消交易
    const [respondingTransaction, setRespondingTransaction] = useState(false)
    const cancelTransaction = () => {
        setRespondingTransaction(true)
        api_with_auth("/api/transaction/" + txn.id + "/cancel", "POST", {})
            .then(res => {
                setRespondingTransaction(false)
                dispatch(openSnackbar({
                    "severity": (res.status === "success" ? "success" : "error"),
                    "snackbarMessage": (res.status === "success" ? "成功取消交易" : res.message),
                }))
                if (res.status === "success") {
                    //成功时重拿列表
                    dispatch(fetchMyTransactionsNow())
                }
            });
    }


    return <div className={"transaction-interface-root"}>
        <div className={"trade-title"}>
            <div className={"title"}>
                {txn.initiator?.pay.items.length + txn.recipient?.pay.items.length
                    + txn.initiator?.pay.buildings.length + txn.recipient?.pay.buildings.length
                }项物品的私人交易
            </div>
            <div className={"type " + ((txn.status === 0) ? " orange" : "")}>
                {TransactionStatusToChs[txn.status]}
            </div>
        </div>
        <div className="headers">
            <PlaceOutlined/>
            <div>长安</div>
            <Schedule/>
            <div>{TimeSlot(Date.now() * 1000000 - txn.initiate_time)}前</div>
            <HourglassEmpty/>
            <div>持续{TimeSlot(txn.expire_time - txn.initiate_time)}</div>
        </div>
        {txn.text_message ?
            <div className="message-container">
                <div className="quote">“</div>
                <div className="view">{txn.text_message}</div>
                <div className="quote end">”</div>
            </div>
            : null}
        <Divider>
            <Avatar alt={txn.initiator.name}
                src={txn.initiator.avatar}
                    sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
            >{txn.initiator.name?.[0]}</Avatar>
            <div className={"two-story-text left-align"}>
                <div className="up">{txn.initiator.name}</div>
                <div className="down">发起方</div>
            </div>
        </Divider>
        {/*主要交易面板，中间那两列*/}
        <div className={"txn-panels"}>
            <div className="l side">
                {/*发起方支出货物*/}
                {/*无建筑交易时必显示货物交易*/}
                {txn.initiator?.pay.items.length || !txn.initiator.pay.buildings.length ?
                    <div className="trade-items">
                        <div className="drop-shadow with-items">
                            <div className="item-storage">
                                {/*发起方出货库房*/}
                                <BuildingInfoCard buildingId={txn.initiator.export_building}/>
                                <div className="trade-items-container">
                                    <div className="trade-items-container-2">
                                        {txn.initiator.pay.items?.map((item: any, index: any) =>
                                            <div className="trade-item">
                                                <Item mr={0.3} id={item.type} t={item.tier}/>
                                                <div className={"two-story-text left-align"}>
                                                    <div className={"up"}>{item.quantity}</div>
                                                    <div className={"down"}>
                                                        {itemInfo(item.type)?.Chs + TierToStars[item.tier]}</div>
                                                </div>
                                            </div>
                                        )}
                                        {!txn.initiator?.pay.items.length ?
                                            <Filler title={"无物品交易"} height={4}/> : null}
                                    </div>

                                </div>
                            </div>
                            <div className={"big-arrow"}/>
                        </div>
                        <div className="drop-shadow">
                            <div className="item-storage">
                                {/*接受方收货库房*/}
                                {/*接收者是我&&订单未决时，显示选择器*/}
                                {(state.uid === txn.recipient.id && txn.status === 0) ?
                                    <BuildingSelector  defaultId={myHideOutStorage.id} title={"接受方收货库房"} pickBuilding={(building) => {
                                        setRespondJson({
                                            "export_building": respondJson.export_building,
                                            "import_building": building.id
                                        })
                                    }}/> :
                                    // 订单未决/取消/拒绝时,显示建筑卡片+固定标题
                                    (txn.status !== 1 ?
                                            <BuildingInfoCard title={"接受方收货库房"}/> :
                                            // 订单已接受时，显示建筑卡片+建筑名字
                                            <BuildingInfoCard buildingId={txn.recipient.import_building}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    : null}

                {/*发起方支出建筑*/}
                {txn.initiator.pay.buildings.length ?
                    <div className="trade-buildings">
                        <div className="buildings-packs">
                            <div className="drop-shadow">
                                <div className="trade-buildings-container">
                                    <div className="trade-buildings-container-2">
                                        {/*支付的建筑*/}
                                        {txn.initiator.pay.buildings?.map((building: number, index: any) =>
                                            <div className="trade-building">
                                                <BuildingInfoCard buildingId={building}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="arrow-container flip">
                            <div className={"big-arrow rotate"}/>
                            <div className="square"/>
                        </div>
                    </div>
                    : null}
            </div>
            <div className="r side">
                {/*接收方支出建筑*/}
                {txn.recipient.pay.buildings.length ?
                    <div className="trade-buildings">
                        <div className="arrow-container">
                            <div className={"big-arrow rotate"}/>
                            <div className="square"/>
                        </div>
                        <div className="buildings-packs">

                            {/*外层放背景*/}
                            <div className="drop-shadow">

                                <div className="trade-buildings-container">
                                    <div className="trade-buildings-container-2">
                                        {/*支付的建筑*/}
                                        {txn.recipient.pay.buildings?.map((building: number, index: any) =>
                                            <div className="trade-building">
                                                <BuildingInfoCard buildingId={building}/>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}


                {/*接收方支出货物*/}
                {txn.recipient?.pay.items.length || !txn.recipient.pay.buildings.length ?
                    <div className="trade-items">
                        <div className="drop-shadow">
                            <div className="item-storage">
                                <BuildingInfoCard buildingId={txn.initiator.import_building}/>
                            </div>
                        </div>
                        <div className="drop-shadow with-items">
                            <div className={"big-arrow rotate"}/>
                            <div className="item-storage">
                                {/*外层放背景*/}
                                <div className="trade-items-container">
                                    <div className="trade-items-container-2">

                                        {txn.recipient?.pay.items?.map((item: any, index: any) =>
                                            <div className="trade-item">
                                                <Item mr={0.3} id={item.type} t={item.tier}/>
                                                <div className={"two-story-text left-align"}>
                                                    <div className={"up"}>{item.quantity}</div>
                                                    <div className={"down"}>
                                                        {itemInfo(item.type)?.Chs + TierToStars[item.tier]}</div>
                                                </div>
                                            </div>
                                        )}
                                        {!txn.recipient?.pay.items.length ?
                                            <Filler title={"无物品交易"} height={4}/> : null}

                                    </div>
                                </div>

                                {/*接受方发货库房*/}
                                {/*接收者是我&&订单未决时，显示选择器*/}
                                {(state.uid === txn.recipient.id && txn.status === 0) ?
                                    <BuildingSelector  defaultId={myHideOutStorage.id} title={"接受方收货库房"} pickBuilding={(building) => {
                                        setRespondJson({
                                            "export_building": building.id,
                                            "import_building": respondJson.import_building
                                        })
                                    }}/> :
                                    // 订单未决/取消/拒绝时,显示建筑卡片+固定标题
                                    (txn.status !== 1 ?
                                            <BuildingInfoCard title={"接受方收货库房"}/> :
                                            // 订单已接受时，显示建筑卡片+建筑名字
                                            <BuildingInfoCard buildingId={txn.recipient.export_building}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    : null}


            </div>

        </div>
        <Divider>
            <Avatar alt={txn.recipient.name}
                src={txn.recipient.avatar}
                    sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
            >{txn.recipient.name?.[0]}</Avatar>
            <div className={"two-story-text left-align"}>
                <div className="up">{txn.recipient.name}</div>
                <div className="down">接受方</div>
            </div>
        </Divider>
        {/*回应按钮*/}
        {txn.status !== 0 ?
            //交易已结束
            <div className={"buttons"}>
                <LoadingButton variant="outlined" color={"hungry"}
                               disabled
                               onClick={() => {
                               }}>
                    交易已结束
                </LoadingButton>
            </div>
            : ((state.uid === txn.recipient.id) ?
                //我是接收方
                <div className={"buttons"}>
                    <LoadingButton variant="outlined" color={"hungry"} loading={false}
                                   onClick={() => {
                                       respondTransaction(false)
                                   }}>
                        回决
                    </LoadingButton>
                    <LoadingButton variant={'contained'} color={"happiness"} loading={false}
                                   disabled={respondJson.export_building === 0 || respondJson.import_building === 0}
                                   onClick={() => {
                                       respondTransaction(true)
                                   }}>
                        {(respondJson.export_building === 0 || respondJson.import_building === 0) ? "选择库房" : "接受"}
                    </LoadingButton>
                </div>
                :
                //我是发起方
                <div className={"buttons"}>
                    <LoadingButton variant="outlined" color={"hungry"}
                                   loading={false}
                                   onClick={() => {
                                       cancelTransaction()
                                   }}>
                        取消交易
                    </LoadingButton>
                </div>)
        }

        <Filler/>
    </div>
}
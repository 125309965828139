import "./HireManagers.scss"
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {Avatar, Button, IconButton, Popover} from "@mui/material";
import * as React from "react";
import {Clear} from "@mui/icons-material";
import Filler from "../../../../components/Filler";
import {PickPlayerList} from "../../../../components/PlayerSelector";
import {useState} from "react";
import {fetchBuildingSummaryNow} from "../../buildingSlice";


export default function HireManagers() {
    const {buildingId} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const currentManager = buildingSummary?.manager
    const managerPositionOpen = buildingSummary?.manager.uid === buildingSummary?.owner.uid

    //选择的管理员
    const [selectedPlayer, setSelectedPlayer] = useState({
        "uid": -1,
        "nick_name": "可以邀请一位管理员",
        "avatar": ""
    })
    const pickPlayer = (player: any) => {
        setSelectedPlayer(player)
        handleClose()
    }

    //邀请成为管理员
    const hire = () => {
        api_with_auth("/api/building/" + buildingId + "/supervisor/invite_manager", "POST", {
            "playerId": selectedPlayer.uid
        })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "已邀请此人成为管理员，对方需要查看通知以回应邀请。",
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }
    //开除
    const fire = () => {
        api_with_auth("/api/building/" + buildingId + "/supervisor/fire_manager", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "罢免了管理员，现在只有你对该建筑有完全控制权。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }


    //选择管理员popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event: any) => {
        if (!open) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'building-selector-popover' : undefined;

    return <div className={"hire-managers-root"}>
        {managerPositionOpen ?
            <div className="current-manager-container select-manager"
                 ref={anchorEl} onClick={handleClick}>
                <Avatar alt={"无"}
                        src={selectedPlayer.avatar}
                        sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                >管</Avatar>
                <div className="r">
                    <div className="name">{selectedPlayer.nick_name}</div>
                    <div className="d">{selectedPlayer.uid === -1 ? "当前无管理员" : "可以成为管理员"}</div>
                </div>
            </div>
            :
            <div className="current-manager-container">
                <IconButton
                    onClick={fire} className="clear" aria-label="clear"
                >
                    <Clear/>
                </IconButton>
                <Avatar alt={currentManager.nick_name}
                        src={currentManager.avatar}
                        sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                >{currentManager.nick_name?.[0]}</Avatar>
                <div className="r">
                    <div className="name">{currentManager.nick_name}</div>
                    <div className="d">当前管理员</div>
                </div>
            </div>
        }
        <div className={"execute-btn-container"}>
            <Button variant={"contained"} color={"happiness"}
                    disabled={!managerPositionOpen || selectedPlayer.uid === -1}
                    onClick={hire}
            >
                {managerPositionOpen ? (selectedPlayer.uid === -1 ? "候选人未选择" : "邀请管理员")
                    : "已有管理员"}</Button>
        </div>

        {/*邀请管理员选择玩家*/}
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <PickPlayerList pickPlayer={(player) => {
                pickPlayer(player)
            }}/>
        </Popover>
    </div>


}
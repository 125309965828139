import "../../components/Styles/BazaarAndDistrictFilter/BADFilterStyle.scss"
import "./BazaarItems.scss"
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItemButton,
    MenuItem,
    Select,
    TextField, Tooltip
} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {BPBuildingInfo, BPItems, districtIdToName, itemInfo} from "../../components/gadgets";
import {TierToStars} from "../../components/mappingTable";
import {Item} from "../../components/IconImg";
import ItemSelector from "../../components/ItemSelector";
import {openSnackbar} from "../../appSnackbarSlice";
import {energyTip, shippingLossTip} from "../../components/ToolTips";
import {
    BalanceRounded,
    Edit,
    FormatListBulleted,
    History,
    SellOutlined,
    ShoppingBasketOutlined
} from "@mui/icons-material";
import {Navigate, Route, Routes, useMatch, useNavigate} from "react-router-dom";
import BazaarItemsView from "./View";
import BazaarItemsEdit from "./Edit";
import BazaarItemsHistory from "./History";
import BazaarItemsAgents from "./Agents";
import {api_with_auth} from "../../utils/api";

export default function BazaarItems(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

    //页面切换：浏览市场"" 编辑我的订单edit 浏览订单历史history 浏览本城市所有中介agents
    let UrlPage = "view"
    if (useMatch("/game/bazaar/items/view/*")) {
        UrlPage = "view"
    }
    if (useMatch("/game/bazaar/items/edit/*")) {
        UrlPage = "edit"
    }
    if (useMatch("/game/bazaar/items/history/*")) {
        UrlPage = "history"
    }
    if (useMatch("/game/bazaar/items/agents/*")) {
        UrlPage = "agents"
    }


    const [page, setPage] = useState(UrlPage)
    const updatePage = (page: "view" | "edit" | "history" | "agents") => {
        setPage(page)
        navigate(`/game/bazaar/items/` + page)
    }

    const [bazaarItemsEdit, setBazaarItemsEdit] = useState<any>({
        "page": 0,
        "pageSize": 20,
        "totalPages": 20,
        "totalResults": 300,
        "content": [
            {
                //订单id
                "id": 1,
                //卖方建筑
                "from": {
                    //建筑id
                    "id": 1,
                    //建筑名字
                    "name": "寻找订单中",
                    //建筑头像
                    "avatar": "",
                    //种类
                    "type": 2,
                    //星级
                    "tier": 1,
                    //所在城市
                    "belong_city": 0,
                    //所在街区
                    "belong_districts": 0,
                    //所在地形
                    "belong_terrain": 0,
                },
                //寄售的贸易站建筑。如果没有通过贸易站，不返回或者返回id=-1都可以
                "agent": {
                    "id": -1,
                    "name": "中介1",
                    "avatar": "",
                    "type": 41,
                    "tier": 1,
                    "belong_city": 0,
                    "belong_districts": 0,
                    "belong_terrain": 0,
                },
                //卖方玩家
                "seller": {
                    "uid": 1,
                    "nick_name": "",
                    "avatar": ""
                },
                //出售的货物
                "selling": {
                    "id": 39,
                    "tier": 1,
                    //出售货物的数量，有人购买时实时更新
                    "quantity": 1,
                    //创建订单时候的总量
                    "quantityWhenCreated": 1,
                },
                //交换的货物
                "buying": {
                    "id": 33,
                    "tier": 1,
                    //单价
                    "quantityPerItem": 1,
                },
                //订单创建时间戳
                "create_time": 1,
                //最后编辑时间戳
                "edit_time": 1,
                //订单截止日期。使用游戏日期game_day
                "available_until": 1,
                //订单状态。0进行中1已完售2已取消3已超时
                "status": 1,
            },
        ]
    })
    const editNumber = bazaarItemsEdit.content?.length || 0
    const fetchBazaarItemsEdit = () => {
        api_with_auth("/api/bazaar/items/my", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                    if (res.status === "success") {
                        setBazaarItemsEdit(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }
    useEffect(() => {
        fetchBazaarItemsEdit()
    }, [])



    return <div className={"bazaar-items-root"}>

        {/*商品过滤器*/}
        <div className={"filters-container"}>

            {/*{UrlPage !== "agents" ?*/}
            {/*    <div className={"filters"}>*/}
            {/*        <FormControl>*/}
            {/*            <InputLabel>订单位置 </InputLabel>*/}
            {/*            <Select label="货物订单位置" value={""} onChange={(e) => {*/}
            {/*            }}>*/}
            {/*                /!*{BPItems.map((item: any, index: any) =>*!/*/}
            {/*                /!*    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>*!/*/}
            {/*                /!*)}*!/*/}
            {/*            </Select>*/}
            {/*        </FormControl>*/}
            {/*        <SellOutlined/>*/}
            {/*        <ItemSelector pickItem={(id, tier) => {*/}
            {/*        }} title={"出售货物"} ml={0.3} mr={1}/>*/}
            {/*        <ShoppingBasketOutlined/>*/}
            {/*        <ItemSelector pickItem={(id, tier) => {*/}
            {/*        }} title={"所需货物"} ml={0.3} mr={1}/>*/}
            {/*    </div>*/}
            {/*    : null*/}
            {/*}*/}


            <Button variant={page === "view" ? "contained" : "text"} color={"happiness"}
                    style={{
                        margin: "0 0.5vw 0 auto",
                    }}
                    onClick={() => updatePage("view")
                    }>
                <FormatListBulleted/>
            </Button>
            <Divider className={"middle-divider"} variant={"middle"} orientation={"vertical"} flexItem/>
            <Button variant={page === "edit" ? "contained" : "text"} color={"happiness"}
                    onClick={() => updatePage("edit")}>
                <Edit/>{editNumber}
            </Button>
            <Divider className={"middle-divider"} variant={"middle"} orientation={"vertical"} flexItem/>
            {/*<Button variant={page === "history" ? "contained" : "text"} color={"happiness"}*/}
            {/*        onClick={() => updatePage("history")}>*/}
            {/*    <History/>*/}
            {/*</Button>*/}
            {/*<Divider className={"middle-divider"} variant={"middle"} orientation={"vertical"} flexItem/>*/}
            <Button variant={page === "agents" ? "contained" : "text"} color={"happiness"}
                    onClick={() => updatePage("agents")}>
                <BalanceRounded/>
            </Button>
        </div>
        <div className={"sort-container"}>
            {{
                "view": "城市实物市场",
                "edit": "编辑我的订单",
                "history": "我的历史订单",
                "agents": "拜访城际贸易站",
            }[UrlPage]}
        </div>
        <Divider variant="middle"
                 className="middle-divider"
        />
        {/*商品列表*/}
        <div className={"goods-list-container"}>
            <Routes>
                <Route index element={<Navigate
                    to={`/game/bazaar/items/view`} replace/>}/>
                <Route path={`/view/*`} element={<BazaarItemsView/>}/>
                <Route path={`/edit/*`} element={<BazaarItemsEdit/>}/>
                <Route path={`/history/*`} element={<BazaarItemsHistory/>}/>
                <Route path={`/agents/*`} element={<BazaarItemsAgents/>}/>
            </Routes>
        </div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
import "./LeaseContract.scss"
import {Button, Checkbox, FormControl, MenuItem, TextField} from "@mui/material";
import * as React from "react";
import {Add} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {BPItems} from "../../../../components/gadgets";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";

export default function LeaseContract() {
    const state = useSelector((state: any) => state.state.data)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {buildingId} = useParams()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const leasingContractsSaved = buildingSummary.residence?.leasingContracts
    const freeForEmployeesSaved = buildingSummary.residence?.freeForEmployees

    const [freeForEmployees, setFreeForEmployees] = useState(freeForEmployeesSaved)

    //合同
    const [leasingContracts, setLeasingContracts] = useState(buildingSummary.residence?.leasingContracts)
    useEffect(() => {
        setLeasingContracts(buildingSummary.residence?.leasingContracts)
        setFreeForEmployees(freeForEmployeesSaved)
    }, [leasingContractsSaved])
    const add = () => {
        if (leasingContracts === null) {
            setLeasingContracts([{
                "rent": {
                    "type": 1,
                    "quantity": 0,
                    "tier": 1
                },
                "duration": 1,
                "allowRenewal": false,
                "id": Math.floor(Math.random() * 10000),
            }])
        } else {
            setLeasingContracts([...leasingContracts,
                {
                    "rent": {
                        "type": 1,
                        "quantity": 0,
                        "tier": 1
                    },
                    "duration": 1,
                    "allowRenewal": false,
                    "id": Math.floor(Math.random() * 10000),
                }])
        }

    }

    function LeaseContractBullet(props: { contract: any, index: number }) {
        const contract = props.contract


        return <div className={"lease-contract-bullet"}>
            <Button variant="text" className={"x"}
                    onClick={() => {
                        let arr = JSON.parse(JSON.stringify(leasingContracts))
                        arr.splice(props.index, 1)
                        setLeasingContracts(arr)
                    }}
            >✖</Button>
            <div className={"no"}>{props.index + 1}</div>
            <TextField label="租金价格" size="small" value={contract.rent?.quantity} onChange={(e) => {
                let arr = JSON.parse(JSON.stringify(leasingContracts))
                arr[props.index].rent.quantity = parseFloat(e.target.value)
                setLeasingContracts([...arr])
            }}/>
            <TextField select label="租金种类" size="small" value={contract.rent.type} onChange={(e) => {
                let arr = JSON.parse(JSON.stringify(leasingContracts))
                arr[props.index].rent.type = parseInt(e.target.value)
                setLeasingContracts([...arr])
            }}
            >
                {BPItems.map((item: any) =>
                    <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>
                )}
            </TextField>
            <TextField select label="租金星级" size="small" value={contract.rent.tier} onChange={(e) => {
                let arr = JSON.parse(JSON.stringify(leasingContracts))
                arr[props.index].rent.tier = parseInt(e.target.value)
                setLeasingContracts([...arr])
            }}
            >
                <MenuItem value={1}>☆</MenuItem>
                <MenuItem value={2}>★</MenuItem>
                <MenuItem value={3}>★★</MenuItem>
                <MenuItem value={4}>★★★</MenuItem>
            </TextField>
            <TextField label="租期（天）" size="small" value={contract.duration} onChange={(e) => {
                let arr = JSON.parse(JSON.stringify(leasingContracts))
                arr[props.index].duration = parseInt(e.target.value)
                setLeasingContracts([...arr])
            }}/>
            <div className={"allow"} onClick={() => {
                let arr = JSON.parse(JSON.stringify(leasingContracts))
                arr[props.index].allowRenewal = !arr[props.index].allowRenewal
                setLeasingContracts([...arr])
            }}>
                <Checkbox size="small" checked={contract.allowRenewal}/>
                允许租客续租
            </div>
        </div>
    }

    const toggleFreeForEmployees = (f: boolean) => {
        setFreeForEmployees(f)
        api_with_auth("/api/building/" + buildingId + "/residence/toggle_free_for_employees", "POST",
            {
                "freeForEmployees": f
            })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": f ? "现在你的员工可以免费入住了" : "你的员工不再能免费入住此处"
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );

    }

    const apply = () => {
        api_with_auth("/api/building/" + buildingId + "/residence/update_leasing_contract", "POST",
            {
                "contracts": leasingContracts
            })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "更新了租房合同。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"lease-contract-root"}>
        <div className={"lease-contract-bullets"}>
            {leasingContracts?.map((contract: any, index: number) =>
                <LeaseContractBullet contract={contract} index={index} key={contract.id}/>
            )}
        </div>
        <div className={""}>
            <Button variant="outlined" className={"new"}
                    onClick={add}>
                + 新建合同
            </Button>
            <Button variant="outlined" className={"new"}
                    onClick={() => {
                        toggleFreeForEmployees(!freeForEmployees)
                    }}>
                <Checkbox size="small" checked={freeForEmployees}/> 员工免费入住
            </Button>
        </div>
        <div className={"row"}>
            <Button variant="text" className={"new"}
                // onClick={Change}
            >
                邀请入住(即将可用)
            </Button>
            <Button variant="contained" className={"accept"} onClick={apply}>
                应用修改
            </Button>
        </div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
import {createSlice} from '@reduxjs/toolkit';
import {getLoginToken} from "../../utils/storage";
import {api_host} from "../../utils/api";

const slice = createSlice({
    name: 'gameDay',
    initialState: {
        "data": [
            {
                "day": 1,
                "city": 0,
                "LootTimesByAreaType": {
                    "0": 0,
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 0
                },
                "lootTimesNumber": 0,
                "loginUserNumbers": 0,
                "worked": 0,
                "workChances": 0,
                "sideJobWorked": 0,
                "totalProductionYield": 0,
                "totalTrade": 0,
                "takenArea": 0,
                "newTakenArea": 0
            },
            {
                "day": 2,
                "city": 0,
                "LootTimesByAreaType": {
                    "0": 0,
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 0
                },
                "lootTimesNumber": 0,
                "loginUserNumbers": 0,
                "worked": 0,
                "workChances": 0,
                "sideJobWorked": 0,
                "totalProductionYield": 0,
                "totalTrade": 0,
                "takenArea": 0,
                "newTakenArea": 0
            },
        ],
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateTwoPreviousGameDaysWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchTwoPreviousGameDaysNow: (state) => {
            state.fetchNow += 1;
        },

    },
});
export const {updateTwoPreviousGameDaysWPayload,  fetchTwoPreviousGameDaysNow} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import "./StatusTutorial.scss"
import "../../../extraGlobalStyle.scss"
import {Divider, Tooltip} from "@mui/material";
import {dayChangeTip, energyTip, happinessTip, healthTip, hungryTip} from "../../ToolTips";
import StatusBar from "../../statusBar";
import {useSelector} from "react-redux";
import {Update, Warning} from "@mui/icons-material";
import Filler from "../../Filler";
import {BuildingTypeTierToChs, Floor1} from "../../gadgets";
import React, {useEffect, useState} from "react";
import {api_with_auth} from "../../../utils/api";
import {openSnackbar} from "../../../appSnackbarSlice";
import {getBPResidence} from "../../../utils/storage";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default function StatusTutorial() {
    const status = useSelector((state: any) => state.status.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    const hideoutId = myHideOutStorage.id
    const [hideout, setHideout] = useState({
        "id": -1,
        "owner": {
            "uid": 130,
            "nick_name": "",
            "avatar": "",
            "born_city": 0
        },
        "owner_id": 130,
        "manager": {
            "uid": 130,
            "nick_name": "",
            "avatar": "",
            "born_city": 0
        },
        "manager_id": 130,
        "name": "",
        "avatar": "",
        "type": 5,
        "tier": 1,
        "is_hiring": false,
        "salary_number": 0,
        "salary_type": 0,
        "salary_tier": 0,
        "storage_capacity": 0,
        "max_storage_capacity": 800,
        "belong_city": 0,
        "belong_districts": 0,
        "belong_terrain": 0,
        "culture": 0,
        "stage": 2,
        "percent": 100,
        "product_type": 0,
        "in_day_product_type": 0,
        "working_method": -1,
        "in_day_working_method": -1,
        "architect": {
            "uid": 130,
            "nick_name": "",
            "avatar": "",
            "born_city": 0
        },
        "remarks": "",
        "public": false,
        "multiply": 1,
        "del_flag": 0,
        "is_selling": false,
        "hideOut": {
            "residence": {
                "id": 0,
                "name": "",
                "avatar": "",
                "type": 0,
                "tier": 0,
                "guestType": "",
                "rent": {
                    "type": 0,
                    "quantity": 0,
                    "tier": 0
                },
                "duration": 0,
                "durationLeft": 0,
                "allowRenewal": false,
                "leaving": false
            }
        },
        "recipe": {
            "public": false,
            "recipes": null
        }
    })
    const residence = hideout.hideOut?.residence
    const residenceNameChs = residence.id === 0 ?
        BuildingTypeTierToChs(hideout) : BuildingTypeTierToChs(residence)
    //配置文件中找到现在住房的内容
    const re = getBPResidence().find((r: any) => r.buildingId === (residence?.type || 0))
        ?.tiers.find((t: any) => t.tier === (residence?.tier || 1))
    const recover = re?.recover
    const recoverCap = re?.recoverCap
    //住房恢复
    const residenceRecover = (type: "energy" | "happiness" | "health") => {
        return recover[type]
    }

    //属性特高/特低的互相影响
    //饥饿修正判定
    const hungryLow1 = status.hunger < 70 && status.hunger > 30
    const hungryLow2 = status.hunger < 30
    //精力修正值
    const energyTotalCorrection = () => {
        if (hungryLow1) {
            return recover.energy * -0.3
        } else if (hungryLow2) {
            return recover.energy * -0.7
        }
    }

    //健康修正值
    const healthTotalCorrection = () => {
        if (hungryLow2) {
            return -recover.health - 0.5
        }
    }

    //住房恢复上限带来的变化值
    const residenceReCapped = (type: "energy" | "happiness" | "health", correction?: number) => {
        //correction:住房恢复后，住房恢复上限前的其他恢复修正值
        //盖帽目标值，恢复上限或者现在的属性取高值
        const t = Math.max(recoverCap[type], status[type])
        //恢复后超出上限减扣的量，如有
        const r = Math.min(t - status[type] - recover[type] - (correction ?? 0), 0)
        return Floor1(r)
    }
    //实际恢复量
    const actualRecover = (type: "energy" | "happiness" | "health", correction?: number) => {
        // const r = Math.min(status[type] + recover[type], recoverCap[type]) - status[type]
        // const zero = Math.max(r, 0)
        // return Floor1(zero)
        return Floor1(recover[type] + (correction ?? 0) + residenceReCapped(type, correction))
    }
    //快乐回中量
    const hAfter = status.happiness + actualRecover("happiness")
    const hFinal = hAfter / 2 + 25
    const happyCenter = Floor1(hFinal - hAfter)


    useEffect(() => {
        if (hideoutId !== -1) {
            api_with_auth("/api/building/" + hideoutId, "GET")
                .then(res => {
                    if (res.status === "success") {
                        setHideout(res.data)
                    } else {
                    }
                })
        }
    }, [hideout])

    //红绿颜色
    const dayChangeStyle = (change: number) => {
        if (change > 0) {
            return {color: "#73b93c"}
        } else if (change < 0) {
            return {color: "#e74652"}
        } else {
            return {color: "#b4b4b4"}
        }
    }

    return <div className={"status-tutorial-root"}>
        {/*精力*/}
        <Divider variant={"middle"}> <Tooltip title={energyTip}><u className={"energy"}><FontAwesomeIcon icon="bolt"/>精力</u></Tooltip></Divider>
        <StatusBar type={"energy"} length={15} changingValue={actualRecover("energy",energyTotalCorrection())} hideIcon/>
        <div className="status-polar">
            <div>力竭</div>
            <div>充沛</div>
        </div>
        <div className={"effect disabled"}>• 精力水平对其他属性无影响</div>
        <div className="day-change">
            <Update/>
            <Divider orientation={"vertical"} flexItem/>
            <div className="changes">
                <div className="change"><b style={dayChangeStyle(recover.energy)}>
                    {recover.energy}</b>来自{residenceNameChs}住房恢复
                </div>
                {/*挨饿1*/}
                {hungryLow1 ? <div className="change"><b style={dayChangeStyle(-1)}>
                    {recover.energy * -0.3}</b>（30%），因为挨饿
                </div> : null}
                {/*挨饿2*/}
                {hungryLow2 ? <div className="change"><b style={dayChangeStyle(-1)}>
                    {recover.energy * -0.7}</b>（70%），因为快饿死了
                </div> : null}
                <div className="change"><b style={dayChangeStyle(residenceReCapped("energy", energyTotalCorrection()))}>
                    {residenceReCapped("energy", energyTotalCorrection())}</b>
                    来自{residenceNameChs}住房恢复上限{recoverCap.energy}</div>
            </div>
        </div>

        {/*快乐*/}
        <Divider variant={"middle"}> <Tooltip title={happinessTip}><u className={"happiness"}><FontAwesomeIcon
            icon="face-smile"/>快乐</u></Tooltip></Divider>
        <StatusBar type={"happiness"} length={15} changingValue={hFinal - status.happiness} hideIcon/>
        <div className="status-polar">
            <div>悲极</div>
            <div>乐极</div>
        </div>
        <div className={"effect " + (status.happiness < 30 ? "" : "disabled")}>• 技能升级速度-30%（当快乐{"<"}30）</div>
        <div className={"effect " + (status.happiness > 50 ? "" : "disabled")}>• 技能升级速度+{
            (status.happiness * 2 - 100 > 0) ?
                (status.happiness * 2 - 100).toFixed(0) : 0
        }%（0-100%，当快乐&gt;50）
        </div>
        <div className="day-change">
            <Update/>
            <Divider orientation={"vertical"} flexItem/>
            <div className="changes">
                <div className="change"><b style={dayChangeStyle(recover.happiness)}>
                    {recover.happiness}</b>来自{residenceNameChs}住房恢复
                </div>
                <div className="change"><b style={dayChangeStyle(residenceReCapped("happiness"))}>
                    {residenceReCapped("happiness")}</b>来自{residenceNameChs}住房恢复上限{recoverCap.happiness}</div>
                <div className="change"><b
                    style={dayChangeStyle(happyCenter)}>{happyCenter}</b>平复心情（向中间值靠近50%）
                </div>
            </div>
        </div>

        {/*健康*/}
        <Divider variant={"middle"}> <Tooltip title={healthTip}><u className={"health"}><FontAwesomeIcon icon="heart"/>健康</u></Tooltip></Divider>
        <StatusBar type={"health"} length={15} changingValue={hungryLow2 ? -0.5 : actualRecover("health")} hideIcon/>
        <div className="status-polar">
            <div>濒死</div>
            <div>无恙</div>
        </div>
        <div className={"effect disabled"}>• 健康水平对其他属性无影响</div>
        <div className="day-change">
            <Update/>
            <Divider orientation={"vertical"} flexItem/>
            <div className="changes">
                <div className={"change " + (hungryLow2 ? "line-through" : "")}><b
                    style={dayChangeStyle(recover.health)}>
                    {recover.health}</b>来自{residenceNameChs}住房恢复
                </div>
                <div className={"change " + (hungryLow2 ? "line-through" : "")}><b
                    style={dayChangeStyle(residenceReCapped("health"))}>
                    {residenceReCapped("health")}</b>来自{residenceNameChs}住房恢复上限{recoverCap.health}</div>
                {/*挨饿2*/}
                {hungryLow2 ? <div className="change"><b style={dayChangeStyle(-1)}>-0.5</b>，因为快饿死了
                </div> : null}
            </div>
        </div>

        {/*饥饿*/}
        <Divider variant={"middle"}> <Tooltip title={hungryTip}><u className={"hungry"}><img
            src={process.env.PUBLIC_URL + `/img/status/statusHungry.svg`}
            className={"type-icon"} style={{width: "1em"}}
            alt={"type-icon"}/>饥饿</u></Tooltip></Divider>
        <StatusBar type={"hungry"} length={15} changingValue={0 - status.hunger / 4} hideIcon/>
        <div className="status-polar">
            <div>受饿</div>
            <div>饱腹</div>
        </div>
        <div className={"effect " + (hungryLow1 ? "warning" : "disabled")}>
            {hungryLow1 ? <Warning/> : "•"} 饥饿低于70，精力恢复受损
        </div>
        <div className={"effect " + (hungryLow2 ? "warning" : "disabled")}>
            {hungryLow2 ? <Warning/> : "•"} 饥饿低于30，精力、健康恢复大幅受损
        </div>
        <div className="day-change">
            <Update/>
            <Divider orientation={"vertical"} flexItem/>
            <div className="changes">
                <div className="change"><b style={dayChangeStyle(status.hunger / -4)}>-{Floor1(status.hunger / 4)}</b>（-25%）每日固定饥饿变化
                </div>
            </div>
        </div>

        {/*<div className={"description"}>*/}
        {/*    <Tooltip title={energyTip}><u>精力</u></Tooltip>、*/}
        {/*    <Tooltip title={happinessTip}><u>快乐</u></Tooltip>、*/}
        {/*    <Tooltip title={healthTip}><u>健康</u></Tooltip>、*/}
        {/*    <Tooltip title={hungryTip}><u>饥饿</u></Tooltip>*/}
        {/*    四项属性描述了你身体和心理的基本状况。饥饿在每天<Tooltip title={dayChangeTip}><u>换日</u></Tooltip>时自动消耗，其他三项在<Tooltip*/}
        {/*    title={dayChangeTip}><u>换日</u></Tooltip>时自动恢复。*/}
        {/*</div>*/}
        <Filler height={5}/>
        <div className={""}></div>
    </div>
}
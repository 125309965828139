import "./ManageGuests.scss"
import {Avatar, Button, ListItemButton, Popover} from "@mui/material";
import {BPBuildingInfo, FindBuildingSkillName, itemInfo} from "../../../../components/gadgets";
import {TierToStars} from "../../../../components/mappingTable";
import {constructionHint, FindBuildingDistrictName} from "../../components/WorkCard/WorkCard";
import * as React from "react";
import {Logout, MoreHoriz} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {Item} from "../../../../components/IconImg";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {api_with_auth} from "../../../../utils/api";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {fetchEmployeesNow} from "../../Work/workSlice";
import {fetchEmployeeSkillsNow} from "../../Work/employeeSkillsSlice";
import {openSnackbar} from "../../../../appSnackbarSlice";

//scss在ListItemBuilding，导入scss在supervisor
function GuestBullet(props: { guest: any }) {
    const guest = props.guest
    const paid = guest.guestType === "paid"

    return <div className={"list-item-building guest-bullet-root"}>
        <Avatar alt="RecruitAvt" src={guest.avatar}
                className={"avt"} variant="rounded"/>
        <div className={"two-story-text"}>
            <div className={"up"}>
                {guest.nick_name}
            </div>
            <div className={"down"}>
                {
                    {
                        "paid": "租客",
                        "employee": "员工入住",
                        "owner": "所有者入住"
                    }[guest.guestType as keyof {
                        "paid": "租客",
                        "employee": "员工入住",
                        "owner": "所有者入住"
                    }]
                }
            </div>
        </div>

        <div className={"two-story-text right margin-left-auto four-vw"}>
            <div className={"up"}>
                {paid ? guest.rent.quantity : "免费"}
            </div>
            <div className={"down"}>
                租金已支付
            </div>
        </div>
        <div className={"two-story-text right three-vw"}>
            <Item id={paid ? guest.rent.type : -1} t={guest.rent.tier}
                  title={paid ? undefined : "免费入住"}
            />
        </div>
        <div className={"two-story-text right six-vw"}>
            <div className={"up orange"}>
                {paid ? `${guest.durationLeft}日到期` : "永不到期"}
            </div>
            <div className={"down"}>
                总租期{paid ? guest.duration : "∞"}日
            </div>
        </div>
        <div className={"two-story-text right five-vw"}>
            <div className={"up orange"}>
                {guest.durationLeft > guest.duration ? "已经续租" : "未续租"}
            </div>
            <div className={"down"}>
                {guest.allowRenewal ? "√可以续租" : "×不可续租"}
            </div>
        </div>
    </div>
}

export default function ManageGuests() {
    const dispatch = useDispatch()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const [processing, setProcessing] = useState(false)
    const leasingContracts = buildingSummary.residence?.leasingContracts
    const guests = buildingSummary.residence?.guests

    const {buildingId} = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(-1)
    const handleClick = (index1: number) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(index1)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(-1)
    };

    //更改允许续租
    const changeAllowRenewal = (guest: any, allowRenewal: boolean) => {
        setProcessing(true)
        api_with_auth("/api/building/" + buildingId + "/residence/change_allow_renewal", "POST",
            {
                "player": guest.uid,
                "allowRenewal": allowRenewal
            })
            .then(res => {
                    setProcessing(false)
                    if (res.status === "success") {
                        handleClose()
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": ("现在" + (allowRenewal ? "允许" : "禁止") + "租户续租了"),
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }
    //逐客
    const expelGuest = (guest: any) => {
        setProcessing(true)
        api_with_auth("/api/building/" + buildingId + "/residence/expel_guest", "POST",
            {
                "player": guest.uid,
            })
            .then(res => {
                    setProcessing(false)
                    if (res.status === "success") {
                        handleClose()
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": ("已经驱逐了房客！" + (guest.guestType === "paid" ? "付费入住的房客将在租期结束后离开。" : "")),
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );

    }


    return <div className={"manage-guests-root"}>

        <div className={"manage-guests-content"}>
            {guests?.map((guest: any, index: number) =>
                <div className={"guest"} key={guest.uid}>
                    <Button variant="text" className={"options"}
                            onClick={handleClick(index)}
                    ><MoreHoriz/></Button>
                    <Popover
                        open={open === index}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <div id="MenuList">
                            {guest.allowRenewal ?
                                <ListItemButton onClick={() => changeAllowRenewal(guest, false)}>
                                    <Logout/>禁止此人续租</ListItemButton> :
                                <ListItemButton disabled={guest.guestType !== "paid"}
                                                onClick={() => changeAllowRenewal(guest, true)}
                                    // style={{color: "#bd1f1f"}}
                                >
                                    <Logout/>允许此人续租</ListItemButton>
                            }
                            <ListItemButton
                                onClick={() => expelGuest(guest)}
                                style={{color: "#bd1f1f"}}
                            >
                                <Logout/>逐客！</ListItemButton>
                        </div>
                    </Popover>
                    <GuestBullet guest={guest}/>
                </div>
            )}
        </div>
        <div className={""}></div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
import "./Skills.scss"
import {Backdrop, Fade, Modal} from "@mui/material";
import {Sort, VerticalAlignTop, Visibility} from "@mui/icons-material";
import * as React from "react";
import {FindPlayerSkillProgress, FindSkillLevel, Floor1, roman, skillInfo} from "../../components/gadgets";
import {Skill, skillSrc} from "../../components/IconImg";
import Gem from "./assets/gem.webp"
import Brain from "./assets/brain.webp"
import {skillStageToChs} from "../../components/mappingTable";
import {getBPSkills} from "../../utils/storage";
import {useState} from "react";
import Filler from "../../components/Filler";

export default function SkillsModal(props: {
    open: boolean
    onClose: () => void
    status: any
}) {
    const BPSkills = getBPSkills()
    const status = props.status
    const skills = status.skills
    const BPMajorSkills = BPSkills.filter((e: any) => e.id % 100 === 0)


    const [selectedL, setSelectedL] = useState(0)
    //左侧列表按钮
    const LListButtons = () => {
        return <div className="list">
            {BPMajorSkills.map((s: any, index: number) =>
                <div className={"l-list-button-root " + (selectedL === index ? "active" : "")} key={s.id}
                     onClick={() => setSelectedL(index)}
                >
                    <div className="l">
                        <div className="title">{s.Chs}</div>
                        <div className="level">{Floor1(FindSkillLevel(status, s.id))}</div>
                    </div>
                    <img className={"icon-image icon-image-skill"}
                         src={process.env.PUBLIC_URL + skillSrc(s.id)}
                         onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                             event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                             event.currentTarget.onerror = null;
                         }}
                         alt={skillInfo(300)?.Chs ?? "skill"}/>
                </div>
            )}
        </div>


    }

    const [selectedM, setSelectedM] = useState(100)
    const BPViewingMinors = BPSkills.filter((e: any) =>
        Math.floor(e.id / 100) * 100 === BPMajorSkills[selectedL].id
        && e.id % 100 !== 0)
    //中间列表按钮
    const MListButton = (props: {
        id: number,
    }) => {
        return <div className={"m-list-button-root " + (props.id === selectedM ? "active" : "")}
                    onClick={() => {
                        setSelectedM(props.id)
                    }}>
            <Skill playerProgress={skills[props.id]} id={props.id} size={3.5} overrideBgColor={"rgba(0,0,0,0)"}/>
        </div>
    }
    const MListButtons = () => {
        return <div className="minor-skills-container">
            {BPViewingMinors.map((s: any, index: number) =>
                <div className={"container"} key={s.id}>
                    <MListButton id={s.id}/>
                    <div className="text">{skillInfo(s.id)?.Chs}</div>
                </div>
            )}
            {BPViewingMinors.length === 0 ?
                <Filler title={"没有下属技能"}/>
                : null}
        </div>
    }
    //技能每段位间距级别
    const levelGap = 14
    //当前等级
    const level = Floor1(FindSkillLevel(status, selectedM))
    //当前技能段位显示（无视突破限制）
    let stageD = Math.floor(level / levelGap + 1)
    //当前技能段位
    let stage = FindPlayerSkillProgress(status, selectedM).breakthrough / levelGap
    //玩家当前卡了技能门槛
    const blocked = stageD > stage

    //进度条
    const SkillProgressBar = () => {
        //技能每段位长度vw
        const gapLength = 10
        //小段等级的横移长度,
        //也是当前段位文字的横移长度
        //如果当前段位===1，也是技能条外壳的位移长度-gapLength
        const barOffset = () => {
            const a = (level % levelGap) / levelGap * gapLength
            //右移一半起点置中长度，总长16
            const b = 8
            return b - gapLength - a
        }
        //技能过小时技能条外壳位移
        const barMaskOffset = Math.max((barOffset() + gapLength), 0)

        //等级标数和当前等级差过小时候隐藏
        const opacity = (stageDis: number) => {
            //过小阈值
            const threshold = 3
            const abs = Math.abs((stageDis - 1) * 14 - level)
            return Math.min(abs, threshold) / 3
        }


        return <div className={"skill-progress-bar-root"}>
            <div className="bar-container">
                {[stageD, stageD + 1].map((stageD: number, index) =>
                    <div className="texts"
                         style={{left: `${barOffset() + gapLength * (index + 1)}vw`}}>
                        <div className="text">{skillStageToChs[stageD]}</div>
                        <div className="number"
                             style={{opacity: `${opacity(stageD)}`}}
                        >{(stageD - 1) * 14}</div>
                    </div>
                )}
                <div className="level-text">{level}</div>
                <div className="rare-dashes-container"
                     style={{left: `${barOffset()}vw`}}>
                    {Array(30).fill(1).map((e: any, index: number) =>
                        <div className="rare-dash" key={index}
                             style={{left: `${index * (gapLength / 10 - 0.1)}vw`}}
                        />
                    )}
                </div>
                <div className="bar-mask" style={stageD === 1 ? {left: `${barMaskOffset}vw`} : {left: "1"}}>
                    <div className={"bar " + (blocked ? "blocked" : "")}
                         style={stageD === 1 ? {left: `${-barMaskOffset}vw`} : {left: "1"}}/>
                </div>
                <div className="front-dashes-container"
                     style={{left: `${barOffset()}vw`}}>
                    {Array(4).fill(1).map((e: any, index: number) =>
                        <div className="front-dash" key={index}
                             style={{left: `${index * (gapLength - 0.3)}vw`}}
                        />
                    )}
                </div>
            </div>
        </div>
    }

    //教育的token
    const Tokens = (props: {
        gem: number
    }) => {
        const total = 5
        const glowing = (index: number) => {
            return index < props.gem
        }
        return <div className="tokens-container-root">
            {Array(total).fill(1).map((gem: any, index: number) =>
                <div className="token-container" key={index}>
                    {/*<div className="gem" style={{backgroundImage: "url(assets/gem.webp)"}}/>*/}
                    <img src={index === 0 ? Brain : Gem} alt={"gem"}
                         style={glowing(index) ?
                             {opacity: 1, filter: "drop-shadow(0 0 0.4vw rgba(255, 255, 255, 0.6))"} :
                             {opacity: 0.5}}/>
                    <div className="indicator"
                         style={glowing(index) ? {opacity: 1} : {opacity: 0}}/>
                </div>
            )
            }
        </div>
    }

    return <Modal
        open={props.open}
        onClose={() => {
            props.onClose()
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{timeout: 500}}
    >
        <Fade in={props.open}>
            <div className={"skills-modal-root"}>
                <div className="banner">
                </div>
                <div className="main-container">
                    <div className="left-list">
                        <div className="filters-container">
                            <Sort className={"filter"}/>
                            <VerticalAlignTop className={"filter"}/>
                            <Visibility className={"filter"}/>
                        </div>
                        {LListButtons()}
                        <div className="filler-bar"/>
                    </div>
                    <div className="middle-list">
                        <div className="title">主要技能</div>
                        <MListButton id={BPMajorSkills[selectedL].id}/>
                        <div className="text">{skillInfo(BPMajorSkills[selectedL].id)?.Chs}</div>
                        <div className="deco2"/>
                        <div className="title title2">下属技能</div>
                        {MListButtons()}
                    </div>
                    {/*右侧面板*/}
                    <div className="right-detail">
                        <img className={"icon-image icon-image-skill"}
                             src={process.env.PUBLIC_URL + skillSrc(selectedM)}
                             onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                 event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                                 event.currentTarget.onerror = null;
                             }}
                             alt={skillInfo(selectedM)?.Chs ?? "skill"}/>
                        <div className="title">
                            {skillInfo(selectedM)?.Chs}
                        </div>
                        <div className="description">{selectedM % 100 === 0 ? "主要技能" : "下属技能"}</div>
                        {/*右侧技能进度条*/}
                        <SkillProgressBar/>
                        <div className="texts-container">
                            <div>{roman(stage)} {skillStageToChs[stage]}</div>
                            <div>{level}</div>
                        </div>
                        <div className="education-container">
                            <div className="title">灵感</div>
                            <Tokens gem={2}/>
                            <div className="education-description">
                                当前没有接受教育。
                            </div>
                            <div
                                className="footer">{skillInfo(selectedM)?.Chs} {skillStageToChs[stage]}{level} {status.nick_name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    </Modal>
}
import "./bazaarBuildingPopover.scss"
import "../../../../components/GenericModalStyle/GenericModalStyle.scss"
import {Backdrop, Button, Divider, Fade, Modal} from "@mui/material";
import {SPVSCheckMark} from "../../../../Buildings/Building/Supervisor/component/CheckMark";
import {Item} from "../../../../components/IconImg";
import {itemInfo} from "../../../../components/gadgets";
import {TierToStars} from "../../../../components/mappingTable";
import {StatusBar2x2} from "../../../../components/statusBar";
import * as React from "react";
import {useState} from "react";
import {Gavel} from "@mui/icons-material";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {useDispatch} from "react-redux";

export default function BazaarBuildingPopover(props: {
    open: boolean,
    onClose: () => void,
    onSuccess?: () => void,
    sell: any,
}) {
    const sell = props.sell
    const dispatch = useDispatch()

//买房
    const buyHouse = () => {
        api_with_auth("/api/bazaar/houses/" + sell?.id + "/buy", "POST", {
            "orderId": sell?.id,
            "buying": sell?.buying
        })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "购买成功，这个建筑和它库房里的所有物品现在属于你。",
                        }))
                        props.onSuccess?.()
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    const open = props.open
    return <div className={"bazaar-building-popover-root"}>
        <Modal
            open={open}
            onClose={() => {
                props.onClose()
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={"generic-modal-root"}
        >
            <Fade in={open}>
                <div className={"modal-content-root"}>
                    <div className={"title-image title-image1"}/>
                    <div className={"title-image title-image4"}/>
                    <div className={"title"}>购买建筑？</div>
                    <div className={"content-container"}>
                        <Divider variant={"middle"} flexItem>商品</Divider>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>这座建筑：{sell?.selling?.name}的所有权
                        </div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>其仓库中的所有物资。</div>
                        <Divider variant={"middle"} flexItem>售价</Divider>
                        <div className={"resources-container"}>
                            <div className={"resource"}>
                                <Item id={sell?.buying?.id} t={sell?.buying?.tier} mr={0.7}/>
                                <div className={"resource-text"}>
                                    <div className={"up"}>
                                        {sell?.buying?.quantity}
                                    </div>
                                    <div className={"down"}>
                                        {itemInfo(sell?.buying?.id)?.Chs}{TierToStars[sell?.buying?.tier]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"condition"}>从藏身处支付价格。由于距离损耗，实际支付的值更高。</div>

                        {/*<StatusBar2x2 changingValueEnergy={-17} changingValueHappiness={-2} changingValueHealth={-2}*/}
                        {/*              changingValueHungry={-0}/>*/}
                        <Button variant={"contained"} color={"happiness"} disabled={false}
                                onClick={buyHouse}>
                            {/*<Gavel/></Button>*/}
                            购买</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    </div>
}
import "./history.scss"
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button, ListItemButton} from "@mui/material";
import BazaarItemSeller from "../component/BazaarItemSeller";
import BazaarItemAgent from "../component/BaazaarItemAgent";
import * as React from "react";
import {bazaarItemStatus} from "../../../components/mappingTable";
import {api_with_auth} from "../../../utils/api";
import {openSnackbar} from "../../../appSnackbarSlice";


export default function BazaarItemsHistory(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const [bazaarItemsHistory, setBazaarItemsHistory] = useState<any>(
           [
                {
                    //订单id
                    "id": 1,
                    //卖方建筑
                    "from": {
                        //建筑id
                        "id": 1,
                        //建筑名字
                        "name": "寻找订单中",
                        //建筑头像
                        "avatar": "",
                        //种类
                        "type": 2,
                        //星级
                        "tier": 1,
                        //所在城市
                        "belong_city": 0,
                        //所在街区
                        "belong_districts": 0,
                        //所在地形
                        "belong_terrain": 0,
                    },
                    //寄售的贸易站建筑。如果没有通过贸易站，不返回或者返回id=-1都可以
                    "agent": {
                        "id": -1,
                        "name": "中介1",
                        "avatar": "",
                        "type": 41,
                        "tier": 1,
                        "belong_city": 0,
                        "belong_districts": 0,
                        "belong_terrain": 0,
                    },
                    //卖方玩家
                    "seller": {
                        "uid": 1,
                        "nick_name": "",
                        "avatar": ""
                    },
                    //出售的货物
                    "selling": {
                        "id": 13,
                        "tier": 1,
                        //出售货物的数量，有人购买时实时更新
                        "quantity": 1,
                        //创建订单时候的总量
                        "quantityWhenCreated": 1,
                    },
                    //交换的货物
                    "buying": {
                        "id": 15,
                        "tier": 1,
                        //单价
                        "quantityPerItem": 1,
                    },
                    //订单创建时间戳
                    "create_time": 1,
                    //最后编辑时间戳
                    "edit_time": 1,
                    //订单截止日期。使用游戏日期game_day
                    "available_until": 1,
                    //订单状态。0进行中1已完售2已取消3已超时
                    "status": 1,
                }
            ]
    )
    const emptyBazaarItemsHistory = bazaarItemsHistory.length === 0


//fetch市场历史记录
    useEffect(() => {
        api_with_auth("/api/bazaar/items/my/history", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                    if (res.status === "success") {
                        setBazaarItemsHistory(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }, [])


    if (emptyBazaarItemsHistory) {
        return <div className={"bazaar-items-history-root"}/>
    } else {
        return <div className={"bazaar-items-history-root"}>
            {bazaarItemsHistory?.map((sell: any, index: any) =>
                <ListItemButton className={"bazaar-item-bullet-root"} key={"bazaarItem_" + sell.id} disableRipple>
                    <div className={"bazaar-item-seller-and-others"}>
                        <BazaarItemSeller sell={sell}/>
                        <div className={"list-item-building status"}>
                            <div className={"two-story-text"}>
                                <div className={"up"}>
                                    {bazaarItemStatus[sell.status]}
                                </div>
                                <div className={"down"}>
                                    完成于{sell.available_until}日
                                </div>
                            </div>
                        </div>
                    </div>
                    {sell.agent.id !== -1 ? <BazaarItemAgent sell={sell}/> : null}
                </ListItemButton>
            )}
        </div>
    }
}
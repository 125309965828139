import "./Storage.scss"
import "../../../components/ListItemBuilding/listItemBuilding.scss"
import {api_host, api_with_auth} from "../../../utils/api";
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Modal,
    styled
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {getLoginToken} from "../../../utils/storage";
import {useParams, useLocation} from "react-router-dom";
import {TierToStars,} from "../../../components/mappingTable";
import {useSelector, useDispatch} from 'react-redux';
import TransitModal from "./Transit";
import {updateStorageWPayload} from "./storageSlice";
import {itemInfo} from "../../../components/gadgets";
import {ifNumberFloor2} from "../../../components/gadgets";
import {Item} from "../../../components/IconImg";
import SellModal from "./Sell";


//进度条
const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: "0.6vw",
    borderRadius: "0.3vw",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"
    }
}));


export default function Storage() {

    const dispatch = useDispatch();
    //拿此处建筑
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const state = useSelector((state: any) => state.state.data)
    //建筑是我管理/拥有
    const IGovern = ((state.uid === buildingSummary.owner.uid) || (state.uid === buildingSummary.manager.uid));
    let urlLocation = useLocation()


    const storage = useSelector((state: any) => state.storage.data)
    const fetchStorageNow = useSelector((state: any) => state.storage.fetchNow)

    //仓储多选框状态
    const [storageCheckBox, setStorageCheckBox] = useState<any>(new Array(70).fill(false))
    const handleCheckboxChange = (e: any) => {
        storageCheckBox[e.target.id] = e.target.checked;
        setStorageCheckBox(
            [...storageCheckBox]
        );
    }
    const handleDivCheckboxChange = (index: number) => {
        storageCheckBox[index] = !storageCheckBox[index];
        setStorageCheckBox(
            [...storageCheckBox]
        );
    }


    const [emptyStorage, setEmptyStorage] = useState<any>(false)
    const {buildingId} = useParams();
    //fetch仓储
    useEffect(() => {
            api_with_auth( "/api/building/" + buildingId + "/storage", "GET" )
                .then(res => {
                    if (!(res.data === undefined) && !(res.data === null)) {
                        setEmptyStorage(false)
                        dispatch(updateStorageWPayload(res.data))
                        setStorageCheckBox(Array(res.data.length).fill(false))
                    } else {
                        setEmptyStorage(true)
                    }
                })
        }
        , [urlLocation, fetchStorageNow])


//库存列表map html写在这，css在listItemBuilding
    const storageRender = storage.map((storage: any, index: any) =>
        <ListItemButton key={"storage_" + storage.id} alignItems="flex-start" sx={{padding: "0.1vw 1vw"}} disableRipple
                        style={(storageCheckBox[index]) ? {backgroundColor: "rgba(237, 108, 2,0.1)"} : {}}
                        onClick={() => {
                            handleDivCheckboxChange(index)
                        }}
        >
            <div className={"list-item-building list-item-building-storage"}>
                    {IGovern ?
                        <Checkbox
                            id={index}
                            onChange={handleCheckboxChange}
                            checked={storageCheckBox[index]}
                            style={{color: "#ed6c02"}}
                        />
                        : <div/>}
                    <Item id={storage.item_type} t={storage.item_tire} mr={0.8}/>
                    <div className={"two-story-text"}>
                        <div className={"up"}>
                            {itemInfo(storage.item_type)?.Chs + TierToStars[storage.item_tire]}
                        </div>
                        <div className={"down"}>
                            库存损耗 0%
                        </div>
                    </div>
                    <div className={"two-story-text five-vw right margin-left-auto"}>
                        <div className={"up"}>
                            {storage.item_soling ? (ifNumberFloor2(storage.item_soling)) : ""}
                        </div>
                        <div className={"down"}>
                            {storage.fresh_item_soling ? "含新增 " + (ifNumberFloor2(storage.fresh_item_soling)) : ``}
                        </div>
                    </div>
                    <div className={"two-story-text five-vw right"}>
                        <div className={"up black"}>
                            {storage.item_in_storage ? (ifNumberFloor2(storage.item_in_storage)) : ""}
                        </div>
                        <div className={"down black"}>
                            {storage.fresh_item_in_storage ? "含新增 " + (ifNumberFloor2(storage.fresh_item_in_storage)) : ""}
                        </div>
                    </div>
                    <div className={"two-story-text five-vw right"}>
                        <div className={"up orange"}>
                            {storage.total_weight ? ifNumberFloor2(storage.total_weight) : ""}
                        </div>
                        <div className={"down"}>
                            {"单重 " + itemInfo(storage.item_type)?.weight}
                        </div>
                    </div>
            </div>
        </ListItemButton>
    )

    //运输模态窗口
    //测试写true
    const [openTransit, setOpenTransit] = useState(false);
    const handleOpenTransit = () => setOpenTransit(true);
    const handleCloseTransit = () => setOpenTransit(false);
    //售卖的
    const [openSell, setOpenSell] = useState(false);
    const handleOpenSell = () => setOpenSell(true);
    const handleCloseSell = () => setOpenSell(false);

    //正文
    return <div className={"storage"} style={{width: "100%", height: "100%"}}>
        <div className={"storage-title-line"}>
            <div className={"storage-title"}>库存</div>
            <div className={"storage-progress"}>
                <Box sx={{width: '8vw', border: 0}}>
                    <BorderLinearProgress variant="determinate"
                                          value={buildingSummary.storage_capacity / buildingSummary.max_storage_capacity * 100}/>
                </Box>
            </div>
            <div
                className={"storage-text"}>{buildingSummary.storage_capacity?.toFixed(2) + " / " + buildingSummary.max_storage_capacity + " " + (buildingSummary.storage_capacity / buildingSummary.max_storage_capacity * 100)?.toFixed(1) + "%"}</div>
        </div>
        <div className={"grid-container"}>
            {IGovern ?
                <div className={"buttons"}>
                    {/*<Button disabled={storageCheckBox.every((v: boolean) => v === false)}*/}
                    <Button disabled={storageCheckBox.every((v: boolean) => !v)} variant={"outlined"}
                            onClick={() => {
                                handleOpenSell()
                            }}>出售</Button>
                    <Button disabled={storageCheckBox.every((v: boolean) => !v)} variant={"outlined"}
                            onClick={() => {
                                handleOpenTransit()
                            }}>运输</Button>
                </div>
                : <div/>}
            <div className={"fake-filter"}>
                <div>已投入市场</div>
                <div>就绪库存量</div>
                <div>总重量</div>
            </div>
        </div>
        <div className={"goods-list-container"}>
            <List sx={{
                width: '100%',
                maxWidth: "100%",
                padding: "0",
            }}>
                {emptyStorage ? <div/> : storageRender}
            </List>
        </div>
        {/*    插入运输的modal*/}
        <Modal
            open={openTransit}
            onClose={handleCloseTransit}
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
        >
            <TransitModal close={handleCloseTransit} storageCheckBox={storageCheckBox} storage={storage}/>
        </Modal>
        {/*售卖的模态*/}
        {/*<Modal*/}
        {/*    open={openSell}*/}
        {/*    onClose={handleCloseSell}*/}
        {/*    // aria-labelledby="modal-modal-title"*/}
        {/*    // aria-describedby="modal-modal-description"*/}
        {/*>*/}
            <SellModal open={openSell} onClose={handleCloseSell} storageCheckBox={storageCheckBox} storage={storage}/>
        {/*</Modal>*/}
    </div>


}
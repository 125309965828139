
//技能等级，门槛，工作策略
export const Strategies = [
    {
        "name": "钻研",
        "id": 4,
        "requested": 29,
        "requestedName": "匠师",
        "img-inactive": "strategy1inactive",
        "img-standard": "strategy1standard",
        "img-selected": "strategy1picked",
        "description": ["精力消耗-30%",
            "快乐、健康消耗-20%",
            "基础产能-10%",
            "技能增长-5%"],
    },
    {
        "name": "闲散",
        "id": 3,
        "requested": 15,
        "requestedName": "学徒",
        "img-inactive": "strategy1inactive",
        "img-standard": "strategy1standard",
        "img-selected": "strategy1picked",
        "description": ["精力消耗-30%",
            "快乐、健康消耗-20%",
            "基础产能-10%",
            "技能增长-5%"],
    },
    {
        "name": "通常",
        "id": 1,
        "requested": 1,
        "requestedName": "懵懂",
        "img-inactive": "strategy1inactive",
        "img-standard": "strategy1standard",
        "img-selected": "strategy1picked",
        "description": [],
    },
    {
        "name": "勤奋",
        "id": 2,
        "requested": 1,
        "requestedName": "懵懂",
        "img-inactive": "strategy1inactive",
        "img-standard": "strategy1standard",
        "img-selected": "strategy1picked",
        "description": ["精力消耗+50%",
            "快乐、健康消耗+50%",
            "基础产能+50%",
            "技能增长+30%"],
    },
    {
        "name": "钻研",
        "id": 5,
        "requested": 15,
        "requestedName": "大师",
        "img-inactive": "strategy1inactive",
        "img-standard": "strategy1standard",
        "img-selected": "strategy1picked",
        "description": ["精力消耗-30%",
            "快乐、健康消耗-20%",
            "基础产能-10%",
            "技能增长-5%"],
    },
    {
        "name": "无为",
        "id": 6,
        "requested": 15,
        "requestedName": "宗师",
        "img-inactive": "strategy1inactive",
        "img-standard": "strategy1standard",
        "img-selected": "strategy1picked",
        "description": ["精力消耗-30%",
            "快乐、健康消耗-20%",
            "基础产能-10%",
            "技能增长-5%"],
    },
]
import * as React from 'react'
import {api_with_auth} from "../utils/api";
import "./transaction.scss";
import "../components/ListItemBuilding/listItemBuilding.scss";
import {
    Button,
    Divider,
    ListItemButton, Popover,
} from '@mui/material';
import {useEffect, useState} from "react";
import {TransactionListBullet} from "../components/ListItemBuilding";
import {Add} from '@mui/icons-material';
import {useDispatch, useSelector} from "react-redux";
import {updateMyTransactionsWPayload} from "./myTransactionsSlice"
import NewTransactionInterface from "./Interface/interfaceNew";
import ViewTransactionInterface from "./Interface/interfaceView";
import {Route, Routes, useMatch, useNavigate, useParams} from "react-router-dom";
import {PickPlayerList} from "../components/PlayerSelector";
import Filler from "../components/Filler";

//TODO:推荐库房，前端部分
//TODO:长连接动态消息提示
//TODO:url绑定显示/创建功能

//交易种类到种类名字
export const TransactionStatusToChs: { [key: number]: string } = {
    0: "未决",
    1: "已完成",
    2: "已取消",
    3: "被拒",
    4: "已超时",
}

export function Transaction() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const state = useSelector((state: any) => state.state.data)
    const myBuildings = useSelector((state: any) => state.myBuildings.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const myTransactions = useSelector((state: any) => state.myTransactions.data)

    const viewingTransactionId = (useParams()["*"] ?? "").slice(5)


    const matchEmpty = useMatch(`/game/transaction`)
    const matchView = useMatch(`/game/transaction/view/:transactionId/*`)
    const matchNew = useMatch(`/game/transaction/new/*`)

    //在看的交易条目
    const nowViewingTransaction = myTransactions?.transactions?.find((txn: any) => (txn.id + "") === viewingTransactionId)

    //刷新获取私人交易数字监测器
    const getFetchMyTransactionsNow = useSelector((state: any) => state.myTransactions.fetchNow)
    useEffect(() => {
        api_with_auth("/api/transaction/my", "GET",)
            .then(res => {
                    if (res.status === "success") {
                        res.data.transactions?.sort((a: any, b: any) => b.initiate_time - a.initiate_time)
                            .sort((a: any, b: any) => a.status === 0 ? -1 : 0)
                        dispatch(updateMyTransactionsWPayload(res.data))
                        //列表如果空，右侧看空页面
                        if (res.data.transactions === null || res.data.transactions.length === 0) {
                        } else {
                            //如果url是根目录 && fetch到了我有交易，自动跳转第一条交易
                            if (matchEmpty) {
                                navigate(`/game/transaction/view/` + res.data.transactions[0].id, {replace: true})
                            }
                        }
                    }
                },
            );
    }, [getFetchMyTransactionsNow])

    //交易列表render
    const transactionList = (myTransactions.transactions === null || myTransactions.transactions.length === 0) ?
        // <ListItemButton disableRipple
        //                 alignItems="flex-start"
        //                 className={""}
        // >
        //     没有找到交易，点+新建一个
        // </ListItemButton>
        <Filler title={"没有找到交易，点+新建一个"}/>
        : (myTransactions.transactions?.map((transaction: any, index: any, array: any) =>
                <ListItemButton key={"personalOwnedBuildings_" + index} disableRipple
                                alignItems="flex-start"
                                onClick={() => {
                                    navigate(`/game/transaction/view/` + transaction.id)
                                }}
                                className={"transaction-bullet " + ((viewingTransactionId === (transaction.id + "")) ? "nowViewing" : "")}
                >
                    <TransactionListBullet transaction={transaction}/>
                </ListItemButton>
            ))

    //+ 选择接受方，新建交易popover
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event: any) => {
        if (!open) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'building-selector-popover' : undefined;
    const pickRecipient = (player: number) => {
        if (player !== -1) {
            navigate(`/game/transaction/new/?recipient=` + player)
        }
        handleClose()
    }

    //正文
    return <div className="transaction-root">
        <div className={"trans-left-list"}>
            {/*标题栏*/}
            <div className={"title-line"}>
                <div className={"title"}>
                    私人交易
                </div>
                {/* 标题右侧新建和设置钮   */}
                <Button className="add" ref={anchorEl} onClick={handleClick}>
                    <Add/></Button>
            </div>
            <div className={"list-title-line"}>
                <div className={"list-title-text"}>持续时间</div>
                <div className={"list-title-text"}>交易状态</div>
            </div>
            {/*列表*/}
            <div className={"transaction-list"}>
                {transactionList}
            </div>
        </div>
        <Divider orientation="vertical" variant="middle" flexItem/>
        <div className={"right-content"}>
            <div className={"right-container"}>
                <Routes>
                    {/*建筑种类的默认导航位置在mapping table，未完成建筑(开垦、建设)的默认导航在下面*/}
                    <Route index element={<div className={"trade-container hide"}/>}/>
                    <Route path={`/new/*`}
                           element={<div className={"trade-container"}><NewTransactionInterface/></div>}/>
                    <Route path={`/view/:transactionId/*`}
                           element={<div className={"trade-container"}><ViewTransactionInterface
                               transaction={nowViewingTransaction}/></div>}/>
                </Routes>
                <div className={"letter-bg"}/>
            </div>
        </div>
        {/*新建交易前选择接受方*/}
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <PickPlayerList pickPlayer={(player) => {
                pickRecipient(player.uid)
            }}/>
        </Popover>
    </div>
}


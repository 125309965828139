import "./edit.scss"
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, ListItemButton, TextField} from "@mui/material";
import BazaarItemSeller from "../component/BazaarItemSeller";
import BazaarItemAgent from "../component/BaazaarItemAgent";
import * as React from "react";
import {useEffect, useState} from "react";
import {openSnackbar} from "../../../appSnackbarSlice";
import {api_with_auth} from "../../../utils/api";
import Filler from "../../../components/Filler";

export default function BazaarItemsEdit(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const [bazaarItemsEdit, setBazaarItemsEdit] = useState<any>({
        "page": 0,
        "pageSize": 20,
        "totalPages": 20,
        "totalResults": 300,
        "content": [
            {
                //订单id
                "id": 1,
                //卖方建筑
                "from": {
                    //建筑id
                    "id": 1,
                    //建筑名字
                    "name": "寻找订单中",
                    //建筑头像
                    "avatar": "",
                    //种类
                    "type": 2,
                    //星级
                    "tier": 1,
                    //所在城市
                    "belong_city": 0,
                    //所在街区
                    "belong_districts": 0,
                    //所在地形
                    "belong_terrain": 0,
                },
                //寄售的贸易站建筑。如果没有通过贸易站，不返回或者返回id=-1都可以
                "agent": {
                    "id": -1,
                    "name": "中介1",
                    "avatar": "",
                    "type": 41,
                    "tier": 1,
                    "belong_city": 0,
                    "belong_districts": 0,
                    "belong_terrain": 0,
                },
                //卖方玩家
                "seller": {
                    "uid": 1,
                    "nick_name": "",
                    "avatar": ""
                },
                //出售的货物
                "selling": {
                    "id": 39,
                    "tier": 1,
                    //出售货物的数量，有人购买时实时更新
                    "quantity": 1,
                    //创建订单时候的总量
                    "quantityWhenCreated": 1,
                },
                //交换的货物
                "buying": {
                    "id": 33,
                    "tier": 1,
                    //单价
                    "quantityPerItem": 1,
                },
                //订单创建时间戳
                "create_time": 1,
                //最后编辑时间戳
                "edit_time": 1,
                //订单截止日期。使用游戏日期game_day
                "available_until": 1,
                //订单状态。0进行中1已完售2已取消3已超时
                "status": 1,
            },
        ]
    })
    const emptyBazaarItemsEdit = !bazaarItemsEdit.content


    const fetch = () => {
        api_with_auth("/api/bazaar/items/my", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                    if (res.status === "success") {
                        setBazaarItemsEdit(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }
    useEffect(() => {
        fetch()
    }, [])

    //取消订单
    const cancel = (sellId: number) => {
        api_with_auth("/api/bazaar/items/" + sellId + "/cancel", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "订单已取消，未售完的物资已归还。",
                        }))
                        fetch()
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    //编辑成功的snackbar
    const editSuccessSnackbar = () => {
        dispatch(openSnackbar({
            "severity": "info",
            "snackbarMessage": "即将可用。",
        }))
    }


    if (emptyBazaarItemsEdit) {
        return <div className={"bazaar-items-edit-root"}><Filler title={"你没有物品在售"}/></div>
    } else {
        return <div className={"bazaar-items-edit-root"}>
            {bazaarItemsEdit.content.map((sell: any, index: any) =>
                <ListItemButton className={"bazaar-item-bullet-root"} key={"bazaarItem_" + sell.id} disableRipple>
                    <div className={"bazaar-item-seller-and-others"}>
                        <BazaarItemSeller sell={sell}/>
                        <Button variant="text" className={"modify-order"} color={"happiness"}
                                onClick={() => {
                                    editSuccessSnackbar()
                                }}
                                style={{
                                    margin: "0.8vw 0.5vw",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    maxWidth: "4vw",
                                    minWidth: "4vw",
                                    fontSize: '0.8vw',
                                    padding: 0,
                                }}>
                            修改
                        </Button>
                        <Button variant="text" className={"modify-order"} color={"happiness"}
                                onClick={() => {
                                    cancel(sell.id)
                                }}
                                style={{
                                    margin: "0.8vw 0.5vw",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    maxWidth: "4vw",
                                    minWidth: "4vw",
                                    fontSize: '0.8vw',
                                    padding: 0,
                                }}>
                            下架
                        </Button>
                    </div>
                    {sell.agent.id !== 0 ? <BazaarItemAgent sell={sell}/> : null}
                </ListItemButton>
            )}
        </div>
    }
}
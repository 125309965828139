import "./CheckMark.scss"

export function SPVSCheckMark(props: {
    mr?: number
    variant?: "check" | "cross" | "dot"
}) {
    const mr = props.mr ?? 0
    const variant = props.variant ?? "check"
    return <span className={"supervisor-check-mark-root " + variant}
                 style={{marginRight: `${mr}vw`}}
    >{{
        "check": "✓",
        "cross": "✘",
        "dot": "•"
    }[variant]}</span>
}
import {Link, useMatch, useParams} from "react-router-dom";
import "./Flowchart.css"
import {useState} from "react";
import {StatusBar1x4} from "../../../../components/statusBar";
import {useSelector} from "react-redux";
import {BPBuildingInfo} from "../../../../components/gadgets";
import {BuildingSpecialType} from "../../../../components/mappingTable";
import {getBPResidence} from "../../../../utils/storage";

function NavButtonFacilities(props: {
    to: string,
    type: number,
}) {
    let match = useMatch(props.to);
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-facilities" + (match ? " building-flow-chart-facilities-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{BPBuildingInfo(props.type).name}</p>
                <p className={"building-flow-chart-left-down"}>设施功能</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>设施</p>
            </div>
        </div>
    </Link>
}

const NavButtonFacilitiesWithRouter = NavButtonFacilities

function NavButtonTools(props: {
    to: string,
}) {
    let match = useMatch(props.to);
    const [leftUpText, setLeftUpText] = useState<any>("100%")
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-tools" + (match ? " building-flow-chart-tools-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{leftUpText}</p>
                <p className={"building-flow-chart-left-down"}>工具已填充</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>工具</p>
            </div>
        </div>
    </Link>
}

const NavButtonToolsWithRouter = NavButtonTools

function NavButtonWorks(props: {
    to: string,
    worker_count: number,
    today_worked_count: number,
}) {
    let match = useMatch(props.to);
    const [employees, setEmployees] = useState<any>({
        "data": [
            {
                "buildingID": 3,
                "employee": {
                    "uid": 13,
                    "user": {
                        "created_at": -6795364578871345152,
                        "updated_at": -6795364578871345152
                    },
                    "nick_name": "气氛组",
                    "avatar": ""
                },
                "employeeID": 13,
                "is_worked": false,
                "quitting": false
            }
        ],
        "status": "success"
    })
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-works" + (match ? " building-flow-chart-works-active" : "")}>
            <div style={{width: "50%", float: "left", opacity: (match ? "0%" : "100%"), transition: "all 100ms"}}>
                <p className={"building-flow-chart-left-up"}>{props.today_worked_count + "/" + props.worker_count}</p>
                <p className={"building-flow-chart-left-down"}>工人已工作</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={(match ? "building-flow-chart-text" : "building-flow-chart-right")}>工作</p>
            </div>
            <img src={require("./assets/building_tab_5_1.webp").default} alt={""} style={{
                width: "100%",
                margin: '0',
                position: 'absolute',
                left: 0,
                top: 0,
                opacity: (match ? "100%" : "0%"),
                transition: "all 100ms"
            }}/>
        </div>
    </Link>
}

const NavButtonWorksWithRouter = NavButtonWorks

function NavButtonWorksSmallOut(props: {
    to: string,
    label: string,
    worker_count: number,
    today_worked_count: number,
}) {
    let match = useMatch(props.to);
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-facilities" + (match ? " building-flow-chart-facilities-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{props.today_worked_count + "/" + props.worker_count}</p>
                <p className={"building-flow-chart-left-down"}>工人已工作</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

const NavButtonWorksSmallOutWithRouter = NavButtonWorksSmallOut

function NavButtonWorksSmallInOut1(props: {
    to: string,
    label: string,
    worker_count: number,
    today_worked_count: number,
}) {
    let match = useMatch(props.to);
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data);
    const progress = (buildingSummary.stage === 0) ? ((buildingSummary.percent).toFixed(2) + "%") : "100%"
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-smallInOut-1" + (match ? " building-flow-chart-smallInOut-1-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{progress}</p>
                <p className={"building-flow-chart-left-down"}>开垦进度</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

const NavButtonWorksSmallInOut1WithRouter = NavButtonWorksSmallInOut1

function NavButtonWorksSmallInOut2(props: {
    to: string,
    label: string,
    worker_count: number,
    today_worked_count: number,
}) {
    let match = useMatch(props.to);
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data);
    const progress = (buildingSummary.stage === 1) ? ((buildingSummary.percent).toFixed(2) + "%") : "0.00%"

    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-smallInOut-2" + (match ? " building-flow-chart-smallInOut-2-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{progress}</p>
                <p className={"building-flow-chart-left-down"}>建造进度</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

const NavButtonWorksSmallInOut2WithRouter = NavButtonWorksSmallInOut2

function NavButtonSupervisor(props: {
    to: string,
}) {
    let match = useMatch(props.to);
    const [leftUpText, setLeftUpText] = useState<any>("100%")
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-supervisor" + (match ? " building-flow-chart-supervisor-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{leftUpText}</p>
                <p className={"building-flow-chart-left-down"}>维护度</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>监工</p>
            </div>
        </div>
    </Link>
}

const NavButtonSupervisorWithRouter = NavButtonSupervisor

function NavButtonOutput(props: {
    to: string,
    label: string,
    max_storage_capacity: number,
    storage_capacity: number
}) {

    let match = useMatch(props.to);
    const [leftUpText, setLeftUpText] = useState<any>("0%")
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-output" + (match ? " building-flow-chart-output-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{(props.storage_capacity / props.max_storage_capacity * 100).toFixed(1) + "%"}</p>
                <p className={"building-flow-chart-left-down"}>仓库容量</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

const NavButtonOutputWithRouter = NavButtonOutput

function NavButtonOrderFood(props: {
    to: string,
}) {
    let match = useMatch(`/game/building/:buildingId/OrderFood/*`);
    // let match = useMatch(props.to);
    const [leftUpText, setLeftUpText] = useState<any>("-份")
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-works" + (match ? " building-flow-chart-works-active" : "")}>
            <div style={{width: "50%", float: "left", opacity: (match ? "0%" : "100%"), transition: "all 100ms"}}>
                <p className={"building-flow-chart-left-up"}>{leftUpText}</p>
                <p className={"building-flow-chart-left-down"}>历史供应</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={(match ? "building-flow-chart-left" : "building-flow-chart-right")}>点餐</p>
            </div>
            <div className={"building-flow-chart-status1x4"} style={{opacity: (match ? "100%" : "0%"),}}>
                <StatusBar1x4/>
            </div>
        </div>
    </Link>
}

const NavButtonOrderFoodWithRouter = NavButtonOrderFood


//通用版本
//矮双箭头1
function ShortDoubleArrow1(props: {
    to: string,
    LUText: string,
    LDText: string,
    label: string,
}) {
    let match = useMatch(props.to + "*");
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-smallInOut-2" + (match ? " building-flow-chart-smallInOut-2-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{props.LUText}</p>
                <p className={"building-flow-chart-left-down"}>{props.LDText}</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

//矮双箭头2
function ShortDoubleArrow2(props: {
    to: string,
    LUText: string,
    LDText: string,
    label: string,
}) {
    let match = useMatch(props.to);
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-smallInOut-1" + (match ? " building-flow-chart-smallInOut-1-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{props.LUText}</p>
                <p className={"building-flow-chart-left-down"}>{props.LDText}</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

//高双箭头1
function HighDoubleArrow1(props: {
    to: string,
    LUText: string,
    LDText: string,
    label: string,
}) {
    let match = useMatch(props.to);
    return <Link to={props.to}>
        <div
            className={"building-flow-chart-link building-flow-chart-supervisor" + (match ? " building-flow-chart-supervisor-active" : "")}>
            <div style={{width: "50%", float: "left"}}>
                <p className={"building-flow-chart-left-up"}>{props.LUText}</p>
                <p className={"building-flow-chart-left-down"}>{props.LDText}</p>
            </div>
            <div style={{width: "41%", float: "right"}}>
                <p className={"building-flow-chart-right"}>{props.label}</p>
            </div>
        </div>
    </Link>
}

export function Flowchart(props: {
    buildingType: number,
    worker_count: number,
    today_worked_count: number,
    max_storage_capacity: number,
    storage_capacity: number
    stage: number,
}) {
    const BId = useParams().buildingId
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const recipes = buildingSummary.recipe?.recipes
    //藏身处的住房
    const residence = buildingSummary.hideOut?.residence
    //住房
    const guests = buildingSummary.residence?.guests
    const residenceInfo = getBPResidence().find((b: any) => b.buildingId === buildingSummary?.type)

    if (props.stage === 0 || props.stage === 1) {
        //没有建完的建筑
        return <div className={"building-flow-chart"}>
            <NavButtonFacilitiesWithRouter to={`/game/building/${BId}/facilities`}
                                           type={props.buildingType}/>
            <NavButtonWorksSmallInOut1WithRouter to={`/game/building/${BId}/works`}
                                                 label={'开垦'}
                                                 worker_count={props.worker_count}
                                                 today_worked_count={props.today_worked_count}
            />
            <NavButtonWorksSmallInOut2WithRouter to={`/game/building/${BId}/works`}
                                                 label={'建造'}
                                                 worker_count={props.worker_count}
                                                 today_worked_count={props.today_worked_count}
            />
            <NavButtonSupervisorWithRouter to={`/game/building/${BId}/supervisor`}/>
            <NavButtonOutputWithRouter to={`/game/building/${BId}/storage`}
                                       label={'产出'}
                                       max_storage_capacity={props.max_storage_capacity}
                                       storage_capacity={props.storage_capacity}
            />
        </div>;
    } else {
        switch (BuildingSpecialType({"type": props.buildingType})) {
            case "restaurant":
                //餐馆，普通加点餐卡。设施 工具 工作（写作烹饪实际上就是工作面板） 点餐 监工 库存
                return <div className={"building-flow-chart"}>
                    <NavButtonFacilitiesWithRouter to={`/game/building/${BId}/facilities`}
                                                   type={props.buildingType}/>
                    <NavButtonToolsWithRouter to={`/game/building/${BId}/tools`}/>
                    <NavButtonWorksSmallOutWithRouter to={`/game/building/${BId}/works`}
                                                      label={'烹饪'}
                                                      worker_count={props.worker_count}
                                                      today_worked_count={props.today_worked_count}
                    />
                    <ShortDoubleArrow1 to={`/game/building/${BId}/recipe`} LUText={recipes?.length ?? 0} LDText={"食谱数目"}
                                       label={"食谱"}/>
                    <NavButtonOrderFoodWithRouter to={`/game/building/${BId}/OrderFood`}/>
                    <NavButtonSupervisorWithRouter to={`/game/building/${BId}/supervisor`}/>
                    <NavButtonOutputWithRouter to={`/game/building/${BId}/storage`}
                                               label={'库存'}
                                               max_storage_capacity={props.max_storage_capacity}
                                               storage_capacity={props.storage_capacity}
                    />
                </div>;
            case "hideout":
                //藏身处
                return <div className={"building-flow-chart"}>
                    <NavButtonFacilitiesWithRouter to={`/game/building/${BId}/facilities`}
                                                   type={props.buildingType}/>
                    <ShortDoubleArrow2 to={`/game/building/${BId}/library`} LUText={"0"} LDText={"藏书数目"}
                                       label={"藏书"}/>
                    <ShortDoubleArrow1 to={`/game/building/${BId}/recipe`} LUText={recipes?.length ?? 0} LDText={"食谱数目"}
                                       label={"食谱"}/>
                    <HighDoubleArrow1 to={`/game/building/${BId}/residence`}
                                      LUText={residence?.type === 0 ? "风餐露宿" : BPBuildingInfo(residence.type)?.name}
                                      LDText={"住宿水平"}
                                      label={"住宿"}/>

                    <NavButtonOutputWithRouter to={`/game/building/${BId}/storage`}
                                               label={'库存'}
                                               max_storage_capacity={props.max_storage_capacity}
                                               storage_capacity={props.storage_capacity}
                    />
                </div>;
            case "housing":
                //宅院/楼房/棚屋？我别墅呢。藏身处不算
                return <div className={"building-flow-chart"}>
                    <NavButtonFacilitiesWithRouter to={`/game/building/${BId}/facilities`}
                                                   type={props.buildingType}/>
                    <HighDoubleArrow1 to={`/game/building/${BId}/guestRooms`}
                                      LUText={(guests?.length ?? 0) + "/" + residenceInfo?.guestSlots} LDText={"已入住"}
                                      label={"客房"}/>
                    <NavButtonSupervisorWithRouter to={`/game/building/${BId}/supervisor`}/>

                    <NavButtonOutputWithRouter to={`/game/building/${BId}/storage`}
                                               label={'库存'}
                                               max_storage_capacity={props.max_storage_capacity}
                                               storage_capacity={props.storage_capacity}
                    />
                </div>;
            case "agent":
                //贸易站
                return <div className={"building-flow-chart"}>
                    <NavButtonFacilitiesWithRouter to={`/game/building/${BId}/facilities`}
                                                   type={props.buildingType}/>
                    <NavButtonToolsWithRouter to={`/game/building/${BId}/tools`}/>
                    <NavButtonWorksSmallOutWithRouter to={`/game/building/${BId}/works`}
                                                      label={'会计'}
                                                      worker_count={props.worker_count}
                                                      today_worked_count={props.today_worked_count}
                    />
                    <HighDoubleArrow1 to={`/game/building/${BId}/agent`} LUText={"0"} LDText={"历史寄售"}
                                      label={"寄售"}/>
                    <NavButtonSupervisorWithRouter to={`/game/building/${BId}/supervisor`}/>

                    <NavButtonOutputWithRouter to={`/game/building/${BId}/storage`}
                                               label={'库存'}
                                               max_storage_capacity={props.max_storage_capacity}
                                               storage_capacity={props.storage_capacity}
                    />
                </div>;

            //所有普通生产建筑
            default:
                return <div className={"building-flow-chart"}>
                    <NavButtonFacilitiesWithRouter to={`/game/building/${BId}/facilities`}
                                                   type={props.buildingType}/>
                    <NavButtonToolsWithRouter to={`/game/building/${BId}/tools`}/>
                    <NavButtonWorksWithRouter to={`/game/building/${BId}/works`}
                                              worker_count={props.worker_count}
                                              today_worked_count={props.today_worked_count}
                    />
                    <NavButtonSupervisorWithRouter to={`/game/building/${BId}/supervisor`}/>
                    <NavButtonOutputWithRouter to={`/game/building/${BId}/storage`}
                                               label={'产出'}
                                               max_storage_capacity={props.max_storage_capacity}
                                               storage_capacity={props.storage_capacity}
                    />
                </div>;
        }
    }
}


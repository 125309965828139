import {createSlice} from '@reduxjs/toolkit';
import {getLoginToken} from "./utils/storage";
import {api_host} from "./utils/api";

const slice = createSlice({
    name: 'myBuildings',
    initialState: {
        "data": {
            "count": 1,
            "buildings":
                [
                    {
                        "id": 6,
                        "owner": {
                            "uid": 4,
                            "nick_name": "fetching",
                            "avatar": "",
                            "born_city": 0
                        },
                        "owner_id": 4,
                        "manager": {
                            "uid": 4,
                            "nick_name": "player",
                            "avatar": "",
                            "born_city": 0
                        },
                        "manager_id": 4,
                        "name": "fetching building",
                        "avatar": "",
                        "type": 5,
                        "tier": 1,
                        "storage_capacity": 42419.67081173745,
                        "max_storage_capacity": 100000,
                        "belong_city": 0,
                        "belong_districts": 0,
                        "belong_terrain": 0,
                        "culture": 0,
                        "stage": 2,
                        "percent": 100,
                        "product_type": 0,
                        "architect": 4
                    }
                ]
        },
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateMyBuildingsWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchMyBuildingsNow: (state) => {
            state.fetchNow += 1;
        },
    },
});
export const {updateMyBuildingsWPayload, fetchMyBuildingsNow} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

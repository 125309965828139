import "./forum.scss"
import * as React from 'react'
import {api_host, api_with_auth} from "../utils/api";
import {useEffect, useState} from 'react'
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions, Collapse,
    createTheme,
    Divider, IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, styled,
    TextField,
    Typography,
} from '@mui/material'
import CircularProgress from "@mui/material/CircularProgress";
import {Link, useNavigate} from "react-router-dom";
import {getLoginToken} from "../utils/storage";
import {id2color} from "../utils/id2color";
import {useSelector, useDispatch} from 'react-redux';
import {fetchStatusNow} from "../appSlice"
import StatusBar from "../components/statusBar";
import SpeechBullet from "./components/speechBullet";
import TimeToNow from "../components/timeToNow";
import {systemSpeeches} from "./systemSpeeches";
import {ExpandMore} from "@mui/icons-material";
import Filler from "../components/Filler";
import {Floor1} from "../components/gadgets";

const Forum: React.FunctionComponent<any> = () => {
    createTheme({
        palette: {
            mode: 'dark',
        },
    });
    const navigate = useNavigate()
    const status = useSelector((state: any) => state.status.data)
    const speechConsumeReduce = (status.skills[100]?.level ?? 0) * 3 / 50
    const speechConsume = -7 + speechConsumeReduce
    const dispatch = useDispatch();
    //自己
    const state = useSelector((state: any) => state.state.data)


    const [fetchSpeechNow, setFetchSpeechNow] = useState(1)
    const [HotSpeech, setHotSpeech] = useState({
        "ID": 0,
        "Uid": 0,
        "Uname": "",
        "Uavatar": "",
        "Time": 0,
        "Content": "",
        "NumReaction1": 0,
        "NumReaction2": 0,
        "NumReaction3": 0,
        "MyReaction": 0
    });

    // 获取热门演讲
    const getHotSpeech = () => {
        api_with_auth("/api/speech/popular", "GET",)
            .then(resJsonToHotSpeech => {
                    setHotSpeech(resJsonToHotSpeech)
                },
            );
    }
    // 获取系统演讲
    const [systemSpeech, setSystemSpeech] = useState({
        "ID": 0,
        "Time": 0,
        "UserName": "",
        "UserAvatar": "",
        "Content": ""
    })
    //去掉第一个的系统演讲
    const trimmedSystemSpeeches = systemSpeeches()
    const firstSystemSpeech = trimmedSystemSpeeches.shift()
    // 获取系统演讲(暂时用前端假的系统演讲列表，也不赖？)
    const getSystemSpeech = () => {
        api_with_auth("/api/speech/system", "GET",)
            .then(resJsonToSystemSpeech => {
                    setSystemSpeech(resJsonToSystemSpeech.data)
                },
            );
    }
    //系统演讲那边的展开关闭
    const [sysExpanded, setSysExpanded] = useState(false);
    const handleSysExpandClick = () => {
        setSysExpanded(!sysExpanded);
    };

    // 获取演讲列表
    const [speechList, setSpeechList] = useState<any>({data: [], status: ""})
    const getSpeech = () => {
        api_with_auth("/api/speeches?pageSize=20", "GET")
            .then(resJsonToSpeechList => {
                    setSpeechList(resJsonToSpeechList)
                },
            );
    }
    useEffect(() => {
        getHotSpeech();
        getSystemSpeech();
        getSpeech()
    }, [fetchSpeechNow])

    //写演讲和发演讲
    const [writingSpeechContent, setWritingSpeechContent] = useState("")
    const postSpeech = () => {
        api_with_auth("/api/speeches", "POST", {
            content: writingSpeechContent,
        })
            .then(data => {
                dispatch(fetchStatusNow())
                //成功发演讲清空输入框
                setWritingSpeechContent("");
                setFetchSpeechNow(fetchSpeechNow + 1)
            });
    }


    //演讲互动在sppechBullet里


//进度圈
    function CircularProgressWithLabel(props: any) {
        return (
            <Box sx={{position: "relative", display: "inline-flex",}}>
                <CircularProgress variant="determinate" {...props} size={"1.6vw"}/>
                <Box
                    sx={{
                        width: '1vw',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // m:"0 0.3vw",
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary"
                                sx={{fontSize: '0.7vw'}}
                    >
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }


    return (
        <div className="c-square container">
            <div className="left-container">
                <div className="header-img-container">
                    <img src={require('./assets/forum-bg@2x.webp').default} alt={""}/>
                </div>
                <div className="artworks-img-container">
                    <header><h2 className={"hot-tweet-container-text"}>热门艺术品</h2></header>
                    <img src={require('./assets/artworks@2x.webp').default} alt={""}/>
                </div>

                {/*热门演讲*/}

                <div className="hot-tweet-container">
                    <header><h2 className={"hot-tweet-container-text"}>热门演讲</h2></header>
                    <div className="tweet-card">
                        <div className="hot-tweet-inner-container">
                            <SpeechBullet key={"speech_" + HotSpeech.ID}
                                          speech={HotSpeech}
                                          updateSpeech={() => setFetchSpeechNow(fetchSpeechNow + 1)}
                            />
                            <Divider variant="middle" component="div"/>
                        </div>
                    </div>
                    <div className="crowd-img-container">
                        <img src={require('./assets/crowd@2x.webp').default} alt={""}/>
                    </div>
                </div>

                {/*系统公告*/}
                <div className="hot-tweet-container">
                    <header><h2 className={"hot-tweet-container-text"}>系统公告</h2></header>
                    <div className="tweet-card">
                        <SpeechBullet key={"speech_" + systemSpeeches()[0].ID}
                                      speech={firstSystemSpeech}
                                      updateSpeech={() => {
                                      }}
                        />
                        <Divider variant="middle" component="div" className="system-speech-divider"/>
                        <div>
                            <IconButton onClick={handleSysExpandClick} className="system-speech-expand"
                                        aria-label="delete">
                                <ExpandMore/>
                            </IconButton>
                        </div>
                        <Collapse in={sysExpanded} timeout="auto" unmountOnExit
                                  style={{marginTop: "-4vw", marginBottom: "4vw"}}>
                            {trimmedSystemSpeeches?.map((speech: any, index) =>
                                <div key={"speech_" + speech.ID}>
                                    <SpeechBullet speech={speech} updateSpeech={() => {
                                    }}/>
                                    <Divider variant="middle" component="div"/></div>
                            )}
                        </Collapse>
                    </div>
                    <div className="system-speech-crowd crowd-img-container">
                        <img src={require('./assets/crowd@2x.webp').default} alt={""}/>
                    </div>
                </div>
            </div>
            <Divider orientation="vertical" variant="middle" className="middle-divider"/>
            <div className="right-container">

                {/*写演讲卡片*/}
                <div className={"writing-speech"}>
                    <TextField
                        sx={{
                            "& > :not(style)": {
                                m: "1vw 1.5vw 1.5vw 1.5vw",
                                width: "25vw",
                                fontSize: '0.88vw',
                                lineHeight: "1.8em"
                            }
                        }}
                        placeholder="酝酿演说。" variant="standard" InputProps={{disableUnderline: true}}
                        multiline value={writingSpeechContent}
                        onChange={(event) => {
                            setWritingSpeechContent(event.target.value);
                        }}/>
                    <Divider variant="middle"/>
                    <div className={"actions"}>
                        <div className="description">
                            {Floor1(status.skills[100]?.level ?? 0)}级演讲技能<br/>
                            减免了{Floor1(speechConsumeReduce)}精力消耗
                        </div>
                        <StatusBar type={"energy"} changingValue={speechConsume}/>
                        <Button color={"happiness"} onClick={postSpeech}>发表
                            <CircularProgressWithLabel
                                sx={{
                                    color: "#ed6c02",
                                    //圆圈进度条的字体大小fontsize无效
                                }}
                                value={writingSpeechContent.length / 2}
                                size={22}
                            />
                        </Button>
                    </div>
                </div>
                <Link to={"/game/personal/" + state.uid} className={"makespeech-avt myavatar"}><Avatar
                    sx={{width: "3vw", height: "3vw", m: "0 2vw 1vw 2vw", bgcolor: id2color(state.uid)}}
                    src={state.avatar}
                    alt={state.nick_name}>{state.nick_name ? state.nick_name[0] : ""}</Avatar></Link>
                <Divider variant="middle"/>
                <header><h2 className={"local-speech-container-text"}>本地演讲</h2></header>

                {/*本地演讲时间轴*/}
                <List sx={{width: '100%', maxWidth: "28.5vw", m: "0 0.6vw", p: "0"}}>
                    {speechList.data.map((speech: any, index: any) =>
                        <div key={"speech_" + speech.ID}>
                            <SpeechBullet
                                speech={speech}
                                updateSpeech={() => setFetchSpeechNow(fetchSpeechNow + 1)}
                            /><Divider variant={"middle"}/></div>)}
                </List>
                <Filler/>
            </div>
        </div>
    )
}

export default Forum

import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'employeeSkills',
    initialState: {
        "data": [
            {
                "user_id": 1,
                "skills": {}
            },
            {
                "user_id": 2,
                "skills": {
                    "100": {
                        "breakthrough": 14,
                        "level": 13.606509996305718,
                        "saved_level": 0,
                        "type": 100
                    },
                }
            },

        ],
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateEmployeeSkillsWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchEmployeeSkillsNow: (state) => {
            state.fetchNow += 1;
        },

    },
});
export const {updateEmployeeSkillsWPayload, fetchEmployeeSkillsNow} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

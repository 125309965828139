import "./BazaarItemAgent.scss"
import {useSelector} from "react-redux";
import {BalanceRounded} from "@mui/icons-material";
import {Avatar, Tooltip} from "@mui/material";
import {BPBuildingInfo, districtIdToName, Floor2} from "../../../../components/gadgets";
import {TierToStars} from "../../../../components/mappingTable";
import {shippingLossTip} from "../../../../components/ToolTips";
import * as React from "react";

export default function BazaarItemAgent(props: {
    sell: any
}) {
    const sell = props.sell
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

//卖货交易站的基础结构
    return <div className={"bazaar-item-agent"}>
        <div className={"list-item-building"}>
            <BalanceRounded/>
            <Avatar alt={sell.agent.name} src={sell.agent.avatar}
                    sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                    variant="rounded"
            >{sell.agent.name?.[0]}</Avatar>

            <div className={"two-story-text"}>
                <div className={"up"}>
                    {sell.agent.name}
                </div>
                <div className={"down"}>
                    {BPBuildingInfo(sell.agent.type)?.name}{TierToStars[sell.agent.tier]}{" - "}{districtIdToName(sell.agent.belong_districts, cityStructure)}
                    ：距离我0里（损耗0%
                    <Tooltip title={shippingLossTip} arrow
                             disableInteractive><u>🛈</u></Tooltip>）
                </div>
            </div>
            <div className={"two-story-text four-vw tax"}>
                <div className={"up"}>
                    {Floor2(sell.agent.tax * 100)}%
                </div>
                <div className={"down"}>
                    税率
                </div>
            </div>
            <div className={"agent-description"}>
                贸易站的专业会计为这条寄售的货物订单免除了大量的运输损耗。贸易税已付清。
            </div>

        </div>
    </div>
}

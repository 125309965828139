import "./RecruitInfo.scss"
import {useDispatch, useSelector} from "react-redux";
import {RecruitBullet, RecruitBulletP} from "../../../../components/ListItemBuilding";
import * as React from "react";
import {Button, Divider, FormControl, InputLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import {BPItems} from "../../../../components/gadgets";
import {api_host, api_with_auth} from "../../../../utils/api";
import {getLoginToken} from "../../../../utils/storage";
import {fetchStatusNow} from "../../../../appSlice";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";


export default function RecruitInfo(props: {}) {
    const dispatch = useDispatch();
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    // const buildingSummary = props.building
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    // const city = props.city

    const [hiring, setHiring] = useState(buildingSummary.is_hiring)
    const [disabled, setDisabled] = useState(false)
    const BId = useParams().buildingId

    const handleToggle = () => {
        Toggle()
        setHiring(!hiring)
        setDisabled(true)
    }
    const [quantity, setQuantity] = useState(buildingSummary.salary_number)
    const [type, setType] = useState(buildingSummary.salary_type)
    const [tier, setTier] = useState(buildingSummary.salary_tier)

    //信息加载后同步当前招聘信息
    useEffect(() => {
        setQuantity(buildingSummary.salary_number)
        setType(buildingSummary.salary_type)
        setTier(buildingSummary.salary_tier)
        setHiring(buildingSummary.is_hiring)
    }, [buildingSummary])
    const Toggle = () => {
        api_with_auth("/api/building/" + BId + "/set_is_hiring",   "POST", {
                "is_hiring": !hiring,
            } )
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": (hiring ? "该建筑停止招聘了。" : "在街区张贴了招聘广告。") + (res.message ? res.message : ""),
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                    setHiring(!hiring)
                }
                dispatch(fetchBuildingSummaryNow())
                setDisabled(false)

            },);
    }
    const Change = () => {
        api_with_auth( "/api/building/" + BId + "/set_salary",  "POST",  {
                "salary_type": type,
                "salary_tier": tier,
                "salary_number": parseFloat(quantity)
            } )
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": ("修改了建筑的工资。" + (res.message ? res.message : "")),
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
                dispatch(fetchBuildingSummaryNow())
            },);
    }

    return <div className={"recruit-info"}>
        <div className={"heavy sub-text"}>当前生效的招聘信息：</div>
        <div className={"preview"}>
            <RecruitBulletP/>
        </div>
        <div className={"heavy sub-text"}>切换招聘状态：</div>
        <div className={"flex"}>
            <Switch checked={hiring}
                    onChange={handleToggle}
                    disabled={disabled}
            />
            <div
                className={buildingSummary.is_hiring ? "active" : ""}>{buildingSummary.is_hiring ? "该建筑正在招聘" : "该建筑未在招聘"}</div>
        </div>
        <div className={"heavy sub-text"}>修改薪水待遇：</div>
        <div className={"change"}>
            <FormControl>
                <TextField label="每产能薪水 " type="number"
                           className={"quantity"}
                           value={quantity}
                           onChange={(e) => {
                               setQuantity(e.target.value);
                           }}
                />
            </FormControl>
            <FormControl>
                <InputLabel>薪水种类</InputLabel>
                <Select label="薪水种类" value={type} onChange={(e) => {
                    setType(e.target.value);
                }}>
                    {BPItems.map((item: any, index: any) =>
                        <MenuItem value={item.id} key={item.id}>{item.Chs}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel>薪水星级</InputLabel>
                <Select label="薪水星级" value={tier} onChange={(e) => {
                    setTier(e.target.value);
                }}
                        className={"tier"}>
                    <MenuItem value={4}>★★★</MenuItem>
                    <MenuItem value={3}>★★</MenuItem>
                    <MenuItem value={2}>★</MenuItem>
                    <MenuItem value={1}>☆</MenuItem>
                </Select>
            </FormControl>
            <Button variant="contained" className={"apply-job-btn"}
                    onClick={Change}>
                应用修改
            </Button>
        </div>
        <div className={"sub-text"}>
            {/*单位薪水是工人每贡献1产能，所有者要支付的薪水。<br />*/}
            薪水将从建筑所有者的私人库房支付到这个建筑（运输损耗由雇主承担），再发送到工人的私人库房（运输损耗由工人承担）。
        </div>
    </div>
}
import "./Residence.scss"
import {Avatar, Divider} from "@mui/material";
import * as React from "react";
import {Delete} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {BPBuildingInfo, BuildingTypeTierToChs} from "../../../components/gadgets";
import {TierToStars} from "../../../components/mappingTable";
import {getBPResidence} from "../../../utils/storage";

export default function Residence() {
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const BPResidence = getBPResidence()
    const residence = buildingSummary.hideOut?.residence
    const residenceInfo = getBPResidence().find((b: any) => b.buildingId === (residence?.type === 0 ? 5 : residence?.type))
    const residenceTierInfo = residenceInfo?.tiers.find((t: any) => t.tier === (residence?.type === 0 ? 1 : residence?.tier))
//building可能没有住宿相关信息（建筑不是住房），需要先判断

    return <div className={"residence-root"}>
        <div className={"residence-title"}>住宿</div>
        <div className={"residence-content"}>
            <div className={"residence-card"}>
                <img src={process.env.PUBLIC_URL + "/img/buildings/hideout_east.webp"} className={"title-img"}
                     alt={"residence-place"}/>
                <Link className={"title-banner"}
                      to={residence?.type === 0 ? `/game/district/housing` : `/game/building/${residence?.id}`}>
                    {/*<div className={"avt"}></div>*/}
                    <Avatar className={"avt"} src={residence?.avatar} sx={{width: '2.8vw', height: '2.8vw'}}
                            variant="rounded"> </Avatar>
                    <div className={"r"}>
                        <div className={""}>{residence?.type === 0 ? "幕天席地！" : residence?.name}</div>
                        <div className={""}>{residence?.type === 0 ? "没有正式住宅" : BuildingTypeTierToChs(residence)}</div>
                    </div>
                </Link>
                <div className={"description"}>
                    <div className={"selector"}>（
                        <span className={residence?.type === 0 ? "black" : ""}>藏身陋室</span>/
                        <span className={residence?.type === 39 ? "black" : ""}>棚屋</span>/
                        <span className={residence?.type === 37 ? "black" : ""}>楼房</span>/
                        <span className={residence?.type === 38 ? "black" : ""}>宅院</span>）
                    </div>
                    <div>{residence?.type === 0 ? "茅草和木棍搭建起来的临时容身之所。" : BPBuildingInfo(residence?.type)?.description}</div>
                    <div>{residence?.type === 0 ? "基本上是风餐露宿。" : "舒适的住处。"}</div>
                </div>
                <Divider/>
                <div className={"status"}>
                    <div className={"sub-title"}>-<b>
                        {residence?.type === 0 ? "藏身处" : BuildingTypeTierToChs(residence)}</b>换日属性基础回复：
                    </div>
                    <div className={"black-text"}><img src={process.env.PUBLIC_URL + `/img/status/statusEnergy.svg`}
                                                       className={"type-icon"}
                                                       alt={"type-icon"}/><span
                        className={"energy"}>精力+{residenceTierInfo?.recover.energy}</span>直到{residenceTierInfo?.recoverCap.energy}
                    </div>
                    <div className={"black-text"}><img src={process.env.PUBLIC_URL + `/img/status/statusHappiness.svg`}
                                                       className={"type-icon"}
                                                       alt={"type-icon"}/><span
                        className={"happiness"}>快乐+{residenceTierInfo?.recover.happiness}</span>直到{residenceTierInfo?.recoverCap.happiness}
                    </div>
                    <div className={"black-text"}><img src={process.env.PUBLIC_URL + `/img/status/statusHealth.svg`}
                                                       className={"type-icon"}
                                                       alt={"type-icon"}/><span
                        className={"health"}>健康+{residenceTierInfo?.recover.health}</span>直到{residenceTierInfo?.recoverCap.health}
                    </div>
                </div>
                <div className={"status"}>
                    <div
                        className={"sub-title"}>- <b>{residence?.type === 0 ? "藏身处" : BuildingTypeTierToChs(residence)}</b>提供的私人库存：
                    </div>
                    <div className={"black-text"}><Delete className={"type-icon"}/>容量{residenceTierInfo?.storage}</div>
                </div>
            </div>
            {residence?.type === 0 ?
                <div className={"hint"}>去
                    <Link to={`/game/district/housing`}>租一间客房</Link>
                    ，或者
                    <Link to={`/game/district/overview/blueprint`}>建造一座住宅</Link>
                </div>
                : null}
        </div>
    </div>
}
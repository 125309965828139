import "./Supervisor.scss"
import "../../../components/ListItemBuilding/listItemBuilding.scss"
import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import {Divider} from "@mui/material";
import {
    FmdBadOutlined,
    History,
    WorkOutline,
    FormatListBulletedOutlined,
    EmojiPeopleOutlined,
    Star,
    MergeType,
    AltRoute,
    CancelOutlined,
    ViewQuilt, LockOutlined, ReceiptLongOutlined, Boy, PersonAddOutlined, StarOutline, StarOutlined, Toll
} from '@mui/icons-material';
import {api_host} from "../../../utils/api";
import {getBPItems, getLoginToken} from "../../../utils/storage";
import WorkingMethod from "./WorkingMethod";
import RecruitInfo from "./RecruitInfo";
import {BPBuildingInfo} from "../../../components/gadgets";
import {BuildingSpecialType, BuildingSpecialTypeWStage} from "../../../components/mappingTable";
import LeaseContract from "./LeaseContract";
import ManageGuests from "./ManageGuests";
import BuildingInfo from "./BuildingInfo";
import ManageWorkers from "./ManageWorkers";
import AgentInfo from "./AgentInfo";
import DeleteBuilding from "./Delete";
import CombineBuildings from "./Combine";
import SplitBuilding from "./Split";
import UpgradeBuilding from "./Upgrade";
import SellBuilding from "./SellBuilding";
import HireManagers from "./HireManagers";

export default function Supervisor() {
    // const buildingSummary = building.building
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const status = useSelector((state: any) => state.status.data)
    const dispatch = useDispatch();


    //建筑特殊功能的条目
    function SwitchBuildingSpecialFunctions() {
        let buildingSpecialFunctions = <div/>
        switch (BuildingSpecialType(buildingSummary)) {
            case "restaurant":
                //餐馆
                buildingSpecialFunctions = <div>

                </div>
                break;
            case "hideout":
                //藏身处
                buildingSpecialFunctions = <div>

                </div>
                break;
            case "housing":
                //宅院/楼房/棚屋？我别墅呢。藏身处不算
                buildingSpecialFunctions = <div>
                    <div className={"sub-title luxury"}><ReceiptLongOutlined/>租房合同</div>
                    <div className={"sub-text luxury"}>设置、调整租房合同。邀请某人免费入住。免费入住的员工如果离职，租期将在换日后结束。</div>
                    <LeaseContract/>
                    <Divider variant={"middle"}/>
                    <div className={"sub-title luxury"}><Boy/>管理租客</div>
                    <div className={"sub-text luxury"}>查看客房的入住情况，调整续租状态或者逐客。被驱逐的房客不可续租，并且会被告知你希望他离开。</div>
                    <ManageGuests/>
                    <Divider variant={"middle"}/>
                </div>
                break;
            case "agent":
                //贸易站
                buildingSpecialFunctions = <div>
                    <div className={"sub-title luxury"}><ReceiptLongOutlined/>贸易站设置</div>
                    <div className={"sub-text luxury"}>设置、调整贸易站相关内容。</div>
                    <AgentInfo/>
                    <Divider variant={"middle"}/>
                </div>
                break;

            //所有普通生产建筑
            default:
                buildingSpecialFunctions = <div>

                </div>
                break;
        }
        return buildingSpecialFunctions
    }

    return <div className={"supervisor"} style={{width: "100%", height: "100%"}}>
        <div className={"supervisor-title-line"}>
            <div className={"title"}>监工</div>
            <div className={"title-text"}>管理工人，切换生产方案，升级、合并或拆毁建筑。亦可以浏览历史记录和为建筑改名。只有所有者和管理者可以进行监工操作。</div>
        </div>
        <div className={"supervisor-content"}>
            {/*自己不是所有者或者管理，禁止操作监工页面*/}
            {(buildingSummary.owner?.uid === status.user_id || buildingSummary.manager?.uid === status.user_id) ? null :
                <div className={"supervisor-backdrop"}>
                    <div className={"lock"}>
                        <LockOutlined/>
                        <div className={""}>仅限所有者或管理员</div>
                    </div>
                </div>
            }
            {/*建筑特殊功能的排在最上面*/}
            <SwitchBuildingSpecialFunctions/>
            {/*当建筑不能拥有工人时（本身是0工人建筑，而且不在建设中状态）*/}
            {(!buildingSummary.max_work_count && buildingSummary.stage !== 0 && buildingSummary.stage !== 1) ?
                null :
                <div>
                    <div className={"sub-title"}><FormatListBulletedOutlined/>生产方案</div>
                    <div className={"sub-text"}>指导工人如何使用这座建筑，以选择的方式开展生产活动。点击以切换生产方案。</div>
                    <WorkingMethod/>
                    <Divider variant={"middle"}/>
                    <div className={"sub-title"}><WorkOutline/>招聘信息</div>
                    <div className={"sub-text"}>开关招聘，调整薪水。设置的单位薪水为每1产能需要支付给工人的数量。</div>
                    <RecruitInfo/>
                    <Divider variant={"middle"}/>
                    <div className={"sub-title"}><EmojiPeopleOutlined/>工人管理</div>
                    <div className={"sub-text"}>查看和开除现有工人。</div>
                    <ManageWorkers/>
                    <Divider variant={"middle"}/>
                </div>
            }
            <div className={"sub-title"}><FmdBadOutlined/>建筑信息</div>
            <div className={"sub-text"}>调整建筑名字和附言。</div>
            <BuildingInfo/>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><History/>历史记录</div>
            <div className={"sub-text"}>工人工作，货物交易和建筑易手记录。</div>
            <div className={"sub-text"}>即将可用。</div>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><StarOutline/>升级建筑</div>
            <div className={"sub-text"}>从藏身处支付建材，将这个建筑改造为高一星级的同种建筑。该操作仅限所有者。</div>
            <UpgradeBuilding/>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><MergeType/>合并建筑</div>
            <div className={"sub-text"}>将位置和种类完全相同的另一座建筑合并进本建筑。提升库存容量和工人槽位，享受额外产能加成。该操作仅限所有者。</div>
            <CombineBuildings/>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><AltRoute/>拆分建筑</div>
            <div className={"sub-text"}>将合并的建筑拆分。基本上是合并建筑的反向操作。该操作仅限所有者。</div>
            <SplitBuilding/>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><CancelOutlined/>拆除建筑</div>
            <div className={"sub-text"}>拆除这座建筑。拆除后建筑和库存物资将不复存在。不返还建材。该操作仅限所有者。</div>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><Toll/>出售建筑</div>
            <div className={"sub-text"}>将建筑所有权以及全部库存物品公开出售以换取物资。该操作仅限所有者。</div>
            <SellBuilding/>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><PersonAddOutlined/>任免管理员</div>
            <div className={"sub-text"}>邀请其他玩家成为该建筑的管理员。管理员拥有大部分的权限。该操作仅限所有者。</div>
            <HireManagers/>
            <Divider variant={"middle"}/>
            <div className={"sub-title"}><ViewQuilt/>街区重规划</div>
            <div className={"sub-text"}>暂不可用。</div>
        </div>
    </div>


}
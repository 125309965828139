import "./EditRecipe.scss"
import * as React from "react";
import {
    ArrowBack, Clear, Delete,
    DinnerDining,
    DriveFileRenameOutline,
    EggOutlined, ExpandMore, Fireplace, RoomServiceOutlined
} from "@mui/icons-material";
import AddPhotoAvt from "../../../../components/AddPhotoAvt";
import {Button, Divider, IconButton, TextField} from "@mui/material";
import {
    BPBWRInfo,
    BPSpicesInfo,
    cookingMethodInfo,
    Floor1,
    Floor2,
    foodMaterialInfo, fuelInfo,
    itemInfo, spiceInfo
} from "../../../../components/gadgets";
import {Item} from "../../../../components/IconImg";
import {TierToStars} from "../../../../components/mappingTable";
import CookingMethodButton from "./CookingMethodButton";
import {useNavigate, useParams} from "react-router-dom";
import ItemSelector from "../../../../components/ItemSelector";
import {useEffect, useState} from "react";
import {
    getBPBuildingsHaveRecipe,
    getBPCookingMethods,
    getBPFoodMaterials,
    getBPSpices
} from "../../../../utils/storage";
import {useDispatch, useSelector} from "react-redux";
import {id2color} from "../../../../utils/id2color";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {foodTypeImg} from "../index";
import {fetchStatusNow} from "../../../../appSlice";
import {stringify} from "querystring";
import {fetchBuildingSummaryNow} from "../../buildingSlice";

//调味料，调料，矩形绘制
export const spiceMatrixDraw = (id: number, tier: number, matrixLineHeight: number, matrixBlockWidth: number) => {
//开始距离（从下向上）
    const startingRow = spiceInfo(id, tier).matrix.reverse().findIndex((row: number[]) => row.some((e: number) => e !== 0))
    const startingHeight = startingRow * matrixLineHeight - 0.6
//开始距离（从左往右）
    const startingColumn = spiceInfo(id, tier).matrix.reverse()[startingRow].findIndex((row: number) => row !== 0)
    const startingWidth = startingColumn * matrixBlockWidth + 9.7
//调料矩形高度
    const endingRow = 4 - spiceInfo(id, tier).matrix.findIndex((row: number[]) => row.some((e: number) => e !== 0))
    const height = (endingRow - startingRow + 1) * matrixLineHeight
//调料矩形宽度
    const endingColumn = 3 - spiceInfo(id, tier).matrix.reverse()[startingRow].reverse().findIndex((row: number) => row !== 0)
    const width = (endingColumn - startingColumn + 1) * matrixBlockWidth + 0.3
    return {
        "startingHeight": startingHeight,
        "startingWidth": startingWidth,
        "height": height,
        "width": width,
    }
}


export default function EditRecipe(props: {}) {
    const navigate = useNavigate()
    const {buildingId} = useParams();
    const {recipeId} = useParams()
    const dispatch = useDispatch();
    const status = useSelector((state: any) => state.status.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)

    //矩阵每行高度
    const matrixLineHeight = 3.5
    const matrixBlockWidth = 4.5

    //非所有者而且非管理员时，只读模式
    const readOnly = buildingSummary.owner.uid !== status.user_id && buildingSummary.manager.uid !== status.user_id

    //服务器保存的食谱
    const [savedRecipe, setSavedRecipe] = useState({
        "id": 1,
        //食谱名字
        "name": "",
        //食谱头像
        "avatar": "",
        "cookingMethod": 0,
        "materials": [
            {
                "id": 16,
                "tier": 1,
                "quantity": 1,
            },
            {
                "id": 50,
                "tier": 1,
                "quantity": 1,
            },
        ],
        "fuelId": 34,
        "fuelTier": 1,
    })
    const savedRecipeStringify = JSON.stringify(savedRecipe)

    //fetch食谱信息
    useEffect(() => {
        api_with_auth("/api/recipe/" + recipeId, "GET")
            .then(res => {
                if (res.status === "success") {
                    setSavedRecipe(res.data)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }, [])


    //服务器保存的烹饪方法
    const savedCookingMethod = savedRecipe.cookingMethod
    //选中的烹饪方法
    const [cookingMethod, setCookingMethod] = useState(savedCookingMethod)
    const methodInfo = getBPCookingMethods().find((method: any) => method.id === cookingMethod)
    const pickCookingMethod = (id: number) => {
        setCookingMethod(id)
    }
    //选中的烹饪方法的bonus array
    const methodBonus = getBPCookingMethods()[cookingMethod].matrix

    //服务器保存的食材
    const savedMaterials = savedRecipe.materials
    //食材
    const [materials, setMaterials] = useState([
        {
            "id": 2,
            "tier": 1,
            "quantity": 3.5,
        },
        {
            "id": 5,
            "tier": 1,
            "quantity": 6.5,
        },
        {
            "id": 16,
            "tier": 1,
            "quantity": 1,
        },
        {
            "id": -1,
            "tier": 1,
            "quantity": 1,
        },
    ])
    //添加食材
    const addMaterial = () => {
        if (materials.length <= 4) {
            setMaterials(previousState => ([...previousState,
                    {
                        "id": -1,
                        // "id": -Math.floor(Math.random() * 10000),
                        "tier": 1,
                        "quantity": 1,
                    }]
            ))
        }
    }
    //删除食材
    const cutMaterial = (index: number) => {
        let materialsDup = [...materials]
        materialsDup.splice(index, 1)
        setMaterials(materialsDup)
    }
    //选择食材种类
    const pickItem = (pickedId: number, pickedTier: number, index: any) => {
        setMaterials((current: any) =>
            current.map((item: any, index2: number) => {
                if (index === index2) {
                    //如果选择的是食材，数量变为1
                    if (BPSpicesInfo(pickedId, pickedTier)) {
                        return {
                            "id": pickedId,
                            "tier": pickedTier,
                            "quantity": 1
                        }
                    } else {
                        return {
                            "id": pickedId,
                            "tier": pickedTier,
                            "quantity": item.quantity
                        }
                    }
                } else {
                    return item
                }
            }))
    }
    //修改食材数量
    const handleChangeQuantity = (index: number) => (event: any) => {
        if (event.target.value >= 0) {
            setMaterials((current: any) =>
                current.map((item: any, index2: number) => {
                    if (index === index2) {
                        return {
                            "id": item.id,
                            "tier": item.tier,
                            "quantity": parseFloat(event.target.value)
                        }
                    } else {
                        return item
                    }
                }))
        }
    }
    //食材总数量
    const sumMaterialsQuantity = () => {
        return materials.reduce((total: number, {quantity}: any) => total + quantity, 0)
    }
    //食材四维属性求和(精力 快乐 健康 饥饿)
    const sumMaterialsStatus = (statusType: "energy" | "happiness" | "health" | "hungry") => {
        const typeIndex = {"energy": 0, "happiness": 1, "health": 2, "hungry": 3}
        //精确位置上的调料加成总乘数。typeIndex列，index行
        const spicesBonus = (index: number) => {
            return spices.reduce((total: number, spice: any) => total *
                    //单个调料的乘数。1变成乘数，0变成1
                    ((spiceInfo(spice.id, spice.tier).matrix[4 - sort(index)][typeIndex[statusType]] !== 0) ?
                        spiceInfo(spice.id, spice.tier).multiply : 1)
                , 1)
        }
        //index是materials的食材index
        return Floor1(materials.reduce((total: number, material: any, index) => total +
                //食材本身属性（配置文件）*食材数量
                (foodMaterialInfo(material.id, material.tier)?.[statusType] ?? 0) * material.quantity
                //*烹饪方法的乘数，sort方法确保乘到了正确的行
                * methodInfo.matrix[4 - sort(index)][typeIndex[statusType]]
                //调料总乘数
                * spicesBonus(index)
            , 0))
    }
    //食材平均奢侈度
    const sumMaterialsLuxury = () => {
        return Floor1(((materials.reduce((total: number, material: any) => total + material.quantity * (foodMaterialInfo(material.id, material.tier)?.luxury ?? 0)
            , 0)) / sumMaterialsQuantity()) ?? 0)
    }
    //食材总重
    const sumMaterialsWeight = () => {
        return Floor1((materials.reduce((total: number, material: any) => total + material.quantity * (itemInfo(material.id)?.weight ?? 0)
            , 0)))
    }

    //调料
    //食材里调料组成的array
    const spices = [...materials].filter((material: any) => [...getBPSpices().map((spice: any) => {
        return spice.id
    })].includes(material.id))
    //调味料绘制参数
    const spiceMatrix = (id: number, tier: number) => {
        return spiceMatrixDraw(id, tier, matrixLineHeight, matrixBlockWidth)
    }


    //服务器保存的燃料
    const savedFuel = {
        "fuelId": savedRecipe.fuelId,
        "fuelTier": savedRecipe.fuelTier,
    }
    //燃料
    const [fuel, setFuel] = useState({...savedFuel})
    //选择燃料
    const pickFuel = (pickedId: number, pickedTier: number) => {
        setFuel({
            "fuelId": pickedId,
            "fuelTier": pickedTier,
        })
    }
    //需要的燃料值：总重x燃料值系数
    const fuelValue = sumMaterialsWeight() * cookingMethodInfo(cookingMethod).fuelFactor
    //选中燃料的单位燃料值
    const fuelValuePerUnit = fuelInfo(fuel.fuelId, fuel.fuelTier).fuel

    //烹饪
    const materialTierArr = materials.map((m: any) => {
        return m.tier
    })
    const cookingTier = Math.max(...materialTierArr)
    //需要的烹饪：总数量×烹饪系数
    const cookingValue = sumMaterialsQuantity() * cookingMethodInfo(cookingMethod).cookingFactor

    //名字和头像
    const savedRecipeInfo = {
        "name": savedRecipe.name,
        "avatar": savedRecipe.avatar,
    }
    const [recipeInfo, setRecipeInfo] = useState({"name": "同步食谱中", "avatar": ""})
    //改名
    const noName = !recipeInfo.name
    const handleChangName = (event: any) => {
        setRecipeInfo((current: any) => ({
                ...current, "name": event.target.value
            })
        )

    }

    //fetch好食材之后更新显示的食材，烹饪方法，燃料
    useEffect(() => {
        setMaterials([...savedMaterials])
        setCookingMethod(savedCookingMethod)
        setFuel({...savedFuel})
        setRecipeInfo({...savedRecipeInfo})
    }, [savedRecipeStringify])


    //食材排序，返回从大到小序号，从0开始。index输入materials里食材的index
    const sort = (index: number) => {
        const comparedMaterial = materials[index]
        if (isNaN(materials[index]?.quantity)) {
            //数量倒序。数量相同id顺序
            return [...materials].filter((material: any, index2: number) =>
                (isNaN(material.quantity) && index2 < index) ||
                !isNaN(material.quantity)
            ).length
        } else {
            //规则不允许同id食材
            //食材违规时排序，仅前端显示效果：
            return [...materials].filter((material: any, index2: number) =>
                material.quantity > comparedMaterial.quantity ||
                (material.quantity === comparedMaterial.quantity && material.id < comparedMaterial.id) ||
                (material.quantity === comparedMaterial.quantity && material.id === comparedMaterial.id && index2 < index)
            ).length
        }
    }

    //食材合法性检查
    //食材id重复.
    const materialsCheckIdDuplicated = () => {
        if (materials.some((material) => materials.filter((materialCompare) => material.id === materialCompare.id).length > 1)) {
            return "！食材不可以重复，不论它们星级是否相同。"
        } else return undefined
    }
    //食材id不合法（未选择食材）
    const materialsCheckIdIllegal = () => {
        if (materials.some((material) => material.id < 1)) {
            return "！起码有一项食材没有进行选择。选择或者移除它。"
        } else return undefined
    }
    //食材数量不合法:nan
    const materialsCheckQuantityIllegal = () => {
        if (materials.some((material) => isNaN(material.quantity))) {
            return "！食材数量不合法。"
        } else return undefined
    }
    //食材受到了未保存的修改
    const materialsUnsavedChange = () => {

    }

    //建筑食谱食材数限制
    const materialsCountLimit = getBPBuildingsHaveRecipe().find((b: any) => b.id === buildingSummary.type)?.tiers.find((t: any) => t.tier === buildingSummary.tier)?.materialSlots ?? 1

    //更改后的食谱
    const editedRecipe = {
        "id": parseInt(recipeId ?? "1"),
        ...recipeInfo,
        "cookingMethod": cookingMethod,
        "materials": materials,
        ...fuel
    }
    //是否有更改
    const notEdited = JSON.stringify(savedRecipeInfo) === JSON.stringify(recipeInfo) &&
        JSON.stringify(savedMaterials) === JSON.stringify(materials) &&
        JSON.stringify(savedFuel) === JSON.stringify(fuel) &&
        savedCookingMethod === cookingMethod

    //提交食谱修改
    const [applying, setApplying] = useState(false)
    const applyEdit = () => {
        setApplying(true)
        api_with_auth("/api/recipe/" + recipeId + "/edit", "POST", editedRecipe)
            .then(res => {
                    setApplying(false)
                    if (res.status === "success") {
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "保存了食谱",
                        }))
                        navigate(`/game/building/` + buildingId + "/recipe")

                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                    dispatch(fetchStatusNow())
                },
            );

    }

    return <div className={"edit-recipe-root"}>
        <div className={"edit-recipe-title"}>
            {/*得先重置各种state*/}
            <IconButton onClick={() => navigate("/game/building/" + buildingId + "/recipe/")}
            ><ArrowBack/></IconButton>
            编辑食谱
        </div>
        <div className={"edit-recipe-content"}>
            {readOnly ? <div className={"read-only"}>只读模式</div> : null}
            <div className={"recipe-image-line"}>
                {readOnly ?
                    <img
                        src={process.env.PUBLIC_URL + "/img/foodType/" + foodTypeImg(parseInt(recipeId || "1")) + ".webp"}
                        className={"food-icon"}
                        alt={"foodIcon"}/> :
                    <AddPhotoAvt type={"recipe"} recipeId={parseInt(recipeId ?? "1")}
                                 src={recipeInfo.avatar || process.env.PUBLIC_URL + "/img/foodType/cake.webp"}
                                 onClick={() => {
                                 }} size={4.8}/>
                }
                <div className={"edit-name-column"}>
                    <div className={"name"}>
                        <DriveFileRenameOutline style={{display: "inline-block"}}/>
                        <TextField onChange={(e) => handleChangName(e)} label="命名食谱*" value={recipeInfo.name}
                                   disabled={readOnly}/>
                    </div>
                    <div className={"description"}>
                        {readOnly ? "没有权限编辑该食谱，你只能浏览。" : "上传食谱图片（可选）和命名食谱。如果没有上传食谱图片，将根据食材自动选择。"}
                    </div>
                </div>
            </div>
            <div className={"sub-title cooking-methods-sub-title"}><DinnerDining/>烹饪方法</div>
            <div className={"cooking-methods"}>
                <div className={"choices"}
                     style={{pointerEvents: (readOnly ? "none" : "auto")}}
                >
                    {getBPCookingMethods().map((method: any, index: any) =>
                        <CookingMethodButton method={method.id} selected={cookingMethod === method.id}
                                             disabled={!BPBWRInfo(buildingSummary)?.methodAllowed?.includes(index)}
                                             pickMethod={pickCookingMethod} key={method.id}/>
                    )}
                </div>
                <div className={"sticker"}>
                    <img src={require("./assets/cooker/cooker" + (methodInfo.id + 1) + ".webp").default} alt="cooker"
                         className="cooker-img"/>
                    <div className={"using"}>当前烹饪方法</div>
                    <div className={"topic"}>{methodInfo.Chs}</div>
                    <div className={"description"}>{methodInfo.description}</div>
                    <div className={"buff1 buff"}>
                        <div className={"preview"}></div>
                        <div className={"description"}>{methodInfo.description2}</div>
                    </div>
                    <div className={"buff2-3"}>
                        <div className={"buff2 buff"}><Fireplace/>所需燃料值:<br/>食材总重<b>×{methodInfo.fuelFactor}</b></div>
                        <div className={"buff3 buff"}>
                            <RoomServiceOutlined/>所需烹饪:<br/>食材总数量<b>×{methodInfo.cookingFactor}</b></div>
                    </div>
                </div>
            </div>
            <div className={"sub-title"}><EggOutlined/>食材搭配</div>
            <div className={"food-materials-combination"}>
                <div className={"tutorial"}>
                    <div className={"topic"}>烹饪方法加成样式</div>
                    <div
                        className={"content"}>
                        {/*烹饪方式以各自独特的样式影响食材的精力、快乐、健康、饥饿属性，例如保留副食更多营养成分（健康x1.2）或者使主食更有饱腹感（饥饿x1.1）。<br/>*/}
                        提示：你不能手动调整食材位置以迎合加成样式，数量多的食材即为主食。
                    </div>
                </div>
                {/*食材过滤器*/}
                <div className={"filters"}>
                    <div className={"filter"}>食材（数量顺序▲）</div>
                    <Divider orientation="vertical" flexItem/>
                    <div className={"filter"}>精力变化</div>
                    <div className={"filter"}>快乐变化</div>
                    <div className={"filter"}>健康变化</div>
                    <div className={"filter"}>饥饿变化</div>
                    <Divider orientation="vertical" flexItem/>
                    <div className={"filter"}>平均奢侈度</div>
                    <div className={"filter"}>食谱总量</div>
                </div>
                <div className={"matrix-root"}>
                    {/*食材下划线*/}
                    <div className={"underlines-container"}>
                        <div className={"underline"}/>
                        <div className={"underline"}/>
                        <div className={"underline"}/>
                        <div className={"underline"}/>
                        <div className={"underline"}/>
                    </div>
                    <div className={"hint-container"}>
                        <div className={""}>副食</div>
                        <img src={require("./assets/arrows.webp").default} alt="arrows"/>
                        <div className={""}>主食</div>
                    </div>
                    {/*absolute的食材卡，添加食材按钮，烹饪方法悬浮字，调料框子*/}

                    {/*食材的卡片 210*/}
                    {materials.map((material: any, index: number) =>
                        <div className={"material-card"}
                             style={{
                                 bottom: `${-0.3 + sort(index) * matrixLineHeight}vw`
                             }}
                             key={material.id + "  " + index}
                        >
                            <IconButton
                                onClick={() => cutMaterial(index)} className="clear" aria-label="clear"
                                style={{opacity: readOnly ? 0 : 1, pointerEvents: readOnly ? "none" : "auto"}}
                            >
                                <Clear/>
                            </IconButton>
                            {readOnly ?
                                <Item id={material.id} t={material.tier} mr={0.3}/> :
                                <ItemSelector pickItem={(id, tier) => pickItem(id, tier, index)} pool={"foodMaterial"}
                                              mr={0.3} default={[material.id, material.tier]}/>
                            }

                            <div className={"two-story-text left-align"}>
                                {/*如果只读，不可编辑*/}
                                {/*如果是调料固定为1，不是调料显示输入框*/}
                                {BPSpicesInfo(material.id, material.tier) ?
                                    <div className={"spice up"}>1<span className={"hint"}>(调味料)</span></div>
                                    :
                                    <TextField size="small" value={material.quantity}
                                               onChange={handleChangeQuantity(index)}
                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                               className={"up"} type={"number"} disabled={readOnly}/>
                                }
                                <div className={"down"}>
                                    {(itemInfo(material.id)?.Chs ?? "<-选择食材") + TierToStars[material.tier]}</div>
                            </div>
                            <div
                                className={"two-story-text " + (foodMaterialInfo(material.id, material.tier)?.energy > 0 ? "energy" : "grey")}>
                                <div
                                    className={"up"}>{Floor1((foodMaterialInfo(material.id, material.tier)?.energy ?? 0) * material.quantity)}</div>
                                <div className={"down"}>精力</div>
                            </div>
                            <div
                                className={"two-story-text " + (foodMaterialInfo(material.id, material.tier)?.happiness > 0 ? "happiness" : "grey")}>
                                <div
                                    className={"up"}>{Floor1((foodMaterialInfo(material.id, material.tier)?.happiness ?? 0) * material.quantity)}</div>
                                <div className={"down"}>快乐</div>
                            </div>
                            <div
                                className={"two-story-text " + (foodMaterialInfo(material.id, material.tier)?.health > 0 ? "health" : "grey")}>
                                <div
                                    className={"up"}>{Floor1((foodMaterialInfo(material.id, material.tier)?.health ?? 0) * material.quantity)}</div>
                                <div className={"down"}>健康</div>
                            </div>
                            <div
                                className={"two-story-text " + (foodMaterialInfo(material.id, material.tier)?.hungry > 0 ? "hungry" : "grey")}>
                                <div
                                    className={"up"}>{Floor1((foodMaterialInfo(material.id, material.tier)?.hungry ?? 0) * material.quantity)}</div>
                                <div className={"down"}>饥饿</div>
                            </div>
                            <div className={"two-story-text luxury"}>
                                <div
                                    className={"up"}>{(foodMaterialInfo(material.id, material.tier)?.luxury ?? 0)}
                                </div>
                                <div className={"down"}>奢侈</div>
                            </div>
                            <div className={"two-story-text "}>

                                <div
                                    className={"up"}>{Floor1(material.quantity * (itemInfo(material.id)?.weight ?? 0))}</div>
                                <div className={"down"}>重量</div>
                            </div>
                        </div>
                    )}
                    {/*烹饪方法加成数值 220*/}
                    {methodBonus?.map((methodRow: number[], indexRow: number) => {
                        return methodRow.map((method: number, indexColumn: number) =>
                            <div className={"method-bonus-number"}
                                 style={{
                                     left: `${12.3 + indexColumn * 4.5}vw`,
                                     bottom: `${0.85 + (4 - indexRow) * matrixLineHeight}vw`
                                 }}
                                 key={method + "" + indexColumn}
                            >×{method.toFixed(1)}</div>
                        )
                    })}
                    {/*添加食材按钮 230*/}
                    {readOnly ?
                        null :
                        <div className={"adding-material-root"}
                             style={{
                                 bottom: `${0.85 + materials.length * matrixLineHeight}vw`,
                                 display: (materials.length <= (materialsCountLimit - 1)) ? "flex" : "none",
                             }}
                        >
                            <div className={"button8 orange-button"} onClick={addMaterial}>+食材</div>
                        </div>
                    }

                    {/*建筑食谱食材数限制 240*/}
                    {Array(5 - materialsCountLimit).fill(undefined).map((limit: undefined, index) =>
                        <div className={"materials-count-limit-root"}
                             style={{
                                 top: `${0.6 + index * matrixLineHeight}vw`
                             }}
                             key={"limit" + index}
                        >
                            ─── 食材槽位受限 ───
                        </div>
                    )}
                    {/*调料绘制 250*/}
                    <div className={"spices-draw-root"}>
                        {spices.map((spice, index) =>
                            <div className={"spice-draw-root"}
                                 style={{
                                     bottom: `${spiceMatrix(spice.id, spice.tier).startingHeight}vw`,
                                     left: `${spiceMatrix(spice.id, spice.tier).startingWidth}vw`,
                                     height: `${spiceMatrix(spice.id, spice.tier).height}vw`,
                                     width: `${spiceMatrix(spice.id, spice.tier).width}vw`,
                                     outlineColor: id2color(spice.id),
                                 }} key={spice.id + " " + spice.tier + index}>
                                <div className={"title"}
                                     style={{
                                         backgroundColor: id2color(spice.id)
                                     }}
                                >×{spiceInfo(spice.id, spice.tier)?.multiply}（来源：{itemInfo(spice.id)?.Chs}）
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={"results"}>
                    <div className={"quantity-result result"}>
                        <div className={"up"}>{sumMaterialsQuantity()}</div>
                        <div className={"down"}>食材总数量</div>
                    </div>
                    <div className={"energy-result result"}>
                        <div className={"up " + (sumMaterialsStatus("energy") > 0 ? "energy" : "grey")}>
                            {sumMaterialsStatus("energy")}</div>
                        <div className={"down"}>精力变化</div>
                    </div>
                    <div className={"happiness-result result"}>
                        <div className={"up " + (sumMaterialsStatus("happiness") > 0 ? "happiness" : "grey")}>
                            {sumMaterialsStatus("happiness")}</div>
                        <div className={"down"}>快乐变化</div>
                    </div>
                    <div className={"health-result result"}>
                        <div className={"up " + (sumMaterialsStatus("health") > 0 ? "health" : "grey")}>
                            {sumMaterialsStatus("health")}</div>
                        <div className={"down"}>健康变化</div>
                    </div>
                    <div className={"hungry-result result"}>
                        <div className={"up " + (sumMaterialsStatus("hungry") > 0 ? "hungry" : "grey")}>
                            {sumMaterialsStatus("hungry")}</div>
                        <div className={"down"}>饥饿变化</div>
                    </div>
                    <div className={"luxury-result result"}>
                        <div className={"up luxury"}>{sumMaterialsLuxury()}</div>
                        <div className={"down"}>奢侈度</div>
                    </div>
                    <div className={"weight-result result"}>
                        <div className={"up"}>{sumMaterialsWeight()}</div>
                        <div className={"down"}>总重</div>
                    </div>
                </div>
            </div>
            <div className={"sub-title"}><Fireplace/>燃料消耗</div>
            <div className={"consume-container"}>
                {readOnly ?
                    <Item id={fuel.fuelId} t={fuel.fuelTier} mr={0.4} size={4}/> :
                    <ItemSelector pickItem={(id, tier) => {
                        pickFuel(id, tier)
                    }}
                                  size={4} pool={"fuel"} default={[fuel.fuelId, fuel.fuelTier]} mr={0.4}/>
                }

                <div className={"consume-right-container"}>
                    <div className={"top"}><span
                        className={"thick-black"}>支付{itemInfo(fuel.fuelId).Chs}{TierToStars[fuel.fuelTier]}作为燃料：{Floor2(fuelValue / fuelValuePerUnit)}</span>（每单位{itemInfo(fuel.fuelId).Chs}{TierToStars[fuel.fuelTier]}{fuelValuePerUnit}燃料值）
                    </div>
                    <div
                        className={"down"}>总燃料值：{Floor2(fuelValue)} = {sumMaterialsWeight()}（食材总重）×{cookingMethodInfo(cookingMethod).fuelFactor}（{cookingMethodInfo(cookingMethod).Chs}-燃料值系数）
                    </div>
                    <div
                        className={"down"}>{readOnly ? "只读模式不可选择燃料。" : ('<-点击图标选择燃料！当前选择了' +
                        itemInfo(fuel.fuelId).Chs + TierToStars[fuel.fuelTier] + "。")}
                    </div>
                </div>
            </div>
            <div className={"sub-title"}><RoomServiceOutlined/>烹饪消耗</div>
            <div className={"consume-container"}>
                <Item id={4} t={cookingTier} size={4.2} mr={0.5}/>
                <div className={"consume-right-container"}>
                    <div className={"top"}><span
                        className={"thick-black"}>支付烹饪{TierToStars[cookingTier]}：{Floor2(cookingValue)}</span> = {sumMaterialsQuantity()}（食材总数量）×{cookingMethodInfo(cookingMethod).cookingFactor}（{cookingMethodInfo(cookingMethod).Chs}-烹饪系数）
                    </div>
                    <div className={"down"}>支付烹饪的星级，起码等同于食谱中最高星级的食材。</div>
                </div>
            </div>
            {/*警告信息*/}
            <div className={"warnings"}>
                {materialsCheckIdDuplicated() ? <div className={"warning"}>{materialsCheckIdDuplicated()}</div> : null}
                {materialsCheckIdIllegal() ? <div className={"warning"}>{materialsCheckIdIllegal()}</div> : null}
                {materialsCheckQuantityIllegal() ?
                    <div className={"warning"}>{materialsCheckQuantityIllegal()}</div> : null}
            </div>
            <div className={"apply-buttons"}>
                <Button variant="outlined" color={"happiness"} onClick={() => {
                    navigate(-1)
                }}>
                    返回并不保存
                </Button>
                <Button variant="contained" color={"happiness"} disabled={readOnly || notEdited || applying || noName}
                        onClick={applyEdit}
                >
                    {readOnly ? "无权保存" : (notEdited ? "没有修改" : (applying ? "保存中" : (noName ? "需要取名" : "保存食谱")))}
                </Button>
            </div>
        </div>
        <div className={""}></div>
        <div className={""}></div>
    </div>

}
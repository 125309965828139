import './App.scss';
import "./extraGlobalStyle.scss"
import "./muiStyleFix.scss"
import FirstFetch from "./components/Fetch/index"
import {Link, Route, Routes, useMatch, useNavigate, useLocation} from "react-router-dom";
import {
    Alert, Avatar, createTheme,
    ListItemButton, MenuList, Popover,
    Snackbar, ThemeProvider,
} from "@mui/material";
import Forum from "./Forum";
import {Bazaar} from "./Bazaar";
import {Buildings} from "./Buildings/Building";
import {Personal} from "./Personal"
import {useEffect, useRef, useState} from "react";
import District from "./Buildings/District";
import {id2color} from "./utils/id2color";
import {openSnackbar, closeSnackbar} from "./appSnackbarSlice";
import {useSelector, useDispatch} from 'react-redux';
import StatusBar, {StatusBar2x2} from "./components/statusBar";
import * as React from "react";
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import {Transaction} from "./Transaction";
import MyBuildings from "./MyBuildings"
import Promo from "./components/Promo"
import SideJob from "./SideJob";
import {Logout, Settings} from "@mui/icons-material";
import StatusTutorial from "./components/Popovers/StatusTutorial";
import Notifications from "./Notifications";
import GameSettings from "./Settings";

//主题色
const theme = createTheme({
    palette: {
        energy: {
            main: '#46b4e6',
            contrastText: '#ffffff',
        },
        happiness: {
            main: '#ff8842',
            contrastText: '#ffffff',
        },
        health: {
            main: '#73b93c',
            contrastText: '#ffffff',
        },
        hungry: {
            main: '#e74652',
            contrastText: '#ffffff',
        },
        luxury: {
            main: '#b38460',
            contrastText: '#ffffff',
        },
        blueGrey: {
            main: '#5f6e7b',
            contrastText: '#ffffff',
        },
    },
});
declare module '@mui/material/styles/' {
    interface Palette {
        energy: { main: string; contrastText: string; };
        happiness: Palette['primary'];
        health: Palette['primary'];
        hungry: Palette['primary'];
        luxury: Palette['primary'];
        blueGrey: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        energy?: { main: string; contrastText: string; };
        happiness?: PaletteOptions['primary'];
        health?: PaletteOptions['primary'];
        hungry?: PaletteOptions['primary'];
        luxury?: PaletteOptions['primary'];
        blueGrey?: PaletteOptions['primary'];
    }

}
// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        energy: true;
        happiness: true;
        health: true;
        hungry: true;
        luxury: true;
        blueGrey: true;
    }
}

export default function App() {
    FirstFetch()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const gameDay = useSelector((state: any) => state.gameDay.data)

    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    const hideout = myHideOutStorage.id
    const state = useSelector((state: any) => state.state.data)
    const status = useSelector((state: any) => state.status.data)

    const myTransactions = useSelector((state: any) => state.myTransactions.data)

    const [inkFoodVisibility, setInkFoodVisibility] = useState(false)
    const [inkResidenceVisibility, setInkResidenceVisibility] = useState(false)
    const [left_list_scroll, set_left_list_scroll] = useState(0)
    let urlLocation = useLocation()


    //工作按钮导航位置
    const workBtnTo = (status.employee && status.employee.length) ?
        `/game/building/${status.employee[0].buildingID}/works` :
        `/game/district`
    const inkWorkVisibility = (status.employee && status.employee.length) ?
        (!inkFoodVisibility ? (!status.employee[0].is_worked) :
                false
        ) :
        true


    //顶部旗帜位置
    let navbarImgClass = "nav-link-flag-other"
    if (useMatch("/game/")) {
        navbarImgClass = "nav-link-flag-forum"
    }
    if (useMatch("/game/forum/*")) {
        navbarImgClass = "nav-link-flag-forum"
    }
    if (useMatch("/game/bazaar/*")) {
        navbarImgClass = "nav-link-flag-bazaar"
    }
    if (useMatch("/game/district/*")) {
        navbarImgClass = "nav-link-flag-district"
    }
    if (useMatch("/game/building/*")) {
        navbarImgClass = "nav-link-flag-district"
    }

// //右上角头像hover菜单的相关
    const avtPopoverAnchor = useRef(null);
    const [avtOpen, setAvtOpen] = useState(false)
    const avtHandleOpen = (event: any) => {
        setAvtOpen(true);
    };
    const avtHandleClose = () => {
        setAvtOpen(false);
    };


//右上角2x2属性条hover
    const statusPopoverAnchor = useRef(null);
    const [statusOpen, setStatusOpen] = useState(false)
    const statusHandleOpen = (event: any) => {
        setStatusOpen(true);
    };
    const statusHandleClose = () => {
        setStatusOpen(false);
    };

//全局snackbar
    const severity = useSelector((state: any) => state.snackbar.data.severity)
    const snackbarMessage = useSelector((state: any) => state.snackbar.data.snackbarMessage)
    const openSnackbar = useSelector((state: any) => state.snackbar.data.open)
    // const callCloseSnackbar = dispatch(closeSnackbar())
    // 调用snackbar示例，全局通用，只要import就行
    //     dispatch(openSnackbar({
    //         "severity": "success",
    //         "snackbarMessage": "",
    //     }))
    //     dispatch(closeSnackbar())
    const testSnackbar = () => {
        dispatch(openSnackbar({
            "severity": "success",
            "snackbarMessage": "信箱是空的，里面积了薄的灰尘。没有人写信过来。",
        }))
    }

    //我有未处理的私人交易
    const unprocessedTransactions = myTransactions.transactions?.find((t: any) =>
        t.status === 0 && state.uid === t.recipient.id)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={"App"}>
                    {Promo()}
                    <div className={"App-background"}>
                        <div className={"app-left"}>
                            <div className={"left-bar"}>
                                <Link to={`/game/district/restaurant`} className={"app-ink-icon food-icon "}>
                                    <img alt={"食物"} src={require("./assets/mainpage_food.webp").default}/>
                                </Link><br/>
                                <Link to={workBtnTo} className={"app-ink-icon work-icon"}>
                                    <img alt={"工作"} src={require("./assets/mainpage_work.webp").default}/>
                                </Link><br/>
                                <Link to={"/game/building/" + hideout} className={"app-ink-icon resident-icon"}>
                                    <img alt={"住所"} src={require("./assets/mainpage_residence.webp").default}/>
                                </Link>
                                <br/>
                                <img className={"left-icon-ink ink-food"} alt={"墨"} style={{visibility: "hidden"}}
                                     src={require("./Buildings/Building/components/WorkCard/assets/ink.webp").default}/>
                                <img className={"left-icon-ink ink-work"} alt={"墨"}
                                     style={{visibility: inkWorkVisibility ? "visible" : "hidden"}}
                                     src={require("./Buildings/Building/components/WorkCard/assets/ink.webp").default}/>
                                <img className={"left-icon-ink ink-resident"} alt={"墨"} style={{visibility: "hidden"}}
                                     src={require("./Buildings/Building/components/WorkCard/assets/ink.webp").default}/>

                                <div className={"left-list"}
                                     style={{transform: "translateY(" + left_list_scroll + "vw)"}}
                                     onClick={(event) => {
                                         event.preventDefault();
                                         return false
                                     }} onWheel={(event) => {
                                    set_left_list_scroll((prevState) => {
                                        let new_state = prevState - 0.04 * event.deltaY
                                        if (new_state < -26 || new_state > 0) return prevState
                                        return new_state
                                    })
                                }}
                                >
                                    <img src={require("./assets/mainpage_leftlist.webp").default}
                                         className={'left-list-bgimg'}
                                         alt={""}/>
                                    <div className={"left-list-text-container"}>
                                        <Link to={`/game/personal/${state.uid}`}
                                              className={"left-list-text"}>我的主页</Link><br/>
                                        <Link to={"/game/notifications"}
                                              className={"left-list-text my-notifications"}>我的通知
                                            {/*<div className="unread-dot" style={{display:(true)?"block":"none"}}/>*/}
                                        </Link><br/>
                                        <Link to={"/game/building/" + hideout + "/storage"}
                                              className={"left-list-text"}>我的库房</Link><br/>
                                        <Link to={`/game/personal/${state.uid}`}
                                              className={"left-list-text"}>我的地产</Link><br/>
                                        <Link to={"/game/transaction"} className={"left-list-text"}>我的私人交易
                                            <div className="app-red-dot"
                                                 style={{display: (unprocessedTransactions ? "block" : "none")}}/>
                                        </Link><br/>
                                        {/*<Link to={"./"} className={"left-list-text"} onClick={testSnackbar}>我的信件</Link><br/>*/}
                                        <Link to={"/game/sideJob"} className={"left-list-text"}>我的副业</Link><br/>
                                        <Link to={"/game/settings"} className={"left-list-text"}>设置</Link><br/>
                                        {/*<Link to={"./"} className={"left-list-text"}>我的教育</Link><br/>*/}
                                        {/*<p className={"left-list-text"}/>*/}
                                        {/*<Link to={"./"} className={"left-list-text"}>我的关系</Link><br/>*/}
                                        {/*<Link to={"./"} className={"left-list-text"}>我的社交</Link><br/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"app-right"}>{/*导航栏*/}
                            {/*导航栏左侧*/}
                            <div className={"nav-bar"}>
                                <div className={"nav-bar-left"}>
                                    <Link to={"/game/forum"} className={"nav-button"}>
                                        <img src={require("./assets/mainpage_topbutton_forum.webp").default}
                                             alt={"广场"}/>
                                    </Link>
                                    <Link to={"/game/bazaar"} className={"nav-button"}>
                                        <img src={require("./assets/mainpage_topbutton_bazzar.webp").default}
                                             alt={"集市"}/>
                                    </Link>
                                    <Link to={"/game/district"} className={"nav-button"}>
                                        <img src={require("./assets/mainpage_topbutton_district.webp").default}
                                             alt={"街区"}/>
                                    </Link>
                                    {/*表示此tab标签被激活的标签，注意插在文字和底部按钮之间*/}
                                    <img src={require("./assets/main_page_sticker.webp").default}
                                         className={"nav-link-flag " + navbarImgClass}
                                         alt={"sticker"}/>
                                </div>
                                {/*    导航栏右侧*/}
                                <div className={"nav-bar-right"}>

                                    {/*游戏日和时间*/}
                                    <div className={"game-day"}>
                                        <div className={""}>
                                            {gameDay.day}日
                                        </div>
                                        <div className={""}>
                                            Beta 0.3
                                        </div>
                                    </div>

                                    {/*头像和悬浮框*/}
                                    <Link to={"/game/personal/" + state.uid} className={"right-icon avt"}

                                          ref={avtPopoverAnchor}
                                          aria-owns={avtOpen ? 'avtPopover' : undefined}
                                          aria-haspopup="true"
                                          onMouseOver={avtHandleOpen}
                                          onMouseLeave={avtHandleClose}
                                    ><Avatar
                                        sx={{width: "3vw", height: "3vw", bgcolor: id2color(state.uid)}}
                                        src={state.avatar}
                                        alt={state.nick_name}

                                    >
                                        {state.nick_name ? state.nick_name[0] : ""}
                                    </Avatar></Link>
                                    <Popover
                                        id={"avtPopover"}
                                        open={avtOpen}
                                        anchorEl={avtPopoverAnchor.current}
                                        onClose={avtHandleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        sx={{
                                            pointerEvents: 'none',
                                        }}
                                        PaperProps={{
                                            onMouseEnter: avtHandleOpen, onMouseLeave: avtHandleClose,
                                            sx: {
                                                pointerEvents: 'auto'
                                            }
                                        }}
                                    >
                                        <MenuList id="menu-list-grow">
                                            <ListItemButton onClick={() => {
                                                navigate(`/game/settings`)
                                            }}><Settings/>设置</ListItemButton>
                                            <ListItemButton onClick={() => {
                                            }}><Logout/>登出（施工中）</ListItemButton>
                                        </MenuList>
                                    </Popover>

                                    <div className={"right-icon status-bar2x2 " + (statusOpen ? "status-open" : "")}

                                         ref={statusPopoverAnchor}
                                         aria-owns={statusOpen ? 'statusPopover' : undefined}
                                         aria-haspopup="true"
                                         onMouseOver={statusHandleOpen}
                                         onMouseLeave={statusHandleClose}
                                    ><StatusBar2x2/></div>
                                    <Popover
                                        id={"statusPopover"}
                                        // open={true}
                                        open={statusOpen}
                                        anchorEl={statusPopoverAnchor.current}
                                        onClose={statusHandleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        sx={{
                                            pointerEvents: 'none',
                                        }}
                                        PaperProps={{
                                            onMouseEnter: statusHandleOpen, onMouseLeave: statusHandleClose,
                                            sx: {
                                                pointerEvents: 'auto'
                                            }
                                        }}
                                    >
                                        <StatusTutorial/>
                                    </Popover>
                                </div>
                            </div>
                            {/*<div>*/}
                            {/*    <Container>*/}
                            <div className={"main-body-container"}>
                                <div className={"main-body-whitebg"}>&nbsp;</div>
                                <Routes>
                                    <Route index element={<Forum/>}/>
                                    <Route path='/forum/*' element={<Forum/>}/>
                                    <Route path="/bazaar/*" element={<Bazaar/>}/>
                                    <Route path="/personal/:UId/*" element={<Personal/>}/>
                                    <Route path="/building/:buildingId/*" element={<Buildings/>}/>
                                    <Route path='/district/*' element={<District/>}/>
                                    <Route path="/transaction/*" element={<Transaction/>}/>
                                    <Route path="/myBuildings/*" element={<MyBuildings/>}/>
                                    <Route path="/sideJob/*" element={<SideJob/>}/>
                                    <Route path="/notifications/*" element={<Notifications/>}/>
                                    <Route path="/settings/*" element={<GameSettings/>}/>
                                </Routes>
                            </div>
                            {/*    </Container>*/}
                            {/*</div>*/}
                        </div>
                        {/*全局snackbar*/}
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={() => dispatch(closeSnackbar())}
                            message="Note archived"
                            anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                        >
                            <Alert sx={{fontSize: "15px"}} variant="filled"
                                   severity={severity}>{snackbarMessage}</Alert>
                        </Snackbar>
                    </div>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );

}



import { createSlice } from '@reduxjs/toolkit';
import {getLoginToken} from "./utils/storage";
import {api_host} from "./utils/api";

const slice = createSlice({
    name: 'CityStructure',
    initialState: {
        "data":
            {
                "id": 0,
                "name": "蓬莱",
                "main_land": 0,
                "districts": [
                    {
                        "id": 1,
                        "name": "载入城区中",
                        "type": 0,
                        "avatar": "",
                        "belong_city": 0,
                        "terrains": [
                            {
                                "id": -2,
                                "type": 0,
                                "area": 400,
                                "area_taken": 100,
                                "belong_city": 0,
                                "belong_districts": 1
                            }
                        ],
                        "buildings": [
                            {
                                "id": 3,
                                "owner": {
                                    "uid": 0,
                                    "nick_name": "",
                                    "avatar": "",
                                    "born_city": 0
                                },
                                "owner_id": 9,
                                "name": "走访建筑中……",
                                "avatar": "",
                                "type": 4,
                                "tier": 1,
                                "max_work_count": 200,
                                "worker_count": 19,
                                "is_hiring": true,
                                "salary_number": 0.4,
                                "salary_type": 5,
                                "salary_tier": 1,
                                "storage_capacity": 0,
                                "max_storage_capacity": 40000,
                                "belong_city": 0,
                                "belong_districts": 1,
                                "belong_terrain": 1
                            },

                        ]
                    },

                    {
                        "id": 3,
                        "name": "载入郊区中",
                        "type": 1,
                        "avatar": "",
                        "belong_city": 0,
                        "terrains": [
                            {
                                "id": 3,
                                "type": 0,
                                "area": 400,
                                "area_taken": 0,
                                "belong_city": 0,
                                "belong_districts": 3
                            }
                        ],
                        "buildings": []
                    },

                ]
            }
        ,
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateCityStructureWPayload: (CityStructure, action) => {
            CityStructure.data = action.payload;
        },
        fetchCityStructureNow: (CityStructure) => {
            CityStructure.fetchNow += 1;
        },

    },
});
export const { updateCityStructureWPayload, fetchCityStructureNow } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the CityStructure. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((CityStructure) => CityStructure.counter.value)`
// export const selectStatus = (CityStructure:any) => CityStructure.status;
// export const selectStatusFetchNow = (CityStructure:any) => CityStructure.status.fetchNow;


export default slice.reducer;

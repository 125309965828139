import {Avatar} from "@mui/material";
import {api_host, api_with_auth} from "../../../../utils/api";
import "./WorkCard.scss"
import React, {useEffect, useState} from 'react';
import {Button, Snackbar, Alert, Modal} from "@mui/material";
import {
    TierToStars
} from "../../../../components/mappingTable";
import {id2color} from "../../../../utils/id2color";
import {getBPBuildings, getLoginToken} from "../../../../utils/storage";
import {useSelector, useDispatch} from 'react-redux';
import {fetchEmployeesNow} from "../../Work/workSlice";
import {fetchStatusNow} from "../../../../appSlice"
import WorkingStrategy from "../WorkingStrategy";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {
    BPBuildingInfo,
    FindBuildingSkillLevel,
    FindBuildingSkillName,
    itemInfo,
    skillInfo
} from "../../../../components/gadgets";
import {useNavigate} from "react-router-dom";
import WorkingResults from "../../../../components/WorkingResults";

//这个建筑的街区名字
export function FindBuildingDistrictName(city: any, building: any) {

    if (city.districts.find((e: any) => e.id === building.belong_districts) === undefined) {
        return ""
    } else {
        return city.districts.find((e: any) => e.id === building.belong_districts).name
    }
}

//正在建设的提示
export const constructionHint = (building: any) => {
    return (building.stage === 0) ? "（开垦中）" : ((building.stage === 1) ? "（建造中）" : "")
}

export function FindBuildingCurrentArea(building: any) {
    const buildingIsReclamation = (building.stage === 0)
    const buildingIsBuilding = (building.stage === 1)
    // const buildingList =
    if (BPBuildingInfo(building.type) === undefined || BPBuildingInfo(building.type).tiers.find((e: any) => e.tier === building.tier) === undefined) {
        return 0
    } else if (buildingIsReclamation) {
        return (BPBuildingInfo(building.type).tiers.find((e: any) => e.tier === building.tier).designed_area * building.percent / 100)
    } else if (buildingIsBuilding) {
        return (BPBuildingInfo(building.type).tiers.find((e: any) => e.tier === building.tier).designed_area)
    } else {
        return (BPBuildingInfo(building.type).tiers.find((e: any) => e.tier === building.tier).designed_area * building.percent / 100)
    }
}


export default function WorkCard(props: {
    employee: any,
}) {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const status = useSelector((state: any) => state.status.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const employeeSkills = useSelector((state: any) => state.employeeSkills.data)
    const cardType = status.user_id === props.employee.employee.uid ? 3 : 2
    // const buildingIsReclamation = (buildingSummary.stage === 0)
    // const buildingIsBuilding = (buildingSummary.stage === 1)
    //这个卡写的玩家的技能
    const skills = employeeSkills?.find((em: any) => em.user_id === props.employee.employee.uid)
    //这个卡写的是我
    const me = props.employee.employee.uid === status.user_id
    const overRideSkillLevelIfIsMe = FindBuildingSkillLevel((me ? status : skills), buildingSummary, 1)

    const [isSuccess, setIsSuccess] = useState(true)
    const [workRespond, setWorkRespond] = useState("")
    const [open, setOpen] = useState(false);
    const openSnackBar = () => {
        setOpen(true);
    };
    const closeSnackBar = () => {
        setOpen(false);
    };
    const typeToBodyClass: { [key: number]: string } = {
        1: "card-type-1",
        // 不可点击，灰色
        2: "card-type-2",
        //普通，描边
        3: "card-type-3",
        //引导点击，浮起卡片
    }

//我在这工作而且今天还没工作
    const hiredButNotWorkedHereYet = status.employee.map((building: any, index: any) => (
        (building.buildingID === buildingSummary.id && building.is_worked === false)
    )).includes(true)

    const handleClick = () => {
        if (cardType === 3 && hiredButNotWorkedHereYet) {
            handleOpenStrategy()
        } else if (cardType === 3) {
            dispatch(openSnackbar({
                "severity": "info",
                "snackbarMessage": "您已经完成了今日的工作",
            }))
        }
    }
    const onWorkDone = (status: any, message: any, data: any) => {
        if (status === "success") {
            dispatch(openSnackbar({
                "severity": "success",
                "snackbarMessage": "你已成功进行工作，雇主支付了工资到你的库房",
            }))
            handleCloseStrategy()
            setResult(data)
            setWorkingResultsOpen(true)
        } else {
            dispatch(openSnackbar({
                "severity": "error",
                "snackbarMessage": message,
            }))
        }
        // if ( "success" === "success"){
        // // if (status === "success"){
        //     setResult(data)
        //     setWorkingResultsOpen(true)
        // }
    }

    //工作策略页面。测试为true。push前调味false
    const [openStrategy, setOpenStrategy] = useState(false);
    const handleOpenStrategy = () => setOpenStrategy(true);
    const handleCloseStrategy = () => setOpenStrategy(false);

    //工作结算页面
    //测试为true
    const [workingResultsOpen, setWorkingResultsOpen] = useState(false)
    const defaultResult = {
        "input": [],
        "output":
        null,
        //     [
        //     // {
        //     //     "id": 2,
        //     //     "tier": 1,
        //     //     "quantity": 1
        //     // }
        // ],
        "buffs": {
            // "random": 1,
        },
        "salary": {
            "id": 0,
            "tier": 0,
            "quantity": 0
        },
        "skills": [
            {
                "id": 216,
                "before": 0,
                "after": 1,
                "thresholdMet": false,
                "breakThrough": false
            },
            {
                "id": 200,
                "before": 0,
                "after": 1,
                "thresholdMet": true,
                "breakThrough": true
            }
        ],
        "characters": null
    }
    const [result, setResult] = useState(defaultResult)

    return <div className={"work-card"}>
        <Avatar sx={{width: "3vw", height: "3vw", bgcolor: id2color(props.employee.employee.uid)}}
                onClick={() => navigate("/game/personal/" + props.employee.employee.uid)}
                style={{cursor: "pointer"}}
            // className={"user-avatar"}>{props.username ? props.username[0] : ""}</Avatar>
                className={"user-avatar"}>{props.employee.employee.nick_name ? props.employee.employee.nick_name[0] : ""}</Avatar>
        <Button variant="text"
                color="warning"
                style={{
                    position: "absolute",
                    zIndex: "3",
                    minHeight: "9vw",
                    top: "1.5vw",
                    left: "0",
                    maxWidth: "6.5vw",
                    minWidth: "6.5vw",
                    color: "#ed6c02",
                    borderColor: "#ed6c02",
                }}
                onClick={handleClick}
        />
        <div className={typeToBodyClass[cardType] + " card-body"}>
            <div className={"worker-name"}>{props.employee.employee.nick_name}</div>
            <div className={"skill-level"}>{overRideSkillLevelIfIsMe.toFixed(1)}</div>
            <div
                className={"skill-needed"}>{FindBuildingSkillName(buildingSummary, 1)}</div>
            <div className={"working-status"}>{props.employee.is_worked ? "已工作" : "未工作"}</div>
            <div className={"extra-message"}>{props.employee.quitting ? "明日离职（工人辞职）" : null}</div>
            <div className={"extra-message"}>{props.employee.firing ? "明日离职（老板辞退）" : null}</div>
        </div>
        <img src={require("./assets/ink.webp").default}
             className={typeToBodyClass[cardType] + " workingStatus_" + (props.employee.is_worked) + " ink"} alt={""}/>
        <img src={require("./assets/checkMark.webp").default}
             className={typeToBodyClass[cardType] + " workingStatus_" + props.employee.is_worked + " checkMark"}
             alt={""}/>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={closeSnackBar}
            message="Note archived"
            anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        >
            <Alert sx={{fontSize: "15px"}} variant="filled"
                   severity={isSuccess ? "success" : "error"}>{workRespond}</Alert>
        </Snackbar>
        <Modal
            open={openStrategy}
            onClose={handleCloseStrategy}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <WorkingStrategy close={handleCloseStrategy}
                             onWorkDone={onWorkDone}/>
        </Modal>
        <WorkingResults type={"working"}
                        open={workingResultsOpen}
                        close={() => {
                            setWorkingResultsOpen(false)
                        }}
                        result={result}
        />
    </div>;
}

export function HiringCard() {
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false)
    const status = useSelector((state: any) => state.status)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const meWorkHere = (status.data.employee && status.data.employee.length) ? (buildingSummary.id === status.data.employee[0].buildingID) : false
    const hireCardType = (status.data.employee && status.data.employee.length) ? 1 : 3
    const hireCardText = fetching ? "联系主人……" : ((status.data.employee && status.data.employee.length && buildingSummary.id === status.data.employee[0].buildingID) ? "辞职" : (buildingSummary.is_hiring ? "应聘" : "未在招聘"))
    const typeToBodyClass: { [key: number]: string } = {
        1: "card-type-1",
        // 不可点击，灰色
        2: "card-type-2",
        //普通，描边
        3: "card-type-3",
        //引导点击，浮起卡片
    }

    //应聘服务器回复转明文
    const failReasonToName: { [key: number]: string } = {
        0: "没有空余工位",
        1: "该建筑不在招工",
        2: "你不能同时拥有更多工作",
        3: "token无效",
        4: "建筑不存在",
        5: "参数错误",
    }


    //零食条文字,应聘结果成功与否
    const [decodeApplyRespond, setDecodeApplyRespond] = useState("")
    const [isSuccess, setIsSuccess] = useState(true)
    const [open, setOpen] = React.useState(false);
    const openSnackBar = () => {
        setOpen(true);
    };
    const closeSnackBar = () => {
        setOpen(false);
    };


//应聘
    const applyForJobHere = () => {
        setFetching(true)
        api_with_auth("/api/building/" + buildingSummary.id + "/work","POST")
            .then(res => {
                dispatch(fetchEmployeesNow())
                dispatch(fetchStatusNow())
                setFetching(false)
                // setIsSuccess(res.isSuccess)
                if (res.isSuccess) {
                    // setDecodeApplyRespond("主人接受了你的应聘")
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "主人接受了你的应聘",
                    }))
                } else {
                    // setDecodeApplyRespond(failReasonToName[res.failReason])
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": (failReasonToName[res.failReason]),
                    }))
                }
            })
    }
//辞职
    const quitFromJobHere = () => {
        setFetching(true)
        api_with_auth("/api/building/" + buildingSummary.id + "/quit", "POST" )
            .then(res => {
                dispatch(fetchEmployeesNow())
                dispatch(fetchStatusNow())
                setFetching(false)
                setIsSuccess(res.isSuccess)
                if (res.isSuccess) {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "你辞去了此处的工作",
                    }))
                    // setDecodeApplyRespond("你辞去了此处的工作")
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": (failReasonToName[res.failReason]),
                    }))
                    // setDecodeApplyRespond(failReasonToName[res.failReason])
                }
                // openSnackBar()

            })
    }


    return <div className={"work-card"}>
        {/*<TouchRipple ref={rippleRef} center={false} />*/}
        <div className={"seal_container"}>
            <img src={require("./assets/building_seal.webp").default} className={'seal'} alt={"蜡封"}/>
        </div>
        <Button variant="text"
                color="warning"
                onClick={meWorkHere ? quitFromJobHere : applyForJobHere}
            // sx={{minWidth:0}}
                style={{
                    position: "absolute",
                    zIndex: "3",
                    minHeight: "9vw",
                    top: "1.5vw",
                    left: "0",
                    maxWidth: "6.5vw",
                    minWidth: "6.5vw",
                    color: "#ed6c02",
                    borderColor: "#ed6c02",
                }}
        />
        <div className={"card-type-" + hireCardType + " card-body"}>
            <div className={"worker-name"}>单位产能薪水</div>
            <div className={"skill-level"}>{buildingSummary.salary_number}</div>
            <div
                className={"skill-needed"}>{itemInfo(buildingSummary.salary_type)?.Chs + TierToStars[buildingSummary.salary_tier]}</div>
            <div className={"working-status"}>{hireCardText}</div>
            <div className={"extra-message"}></div>
        </div>
    </div>;
}
import "./Menu.scss"
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Floor1} from "../../../../components/gadgets";
import {Divider, Switch} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import Filler from "../../../../components/Filler";
import {RecipeRender} from "../index";
import {Item} from "../../../../components/IconImg";
import {fetchStatusNow} from "../../../../appSlice";

export default function RestaurantMenu(props: {}) {
    const {buildingId} = useParams()
    const dispatch = useDispatch()
    const status = useSelector((state: any) => state.status.data)
    const navigate = useNavigate()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const state = useSelector((state: any) => state.state.data)
    const IGovern = ((state.uid === buildingSummary.owner.uid) || (state.uid === buildingSummary.manager.uid));

    //前往后厨
    const goEditMenu = () => {
        if (IGovern) {
            navigate("/game/building/" + buildingId + "/OrderFood/EditMenu")
        } else {
            dispatch(openSnackbar({
                "severity": "info",
                "snackbarMessage": "后厨区域闲人免入（你不是管理员）",
            }))
        }
    }

    //获取菜单
    const [fetchedMenu, setFetchedMenu] = useState({
        "ImWorker": false,
        "ImGuest": false,
        "menu": [
            {
                "id": -1,
                "building_id": 185,
                "name": "获取菜单中",
                "avatar": "",
                "cookingMethod": 0,
                "cookingId": 4,
                "cookingTier": 1,
                "cooking_quantity": 0.1,
                "fuelId": 33,
                "fuelTier": 1,
                "fuelQuantity": 0,
                "materials": [
                    {
                        "id": 2,
                        "tier": 3,
                        "quantity": 1
                    }
                ],
                "energy": 0,
                "happiness": -0.05,
                "health": -0.04,
                "hunger": 3.3,
                "luxury": 25,
                "selling": true,
                "price": {
                    "id": 2,
                    "tier": 1,
                    "quantity": 1
                },
                "freeForWorkers": false,
                "freeForGuests": false,
                "dailyLimit": 0,
                "oneForAll": false
            }]
    })
    const ImWorker = fetchedMenu.ImWorker
    const ImGuest = fetchedMenu.ImGuest
    const freeWorker = (recipe: any) => {
        return recipe.freeForWorkers && ImWorker
    }
    const freeGuest = (recipe: any) => {
        return recipe.freeForGuests && ImGuest
    }


    const menu = fetchedMenu.menu
    const fetchMenu = () => {
        api_with_auth("/api/restaurant/" + buildingId + "/menu", "GET")
            .then(res => {
                if (res.status === "success") {
                    setFetchedMenu(res.data)
                } else {
                    // dispatch(openSnackbar({
                    //     "severity": "error",
                    //     "snackbarMessage": res.message,
                    // }))
                }
            },);
    }
    useEffect(() => {
        fetchMenu()
    }, [])


    //买饭吃
    const order = (event: any, index: number) => {
        api_with_auth("/api/restaurant/" + buildingId + "/order", "POST", {
            "id": menu[index].id
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "购买并享用了食物，属性恢复了",
                    }))
                    fetchMenu()
                    dispatch(fetchStatusNow())
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            },);
    }


    return <div className={"restaurant-menu-root"}>
        <div className={"restaurant-menu-title"}>点餐
            <div className="my-luxury">
                <div className="t">{Floor1(status.luxury)}</div>
                <div className="d">我的奢侈度</div>
            </div>
            <div className={"white-button button8"}
                 onClick={goEditMenu}>前往后厨
            </div>
        </div>

        <div className={"restaurant-menu-content"}>
            {menu?.map((recipe: any, index: number) =>
                <div className={"recipe"}>
                    <RecipeRender recipe={recipe} key={recipe.id} hideMaterials/>
                    <div className={"actions"}>
                        <div className={"hints"}>
                            {recipe.freeForWorkers ? <div className={"hint"}>・这份餐食对餐馆所有者的<b>员工</b>免费供应。</div> : null}
                            {recipe.freeForGuests ? <div className={"hint"}>・这份餐食对餐馆所有者的<b>租客</b>免费供应。</div> : null}
                            {recipe.oneForAll ? <div className={"hint"}>・这份餐食每人限购<b>1</b>份。</div> : null}
                            {recipe.dailyLimit !== 0 ?
                                <div className={"hint"}>・这份餐食每日限量供应<b>{recipe.dailyLimit}</b>份，还剩-份。</div> : null}
                        </div>
                        <div className={"t"}>
                            点餐
                        </div>
                        <div
                            className={"button8 " + ((freeWorker(recipe) || freeGuest(recipe)) ? " orange-button" : " white-button")}
                            onClick={(e) => {
                                order(e, index)
                            }}>
                            {freeWorker(recipe) ? "作为员工免费享用" :
                                freeGuest(recipe) ? "作为租客免费享用" :
                                    <span>售价<Item id={recipe.price.id} t={recipe.price.tier} size={2} ml={0.5}
                                                  mr={0.5}/>{recipe.price.quantity?.toFixed(2)}</span>}</div>
                    </div>
                    <Divider variant={"middle"}/>
                </div>
            )}
            {!menu.length ? <Filler title={"没有在售餐食"}/> : null}
            <Filler/>
        </div>
    </div>
}
import "./WorkingStrategy.scss"
import {api_host, api_with_auth} from "../../../../utils/api";
import {
    Close, ModeStandby, Bolt, FileDownload, Payments, Moving, SettingsAccessibility, ClearAll,
    ArrowForwardIos, KeyboardArrowLeft, KeyboardArrowRight,
} from '@mui/icons-material';
import {Link} from "react-router-dom";
import {Avatar, MobileStepper, Button, useTheme} from "@mui/material";
import {id2color} from "../../../../utils/id2color";
import {useSelector, useDispatch} from "react-redux";
import {StatusBar1x4} from "../../../../components/statusBar";
import * as React from "react";
import SwipeableViews from 'react-swipeable-views';
import {fetchEmployeesNow} from "../../Work/workSlice";
import {fetchStatusNow} from "../../../../appSlice"
import {getLoginToken} from "../../../../utils/storage";
import {useState} from "react";
import {Strategies} from "./StrategiesAndSkillLevelTable";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {
    BaseCapacity,
    BPBuildingInfo,
    FindBuildingSkill,
    FindBuildingSkillLevel,
    FindBuildingSkillName, FindPlayerSkillProgress,
    itemInfo,
    skillInfo
} from "../../../../components/gadgets";
import {Skill} from "../../../../components/IconImg";
import WorkingResults from "../../../../components/WorkingResults";

//TODO:snackbar还在workCard里面，显示效果是在这个modal下层。有空要把它放进来

export default function WorkingStrategy(props: {
    close: () => void
    onWorkDone: (status: any, message: any, data: any) => void
}) {
    const dispatch = useDispatch();
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const state = useSelector((state: any) => state.state.data)
    const status = useSelector((state: any) => state.status.data)

    //这个建筑的大小技能等级,基础产能
    const levelOfSkill = (skill: number) => (status.skills[skill] === undefined) ? 0 : (status.skills[skill].level)
    const minorSkillAtThisBuilding = FindBuildingSkill(buildingSummary, 1)
    const minorSkillNameAtThisBuilding = FindBuildingSkillName(buildingSummary, 1)
    const minorSkillLevelAtThisBuilding = FindBuildingSkillLevel(status, buildingSummary, 1)
    const majorSkillAtThisBuilding = FindBuildingSkill(buildingSummary, 0)
    const majorSkillLevelAtThisBuilding = FindBuildingSkillLevel(status, buildingSummary, 0)
    const baseCapacityAtThisBuilding = BaseCapacity(majorSkillLevelAtThisBuilding, minorSkillLevelAtThisBuilding)

    const wageAtThisBuilding = (buildingSummary.salary_number * baseCapacityAtThisBuilding)

    const fakeCurrentSkillCap = (status.skills[minorSkillAtThisBuilding] === undefined) ? 14 : status.skills[minorSkillAtThisBuilding].breakthrough

    const m = (status.happiness / 50 - 1) > 0 ? (status.happiness / 50 - 1) : 0


//fetch工作
    const [canPick, setCanPick] = useState(true)
    const [picked, setPicked] = useState(0)
    const fetchWork = (strategyId: number) => {
        api_with_auth("/api/building/" + buildingSummary.id + "/working?work_strategy=" + strategyId, "POST" )
            .then(res => {
                dispatch(fetchEmployeesNow())
                dispatch(fetchStatusNow())
                dispatch(fetchBuildingSummaryNow())
                if (res.status === "success") {
                    setCanPick(false)
                } else {
                    setCanPick(true)
                    setPicked(0)
                }
                props.onWorkDone(res.status, res.message, res.data)
            })
    }

    const [activeStep, setActiveStep] = React.useState(3);
    const maxSteps = Strategies.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: any) => {
        setActiveStep(step);
    };

    const theme = useTheme();

    return <div className="workingStrategy-root">
        <div className="workingStrategy-white-background"/>
        <div className="workingStrategy-content">
            <div className="title">
                <div className="title-icon" onClick={props.close}>
                    <Close sx={{fontSize: "3vw"}}/>
                </div>
                <div className="title-text">今日工作</div>
            </div>
            <div className="pre-work">
                <div className="left-column">
                    <div className="status card-element">
                        <div className="status-title sub-title">
                            <div className="sub-title-icon"><Bolt sx={{fontSize: "1.2vw"}}/></div>
                            属性消耗
                        </div>
                        <div className="status-content">
                            <Link to={"/game/personal"} className={"me-avatar"}>
                                <Avatar
                                    sx={{
                                        width: "3vw",
                                        height: "3vw",
                                        top: "1.1vw",
                                        ml: "1.2vw",
                                        bgcolor: id2color(state.uid)
                                    }}
                                    src={state.avatar}
                                    alt={state.nick_name}>{state.nick_name ? state.nick_name[0] : ""}</Avatar>
                            </Link>
                            <div className="status-bars">
                                <StatusBar1x4 barLength={7} changingValueEnergy={-20} changingValueHappiness={-5}
                                              changingValueHealth={-3} changingValueHungry={-3}/>
                            </div>
                        </div>
                    </div>
                    <div className="skill card-element">
                        <div className="status-title sub-title">
                            <div className="sub-title-icon"><ModeStandby sx={{fontSize: "1.2vw"}}/></div>
                            技能发挥
                        </div>
                        <div className="skill-content">
                            {[majorSkillAtThisBuilding, minorSkillAtThisBuilding]?.map((skill: number, index: any) =>
                                <div className="skill-bullet" key={skill}>
                                    <Skill id={skill} playerProgress={FindPlayerSkillProgress(status, skill)}/>
                                    <div className="bullet-right">
                                        <div className="skill-value">{levelOfSkill(skill).toFixed(2)}</div>
                                        <div className="skill-name">{skillInfo(skill)?.Chs}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="personality card-element">
                    <div className="personality-title sub-title">
                        <div className="sub-title-icon"><SettingsAccessibility sx={{fontSize: "1.2vw"}}/></div>
                        性格倾向
                    </div>
                    <div className="personality-content">
                        <div>这项工作不受你的性格倾向影响。</div>
                    </div>

                </div>
            </div>


            <div className="strategy"
                 style={{
                     top: "22.5vw",
                     boxShadow: "0 0 0.5vw rgba(0, 0, 0, 0.4)"
                 }}>
                <div className="status-title sub-title">
                    <div className="sub-title-icon"><ClearAll sx={{fontSize: "1.2vw"}}/></div>
                    选择一个工作策略
                </div>
                <div className="swipeable-container">
                    <SwipeableViews
                        enableMouseEvents
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        style={{
                            width: '8.8vw',
                            overflow: 'visible',
                        }}
                        slideStyle={{
                            flex: 'none',
                            height: "15vw",
                        }}
                    >
                        {
                            Strategies.map((strategy, index) => (
                                <div key={strategy.id}
                                     className="choice-card "
                                     onClick={() => {
                                         if (canPick && strategy.requested < fakeCurrentSkillCap) {
                                             fetchWork(strategy.id);
                                             setPicked(strategy.id)
                                             setCanPick(false)
                                         }
                                     }}>
                                    <div
                                        className={"top-card " + ((picked === strategy.id) ? "picked" : ((strategy.requested < fakeCurrentSkillCap) ? "available" : "not-available"))}>
                                        <div
                                            className="choice-title">{strategy.requested > fakeCurrentSkillCap ? "⠀" : strategy.name}</div>
                                        <div className="choice-description">{strategy.requested > fakeCurrentSkillCap ? <div>
                                            <div>{minorSkillNameAtThisBuilding}</div>
                                            <div>达到{strategy.requestedName}时</div>
                                            <div>解锁</div>
                                        </div> : strategy.description.map((description, index) => (
                                            <div key={index}>{description}</div>
                                        ))}</div>
                                    </div>
                                    <div className="bottom-bar"/>
                                </div>
                            ))
                        }
                    </SwipeableViews>
                </div>
                <MobileStepper
                    steps={maxSteps}
                    style={{
                        position: "absolute",
                        top: "15vw",
                        left: "8vw",
                        backgroundColor: "rgba(0,0,0,0)"
                    }}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >

                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft/>
                            ) : (
                                <KeyboardArrowRight/>
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight/>
                            ) : (
                                <KeyboardArrowLeft/>
                            )}
                        </Button>
                    }
                />
            </div>
        </div>
    </div>
}

export function WorkingStrategy2(props: {
    close: () => void
    onWorkDone: (status: any, message: any) => void
}) {
    const dispatch = useDispatch();
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)

    //自己
    const [state, setState] = useState(useSelector((state: any) => state.state.data))
    const [status, setStatus] = useState(useSelector((state: any) => state.status.data))
    // const state = useSelector((state: any) => state.state.data)
    // const status = useSelector((state: any) => state.status.data)

    //这个建筑的大小技能等级,基础产能
    const levelOfSkill = (skill: number) => (status.skills[skill] === undefined) ? 0 : (status.skills[skill].level)
    const minorSkillAtThisBuilding = FindBuildingSkill(buildingSummary, 1)
    const minorSkillNameAtThisBuilding = FindBuildingSkillName(buildingSummary, 1)
    const minorSkillLevelAtThisBuilding = FindBuildingSkillLevel(status, buildingSummary, 1)
    const majorSkillAtThisBuilding = FindBuildingSkill(buildingSummary, 0)
    const majorSkillLevelAtThisBuilding = FindBuildingSkillLevel(status, buildingSummary, 0)
    const baseCapacityAtThisBuilding = (majorSkillLevelAtThisBuilding / 6 + minorSkillLevelAtThisBuilding / 3) + 5

    const wageAtThisBuilding = (buildingSummary.salary_number * baseCapacityAtThisBuilding)

    const fakeCurrentSkillCap = (status.skills[minorSkillAtThisBuilding] === undefined) ? 14 : status.skills[minorSkillAtThisBuilding].breakthrough

    const m = (status.happiness / 50 - 1) > 0 ? (status.happiness / 50 - 1) : 0
    const skillGrowth = (skill: number) => 25 * Math.log(Math.exp(levelOfSkill(skill) / 25) + (1 + m) * 50 / ((skillInfo(skill)?.n) ? (skillInfo(skill)?.n) : 1)) - levelOfSkill(skill)
    //生成技能

    const skillRender = [majorSkillAtThisBuilding, minorSkillAtThisBuilding]?.map((skill: number, index: any) =>
        <div className="skill-bullet" key={skill}>
            <img
                src={process.env.PUBLIC_URL + "/img/skills/" + skillInfo(skill)?.Eng + ".webp"}
                onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                    event.currentTarget.onerror = null;
                }}
                alt={`${skill}`}
                className="skill-icon"/>
            <div className="bullet-right">
                <div className="skill-value">{levelOfSkill(skill).toFixed(2)}</div>
                <div className="skill-name">{skillInfo(skill)?.Chs}</div>
            </div>
        </div>
    )

    const skillGrowthRender = [majorSkillAtThisBuilding, minorSkillAtThisBuilding].map((skill: number, index: any) =>
        <div className="skill-growth-bullet" key={skill}>
            <img src={process.env.PUBLIC_URL + "/img/skills/" + skillInfo(skill)?.Eng + ".webp"}
                 onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                     event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                     event.currentTarget.onerror = null;
                 }}
                 alt={`${skill}`}
                 className="skill-icon"/>
            <div className="bullet-right">
                <div className="skill-value">+{skillGrowth(skill).toFixed(2)}↑</div>
                <div className="skill-name">(HPY+{(m * 100).toFixed(0)}%)</div>
            </div>
        </div>
    )

    //生成资源产出小票
    const outcomeReceipt = [
        {
            "name": "开发度",
            "value": `100%`,
        },
        {
            "name": "灌溉",
            "value": `100%`,
        },
        {
            "name": "工具",
            "value": `0%`,
        },
        {
            "name": "设施完整度",
            "value": `100%`,
        },
    ]
    const outcomeReceiptRender = outcomeReceipt.map((receipt: any, index: any) =>
        <div className="outcome-row">
            <div className="outcome-name">
                {receipt.name}
            </div>
            <div className="outcome-value">
                {receipt.value}
            </div>
        </div>
    )
//生成工作策略卡片，策略和技能等级对照表在import里面


//fetch工作
    const [canPick, setCanPick] = useState(true)
    const [picked, setPicked] = useState(0)
    const [worked, setWorked] = useState(false)
    const fetchWork = (strategyId: number) => {
        api_with_auth("/api/building/" + buildingSummary.id + "/working?work_strategy=" + strategyId,  "POST" )
            .then(res => {
                dispatch(fetchEmployeesNow())
                dispatch(fetchStatusNow())
                dispatch(fetchBuildingSummaryNow())
                if (res.status === "success") {
                    setCanPick(false)
                    setWorked(true)

                } else {
                    setCanPick(true)
                    setPicked(0)
                }
                props.onWorkDone(res.status, res.message)
                // openSnackBar()
                // setIsSuccess(res.status === "success")
                // setWorkRespond(res.status === "success"? "你已成功进行工作，雇主支付了工资到你的库房":res.message )
            })
    }

    const [activeStep, setActiveStep] = React.useState(3);
    const maxSteps = Strategies.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: any) => {
        setActiveStep(step);
    };


    const strategiesRender = Strategies.map((strategy, index) => (
        <div key={strategy.id}
             className="choice-card "
             onClick={() => {
                 if (canPick && strategy.requested < fakeCurrentSkillCap) {
                     fetchWork(strategy.id);
                     setPicked(strategy.id)
                     setCanPick(false)
                 }
             }}>
            <div
                className={"top-card " + ((picked === strategy.id) ? "picked" : ((strategy.requested < fakeCurrentSkillCap) ? "available" : "not-available"))}>
                <div className="choice-title">{strategy.requested > fakeCurrentSkillCap ? "⠀" : strategy.name}</div>
                <div className="choice-description">{strategy.requested > fakeCurrentSkillCap ? <div>
                    <div>{minorSkillNameAtThisBuilding}</div>
                    <div>达到{strategy.requestedName}时</div>
                    <div>解锁</div>
                </div> : strategy.description.map((description, index) => (
                    <div key={index}>{description}</div>
                ))}</div>
            </div>
            <div className="bottom-bar"/>
        </div>
    ))
    const theme = useTheme();

    return <div className="workingStrategy-root">
        <div className="workingStrategy-white-background"/>
        <div className="workingStrategy-content">
            <div className="title">
                <div className="title-icon" onClick={props.close}>
                    <Close sx={{fontSize: "3vw"}}/>
                </div>
                <div className="title-text">今日工作</div>
            </div>
            <div className="pre-work">
                <div className="left-column">
                    <div className="status card-element">
                        <div className="status-title sub-title">
                            <div className="sub-title-icon"><Bolt sx={{fontSize: "1.2vw"}}/></div>
                            属性消耗
                        </div>
                        <div className="status-content">
                            <Link to={"/game/personal"} className={"me-avatar"}>
                                <Avatar
                                    sx={{
                                        width: "3vw",
                                        height: "3vw",
                                        top: "1.1vw",
                                        ml: "1.2vw",
                                        bgcolor: id2color(state.uid)
                                    }}
                                    src={state.avatar}
                                    alt={state.nick_name}>{state.nick_name ? state.nick_name[0] : ""}</Avatar>
                            </Link>
                            <div className="status-bars">
                                <StatusBar1x4 barLength={7} changingValueEnergy={-20} changingValueHappiness={-5}
                                              changingValueHealth={-3} changingValueHungry={-3}/>
                            </div>
                        </div>
                    </div>
                    <div className="skill card-element">
                        <div className="status-title sub-title">
                            <div className="sub-title-icon"><ModeStandby sx={{fontSize: "1.2vw"}}/></div>
                            技能发挥
                        </div>
                        <div className="skill-content">
                            <div className="left">
                                {skillRender}
                            </div>
                            <div className="middle"><ArrowForwardIos sx={{fontSize: "2.5vw"}}/></div>
                            <div className="right">
                                <div>{baseCapacityAtThisBuilding.toFixed(2)}</div>
                                <div className="base-capacity">基础产能</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="personality card-element">
                    <div className="personality-title sub-title">
                        <div className="sub-title-icon"><SettingsAccessibility sx={{fontSize: "1.2vw"}}/></div>
                        性格倾向
                    </div>
                    <div className="personality-content">
                        <img src={require("./assets/personality-temp.webp").default} alt="personality"
                             className="personality-img"/>
                    </div>

                </div>
            </div>

            {worked ? <div className="after-work">
                <div className="outcome card-element">
                    <div className="status-title sub-title">
                        <div className="sub-title-icon"><FileDownload sx={{fontSize: "1.2vw"}}/></div>
                        资源产出
                    </div>
                    <div className="outcome-content">
                        {outcomeReceiptRender}
                        <div className="final-capacity-row">
                            <div className="final-capacity-name">最终产能</div>
                            <div className="final-capacity-value">{baseCapacityAtThisBuilding.toFixed(2)}</div>
                        </div>
                        <div className="outcome-belongs-to-landlord">产出的物资归地主所有：</div>
                        <div className="final-capacity-row">
                            <div
                                className="final-capacity-name">{itemInfo(BPBuildingInfo(buildingSummary.type)?.tiers.find((t: any) => t.tier === buildingSummary.tier)?.working_method)?.Chs}</div>
                            <div className="final-capacity-value">{baseCapacityAtThisBuilding.toFixed(2)}</div>
                        </div>
                    </div>

                </div>
                <div className="right-column">
                    <div className="skill-growth card-element">
                        <div className="status-title sub-title">
                            <div className="sub-title-icon"><Moving sx={{fontSize: "1.2vw"}}/></div>
                            技能增长
                        </div>
                        <div className="skill-growth-content">
                            {skillGrowthRender}
                        </div>

                    </div>
                    <div className="wage" onClick={props.close}>
                        <img src={require("./assets/wage.webp").default} alt="wage"
                             className="wage-img"/>
                        <div className="content">
                            <div className="wage-sub-title"><Payments sx={{fontSize: "1.2vw"}}
                                                                      className="sub-title-icon"/>获得报酬
                            </div>
                            <div className="wage-icon-number">
                                <Avatar
                                    sx={{
                                        width: "2.4vw",
                                        height: "2.4vw",
                                        // top: "1.1vw",
                                        // ml: "1.2vw",
                                        bgcolor: "grey",
                                    }}
                                    // src={state.data.avatar}
                                    variant="rounded"
                                    alt="wage-item">M
                                </Avatar>
                                <div className="wage-number">{wageAtThisBuilding.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div/>}

            <div className="strategy" style={worked ? {
                boxShadow: "0 0 0.4vw rgba(0, 0, 0, 0.4)",
                top: "4vw",
            } : {boxShadow: "0 0 1.5vw rgba(0, 0, 0, 0.7)", top: "22.5vw",}}>
                <div className="status-title sub-title">
                    <div className="sub-title-icon"><ClearAll sx={{fontSize: "1.2vw"}}/></div>
                    选择一个工作策略
                </div>
                <div className="swipeable-container">
                    <SwipeableViews
                        enableMouseEvents
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        style={{
                            width: '8.8vw',
                            overflow: 'visible',
                        }}
                        slideStyle={{
                            flex: 'none',
                            // width: "8vw",
                            height: "15vw",
                            // backgroundColor: "#FEA900"
                        }}
                    > {strategiesRender}</SwipeableViews>
                </div>
                <MobileStepper
                    steps={maxSteps}
                    style={{
                        position: "absolute",
                        top: "15vw",
                        left: "8vw",
                        backgroundColor: "rgba(0,0,0,0)"
                    }}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >

                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft/>
                            ) : (
                                <KeyboardArrowRight/>
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight/>
                            ) : (
                                <KeyboardArrowLeft/>
                            )}
                        </Button>
                    }
                />
            </div>
        </div>
    </div>
}
import * as React from 'react'
import {api_host} from "./utils/api";
import {useRef, useState} from 'react'
import "./login.scss"
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    createTheme,
    FormControl,
    Modal,
    Snackbar,
    TextField,
    ThemeProvider,
    Typography
} from '@mui/material'
import HCaptcha from "@hcaptcha/react-hcaptcha";
import * as yup from "yup";
import {useFormik} from "formik";
import {passwordEncrypt} from "./utils/PasswordEncrypt";
import {saveLoginToken} from "./utils/storage";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

export default function Login() {
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const [searchParams] = useSearchParams();

    const validationSchema = yup.object({
        email: yup.string().email("邮箱格式错误").required("你必须输入邮箱"),
        // password: yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_=+])[A-Za-z\d@$!%*#?&\-_=+]{8,24}$/, "请确保你输入的密码中长度为8-24位并至少含有一个字母和一个数字和一个特殊字符").required("你必须输入密码"),
        password: yup.string().required("你必须输入密码"),
        //测试服禁用验证码captcha，注释这里一行
        captcha: yup.string().required("请完成人机验证"),
    })
    const validationSchema2 = yup.object({
        email: yup.string().email("邮箱格式错误").required("你必须输入邮箱"),
        captcha: yup.string().required("请完成人机验证"),
    })
    const navigate = useNavigate()
    const [login_error, set_login_error] = useState("")
    const captchaRef = useRef<HCaptcha>(null)
    const [submitting, setSubmitting] = useState(false)
    const [openResetPW, setOpenResetPW] = useState(false);
    const handleOpenResetPW = () => setOpenResetPW(true);
    const handleCloseResetPW = () => setOpenResetPW(false);
//snackbar
    const [submittingReset, setSubmittingReset] = useState(false)
    const [isSuccess, setIsSuccess] = useState(true)
    const [changePWResponse, setChangePWResponse] = useState("")
    const [open, setOpen] = React.useState(false);
    const openSnackBar = () => {
        setOpen(true);
    };
    const closeSnackBar = () => {
        setOpen(false);
    };


    //登录
    let redirectUrl = searchParams.get('redirect_url')
    const formik = useFormik<{
        email: string,
        password: string,
        captcha: string
    }>({
        initialValues: {
            captcha: "",
            email: "",
            password: ""
        },
        validationSchema: validationSchema,
        onSubmit(values): void | Promise<any> {
            setSubmitting(true)
            set_login_error("")
            fetch(api_host() + "/login", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
                body: JSON.stringify({
                    name: values.email,
                    password: passwordEncrypt(values.password),
                    h_captcha_token: values.captcha
                }), // body data type must match "Content-Type" header
            })
                .then(res => res.json())
                .then(loginInfo => {
                    if (loginInfo.status === "success") {
                        if (redirectUrl) {
                            window.location.replace(redirectUrl + "?token=" + encodeURIComponent(loginInfo.token))
                        } else {
                            saveLoginToken(loginInfo.token)
                            navigate("/")
                        }

                    } else {
                        if (captchaRef.current !== null) {
                            captchaRef.current.resetCaptcha()
                        } else {
                            set_login_error("验证码为空")
                        }
                        set_login_error(loginInfo.message)
                    }
                    setSubmitting(false)
                });
        },
    });

    //改密码
    const formikResetPW = useFormik<{
        email: string,
        captcha: string
    }>({
        initialValues: {captcha: "", email: ""},
        validationSchema: validationSchema2,
        onSubmit(values): void | Promise<any> {
            setSubmittingReset(true)
            fetch(api_host() + "/reset_password", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
                body: JSON.stringify({
                    name: values.email,
                    h_captcha_token: values.captcha
                }), // body data type must match "Content-Type" header
            })
                .then(res => res.json())
                .then(res => {
                    setIsSuccess(res.status === "success")
                    if (res.status === "success") {
                        handleCloseResetPW()
                        setChangePWResponse("成功提交请求，新密码将发到您的邮箱")
                    } else {
                        setChangePWResponse(res.message)
                    }
                    openSnackBar()
                    setSubmittingReset(false)
                });
        },
    });

    return (
        <div className="p-login page">
            <div className="left-container">
                <div className="left">
                    <img src={require("./assets/login/login2.jpg").default} alt={""}/>
                </div>
            </div>
            <div className="login-container">
                <form onSubmit={formik.handleSubmit}>
                    <div className="logo">
                        {/*<img className="logo-img" src={require("./assets/login/logo_cn.webp").default} alt={""}/>*/}
                        <iframe
                            src="https://store.steampowered.com/widget/1925820/?t=Civitas%20Rise%20%E6%98%AF%E4%B8%80%E6%AC%BE%E5%8F%A4%E5%85%B8%E7%A4%BE%E4%BC%9A%E6%A8%A1%E6%8B%9F%E6%B8%B8%E6%88%8F%E3%80%82%E6%9C%AC%E6%B8%B8%E6%88%8F%E4%B8%AD%EF%BC%8C%E6%95%B0%E5%8D%83%E5%90%8D%E7%8E%A9%E5%AE%B6%E5%85%B1%E5%90%8C%E5%BB%BA%E8%AE%BE%E4%B8%80%E5%BA%A7%E5%BA%A7%E8%91%97%E5%90%8D%E7%9A%84%E6%B1%89%E4%BB%A3%E6%97%B6%E6%9C%9F%E4%B8%9C%E8%A5%BF%E6%96%B9%E5%9F%8E%E5%B8%82%EF%BC%8C%E5%8F%91%E5%B1%95%E7%BB%8F%E6%B5%8E%E7%B3%BB%E7%BB%9F%EF%BC%8C%E5%88%B6%E5%AE%9A%E7%A4%BE%E4%BC%9A%E8%A7%84%E5%88%99%EF%BC%8C%E6%B2%89%E6%B7%80%E6%96%87%E5%8C%96%E8%AE%B0%E5%BF%86%E3%80%82%E7%8E%A9%E5%AE%B6%E4%BB%AC%E7%9A%84%E7%BE%A4%E4%BD%93%E5%86%B3%E7%AD%96%E4%BC%9A%E5%AF%B9%E5%9F%8E%E5%B8%82%E4%BA%A7%E7%94%9F%E4%B8%8D%E5%8F%AF%E9%80%86%E7%9A%84%E6%B7%B1%E8%BF%9C%E5%BD%B1%E5%93%8D%E3%80%82"
                            frameBorder="0" width="546" height="190"></iframe>
                    </div>
                    <div className="login-form">
                        <div className={"title"}>开发版demo试玩</div>
                        <ThemeProvider theme={darkTheme}>
                            <FormControl fullWidth>
                                <TextField
                                    id="email"
                                    label="邮箱"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    id="password"
                                    label="密码"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    type={"password"}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}
                                />
                            </FormControl>
                            <div className="HCaptcha-texT">hCaptcha验证码</div>
                            <FormControl sx={{marginLeft: "10px"}} className="captcha">

                                <HCaptcha sitekey={"fdffc360-6741-484e-bb76-80bc4f9b6371"} onVerify={(token) => {
                                    formik.setFieldValue('captcha', token)
                                }} ref={captchaRef}/>
                            </FormControl>
                        </ThemeProvider>
                    </div>
                    {/*登录按钮*/}
                    <div className="form-actions">
                        <button type="submit" className="submit-btn">{submitting ?
                            <CircularProgress color="primary"/> : "登录"}
                        </button>
                        <div className="login-signUp-jump">还没有账户？<Link className="login-signUp-jump"
                                                                       to={`/signup`}>注册</Link></div>
                        <div className="login-forgetPW">忘记密码？<Link className="login-forgetPW" to={`/login`}
                                                                   onClick={handleOpenResetPW}>重置密码</Link></div>

                    </div>
                    {Boolean(login_error) && <div className={"alert"}>{login_error}</div>}
                </form>
                <Modal
                    open={openResetPW}
                    onClose={handleCloseResetPW}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: "5px",
                    }}>
                        <form onSubmit={formikResetPW.handleSubmit}>
                            <Typography id="modal-modal-title" variant="h5" component="h2" fontSize="25px">
                                重置密码（这是一个临时功能！）
                            </Typography>
                            <Typography id="modal-modal-description" fontSize="15px" sx={{mb: 2}}>
                                你暂时不能自定新密码，系统将会随机一个极其安全的新密码发送到你的邮箱。如果失败就多试几次。
                            </Typography>
                            <FormControl fullWidth>

                                <TextField
                                    id="email"
                                    label="邮箱"
                                    value={formikResetPW.values.email}
                                    onChange={formikResetPW.handleChange}
                                    error={formikResetPW.touched.email && Boolean(formikResetPW.errors.email)}
                                    helperText={formikResetPW.touched.email && formikResetPW.errors.email}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}

                                />

                            </FormControl>
                            <FormControl sx={{marginLeft: "20px"}}>
                                <HCaptcha sitekey={"fdffc360-6741-484e-bb76-80bc4f9b6371"} onVerify={(token) => {
                                    formikResetPW.setFieldValue('captcha', token)
                                }} ref={captchaRef}/>
                            </FormControl>
                            <Button variant="outlined"
                                    color="warning"
                                    type="submit"
                                    style={{
                                        fontSize: '20px',
                                        minHeight: "65px",
                                        width: "197px",
                                        borderColor: "#ed6c02",
                                    }}
                            > {submittingReset ? <CircularProgress color="primary"/> : "重置密码"}</Button>
                        </form>
                    </Box>
                </Modal>
            </div>
            <div className="right-container">
                <p className="texts">
                    ……丙申，月建临卯，严道君曾孙武阳令之少息孟广宗卒。┘……遂。广四岁失母，十二随官，受《韩诗》，兼通《孝经》二卷，博览┘……乃改名为琁，字孝琚，闵其敦仁，为问蜀郡何彦珍女，未娶┘……十月癸卯于茔西起攒，十ー月乙卯平下。怀抱之思，心┘……其辞曰：┘……结。四时不和，害气蕃溢。嗟命何辜，独遭斯疾。中夜奄丧，┘……茕，忽然远游。将即幽都，归于电丘。凉风渗淋，寒水北流。┘……期。痛哉仁人，积德若滋。孔子大圣，抱道不施，尚困于世，┘……渊，亦遇此灾。守善不振，自古有之。非独孝琚，遭逢百罹。┘……覆。恨不伸志，翻扬隆洽。身灭名存，美称修殇。勉崇素意，┘……皓，流惠后昆。四时祭祀，烟火连延。万岁不绝，勋于后人。┘……失雏，颜路哭回孔尼鱼。澹台忿怒投流河，世所不闵如┘……
                    <p>- <strong>孟孝琚碑</strong> 25 BC - "古汉碑第一"</p>
                </p>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={closeSnackBar}
                message="Note archived"
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
            >
                <Alert sx={{fontSize: "15px"}} variant="filled"
                       severity={isSuccess ? "success" : "error"}>{changePWResponse}</Alert>
            </Snackbar>
        </div>
    )
}

import "./transit.scss"
import {Select, MenuItem, Button, TextField, Input, Avatar, Checkbox, Radio} from "@mui/material";
import {LoadingButton} from '@mui/lab';
import * as React from "react";
import {Close} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {StorageBullet} from "../../components/BuildingBulletRender";
import {TierToStars} from "../../../../components/mappingTable";
import {useEffect, useState} from "react";
import {api_host, api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchStorageNow} from "../storageSlice";
import {ifNumberFloor2, itemInfo} from "../../../../components/gadgets";
import {Item} from "../../../../components/IconImg";

export default function TransitModal(props: {
    close: () => void
    storageCheckBox: any[]
    storage: any[]
    // onWorkDone: (status: any, message: any) => void
}) {
    const dispatch = useDispatch()
    //拿此处建筑
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    //checkbox里面true的index的array
    const trueArr = props.storageCheckBox.reduce(
        (out, bool, index) => bool ? out.concat(index) : out,
        []
    )
    //storage里面选了的item的array
    let itemArr = [...trueArr]
    itemArr?.map((item: any, index: number) =>
        itemArr[index] = props.storage[item]
    )
    //新增优先boolean
    const [freshF, setFreshF] = useState(new Array(trueArr.length).fill(false))
    //运输的数量的array
    // const [quantity, setQuantity] = useState(trueArr?.map((item: any, index: number) => (Math.floor(itemArr[index].item_in_storage * 100) / 100)))
    const [quantity, setQuantity] = useState(trueArr?.map((item: any, index: number) => (itemArr[index].item_in_storage)))


    const city = useSelector((state: any) => state.cityStructure.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    const myBuildings = useSelector((state: any) => state.myBuildings.data)

    //我藏身处的建筑信息
    const [myHideoutInfo, setMyHideoutInfo] = useState({
        id: -1,
        owner: {
            uid: 4,
            nick_name: 'name',
            avatar: '',
            born_city: 0
        },
        owner_id: 4,
        manager: {
            uid: 4,
            nick_name: 'name',
            avatar: '',
            born_city: 0
        },
        manager_id: 4,
        name: 'myHideout',
        avatar: '',
        type: 5,
        tier: 1,
        storage_capacity: 0,
        max_storage_capacity: 100,
        belong_city: 0,
        belong_districts: 0,
        belong_terrain: 0,
        culture: 0,
        stage: 2,
        percent: 100,
        product_type: 0,
        architect: 4
    })
    //目标建筑
    const [targetB, setTargetB] = React.useState(myHideOutStorage.id);
    const [targetBInfo, setTargetBInfo] = useState(myHideoutInfo);

    const handleChangeTargetB = (event: any) => {
        setTargetB(event.target.value);
        if (event.target.value === myHideOutStorage.id) {
            setTargetBInfo(myHideoutInfo)
        } else {
            setTargetBInfo(myBuildings.buildings.find((e: any) => e.id === event.target.value))
        }
    };
    //更新库存内容
    // const fetchStorageNow = useSelector((state: any) => state.storage.fetchNow)

    //更新藏身处信息.迟早要放在app。基本上都放在app（独立出来）
    useEffect(() => {
            api_with_auth("/api/building/" + myHideOutStorage.id, "GET")
                .then(res => {
                    if (!(res === undefined) && !(res === null)) {
                        setMyHideoutInfo(res)
                    } else {
                    }
                })
        }
        , [myHideOutStorage.id])

    const transitJson = {
        "from": buildingSummary.id,
        "to": targetB,
        "items": trueArr.map((ind: number, index: number) => {
            return {
                "type":
                itemArr[index]?.item_type,
                "tier":
                itemArr[index]?.item_tire,
                "quantity":
                    quantity[index],
                "fresh_first":
                    freshF[index]
            }
        })
    }
    //发起运输
    const [transiting, setTransiting] = useState(false)
    const Transit = () => {
        setTransiting(true)
        api_with_auth("/api/building/item_transit", "POST", transitJson,)
            .then(res => {
                    setTransiting(false)
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "运输了选择的物品。",
                        }))
                        dispatch(fetchStorageNow())
                        props.close()
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"TransitModal"}>
        <div className={"title-line"}>
            <Close className={"close"}/>
            <div className={"r"}>
                <div className={"title"}>运输</div>
                <div className={"sub-title"}>在你拥有的同城建筑之间运输物资。</div>
            </div>
        </div>

        <div className={""}>出发建筑（此处）：</div>
        <StorageBullet building={buildingSummary}/>
        <div className={"building-line"}>
            {/*<TransactionListBullet />*/}
        </div>
        <div className={""}>运输物品：</div>
        {trueArr.map((ind: any, index: number) =>
                <div className={"list-item-building list-item-building-storage"}
                     key={props.storage[ind]?.item_type + "1" + props.storage[ind]?.item_tire}>
                    <Item id={props.storage[ind]?.item_type} t={props.storage[ind]?.item_tire ?? 1} mr={0.5}/>
                    <div className={"two-story-text"}>
                        <div className={"up"}>
                            {itemInfo(props.storage[ind]?.item_type)?.Chs + TierToStars[props.storage[ind]?.item_tire]}
                        </div>
                        <div className={"down"}>
                            运输损耗 0%
                        </div>
                    </div>
                    <div className={"right margin-left-auto"}>
                        新增优先→
                    </div>
                    <Checkbox className={"radio"}
                              checked={freshF[index]}
                              onClick={() => {
                                  freshF[index] = !freshF[index]
                                  setFreshF([...freshF])
                              }}
                    />
                    <TextField type="number"
                               className={"quantity"} variant="outlined"
                               value={quantity[index]}
                               onChange={(e) => {
                                   setQuantity(
                                       quantity.map((number: any, index2: any) =>
                                           index2 === index
                                               ?
                                               +e.target.value
                                               : number
                                       ));
                               }}
                               id={ind.toString()} sx={{mr: "0.1vw"}}
                    />/
                    <div className={"two-story-text four-vw"}>
                        <div className={"up black"}>
                            {props.storage[ind]?.item_in_storage ? (ifNumberFloor2(props.storage[ind]?.item_in_storage)) : ""}
                        </div>
                        <div className={"down black"}>
                            {props.storage[ind]?.fresh_item_in_storage ? "含新增 " + (ifNumberFloor2(props.storage[ind]?.fresh_item_in_storage)) : ""}
                        </div>
                    </div>
                    <div className={"two-story-text four-vw"}>
                        <div className={"up orange"}>
                            {props.storage[ind]?.total_weight ? ifNumberFloor2(props.storage[ind]?.total_weight) : ""}
                        </div>
                        <div className={"down"}>
                            {"单重 " + itemInfo(props.storage[ind]?.item_type).weight}
                        </div>
                    </div>
                </div>
        )}
        <div className={""}>目标建筑：</div>
        <div className={"destination-building"}>
            <Select defaultValue={myHideOutStorage.id} value={targetB} onChange={handleChangeTargetB}>
                <MenuItem value={myHideOutStorage.id}>
                    <StorageBullet building={myHideoutInfo}/>
                </MenuItem>
                {myBuildings.buildings.map((building: any, index: any) =>
                    <MenuItem value={building.id} key={building.id}>
                        <StorageBullet building={building}/>
                    </MenuItem>
                )}
            </Select>
        </div>
        <div className={"buttons"}>
            <Button variant="outlined" className={"modify-order"}
                    style={{
                        marginTop: "0.25vw",
                        padding: 0,
                        maxWidth: "7vw",
                        minWidth: "6vw",
                        maxHeight: '1.8vw',
                        minHeight: '2vw',
                        fontSize: '0.8vw',
                        color: "#ed6c02",
                        borderColor: "#ed6c02"
                    }}
                    onClick={props.close}
            >
                取消
            </Button>
            <LoadingButton variant="outlined" className={"modify-order"}
                           loading={transiting}
                           style={{
                               marginTop: "0.25vw",
                               padding: 0,
                               maxWidth: "7vw",
                               minWidth: "6vw",
                               maxHeight: '1.8vw',
                               minHeight: '2vw',
                               fontSize: '0.8vw',
                               backgroundColor: "#ed6c02",
                               color: "white",
                               borderColor: "#ed6c02"
                           }}
                           onClick={Transit}
            >
                {transiting ? "" : "确认"}
            </LoadingButton>
        </div>
    </div>
}
import "./AgentInfo.scss"
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {RecruitBulletP} from "../../../../components/ListItemBuilding";
import {
    Button,
    Checkbox,
    FormControl, FormControlLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";
import {BPItems, ifNumberFloor2} from "../../../../components/gadgets";
import * as React from "react";

export default function AgentInfo(props: {}) {
    const dispatch = useDispatch();
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const agent = buildingSummary.agent
    // const buildingSummary = props.building
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    // const city = props.city

    const [open, setOpen] = useState(agent?.open)
    const [disabled, setDisabled] = useState(false)
    const BId = useParams().buildingId

    const handleToggle = () => {
        Toggle(!open)
        setOpen(!open)
    }
    //税
    const [tax, setTax] = useState(agent?.tax * 100)
    //仅限同街区
    const [sameDistrict, setSameDistrict] = useState<boolean>(agent?.same_district_only)


    //信息加载后同步当前招聘信息
    useEffect(() => {
        setTax(agent?.tax * 100)
        setOpen(agent?.open)
    }, [buildingSummary])

    const Toggle = (open: boolean) => {
        setDisabled(true)
        api_with_auth("/api/agent/" + BId + "/switch_open", "POST", {
            "open": open
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": (open ? "贸易站开始营业了。" : "贸易站不再营业。") + (res.message ? res.message : ""),
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                    setOpen(!open)
                }
                dispatch(fetchBuildingSummaryNow())
                setDisabled(false)
            },);
    }
    const Change = () => {
        api_with_auth("/api/agent/" + BId + "/settings", "POST", {
            "sameDistrictOnly": sameDistrict,
            "tax": tax / 100
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": ("修改了贸易站设置"),
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
                dispatch(fetchBuildingSummaryNow())
            },);

    }

    return <div className={"agent-info-root"}>
        {/*<div className={"heavy sub-text"}>当前生效的招聘信息：</div>*/}
        {/*<div className={"preview"}>*/}
        {/*    <RecruitBulletP/>*/}
        {/*</div>*/}
        <div className={"heavy sub-text"}>切换贸易站营业状态：</div>
        <div className={"flex"}>
            <Switch checked={open}
                    onChange={handleToggle}
                    disabled={disabled}
            />
            <div
                className={open ? "active" : ""}>{open ? "该贸易站正在营业" : "该贸易站未在营业"}</div>
        </div>
        <div className={"heavy sub-text"}>会计服务：</div>
        <div className={"sub-text"}>
            你需要雇佣会计来为货物寄售提供文书服务支持。 < br/>
            每有1单位物品寄售，本贸易站库房消耗1单位会计。请保证有足够的会计物品支持营业。
        </div>
        <div className={"heavy sub-text"}>抽税和其他设置：</div>
        <div className={"sub-text"}>
            抽税：你可以从卖家寄售的货物中抽出一部分收为己有。< br/>
            仅限同街区:只有相同街区的建筑发出的卖货订单可以通过此贸易站寄售。
        </div>
        <div className={"change"}>
            <TextField label="税率 " type="number"
                       className={"quantity"}
                       value={tax}
                       onChange={(e) => {
                           setTax(parseFloat(e.target.value));
                       }}
                       InputProps={{
                           endAdornment: <InputAdornment position="end">
                               %
                           </InputAdornment>,
                       }}
            />
            <Checkbox value={sameDistrict} onChange={() => setSameDistrict(!sameDistrict)}/>
            <div className={"same-district-only"}>仅限同街区</div>
            <Button variant="contained" className={"apply-job-btn"}
                    onClick={Change}>
                应用修改
            </Button>
        </div>
    </div>
}
import { configureStore } from "@reduxjs/toolkit";
import stateReducer from "./appStateSlice"
import statusReducer from "./appSlice"
import buildingSummaryReducer from "./Buildings/Building/buildingSlice"
import employeesReducer from "./Buildings/Building/Work/workSlice"
import snackbarReducer from "./appSnackbarSlice"
import cityStructureReducer from "./appCitytStructureSlice"
import myHideOutStorageReducer from "./myHideOutStorageSlice";
import myBuildingsReducer from "./myBuildingsSlice"
import myTransactionsReducer from  "./Transaction/myTransactionsSlice"
import storageReReducer from "./Buildings/Building/Storage/storageSlice"
import employeeSkillsReducer from "./Buildings/Building/Work/employeeSkillsSlice"
import gameDayReducer from  "./components/Fetch/gameDaySlice"
import twoPreviousGameDaysReducer from  "./components/Fetch/twoPreviousGameDaysSlice"
import otherUserStatusReducer from "./components/Fetch/otherUserStatusSlice"
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const store =configureStore({
    reducer: {
        state: stateReducer,
        status: statusReducer,
        buildingSummary: buildingSummaryReducer,
        employees: employeesReducer,
        snackbar: snackbarReducer,
        cityStructure:cityStructureReducer,
        myHideOutStorage : myHideOutStorageReducer,
        myBuildings : myBuildingsReducer,
        myTransactions : myTransactionsReducer,
        storage : storageReReducer,
        employeeSkills :employeeSkillsReducer,
        gameDay : gameDayReducer,
        twoPreviousGameDays : twoPreviousGameDaysReducer,
        otherUserStatus : otherUserStatusReducer,
    }
});

export default store

//官网魔改版dispatch和selector，适用thunk
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// Use throughout your app instead of plain `useDispatch` and `useSelector`

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
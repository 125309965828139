import "./IconImg.scss"
import "./BuildingInList.scss"
import {getBPItems, getBPSkills} from "../../utils/storage";
import {Tooltip} from "@mui/material";
import blank from "./assets/blank.webp"
import error from "./assets/error.webp"
import {BPBuildingInfo, Floor1, foodMaterialInfo, fuelInfo, itemInfo, roman, skillInfo, spiceInfo} from "../gadgets";
import * as React from "react";
import {TierToStars} from "../mappingTable";
import * as queryString from "querystring";


//物品src
const itemSrc = (id: number) => {
    //public写法
    return "/img/items/" + itemInfo(id)?.Eng + ".webp"
}

//技能src
export const skillSrc = (id: number) => {
    //public写法
    return "/img/skills/" + skillInfo(id)?.Eng + ".webp"
}

//建筑src
const buildingSrc = (id: number, culture: string) => {
    //public写法
    return "/img/buildings/" + BPBuildingInfo(id)?.Eng + "_" + culture + ".webp"
}


export function Item(this: any, props: {
    id: number,
    //tier等级
    t: number,
    size?: number,
    ml?: number,
    mr?: number,
    title?: string,
    //如果指定英文名，覆盖id，用Eng拿物品图片
    //推荐同时用title覆盖描述文字，不然描述文字还是id的物品
    Eng?: string,
}) {
    const size = props.size ?? 2.5
    const engUrl = "/img/items/" + props.Eng + ".webp"
    const url = props.Eng ? engUrl : itemSrc(props.id)

    const nameAndStars = itemInfo(props.id)?.Chs + TierToStars[props.t]
    //注释文字
    const TooltipTitle = () => {
        return <div>
            {/*物品名字和星级的星星*/}
            <div>{nameAndStars}</div>
            {/*食材*/}
            {foodMaterialInfo(props.id, props.t) ?
                <div>
                    <br/>
                    <div>{nameAndStars}是一种食材。每单位{nameAndStars}提供属性恢复：</div>
                    <div>{foodMaterialInfo(props.id, props.t).energy}精力，{foodMaterialInfo(props.id, props.t).happiness}快乐，{foodMaterialInfo(props.id, props.t).health}健康，{foodMaterialInfo(props.id, props.t).hungry}饥饿。</div>
                    <div>食材奢侈度：{foodMaterialInfo(props.id, props.t).luxury}。</div>
                </div>
                : null}
            {/*调料*/}
            {spiceInfo(props.id, props.t) ?
                <div><br/>{nameAndStars}是一种调味料。{nameAndStars}为食谱的部分属性提供×{spiceInfo(props.id, props.t).multiply}加成。</div>
                : null}
            {/*燃料*/}
            {fuelInfo(props.id, props.t) ?
                <div><br/>{nameAndStars}是一种燃料。每单位燃烧的{nameAndStars}提供{fuelInfo(props.id, props.t).fuel}燃料值。</div>
                : null}

        </div>
    }

    return <Tooltip title={props.title ? props.title : <TooltipTitle/>} arrow
                    disableInteractive>
        <div className={"icon-image-root item-image-root"} style={{
            marginRight: ((props.mr ?? 0) + "vw"),
            marginLeft: ((props.ml ?? 0) + "vw"),
            width: (size + "vw"),
            height: (size + "vw"),
        }}>
            <img style={{
                width: (size + "vw"),
                height: (size + "vw"),
            }}
                 className={"icon-image icon-image-item"}
                 src={process.env.PUBLIC_URL + url}
                 onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                     event.currentTarget.src = (process.env.PUBLIC_URL + "/img/items/blank.webp");
                     event.currentTarget.onerror = null;
                 }}
                 alt={props.title ? props.title : itemInfo(props.id)?.Chs}/>
            <div className={"stars"}>
                {!props.t ? null : Array(Math.max(props.t - 1, 0))?.fill("").map((star: any, index) =>
                    <div className={"star"} style={{
                        width: (size * 0.18 + "vw"),
                        fontSize: (size * 0.4 + "vw"),
                        WebkitTextStrokeWidth: (size * 0.02 + "vw")
                    }}
                         key={star + " " + index}
                    >★</div>
                )}
                <div className={""}></div>
            </div>
        </div>
    </Tooltip>
}

export function Skill(this: any, props: {
    id: number,
    //技能没有tier等级
    size?: number,
    mr?: number,
    //覆盖tooltip的描述文字
    title?: string,
    //如果指定英文名，覆盖id，用Eng拿物品图片
    //推荐同时用title覆盖描述文字，不然描述文字还是id的物品
    Eng?: string,
    //玩家技能进度的{}
    playerProgress?: {
        breakthrough: number,
        level: number,
        saved_level: number,
        type: number,
    },
    overrideBgColor?: string,
}) {
    const size = props.size ?? 3.2
    const engUrl = "/img/skills/" + props.Eng + ".webp"
    const url = props.Eng ? engUrl : itemSrc(props.id)

    //进度条从左下方开始的角度。另一边的结束角度是对称的。角度0从上方算起。范围180-360
    const startDeg = 220
    // 一周的实际角度。startDeg=180时候为360
    const circleDeg = 720 - 2 * startDeg
    //技能卡突破中
    const breaking = (props.playerProgress?.breakthrough ?? 14) - (props.playerProgress?.level ?? 0) < 0
    //进度，0-100
    const progress = () => {
        if (breaking) {
            return 100
        } else {
            return ((props.playerProgress?.level ?? 0) - (props.playerProgress?.breakthrough ?? 14) + 14) / 14 * 100
        }

    }
    //进度条颜色。卡突破时候变化
    const progressColor = (breaking) ? `#bd1f1f` : `#9c7732`
    //第一个壳子不走，内容走一半。第二个壳子走一半，内容走一半。起始角度微调在最外围div不在这
    const clipContainer = `rect(0, ${size}vw,${size}vw, ${0.5 * size}vw)`
    const clipContent = `rect(0, ${0.5 * size}vw,${size}vw, 0)`
    //走一半的角度
    const transform = `rotate(${(progress() / 200 * circleDeg)}deg)`


    return <Tooltip title={props.title ? props.title : skillInfo(props.id)?.Chs} arrow>
        <div className={"icon-image-root skill-image-root"} style={{
            marginRight: ((props.mr ?? 0) + "vw"),
            width: (size + "vw"),
            height: (size + "vw"),
        }}>
            <div className={"progress-container"}
                 style={{
                     transform: `rotate(${startDeg}deg)`,
                     backgroundColor: props.overrideBgColor ?? "white"
                 }}>
                <div className={"half-container"} style={{clip: clipContainer}}>
                    <div className={"half-progress"}
                         style={{clip: clipContent, transform: transform, backgroundColor: progressColor}}/>
                </div>
                <div className={"half-container"} style={{clip: clipContainer, transform: transform}}>
                    <div className={"half-progress"}
                         style={{clip: clipContent, transform: transform, backgroundColor: progressColor}}/>
                </div>
                <div className={"inner-circle"}/>
            </div>
            <img style={{
                width: (size + "vw"),
                height: (size + "vw"),
            }}
                 className={"icon-image icon-image-skill"}
                 src={process.env.PUBLIC_URL + skillSrc(props.id)}
                 onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                     event.currentTarget.src = (process.env.PUBLIC_URL + "/img/skills/blank.webp");
                     event.currentTarget.onerror = null;
                 }}
                 alt={props.title ? props.title : skillInfo(props.id)?.Chs}/>
            <div className={"icon-image-text"}
                 // style={{backgroundColor: props.overrideBgColor ?? "white"}}
            >{roman((props.playerProgress?.breakthrough ?? 14) / 14)}-{Floor1(props.playerProgress?.level ?? 0)}</div>
        </div>
    </Tooltip>
}


export function BuildingInList(this: any, props: {
    id: number,
    t?: number,
    width?: number,
    height?: number,
    mr?: number,
    ml?: number,
    // fadeLeft?: boolean,
}) {
    const width = props.width ?? 7
    const height = props.height ?? 2.5
    // const fadeLeft = props.fadeLeft
    return <div className={"building-in-list-root"}
                style={{
                    marginLeft: (props.ml ?? 0) + "vw",
                    marginRight: (props.mr ?? 0) + "vw",
                }}
    >
        <div className={"img-container"}
             style={{
                 width: (width + "vw"),
                 height: (height + "vw"),
                 maxHeight: (height + "vw"),
             }}>
            <img
                className={"icon-image icon-image-building"}
                // style={{
                //     maskImage: "linear-gradient(to right, transparent 10%, black 35%)"
                // }}
                src={process.env.PUBLIC_URL + buildingSrc(props.id, "east")}
                onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    event.currentTarget.src = (process.env.PUBLIC_URL + "/img/buildings/blank.webp");
                    event.currentTarget.onerror = null;
                }}
                alt={BPBuildingInfo(props.id)?.Chs}/>
        </div>
        <div className={"stars"}>
            {!props.t ? null : Array(Math.max(props.t - 1, 0))?.fill("").map((star: any, index) =>
                // {Array(Math.max(3))?.fill("").map((star: any, index) =>
                <div className={"star"} style={{
                    width: (height * 0.27 + "vw"),
                    fontSize: (height * 0.63 + "vw"),
                    WebkitTextStrokeWidth: (height * 0.03 + "vw")
                }}
                     key={star + " " + index}
                >★</div>
            )}
        </div>
    </div>


}
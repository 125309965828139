import "./Combine.scss"
import "../../../../components/GenericModalStyle/GenericModalStyle.scss"
import {useSelector} from "react-redux";
import {useState} from "react";
import {BuildingSpecialType, TierToStars} from "../../../../components/mappingTable";
import {BPBuildings, itemInfo} from "../../../../components/gadgets";
import {SPVSCheckMark} from "../component/CheckMark";
import {Backdrop, Button, Divider, Fade, Modal} from "@mui/material";
import {Item} from "../../../../components/IconImg";
import {StatusBar2x2} from "../../../../components/statusBar";
import * as React from "react";


export default function CombineBuildings(props: {}) {
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const state = useSelector((state: any) => state.state.data)
    const [open, setOpen] = useState(false)
    const ImOwner = buildingSummary.owner.uid === state.uid

    //建筑为正常运作阶段
    const BFunctioning = buildingSummary.stage === 2
    //建筑不是合并后的
    const noMultiplied = (!buildingSummary.multiply) || buildingSummary.multiply === 1
    //建筑没有工人
    const noWorkers = !buildingSummary.worker_count
    //建筑没有房客
    const noGuests = BuildingSpecialType(buildingSummary) !== "housing" ||
        buildingSummary.residence?.guests?.length === 0 //满足的条件数量
    const fulfilledCount = [noMultiplied, noWorkers, noGuests].filter(Boolean).length
    //条件总数
    const fulfillNeeded = 5
    //全部满足
    const fulfilledAll = fulfilledCount === fulfillNeeded


    return <div className={"upgrade-building-root"}>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={BFunctioning ? "check" : "cross"}/>建筑处在正常运作阶段。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noWorkers ? "check" : "cross"}/>建筑没有工人。</div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noGuests ? "check" : "cross"}/>如果建筑是一座住房，没有房客。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>选择你拥有的另一座种类、位置完全相同的建筑开始合并。</div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>合并后的建筑拥有合并的工人槽位和库存容量。</div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>合并后的建筑享受少量产能加成。</div>

        <div className={"execute-btn-container"}>
            <Button variant={"contained"} color={"happiness"}
                    disabled={true}
                    // disabled={!ImOwner}
                    onClick={() => {
                        setOpen(true)
                    }}
            >
                {"暂不开放"}</Button>
                {/*{ImOwner ? "详情" : "仅限所有者"}</Button>*/}
        </div>
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={"generic-modal-root"}
        >
            <Fade in={open}>
                <div className={"modal-content-root"}>
                    <div className={"title-image title-image1"}/>
                    <div className={"title-image title-image2"}/>
                    <div className={"title"}>合并建筑</div>
                    <div className={"content-container"}>
                        <Divider variant={"middle"} flexItem>条件（{fulfilledCount}/{fulfillNeeded}）</Divider>
                        <div className={"conditions-container " + (fulfilledAll ? "yes" : "no")}>
                            <div className={"condition"}><SPVSCheckMark mr={0.5} variant={BFunctioning ? "check" : "cross"}/>建筑处在正常运作阶段。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noMultiplied ? "check" : "cross"}/>建筑不是一座合并后的建筑。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noWorkers ? "check" : "cross"}/>建筑没有工人。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noGuests ? "check" : "cross"}/>如果建筑是一座住房，没有房客。
                            </div>
                        </div>
                        <Divider variant={"middle"} flexItem>结果</Divider>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>两座建筑合二为一。</div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>合并的建筑拥有合并的工人槽位数量。</div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>合并的建筑拥有合并的库存容量。</div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>工人产能稍微上升。并联越多提升越大，最高30%。</div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>如果建筑是住房，租客槽位数量合并。</div>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>如果建筑是贸易站，可容纳订单上限合并。</div>
                        <Divider variant={"middle"} flexItem>消耗</Divider>

                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>消耗属性来开始建筑合并。</div>
                        <StatusBar2x2 changingValueEnergy={-9} changingValueHappiness={-1.5} changingValueHealth={-1.5}
                                      changingValueHungry={-0}/>
                        <Button variant={"contained"} color={"happiness"} disabled={!fulfilledAll}
                                onClick={() => {
                                }}
                        >
                            {fulfilledAll ? "合并建筑" : "条件未满足"}</Button>
                    </div>
                    <div className={""}></div>
                </div>
            </Fade>
        </Modal>
        <div className={""}></div>
    </div>
}
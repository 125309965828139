//时间戳转换距今时间
export default  function TimeToNow(time: any) {
// Create a new JavaScript Date object based on the timestamp
// multiplied by 1000 so that the argument is in milliseconds, not seconds.
    let date = Date.now() - time / 1000000;
    if (date >= 365 * 24 * 60 * 60 * 1000) {
        return Math.floor(date / (365 * 24 * 60 * 60 * 1000)) + "年"
    } else if (date >= 30 * 24 * 60 * 60 * 1000) {
        return Math.floor(date / (30 * 24 * 60 * 60 * 1000)) + "月"
    } else if (date >= 24 * 60 * 60 * 1000) {
        return Math.floor(date / (24 * 60 * 60 * 1000)) + "日"
    } else if (date >= 60 * 60 * 1000) {
        return Math.floor(date / (60 * 60 * 1000)) + "时"
    } else if (date >= 60 * 1000) {
        return Math.floor(date / (60 * 1000)) + "分"
    } else if (date >= 1000) {
        return Math.floor(date / (1000)) + "秒"
    } else {
        return "刚才"
    }
}
//时间戳转换时长
export  function TimeSlot(time: any) {
// Create a new JavaScript Date object based on the timestamp
// multiplied by 1000 so that the argument is in milliseconds, not seconds.
    let date =  time / 1000000;
    if (date >= 365 * 24 * 60 * 60 * 1000) {
        return Math.floor(date / (365 * 24 * 60 * 60 * 1000)) + "年"
    } else if (date >= 30 * 24 * 60 * 60 * 1000) {
        return Math.floor(date / (30 * 24 * 60 * 60 * 1000)) + "月"
    } else if (date >= 24 * 60 * 60 * 1000) {
        return Math.floor(date / (24 * 60 * 60 * 1000)) + "日"
    } else if (date >= 60 * 60 * 1000) {
        return Math.floor(date / (60 * 60 * 1000)) + "时"
    } else if (date >= 60 * 1000) {
        return Math.floor(date / (60 * 1000)) + "分"
    } else if (date >= 1000) {
        return Math.floor(date / (1000)) + "秒"
    } else {
        return "刚才"
    }
}


import { createSlice } from '@reduxjs/toolkit';
import {getLoginToken} from "./utils/storage";
import {api_host} from "./utils/api";

const slice = createSlice({
    name: 'state',
    initialState: {
        "data": {
            "uid": 0,
            "user": {
                "uid": 0,
                "email": null,
                "activated_at": null,
                "created_at": null,
                "updated_at": null
            },
            "nick_name": "",
            "avatar": ""
        },
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateStateWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchStateNow: (state) => {
            state.fetchNow += 1;
        },


    },
});
export const { updateStateWPayload, fetchStateNow } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

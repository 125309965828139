import "./interface.scss"
import {Avatar, Divider, IconButton, InputAdornment, TextField} from "@mui/material";
import {Floor1, Floor2, ifNumberFloor2, itemInfo} from "../../components/gadgets";
import {TierToStars} from "../../components/mappingTable";
import {LoadingButton} from "@mui/lab";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Clear, HourglassEmpty, NavigateBefore, NavigateNext, PlaceOutlined, Schedule,
} from "@mui/icons-material";
import ItemSelector from "../../components/ItemSelector";
import Filler from "../../components/Filler";
import {useEffect, useState} from "react";
import {api_with_auth} from "../../utils/api";
import {ProgressDots} from "../../components/ProgressDots";
import itemIcon from "./assets/item.webp"
import buildingIcon from "./assets/building.webp"
import {useNavigate, useSearchParams} from "react-router-dom";
import {openSnackbar} from "../../appSnackbarSlice";
import {fetchMyTransactionsNow} from "../myTransactionsSlice";
import {fetchStatusNow} from "../../appSlice";
import {BuildingInfoCard, BuildingSelector} from "../../components/BuildingSelectorAndDisplay";


//交易发起的主要信息面板
//浏览交易结构相似 共享css但是不在这里
export default function NewTransactionInterface(props: {}) {
    const dispatch = useDispatch();
    const state = useSelector((state: any) => state.state.data)
    const status = useSelector((state: any) => state.status.data)
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const myHideOutStorage = useSelector((state: any) => state.myHideOutStorage.data)
    let navigate = useNavigate();

    //交易默认值
    const transactionInfoDefault = {
        "id": 1,
        "status": 1,
        "city": 0,
        "initiate_time": 1652802087074262000,
        "expire_time": 1652888487074262000,
        "text_message": "",
        "initiator": {
            "id": 4,
            "name": "加载中",
            "export_building": 6,
            "import_building": 6,
            "fresh_only": false,
            "pay": {
                "items": [
                    {
                        "type": 5,
                        "tier": 1,
                        "quantity": 0
                    }
                ],
                "buildings": [
                    71,
                    75
                ]
            }
        },
        "recipient": {
            "id": 11,
            "name": "1234",
            "export_building": 0,
            "import_building": 0,
            "fresh_only": false,
            "pay": {
                "items": [
                    {
                        "type": 5,
                        "tier": 1,
                        "quantity": 0
                    }
                ],
                "buildings": []
            }
        },
        //交易中涉及的所有建筑
        "buildings": {
            "-2": {
                "id": 6,
                "owner": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "owner_id": 4,
                "manager": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "manager_id": 4,
                "name": "加载中",
                "avatar": "",
                "type": 5,
                "tier": 1,
                "storage_capacity": 451,
                "max_storage_capacity": 100000,
                "belong_city": 0,
                "belong_districts": 1,
                "belong_terrain": 1,
                "culture": 0,
                "stage": 2,
                "percent": 100,
                "product_type": 0,
                "architect": 4
            },
            "-1": {
                "id": 72,
                "owner": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "owner_id": 4,
                "manager": {
                    "uid": 4,
                    "nick_name": "加载中",
                    "avatar": "",
                    "born_city": 0
                },
                "manager_id": 4,
                "name": "加载中",
                "avatar": "",
                "type": 3,
                "tier": 2,
                "max_work_count": 1,
                "is_hiring": true,
                "salary_number": 0.4,
                "salary_type": 5,
                "salary_tier": 1,
                "storage_capacity": 0,
                "max_storage_capacity": 400,
                "belong_city": 0,
                "belong_districts": 3,
                "belong_terrain": 3,
                "culture": 0,
                "stage": 0,
                "percent": 0,
                "product_type": 3,
                "architect": 0
            }
        }
    }

    //url query
    const [searchParams, setSearchParams] = useSearchParams();
    //接收者id
    const receiverId = parseInt(searchParams.get('recipient') || "")
    //推荐接收者出货库房
    const recommendStorage = parseInt(searchParams.get('recommendStorage') || "")

    //获取接受方信息，显示用
    const [receiverInfo, setReceiverInfo] = useState({
        //接受方id是url里拿的receiverId
        //接受方昵称头像
        "nick_name": "加载中",
        "avatar": "",
    })
    useEffect(() => {
        api_with_auth("/api/user/" + receiverId + "/status", "GET")
            .then(res => {
                if (res.status === "success") {
                    setReceiverInfo({
                        "nick_name": res.data.nick_name,
                        "avatar": res.data.avatar,
                    })
                }
            })
    }, [receiverId])

    //交易默认占位物品
    const defaultItem = {"type": 5, "tier": 1, "quantity": 0}
    //交易默认占位建筑
    const defaultBuilding = {
        "id": -1,
        "owner": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "owner_id": -1,
        "manager": {
            "uid": -1,
            "nick_name": "-",
            "avatar": "",
            "born_city": 0
        },
        "manager_id": -1,
        "name": "未选择建筑",
        "avatar": "",
        "type": 5,
        "tier": 1,
        "storage_capacity": 0,
        "max_storage_capacity": 1,
        "remarks": "",
        "multiply": 1,
        "belong_districts": 0,
        "belong_city": 0
    }
    //新建交易提交的json，还未标准化
    const [submitNewJson, setSubmitNewJson] = useState({
        "city": 0,
        "duration": 7,
        "text_message": "",
        "initiator": {
            "export_building": -1,
            "import_building": -1,
            "fresh_only": false,
            "pay": {
                "items": [{...defaultItem}],
                "buildings": [-1],
            }
        },
        "recipient": {
            "id": receiverId,
            //推荐接受方出货库房，0是不传的时候的数字 测试
            "recommended_export_building": recommendStorage || 0,
            "fresh_only": false,
            "pay": {
                "items": [{...defaultItem}],
                "buildings": [-1],
            }
        }
    })
    //标准化的最终提交json，去除非法物品建筑，交易的建筑信息变成只剩id
    const submitNewJsonStandardized = () => {
        let n = {...submitNewJson}
        //仅保留数量大于0的交易物品
        n.initiator.pay.items = n.initiator.pay.items.filter((i: any) => i.quantity > 0)
        n.recipient.pay.items = n.recipient.pay.items.filter((i: any) => i.quantity > 0)
        //保留Id不为-1的交易建筑
        n.initiator.pay.buildings = n.initiator.pay.buildings.filter((i: any) => i !== -1)
        n.recipient.pay.buildings = n.recipient.pay.buildings.filter((i: any) => i !== -1)
        return n
    }

    //拿我的藏身处，如果还没选库房，自动设定选择的库房为藏身处
    useEffect(() => {
        let n = {...submitNewJson}
        if (n.initiator.export_building === -1 && myHideOutStorage.id !== -1) {
            n.initiator.export_building = myHideOutStorage.id
        }
        if (n.initiator.import_building === -1 && myHideOutStorage.id !== -1) {
            n.initiator.import_building = myHideOutStorage.id
        }
        setSubmitNewJson(n)
    }, [myHideOutStorage.id])


    //每当我的出货库房改变，拿我的出货库房库存
    const [myExportStorageItems, setMyExportStorageItems] = useState([{
        "id": 2442,
        "item_type": 33,
        "item_tire": 0,
        "item_in_storage": 0,
        "total_weight": 0
    }])
    useEffect(() => {
        if (submitNewJson.initiator.export_building !== -1) {
            api_with_auth("/api/building/" + submitNewJson.initiator.export_building + "/storage", "GET")
                .then(res => {
                    if (res.status === "success") {
                        setMyExportStorageItems(res.data)
                    }
                })
        }
    }, [submitNewJson.initiator.export_building])
    //库存中找物品剩余数量
    const itemAvailableQuantity = (type: number, tier: number) => {
        const n = myExportStorageItems.find((i: any) => i.item_type === type && i.item_tire === tier)?.item_in_storage
        console.log(myExportStorageItems)
        console.log(n)
        return ifNumberFloor2(n) || 0
    }

    //提交新建交易
    const submitNew = () => {
        api_with_auth("/api/transaction/create", "POST", submitNewJsonStandardized())
            .then(res => {
                dispatch(openSnackbar({
                    "severity": (res.status === "success" ? "success" : "error"),
                    "snackbarMessage": (res.status === "success" ? "成功发出交易请求" : res.message),
                }))
                if (res.status === "success") {
                    //成功时重新拿表，拿我的status
                    dispatch(fetchMyTransactionsNow())
                    dispatch(fetchStatusNow())
                    navigate(`/game/transaction/`)
                }
            });
    }

    //支付模式和索取模式。"物品"或"建筑"
    const [mode, setMode] = useState(["items", "items"])
    const ModeSelector = (mode2: "give" | "take") => {
        //切换模式，重置草稿
        const toggleMode = () => {
            let n = {...submitNewJson}
            let newArr = [...mode]
            if (newArr[mode2 === "give" ? 0 : 1] === "items") {
                newArr[mode2 === "give" ? 0 : 1] = "buildings"
                n[mode2 === "give" ? "initiator" : "recipient"].pay.items = [defaultItem]
            } else {
                newArr[mode2 === "give" ? 0 : 1] = "items"
                n[mode2 === "give" ? "initiator" : "recipient"].pay.buildings = [-1]
            }
            setMode([...newArr])
            dispatch(openSnackbar({
                "severity": "info",
                "snackbarMessage": "模式切换，草稿已删除",
            }))
        }

        // 货物/建筑
        return <div className="mode-selector-container">
            <div className="mode-selector">
                <IconButton
                    onClick={toggleMode}>
                    <NavigateBefore/>
                </IconButton>
                <div className="img" style={{
                    backgroundImage: `linear-gradient(to right, rgba(255, 255, 225, 0) 45%, rgba(255, 255, 255, 1) 75%),
                    url(${(mode[mode2 === "give" ? 0 : 1] === "items") ? itemIcon : buildingIcon})`
                }}/>
                <div className="mode">{mode2 === "give" ? "支付" : "索取"}{{
                    "items": "货物",
                    "buildings": "建筑"
                }[mode[mode2 === "give" ? 0 : 1]]}</div>
                <IconButton
                    onClick={toggleMode}>
                    <NavigateNext/>
                </IconButton>
            </div>
            <ProgressDots totalSteps={2} currentStep={(mode[mode2 === "give" ? 0 : 1] === "items") ? 0 : 1}/>
        </div>
    }


    return <div className={"transaction-interface-root"}>
        <div className={"trade-title"}>
            <div className={"title"}>
                新建私人交易
            </div>
            <div className={"type"}>
                {/*拟撰中……*/}
            </div>
        </div>
        <div className="headers">
            <PlaceOutlined/>
            <div>长安</div>
            <Schedule/>
            <div>现在</div>
            <HourglassEmpty/>
            <div>持续7日</div>
        </div>
        <div className="message-container">
            <div className="quote">“</div>
            <TextField
                sx={{
                    "& > :not(style)": {
                        m: "0.5vw",
                        width: "28vw",
                        fontSize: '0.88vw',
                        lineHeight: "1.8em"
                    },
                }}
                placeholder="随附留言（可选）" variant="standard" InputProps={{disableUnderline: true}}
                multiline value={submitNewJson.text_message}
                onChange={(event) => {
                    setSubmitNewJson(
                        {
                            ...submitNewJson,
                            "text_message": event.currentTarget.value,
                        }
                    )
                }}/>
            <div className="quote end">”</div>
        </div>
        <Divider>
            <Avatar alt={state.nick_name}
                    src={state.avatar}
                    sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
            >{state.nick_name?.[0]}</Avatar>
            <div className={"two-story-text left-align"}>
                <div className="up">{state.nick_name}</div>
                <div className="down">发起方（我）</div>
            </div>
        </Divider>
        {/*主要交易面板，中间那两列*/}
        <div className={"txn-panels"}>
            <div className="l side">
                {ModeSelector("give")}
                {(mode[0] === "items") ?
                    <div className="trade-items">
                        <div className="drop-shadow with-items">
                            <div className="item-storage">
                                <BuildingSelector defaultId={myHideOutStorage.id} pickBuilding={(building) => {
                                    setSubmitNewJson({
                                        ...submitNewJson,
                                        "initiator": {
                                            ...submitNewJson.initiator,
                                            "export_building": building.id
                                        }
                                    })
                                }} title={"选择你的发货库房"}/>
                                {/*外层放背景*/}
                                <div className="trade-items-container">
                                    <div className="trade-items-container-2">
                                        {/*单个物品*/}
                                        {submitNewJson.initiator.pay.items?.map((item: any, index: any) =>
                                            <div className="trade-item">
                                                <IconButton
                                                    onClick={() => {
                                                        let n = {...submitNewJson}
                                                        n.initiator.pay.items.splice(index, 1)
                                                        setSubmitNewJson(n)
                                                    }} className="clear" aria-label="clear"
                                                >
                                                    <Clear/>
                                                </IconButton>
                                                <ItemSelector pickItem={(id, tier) => {
                                                    let n = {...submitNewJson}
                                                    n.initiator.pay.items[index] = {
                                                        "type": id,
                                                        "tier": tier,
                                                        "quantity": 0
                                                    }
                                                    setSubmitNewJson(n)
                                                }}
                                                              mr={0.3} default={[item.type, item.tier]}/>
                                                <div className={"two-story-text left-align"}>
                                                    <TextField size="small" value={item.quantity}
                                                               onChange={(event) => {
                                                                   let n = {...submitNewJson}
                                                                   n.initiator.pay.items[index].quantity = parseFloat(event.currentTarget.value)
                                                                   setSubmitNewJson(n)
                                                               }}
                                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                               className={"up"} type={"number"} disabled={false}
                                                               InputProps={{
                                                                   endAdornment: <InputAdornment
                                                                       position="end">/{itemAvailableQuantity(
                                                                       submitNewJson.initiator.pay.items[index].type,
                                                                       submitNewJson.initiator.pay.items[index].tier,
                                                                   )}</InputAdornment>
                                                               }}
                                                    />
                                                    <div className={"down"}>
                                                        {(itemInfo(item.type)?.Chs ?? "<-选择货物") + TierToStars[item.tier]}</div>
                                                </div>
                                            </div>
                                        )}


                                        <div className={"button8 orange-button"} onClick={() => {
                                            let n = {...submitNewJson}
                                            n.initiator.pay.items = [...n.initiator.pay.items, defaultItem]
                                            setSubmitNewJson(n)
                                        }}>+货物
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className={"big-arrow"}/>
                        </div>
                        <div className="drop-shadow">
                            <div className="item-storage">
                                <BuildingInfoCard title={"接受方收货库房"}/>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="trade-buildings">
                        <div className="buildings-packs">
                            {/*外层放背景*/}
                            <div className="drop-shadow">

                                <div className="trade-buildings-container">
                                    <div className="trade-buildings-container-2">
                                        {/*支付的建筑*/}
                                        {submitNewJson.initiator.pay.buildings?.map((building: any, index: any) =>
                                            <div className="trade-building">
                                                <IconButton
                                                    onClick={() => {
                                                        let n = {...submitNewJson}
                                                        n.initiator.pay.buildings.splice(index, 1)
                                                        setSubmitNewJson(n)
                                                    }} className="clear" aria-label="clear"
                                                >
                                                    <Clear/>
                                                </IconButton>
                                                <BuildingSelector pickBuilding={(building2) => {
                                                    let n = {...submitNewJson}
                                                    n.initiator.pay.buildings[index] = building2.id
                                                    setSubmitNewJson(n)
                                                }} title={"选择支付的建筑"}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={"button8 orange-button"} onClick={() => {
                                let n = {...submitNewJson}
                                n.initiator.pay.buildings = [...n.initiator.pay.buildings, -1]
                                setSubmitNewJson(n)
                            }}>+建筑
                            </div>
                        </div>
                        <div className="arrow-container flip">
                            <div className={"big-arrow rotate"}/>
                            <div className="square"/>
                        </div>
                    </div>

                }
            </div>
            {/*右边列，接受方付款部分*/}
            <div className="r side">
                {ModeSelector("take")}
                {(mode[1] === "items") ?
                    <div className="trade-items">
                        <div className="drop-shadow">
                            <div className="item-storage">
                                <BuildingSelector defaultId={myHideOutStorage.id} pickBuilding={(building) => {
                                    setSubmitNewJson({
                                        ...submitNewJson,
                                        "initiator": {
                                            ...submitNewJson.initiator,
                                            "import_building": building.id
                                        }
                                    })
                                }} title={"选择你的收货库房"}/>
                            </div>
                        </div>
                        <div className="drop-shadow with-items">
                            <div className={"big-arrow rotate"}/>
                            <div className="item-storage">
                                {/*外层放背景*/}
                                <div className="trade-items-container">
                                    <div className="trade-items-container-2">
                                        {/*索取货物*/}
                                        {submitNewJson.recipient.pay.items?.map((item: any, index: any) =>
                                            <div className="trade-item">
                                                <IconButton
                                                    onClick={() => {
                                                        let n = {...submitNewJson}
                                                        n.recipient.pay.items.splice(index, 1)
                                                        setSubmitNewJson(n)
                                                    }} className="clear" aria-label="clear"
                                                >
                                                    <Clear/>
                                                </IconButton>
                                                <ItemSelector pickItem={(id, tier) => {
                                                    let n = {...submitNewJson}
                                                    n.recipient.pay.items[index] = {
                                                        "type": id,
                                                        "tier": tier,
                                                        "quantity": 0
                                                    }
                                                    setSubmitNewJson(n)
                                                }}
                                                              mr={0.3} default={[item.type, item.tier]}/>
                                                <div className={"two-story-text left-align"}>
                                                    <TextField size="small" value={item.quantity}
                                                               onChange={(event) => {
                                                                   let n = {...submitNewJson}
                                                                   n.recipient.pay.items[index].quantity = parseFloat(event.currentTarget.value)
                                                                   setSubmitNewJson(n)
                                                               }}
                                                               onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                                               className={"up"} type={"number"} disabled={false}
                                                    />
                                                    <div className={"down"}>
                                                        {(itemInfo(item.type)?.Chs ?? "<-选择货物") + TierToStars[item.tier]}</div>
                                                </div>
                                            </div>
                                        )}
                                        <div className={"button8 orange-button"} onClick={() => {
                                            let n = {...submitNewJson}
                                            n.recipient.pay.items = [...n.recipient.pay.items, defaultItem]
                                            setSubmitNewJson(n)
                                        }}>+货物
                                        </div>

                                    </div>
                                </div>
                                <BuildingInfoCard title={"接受方发货库房"}/>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="trade-buildings">
                        <div className="arrow-container">
                            <div className={"big-arrow rotate"}/>
                            <div className="square"/>
                        </div>
                        <div className="buildings-packs">

                            {/*外层放背景*/}
                            <div className="drop-shadow">

                                <div className="trade-buildings-container">
                                    <div className="trade-buildings-container-2">

                                        {/*索取的建筑*/}
                                        {submitNewJson.recipient.pay.buildings?.map((building: any, index: any) =>
                                            <div className="trade-building">
                                                <IconButton
                                                    onClick={() => {
                                                        let n = {...submitNewJson}
                                                        n.recipient.pay.buildings.splice(index, 1)
                                                        setSubmitNewJson(n)
                                                    }} className="clear" aria-label="clear"
                                                >
                                                    <Clear/>
                                                </IconButton>
                                                <BuildingSelector pickBuilding={(building2) => {
                                                    let n = {...submitNewJson}
                                                    n.recipient.pay.buildings[index] = building2.id
                                                    setSubmitNewJson(n)
                                                }} poolOwnedByPlayerId={receiverId}
                                                                  title={"选择索取的建筑"}/>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className={"button8 orange-button"} onClick={() => {
                                let n = {...submitNewJson}
                                n.recipient.pay.buildings = [...n.recipient.pay.buildings, -1]
                                setSubmitNewJson(n)
                            }}>+建筑
                            </div>
                        </div>
                    </div>

                }
            </div>

        </div>
        <Divider>
            <Avatar alt={receiverInfo.nick_name}
                    src={receiverInfo.avatar}
                    sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
            >{receiverInfo.nick_name?.[0]}</Avatar>
            <div className={"two-story-text left-align"}>
                <div className="up">{receiverInfo.nick_name}</div>
                <div className="down">接受方</div>
            </div>
        </Divider>
        <div className={"buttons"}>
            <LoadingButton variant="outlined" className={"button red"}
                           disabled={submitNewJson.initiator.export_building === -1 ||
                               submitNewJson.initiator.import_building === -1}
                           onClick={submitNew}>
                {submitNewJson.initiator.export_building === -1 ||
                submitNewJson.initiator.import_building === -1 ? "选择库房" : "确认提交"}
            </LoadingButton>
        </div>
        <Filler/>

    </div>
}
import * as React from 'react'
import {api_host} from "./utils/api";
import {useRef, useState} from 'react'
import "./signup.scss"
import {Button, CircularProgress, createTheme, FormControl, MenuItem, TextField, ThemeProvider} from '@mui/material'
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {useFormik} from "formik";
import * as yup from "yup";
import {passwordEncrypt} from "./utils/PasswordEncrypt";
import {Link, useNavigate} from "react-router-dom";

export default function Signup() {
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    const validationSchema = yup.object({
        invitationCode: yup.string().required("你必须输入邀请码").matches(/^[A-Za-z0-9]{6}$/, "请保证你输入的邀请码正确，区分大小写"),
        email: yup.string().email("邮箱格式错误").required("你必须输入邮箱"),
        password: yup.string().matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\-_=+])[A-Za-z\d@$!%*#?&\-_=+]{8,24}$/, "请确保你输入的密码中长度为8-24位并至少含有一个字母和一个数字和一个特殊字符(@$!%*#?&-_=+)").required("你必须输入密码"),
        nickname: yup.string().max(12, "昵称长度为2-12位").min(2, "昵称长度为2-12位").required("你必须输入昵称"),
        initCity: yup.number().required("你必须选择城市"),
        captcha: yup.string().required("请完成人机验证"),
    })

    const navigate = useNavigate()
    const [signup_error, set_signup_error] = useState("")
    const captchaRef = useRef<HCaptcha>(null);

    const [submitting, setSubmitting] = useState(false)

    const formik = useFormik<{
        invitationCode: string,
        email: string,
        password: string,
        nickname: string,
        initCity: string,
        captcha: string
    }>({
        initialValues: {
            invitationCode: "045100",
            email: "",
            password: "",
            nickname: "",
            initCity: "2",
            captcha: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true)
            set_signup_error("")
            fetch(api_host() + "/register", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
                body: JSON.stringify({
                    nick_name: values.nickname,
                    email: values.email,
                    password: passwordEncrypt(values.password),
                    init_city: values.initCity,
                    h_captcha_token: values.captcha,
                }), // body data type must match "Content-Type" header
            })
                .then(res => res.json())
                .then(loginInfo => {
                    if (loginInfo.status === "success") {
                        navigate("/login")
                    } else {
                        if (captchaRef.current !== null) {
                            captchaRef.current.resetCaptcha()
                        }
                        set_signup_error(loginInfo.message)
                    }
                    setSubmitting(false)
                });
            // api_without_auth("POST", "/login", JSON.stringify(values, null, 2)).then((response) => {
            // })
        },
    })

    return (
        <div className="p-signup page">
            <div className="left-container">
                <div className="left">
                    <img src={require('./assets/login/login2.jpg').default} alt={""}/>
                </div>
            </div>
            <div className="signup-container">
                <div className="logo">
                    {/*<img className="logo-img" src={require('./assets/login/logo_cn.webp').default} alt={""}/>*/}
                    <iframe
                        src="https://store.steampowered.com/widget/1925820/?t=Civitas%20Rise%20%E6%98%AF%E4%B8%80%E6%AC%BE%E5%8F%A4%E5%85%B8%E7%A4%BE%E4%BC%9A%E6%A8%A1%E6%8B%9F%E6%B8%B8%E6%88%8F%E3%80%82%E6%9C%AC%E6%B8%B8%E6%88%8F%E4%B8%AD%EF%BC%8C%E6%95%B0%E5%8D%83%E5%90%8D%E7%8E%A9%E5%AE%B6%E5%85%B1%E5%90%8C%E5%BB%BA%E8%AE%BE%E4%B8%80%E5%BA%A7%E5%BA%A7%E8%91%97%E5%90%8D%E7%9A%84%E6%B1%89%E4%BB%A3%E6%97%B6%E6%9C%9F%E4%B8%9C%E8%A5%BF%E6%96%B9%E5%9F%8E%E5%B8%82%EF%BC%8C%E5%8F%91%E5%B1%95%E7%BB%8F%E6%B5%8E%E7%B3%BB%E7%BB%9F%EF%BC%8C%E5%88%B6%E5%AE%9A%E7%A4%BE%E4%BC%9A%E8%A7%84%E5%88%99%EF%BC%8C%E6%B2%89%E6%B7%80%E6%96%87%E5%8C%96%E8%AE%B0%E5%BF%86%E3%80%82%E7%8E%A9%E5%AE%B6%E4%BB%AC%E7%9A%84%E7%BE%A4%E4%BD%93%E5%86%B3%E7%AD%96%E4%BC%9A%E5%AF%B9%E5%9F%8E%E5%B8%82%E4%BA%A7%E7%94%9F%E4%B8%8D%E5%8F%AF%E9%80%86%E7%9A%84%E6%B7%B1%E8%BF%9C%E5%BD%B1%E5%93%8D%E3%80%82"
                        frameBorder="0" width="546" height="190"></iframe>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="signup-form">
                        <div className={"title"}>开发版demo试玩</div>
                        <ThemeProvider theme={darkTheme}>
                            {/*<FormControl fullWidth>*/}
                            {/*    <TextField*/}
                            {/*        size="small"*/}
                            {/*        id="invitationCode"*/}
                            {/*        label="邀请码"*/}
                            {/*        onChange={formik.handleChange}*/}
                            {/*        value={formik.values.invitationCode}*/}
                            {/*        error={formik.touched.invitationCode && Boolean(formik.errors.invitationCode)}*/}
                            {/*        helperText={formik.touched.invitationCode && formik.errors.invitationCode}*/}
                            {/*        sx={{*/}
                            {/*            '& .MuiInputLabel-root': {*/}
                            {/*                fontSize: '15px',*/}
                            {/*            },*/}
                            {/*            '& .MuiOutlinedInput-root': {*/}
                            {/*                fontSize: '15px',*/}
                            {/*            },*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</FormControl>*/}
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="email"
                                    label="邮箱"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="password"
                                    label="密码"
                                    type="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    id="nickname"
                                    label="昵称"
                                    onChange={formik.handleChange}
                                    value={formik.values.nickname}
                                    error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                                    helperText={formik.touched.nickname && formik.errors.nickname}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    size="small"
                                    select
                                    label="出生城市"
                                    id="initCity"
                                    onChange={formik.handleChange("initCity")}
                                    value={formik.touched.initCity}
                                    error={formik.touched.initCity && Boolean(formik.errors.initCity)}
                                    helperText={formik.touched.initCity && formik.errors.initCity}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            fontSize: '15px',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '15px',
                                        },
                                    }}
                                >
                                    {/*<MenuItem value={1}>蓬莱</MenuItem>*/}
                                    <MenuItem value={2}>长安</MenuItem>
                                </TextField>
                            </FormControl>
                            <div className="HCaptcha-texT">hCaptcha验证码</div>
                            <FormControl sx={{marginLeft: "20px"}} className="captcha">
                                <HCaptcha sitekey={"fdffc360-6741-484e-bb76-80bc4f9b6371"} ref={captchaRef}
                                          onVerify={(token) => {
                                              formik.setFieldValue('captcha', token)
                                          }}/>
                            </FormControl>
                        </ThemeProvider>

                    </div>
                    <div className="form-actions">
                        <div className="form-actions">
                            {/*<Button variant="text"*/}
                            {/*        color="warning"*/}
                            {/*        type="submit"*/}
                            {/*        style={{*/}
                            {/*            position: "absolute",*/}
                            {/*            zIndex: "3",*/}
                            {/*            minHeight: "65px",*/}
                            {/*            top: "0",*/}
                            {/*            left: "72px",*/}
                            {/*            width: "197px",*/}
                            {/*            color: "#ed6c02",*/}
                            {/*            borderColor: "#ed6c02",*/}
                            {/*        }}*/}
                            {/*    // onClick={(e) => handleClick(e, {submit})}*/}
                            {/*/>*/}
                            <button type="submit" className="submit-btn">{submitting ? <CircularProgress color="primary" />:"注册"}</button>
                            {/*<input className="submit-btn" type="submit" value={"注册"}/>*/}
                        </div>
                        <div className="login-signUp-jump">已经有一个账户？<Link className="login-signUp-jump" to={`/login`} >登录</Link></div>
                    </div>
                    {Boolean(signup_error) && <div className={"alert"}>{signup_error}</div>}
                </form>

            </div>

            <div className="right-container">
                <div className="texts">
                    <p>……丙申，月建临卯，严道君曾孙武阳令之少息孟广宗卒。┘……遂。广四岁失母，十二随官，受《韩诗》，兼通《孝经》二卷，博览┘……乃改名为琁，字孝琚，闵其敦仁，为问蜀郡何彦珍女，未娶┘……十月癸卯于茔西起攒，十ー月乙卯平下。怀抱之思，心┘……其辞曰：┘……结。四时不和，害气蕃溢。嗟命何辜，独遭斯疾。中夜奄丧，┘……茕，忽然远游。将即幽都，归于电丘。凉风渗淋，寒水北流。┘……期。痛哉仁人，积德若滋。孔子大圣，抱道不施，尚困于世，┘……渊，亦遇此灾。守善不振，自古有之。非独孝琚，遭逢百罹。┘……覆。恨不伸志，翻扬隆洽。身灭名存，美称修殇。勉崇素意，┘……皓，流惠后昆。四时祭祀，烟火连延。万岁不绝，勋于后人。┘……失雏，颜路哭回孔尼鱼。澹台忿怒投流河，世所不闵如┘……

                    </p>
                    <p>- <strong>孟孝琚碑</strong> 25 BC - "古汉碑第一"</p>
                </div>
            </div>
        </div>
    )
}

import "./BazaarBuildingSeller.scss"
import {useSelector} from "react-redux";
import {Avatar, Tooltip} from "@mui/material";
import {BPBuildingInfo, districtIdToName, itemInfo} from "../../../../components/gadgets";
import {buildingStageToChs, TierToStars} from "../../../../components/mappingTable";
import {shippingLossTip} from "../../../../components/ToolTips";
import {BuildingInList, Item} from "../../../../components/IconImg";
import * as React from "react";

export default function BazaarBuildingSeller(props: {
    sell: any
}) {
    const sell = props.sell
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    //卖建筑的基础条目结构
    return <div className={"list-item-building bazaar-building-seller"}>
        <Avatar alt={sell.selling.name} src={sell.selling.avatar}
                sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                variant="rounded"
        >{sell.selling.name?.[0]}</Avatar>

        <div className={"two-story-text"}>
            <div className={"up"}>
                {sell.selling.name}
            </div>
            <div className={"down"}>
                {BPBuildingInfo(sell.selling.type)?.name}{TierToStars[sell.selling.tier]}{" - "}
                {districtIdToName(sell.selling.belong_districts, cityStructure)}
                <Tooltip title={shippingLossTip} arrow
                         disableInteractive><u>🛈</u></Tooltip>）
            </div>
        </div>
        <div className={"two-story-text right four-vw margin-left-auto"}>
            <div className={"up"}>
                {sell.selling.percent}
            </div>
            <div className={"down"}>
                维护度
            </div>
        </div>
        <div className={"two-story-text right five-vw"}>
            <div className={"up"}>
                {buildingStageToChs[sell.selling.stage] || "运作中"}
            </div>
            <div className={"down"}>
                建筑阶段
            </div>
        </div>
        <BuildingInList id={sell.selling.type} t={sell.selling.tier}/>
        <div className={"two-story-text right three-vw"}>
            <div className={"up"}>
                &nbsp;
            </div>
            <div className={"down"}>
                交换
            </div>
        </div>
        <Item id={sell.buying.id} t={sell.buying.tier} ml={0.3} mr={0.3}/>
        <div className={"two-story-text four-vw"}>
            <div className={"up happiness"}>
                {(sell.buying.quantity).toFixed(2)}
            </div>
            <div className={"down"}>
                {itemInfo(sell.buying.id).Chs}{TierToStars[sell.buying.tier]}🛈
            </div>
        </div>
    </div>
}
import "./View.scss"
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {Button, ListItemButton, TextField} from "@mui/material";
import BazaarItemSeller from "../../../../Bazaar/BazaarItems/component/BazaarItemSeller";
import BazaarItemAgent from "../../../../Bazaar/BazaarItems/component/BaazaarItemAgent";
import * as React from "react";
import AgentItemAgent from "./AgentItemAgent";
import {api_with_auth} from "../../../../utils/api";
import Filler from "../../../../components/Filler";

export default function AgentItemsView(props: {}) {
    const {buildingId} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)


    const [bazaarItems, setBazaarItems] = useState<any>({
            "page": 0,
            "pageSize": 20,
            "totalPages": 20,
            "totalResults": 300,
            "content": [
                {
                    "id": 1,
                    "from": {
                        "id": 1,
                        "name": "卖家建筑",
                        "avatar": "",
                        "type": 1,
                        "tier": 1,
                        "belong_city": 0,
                        "belong_districts": 0,
                        "belong_terrain": 0,
                    },
                    "agent": {
                        "id": 1,
                        "name": "贸易站",
                        "avatar": "",
                        "type": 1,
                        "tier": 1,
                        "belong_city": 0,
                        "belong_districts": 0,
                        "belong_terrain": 0,
                    },
                    "seller": {
                        "uid": 1,
                        "nick_name": "",
                        "avatar": ""
                    },
                    "selling": {
                        "id": 1,
                        "tier": 1,
                        "quantity": 1,
                        "quantityWhenCreated": 1,
                    },
                    "buying": {
                        "id": 1,
                        "tier": 1,
                        "quantityPerItem": 1,
                    },
                    "create_time": 1,
                    "edit_time": 1,
                    "available_until": 1,
                    "status": 0,
                },
            ]
        }
    )

    const emptyBazaarItems = !bazaarItems.content


//    贸易站
//fetch市场物品
// https://api.civitasrise.com/api/bazaar/agents/:buildingId
    useEffect(() => {
            api_with_auth("/api/bazaar/agents/" + buildingId, "POST",
                {
                    "page": 0,
                    "pageSize": 20
                })
                .then(res => {
                    if (res.data === null) {
                    } else {
                        setBazaarItems(res.data)
                    }
                })
        }
        , [])


    //买的数量
    const [buyQuantity, setBuyQuantity] = useState(Array(200).fill(0))
    const changeQuantity = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let newArr = [...buyQuantity]
        newArr[index] = parseFloat(e.target.value)
        setBuyQuantity([...newArr])
    }

    //买
    const buy = (index:number)=>{
        api_with_auth("/api/bazaar/items/"+bazaarItems.content[index].id+"/buy", "POST", {
            "orderId": bazaarItems.content[index].id,
            "quantity": buyQuantity[index],
            "buying": bazaarItems.content[index].buying
        })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage":"购买成功",
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }


//货物列表
    if (emptyBazaarItems) {
        return <div className={"bazaar-items-view-root"}><Filler title={"没有找到寄售的货物"}/></div>
    } else {
        return <div className={"bazaar-items-view-root"}>
            {bazaarItems.content.map((sell: any, index: any) =>
                <ListItemButton className={"bazaar-item-bullet-root"} key={"bazaarItem_" + sell.id} disableRipple>
                    <div className={"bazaar-item-seller-and-others"}>
                        <BazaarItemSeller sell={sell}/>
                        <TextField size={"small"} value={buyQuantity[index]}
                                   onChange={changeQuantity(index)}
                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                   type={"number"} disabled={false}/>
                        <Button variant="text" className={"modify-order"} color={"happiness"}
                                onClick={()=>buy(index)}
                                style={{
                                    margin: "0.8vw 0",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    maxWidth: "4vw",
                                    minWidth: "4vw",
                                    fontSize: '0.8vw',
                                    padding: 0,
                                }}>
                            交易
                        </Button>
                    </div>
                    {sell.agent.id !== -1 ? <AgentItemAgent sell={sell}/> : null}
                </ListItemButton>
            )}
        </div>
    }
}

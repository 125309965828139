import "./ManageWorkers.scss"
import {Avatar, Button, ListItemButton, MenuList, Paper, Popover, Typography} from "@mui/material";
import {Logout, MoreHoriz, Settings} from "@mui/icons-material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {BaseCapacity, FindBuildingSkillLevel, FindBuildingSkillName, Floor1} from "../../../../components/gadgets";
import {api_host, api_with_auth} from "../../../../utils/api";
import {getLoginToken} from "../../../../utils/storage";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {fetchEmployeesNow} from "../../Work/workSlice";
import {fetchEmployeeSkillsNow} from "../../Work/employeeSkillsSlice";

//scss在ListItemBuilding，导入scss在supervisor
function WorkerBullet(props: {
    worker: any
}) {
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const employeeSkills = useSelector((state: any) => state.employeeSkills.data)
    //小技能等级
    const minorSkill = FindBuildingSkillLevel((employeeSkills.find((e: any) => e.user_id === props.worker.employeeID)), buildingSummary, 1)
    //产能
    const capacity = BaseCapacity(FindBuildingSkillLevel((employeeSkills.find((e: any) => e.user_id === props.worker.employeeID)), buildingSummary, 0), FindBuildingSkillLevel((employeeSkills.find((e: any) => e.user_id === props.worker.employeeID)), buildingSummary, 1))

    return <div className={"list-item-building worker-bullet-root"}>
            <Avatar alt={props.worker.employee.nick_name} src="/static/images/avatar/1.jpg"
                    className={"avt"} variant="rounded"/>
            <div className={"two-story-text"}>
                <div className={"up"}>
                    {props.worker.employee.nick_name}
                </div>
                <div className={"down"}>
                    工人
                </div>
            </div>

            <div className={"two-story-text right margin-left-auto"}>
                <div className={"up"}>
                    {Floor1(minorSkill)}
                </div>
                <div className={"down"}>
                    {FindBuildingSkillName(buildingSummary, 1)}
                </div>
            </div>
            <div className={"longer two-story-text right"}>
                <div className={"up"}>
                    {Floor1(capacity)}
                </div>
                <div className={"down"}>
                    预期产能
                </div>
            </div>
            <div className={"longer two-story-text period right"}>
                <div className={"up " + (props.worker.is_worked ? "" : "orange")}>
                    {props.worker.is_worked ? "√已上工" : "未上工"}
                </div>
                <div className={"down"}>
                    今日工作
                </div>
            </div>
    </div>
}

export default function ManageWorkers() {
    const dispatch = useDispatch()
    const employees = useSelector((state: any) => state.employees.data)
    const {buildingId} = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open,setOpen]= useState(-1)
    const handleClick = (index1:number)=>(event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(index1)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(-1)
    };
    const [processing, setProcessing] = useState(false)

    //辞退工人
    const fireWorker = (id:number) => {
        setProcessing(true)
        api_with_auth("/api/building/" + buildingId + "/fire",  "POST",  {
                "worker": id
            } )
            .then(res => {
                    setProcessing(false)
                    if (res.status === "success") {
                        handleClose()
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(fetchEmployeesNow())
                        dispatch(fetchEmployeeSkillsNow())
                            if (employees.find((e:any)=>e.employeeID === id)?.is_worked){
                                dispatch(openSnackbar({
                                    "severity": "info",
                                    "snackbarMessage": "成功辞退了工人！工人已经工作，将会在明日离职。",
                                }))
                            }else{
                                dispatch(openSnackbar({
                                    "severity": "success",
                                    "snackbarMessage": "成功辞退了工人！",
                                }))
                            }

                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))

                    }
                },
            );
    }

    //取消辞退工人
    const cancelFireWorker = (id:number) => {
        setProcessing(true)
        api_with_auth("/api/building/" + buildingId + "/canceling_fire", "POST",  {
                "worker": id
            } )
            .then(res => {
                    setProcessing(false)
                    if (res.status === "success") {
                        handleClose()
                        dispatch(fetchBuildingSummaryNow())
                        dispatch(fetchEmployeesNow())
                        dispatch(fetchEmployeeSkillsNow())
                            dispatch(openSnackbar({
                                "severity": "success",
                                "snackbarMessage": "挽回了工人！你现在不再辞退他了，他应该不会介意……",
                            }))

                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))

                    }
                },
            );
    }
    return <div className={"manage-workers-root"}>

        <div className={"manage-workers-content"}>
            {employees?.map((employee: any, index: number) =>
                <div className={"worker"} key={employee.employeeID}>
                    <Button variant="text" className={"options"}
                            onClick={handleClick(index)}
                    ><MoreHoriz/></Button>
                    <Popover
                        open={open === index}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    >
                        <div id="MenuList">
                            {employee.firing ?
                                <ListItemButton onClick={()=> cancelFireWorker(employee.employeeID)} >
                                    <Logout/>取消辞退</ListItemButton>:
                                <ListItemButton
                                    onClick={()=>fireWorker(employee.employeeID)}
                                                style={{color:"#bd1f1f"}}
                                >
                                    <Logout/>辞退工人</ListItemButton>
                            }
                        </div>
                    </Popover>
                    <WorkerBullet worker={employee}/>
                </div>
            )}
        </div>
        <div className={""}></div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
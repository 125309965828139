import {Avatar} from "@mui/material";
import * as React from "react";
import {useSelector} from "react-redux";

//显示库存容量
//city进目标城市的城市结构
export function StorageBullet(props: { building: any }) {
    const city = useSelector((state: any) => state.cityStructure.data)
    const buildingSummary = props.building
    const districtName = city.districts.find((e: any) => e.id === buildingSummary.belong_districts)?.name
    const isSuburb = city.districts.find((e: any) => e.id === buildingSummary.belong_districts)?.type
    const cityName = (city.id === buildingSummary.belong_city) ? city.name : "非本地城市"

    return <div className={"storage-bullet list-item-building"}>
        <Avatar alt="玩家BuildingAvt" src=" "
                sx={{width: "2.5vw", height: "2.5vw"}}
                variant="rounded"
                className={"avt"}
        />
        <div className={"two-story-text"}>
            <div className={"down"}>
                {cityName + ">" + (isSuburb ? "郊区" : "城区") + ">" + districtName}
            </div>
            <div className={"up"}>
                {buildingSummary.name}
            </div>
        </div>
        <div className={"two-story-text margin-left-auto"}>
            <div className={"up"}>
                {buildingSummary.storage_capacity?.toFixed(1)}/{buildingSummary.max_storage_capacity?.toFixed(0)}
            </div>
            <div className={"down"}>
                库房
            </div>

        </div>
    </div>
}
import {HiringCard} from "../components/WorkCard/WorkCard";
import {api_host} from "../../../utils/api";
import WorkCard from "../components/WorkCard/WorkCard";
import Grid from '@mui/material/Grid';
import {Link, Navigate, Route, Routes, useLocation, useParams} from "react-router-dom";
import "./Work.scss"
import {useEffect, useState} from "react";
import {getLoginToken} from "../../../utils/storage";
import {useSelector, useDispatch} from 'react-redux';
import {updateEmployeesWPayload, fetchEmployeesNow} from "./workSlice"
import {Box, styled} from "@mui/material";
import * as React from "react";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {BPBuildingInfo, FindBuildingSkill, itemInfo, skillInfo} from "../../../components/gadgets";
import {TierToStars} from "../../../components/mappingTable";
import MethodBullet, {ConstructionMethodBullet} from "../components/MethodBullet";
import {updateEmployeeSkillsWPayload} from "./employeeSkillsSlice";

//进度条,storage复制过来的，都是d1的垃圾代码，有空改掉
const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: "0.6vw",
    borderRadius: "0.3vw",
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8"
    }
}));

export default function Work() {
    const dispatch = useDispatch();
    const status = useSelector((state: any) => state.status)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const employees = useSelector((state: any) => state.employees.data)
    const employeeSkills = useSelector((state: any) => state.employeeSkills.data)
    const fetchEmployeesNow = useSelector((state: any) => state.employees.fetchNow)
    let urlLocation = useLocation()
    let buildingId = parseInt(useParams().buildingId as string, 10)



    //本地建筑方案列表
   function methodsList() {
        return BPBuildingInfo(buildingSummary.type)?.tiers.find((t:any)=>t.tier === buildingSummary.tier)?.working_method
    }

    // 工作方案的文字描述
    const methodDescription = (method: any) => {
        const no = (buildingSummary.working_method + 1) + "："
        const input2 = method?.input?.map((input: number, index: number) => {
            return (index === 0) ? ("消耗" + itemInfo(input)?.Chs + TierToStars[method.tier_input[index]]) : ("和" + itemInfo(input)?.Chs + TierToStars[method.tier_input[index]])
        })
        const input3 = input2 ? (input2?.join('') + ",") : ""
        const output2 = "产出" + itemInfo(method?.output)?.Chs + TierToStars[method?.tier_output] + "。"
        return no + input3 + output2
    }
    //本地建筑的方案文字描述1
    const thisBuildingMD = methodDescription(methodsList()[buildingSummary.working_method])
    const MD1 = (buildingSummary.stage <= 1 )?"该建筑处在开垦/建造阶段，建设完成后才能发挥原本功能。": thisBuildingMD
    //本地建筑的方案文字描述2
    const thisBuildingMD2 = "工人使用技能" + skillInfo(FindBuildingSkill(buildingSummary,1))?.Chs + "和" + skillInfo(FindBuildingSkill(buildingSummary,0))?.Chs + "。"
    // const MD2 = (buildingSummary.stage <= 1 )?

    return <div className={"work"}>
        <div className={"work-title-line"}>
            <div className={"work-title"}>
                工作
            </div>
            <div className={"method-info"}>
                {MD1}<br/>
                {thisBuildingMD2}
            </div>
            <div className={"WMethod"}>
                {(buildingSummary.stage === 0 || buildingSummary.stage === 1) ?
                   <ConstructionMethodBullet buildingSummary={buildingSummary}/>
                    :
                    <MethodBullet method={methodsList()[buildingSummary.working_method]}  n={buildingSummary.working_method+1}/>
                }
            </div>
            <div className={"WMethod-text"}>
                █ {buildingSummary.working_method + 1}/{methodsList().length}{(buildingSummary.in_day_working_method === -1)? "":" ⟳"}
            </div>
        </div>
        <div className={"grid-container"}>
            <Grid
                container
                justifyContent="center"
                spacing={"1vw"}
                rowSpacing={"2vw"}
            >
                <Grid key={1} item>
                    <HiringCard
                    />
                </Grid>
                {employees?.map((person: any, index: any) => (
                    <Grid key={"employee_" + person.employeeID} item>
                        <WorkCard employee={person}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    </div>;
}


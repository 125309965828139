import "./promo.scss"
import {useEffect, useRef, useState} from "react";
import {Button, Collapse, Divider} from "@mui/material";
import * as React from "react";
import {OpenInNew} from "@mui/icons-material";


const VerticalProgress = (value: number) => {
    return <div className={"vertical-progress-root"}>
        <div className={"vertical-progress"} style={{top: `${(value - 100)}%`}}>

        </div>
    </div>
}
export default function Promo() {
    const [expand, setExpand] = useState(false)

    const applyScaling = (scaledWrapper: any, scaledContent: any) => {
        scaledContent.style.transform = 'scale(1, 1)';
        let {width: cw, height: ch} = scaledContent.getBoundingClientRect();
        let {width: ww, height: wh} = scaledWrapper.getBoundingClientRect();
        let scaleAmtX = Math.min(ww / cw, wh / ch);
        let scaleAmtY = scaleAmtX;
        scaledContent.style.transform = `scale(${scaleAmtX}, ${scaleAmtY})`;
    };
    const scaledWrapper = useRef<HTMLDivElement>(null);
    const scaledContent = useRef<HTMLIFrameElement>(null);
    setInterval(function () {
        /// call your function here
        if (scaledWrapper.current && scaledContent.current) {
            applyScaling(scaledWrapper.current, scaledContent.current);
        }
    }, 1000);
    ;

    return <div className={"promo-root"}
                onClick={() => setExpand(!expand)}
    >
        <div className={"bg1"}/>
        <Collapse in={expand}>
            <div className={"bg2"}/>
            <div className={"content"}>
                <div className={"intro"}>
                    <div
                        className={"intro-text"}>本游戏由<i>复兴艺术工作室</i>呈现。<i>复兴艺术工作室</i>是由两名模拟游戏爱好者在业余时间组建的工作室，专门用来制作<i> Civitas
                        Rise</i> 。
                    </div>
                    <div className={"logo"}/>
                </div>
                <div className="contacts">
                    <Button variant="contained" color={"health"} target="_blank"
                            href="https://qm.qq.com/cgi-bin/qm/qr?k=RxPDGmbitEh42hihR6xAhADHXeKA2KQl&jump_from=webapi&authKey=JPYwo3VaeerhdelL1cJdLdyYExhjoqMH5BLXiEYdkosDhxdKA6yvOk5DCUYlr+VP"
                            onClick={() => {

                    }}>
                        <OpenInNew/>玩家-开发群
                    </Button>
                    <Button variant="contained" color={"health"} target="_blank"
                            href="https://afdian.net/a/CivitasRise"
                            onClick={() => {

                            }}>
                        <OpenInNew/>爱发电赞助
                    </Button>
                    <Button variant="contained" color={"health"} target="_blank"
                            href="mailto:joshpreslion@gmail.com?subject=CivitasRise开发"
                            onClick={() => {

                    }}>
                        <OpenInNew/>邮箱
                    </Button>
                </div>
                <div className={"steam"} ref={scaledWrapper}>
                    <iframe ref={scaledContent} title="Steam"
                            src="https://store.steampowered.com/widget/1925820/?t=Civitas%20Rise%20%E6%98%AF%E4%B8%80%E6%AC%BE%E5%8F%A4%E5%85%B8%E7%A4%BE%E4%BC%9A%E6%A8%A1%E6%8B%9F%E6%B8%B8%E6%88%8F%E3%80%82%E6%9C%AC%E6%B8%B8%E6%88%8F%E4%B8%AD%EF%BC%8C%E6%95%B0%E5%8D%83%E5%90%8D%E7%8E%A9%E5%AE%B6%E5%85%B1%E5%90%8C%E5%BB%BA%E8%AE%BE%E4%B8%80%E5%BA%A7%E5%BA%A7%E8%91%97%E5%90%8D%E7%9A%84%E6%B1%89%E4%BB%A3%E6%97%B6%E6%9C%9F%E4%B8%9C%E8%A5%BF%E6%96%B9%E5%9F%8E%E5%B8%82%EF%BC%8C%E5%8F%91%E5%B1%95%E7%BB%8F%E6%B5%8E%E7%B3%BB%E7%BB%9F%EF%BC%8C%E5%88%B6%E5%AE%9A%E7%A4%BE%E4%BC%9A%E8%A7%84%E5%88%99%EF%BC%8C%E6%B2%89%E6%B7%80%E6%96%87%E5%8C%96%E8%AE%B0%E5%BF%86%E3%80%82%E7%8E%A9%E5%AE%B6%E4%BB%AC%E7%9A%84%E7%BE%A4%E4%BD%93%E5%86%B3%E7%AD%96%E4%BC%9A%E5%AF%B9%E5%9F%8E%E5%B8%82%E4%BA%A7%E7%94%9F%E4%B8%8D%E5%8F%AF%E9%80%86%E7%9A%84%E6%B7%B1%E8%BF%9C%E5%BD%B1%E5%93%8D%E3%80%82"
                            frameBorder="0" width="646" height="190"/>
                </div>
                <div className={"story"}>
                    <i>Civitas Rise</i> 是应玩家呼声而诞生的游戏。这是开发成本：
                </div>
                <div className={"story"}>
                    主程序员：气氛组-用爱发电￥0， 美术设计：达芬奇-用爱发电￥0，
                    前端编程：达芬奇-用爱发电￥0， 游戏设计：达芬奇-用爱发电￥0， <b>后端外包开发：￥6000每期更新</b>
                </div>
                <Divider style={{backgroundColor: "#3d3d3d"}}/>
                <div className={"filters"}>
                    <div className={"l filter"}>
                        <div>功能</div>
                    </div>
                    <div className={"r filter"}>
                        <div>前期设计</div>
                        <div>完整设计</div>
                        <div>众筹金额</div>
                        <div>已交付</div>
                    </div>
                </div>

                {/*“星火奠基”更新*/}
                <div className={"update-block"}>
                    <div className={"progress-container"}>{VerticalProgress(100)}</div>
                    <div className={"title"}>“星火奠基”更新</div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>城市结构</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>将城市区分为城区和郊区，丘陵、山脉等地形坐落其上。再以行政街区划分建筑区块。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>玩家建造建筑</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>消耗资源，在选定的位置开辟和拥有一块属于你的私人地产。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>私人交易</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>向另一位玩家发送私人合同，以交易物资或者建筑。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>库存转移</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>在你拥有/管理的建筑库房之间转移物资。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>其他次要内容</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>实装35种新物品，22种新建筑和45种新技能。@招哥 提供了设计。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div className={"summary orange"}>“星火奠基”更新已于2022/6/10实装完毕</div>
                </div>

                {/*“吃穿用度”更新*/}
                <div className={"update-block"}>
                    <div className={"progress-container"}>{VerticalProgress(100)}</div>
                    <div className={"title"}>“吃穿用度”更新</div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>副业</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>于城市中进行探索、拾荒以获得随机物资，或者在你的藏身处进行徒手加工。消耗较少的属性值。有机会获得当地城市特产。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>市场交易+贸易站</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>于集士中和其他玩家以物易物，自由交换物资。贸易站是一种玩家建筑，可以减免交易损耗；贸易站主人亦可对每一笔经过该建筑的交易抽税。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>住宿系统+宅院/楼房/别墅</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>从简陋的藏身帐篷搬入高级住宅，享受尊贵身份、额外换日属性恢复和扩大的私人库房。也可以出租空余客房来收取租金。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>烹饪系统+餐馆</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>以蒸、烤、煮、羹、炙、炮、煎、熬等烹饪方法，组合数十种可选食材，写成“食谱”。享用食谱可以立即获得属性恢复。餐馆可以根据食谱售卖餐食以换取报酬。同时引入“奢侈度”来衡量你作风奢靡的程度。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>建筑高级监工</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div>√</div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>隶属于建筑监工的一系列设置功能。包括浏览工人工作历史，辞退/管理工人，升级建筑，合并和拆分建筑，拆除建筑。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>其他次要内容</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div>√</div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>玩家上传/修改头像，其他玩家的个人主页，我的建筑页面，个人技能页面。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div className={"summary orange"}>“吃穿用度”更新已于2022/6/18开始</div>
                </div>

                <div className={"orange"}>现在在这！</div>


                {/*“车马盈门”更新*/}
                <div className={"update-block"}>
                    <div className={"progress-container"}>{VerticalProgress(48)}</div>
                    <div className={"title"}>“车马盈门”更新</div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>古代遗产</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>开启古代遗产以获得先民的馈赠。这是一个接收各种奖励物资的系统。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>好友/仇敌+花园</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>于花园种植鲜花。与其他玩家结为好友，互赠礼物和鲜花以提升关系。关系亲密的好友之间可以进行额外互动。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>邀请码+导师/学徒关系</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>进行游戏以获取邀请码。当新玩家使用您的邀请码注册，您将获得回报并且被永久记录为他的导师。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>抄本/出版物+书局</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>编撰、发行出版物（写在竹简或者绢帛上），售卖或者借阅他们。在抄本上盖章或留言。建造图书馆以储存这些文字。</div>
                            <div className={"r"}/>
                        </div>
                    </div>

                    <div className={"summary"}>没有开始的更新，内容和顺序会根据玩家意见调整。</div>
                </div>

                {/*“风调雨顺”更新*/}
                <div className={"update-block"}>
                    <div className={"progress-container"}>{VerticalProgress(0)}</div>
                    <div className={"title"}>“风调雨顺”更新</div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>气温和降雨</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>以28天为周期，每日变化的气温和降雨模拟当地当时的气候环境。夏季气温适宜作物丰收，大部分作物无法熬过冬季严寒。干燥的天气适合伐木，而强降雨给采矿带来隐患。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>耕种系统+农场</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>向您重新介绍农场。气温、降雨、肥沃度均会影响收成，栽培的作物周期性收获，各自有其适宜的气温区间。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>勘探、储量和矿井</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>建设矿井前需要工人勘探，勘探决定矿物的存量和星级。</div>
                            <div className={"r"}/>
                        </div>
                    </div>

                {/*    <div className={"summary"}>没有开始的更新，内容和顺序会根据玩家意见调整。</div>*/}
                {/*</div>*/}

                {/*/!*“条条大路”更新*!/*/}
                {/*<div className={"update-block"}>*/}
                {/*    <div className={"progress-container"}>{VerticalProgress(0)}</div>*/}
                {/*    <div className={"title"}>“条条大路”更新</div>*/}
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>奠基一座新城市</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>以群体为单位，从现有城市出发向蛮荒之地发起迁徙。明智的选择一块资源丰富或者险要的位置建造新的城市。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>真实的库存和运输损耗</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>独特的数值设定真实的反应每种物品在不同储存条件下的腐败/消散情况。你需要于阴凉处存放水果，让青铜器远离水源。原木适合长途运输，而脆弱的陶器最好就近投入使用。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>

                    <div className={"summary"}>没有开始的更新，内容和顺序会根据玩家意见调整。</div>
                </div>

                {/*将会到来的内容*/}
                <div className={"update-block"}>
                    <div className={"progress-container"}>{VerticalProgress(0)}</div>
                    <div className={"title"}>将会到来的内容</div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>行政街区</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>由玩家将城市划分为多个行政区，同街区的建筑根据互相影响互相加成，而投票当选的区长拥有操作整个街区的权利，他将掌控街区发展的方向。附带专门加成建筑和行政设施。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>二维大地图</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>想象一张世界地图，长安坐落在中原。蓝色的线连接到河流，是居民建造的水渠。经商官道将蜀郡和长安、其他中原城市连接起来。出海航线从齐鲁地区出发，到埃及亚历山大去。你和同伴挑选了一处资源丰富的位置，从长安迁徙去拓荒新的城市。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div className={"summary"}>没有开始的更新，内容和顺序会根据玩家意见调整。</div>
                </div>

                {/*想象一下……*/}
                <div className={"update-block"}>
                    <div className={"progress-container"}>{VerticalProgress(0)}</div>
                    <div className={"title"}>想象一下……</div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>艺术品</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>一种难以精通的技巧性工作。完美的艺术品不仅给建筑提供额外加成，还会受到全城市玩家的瞩目。可以自定义上传图像。类似网游洗装备的昂贵end
                                game玩法，也是展示个性的机会……
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>死亡和殡葬</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div
                                className={"l"}>假设说你在战争中牺牲，或者种田过劳而死，你需要一场风光大葬。不论是汉代东方侍死如侍生的华丽墓穴，还是罗马的繁复石棺，总有千百种方式把你的尸体过于体面的供奉起来。墓地根据建筑水平和*<em>你的生前属性</em>*提供街区加成。死亡按百分比回退技能水平和社交关系，不会销毁账号。
                            </div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"line second-title"}>
                            <div className={"l"}>科技进步+学堂</div>
                            <div className={"r"}>
                                <div>√</div>
                                <div></div>
                                <div/>
                                <div></div>
                            </div>
                        </div>
                        <div className={"line description"}>
                            <div className={"l"}>走在汉代科研一线，发明造纸、深钻、高炉、船舵，甚至赶超唐宋-文艺复兴。科技以玩家个人为载体，通过一对一教学传播。</div>
                            <div className={"r"}/>
                        </div>
                    </div>
                    <div className={"summary"}>没有开始的更新，内容和顺序会根据玩家意见调整。</div>
                </div>

                <div className={"orange"}>让我们共同完成这个奇迹！</div>

                <div className={""}></div>
                <div className={""}></div>
            </div>
        </Collapse>
    </div>
}
import "./RoomCard.scss"
import * as React from "react";
import {Avatar, ListItemButton, Popover} from "@mui/material";
import {id2color} from "../../../../utils/id2color";
import {useNavigate, useParams} from "react-router-dom";
import {skillInfo} from "../../../../components/gadgets";
import {Item} from "../../../../components/IconImg";
import {AccessTime, Autorenew, Logout} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {useState} from "react";

export function RoomGuestCard(props: { player: any }) {
    const state = useSelector((state: any) => state.state.data)
    const navigate = useNavigate()
    const player = props.player
    const dispatch = useDispatch()
    const {buildingId} = useParams()
    // 此人是我
    const me = state.uid === player.uid
    //弹窗
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = () => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };

    const leave = () => {
        api_with_auth("/api/building/" + buildingId + "/residence/leave", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "离开了该住房。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }
    const extend = () => {
        api_with_auth("/api/building/" + buildingId + "/residence/extend", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "付清费用，又续租了一期。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"room-card-root guest-card-root"}>
        <Avatar sx={{bgcolor: id2color(player.uid)}} src={player.avatar}
                onClick={() => navigate("/game/personal/" + player.uid)} className={"avt"}
        >{player.nick_name ? player.nick_name?.[0] : ""}</Avatar>

        <div className={"card-body " + (me ? "solid" : "dashed")}
             onClick={(e)=>handleClick()(e)}
        >
            <div className={"name"}>{player.nick_name}</div>
            <div className={"description"}>{{
                "paid": "租客",
                "employee": "免费入住员工",
                "owner": "所有者/管理员入住",
                "invited": "受邀免费入住",
            }[player.guestType as "paid" | "employee" | "owner" | "invited"]}</div>
            {player.guestType === "paid" ? <div className={"condition"}><AccessTime/><span
                className={"heavy"}>{player.durationLeft}/{player.duration}日</span>,&nbsp;&nbsp;
                <Autorenew/>{player.allowRenewal ? "✓" : "✗"}
            </div> : null}
        </div>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <div id="MenuList">
                    <ListItemButton disabled={player.guestType !== "paid"}
                                    onClick={extend}
                        // style={{color: "#bd1f1f"}}
                    >
                        <Autorenew/>付费续租</ListItemButton>
                <ListItemButton
                    onClick={leave}
                    style={{color: "#bd1f1f"}}
                >
                    <Logout/>退租</ListItemButton>
            </div>
        </Popover>
    </div>
}

export function RoomContractCard(props: { contract: any }) {
    const contract = props.contract
    const dispatch = useDispatch()
    const {buildingId} = useParams()

    const rent = () => {
        api_with_auth("/api/building/" + buildingId + "/residence/rent", "POST", {"leaseId": contract.id})
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "付费入住了住房。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"room-card-root contract-card-root"} onClick={rent}>
        <img src={process.env.PUBLIC_URL + "/img/seal.webp"} className={'avt seal'} alt={"蜡封"}/>

        <div className={"card-body float"}>
            <div className={"contract-orange"}>租房合同</div>
            <div className={"flex"}><Item id={contract.rent.type} t={contract.rent.tier} size={2} mr={0.5} ml={0.5}/>
                <div className={"price"}>
                    <div className={"price-number"}>{contract.rent.quantity}</div>
                    <div className={"text"}>租金</div>
                </div>
            </div>
            <div className={"condition"}><AccessTime/><span className={"heavy"}>{contract.duration}日</span>,&nbsp;&nbsp;
                <Autorenew/>{contract.allowRenewal ? "✓" : "✗"}</div>
            <div className={""}></div>
        </div>
    </div>

}

export function RoomContractEmployeeFreeCard() {
    const dispatch = useDispatch()
    const {buildingId} = useParams()

    const rent = () => {
        api_with_auth("/api/building/" + buildingId + "/residence/rent_as_employee", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "作为员工免费入住了。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"room-card-root contract-card-root"} onClick={rent}>
        <img src={process.env.PUBLIC_URL + "/img/seal.webp"} className={'avt seal'} alt={"蜡封"}/>

        <div className={"card-body float"}>
            <div className={"contract-orange"}>租房合同</div>
            <div className={"flex"}>
                <div className={"price"}>
                    <div className={"price-number"}>员工免费入住</div>
                </div>
            </div>
            <div className={"condition"}><AccessTime/><span className={"heavy"}>∞</span>,&nbsp;&nbsp;
                <Autorenew/>✗
            </div>
            <div className={""}></div>
        </div>
    </div>
}

export function RoomContractOwnerFreeCard() {
    const dispatch = useDispatch()
    const {buildingId} = useParams()

    const rent = () => {
        api_with_auth("/api/building/" + buildingId + "/residence/rent_as_owner", "POST")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "作为管理者入住了。",
                        }))
                        dispatch(fetchBuildingSummaryNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    return <div className={"room-card-root contract-card-root"} onClick={rent}>
        <img src={process.env.PUBLIC_URL + "/img/seal.webp"} className={'avt seal'} alt={"蜡封"}/>
        <div className={"card-body float"}>
            <div className={"contract-orange"}>租房合同</div>
            <div className={"flex"}>
                <div className={"price"}>
                    <div className={"price-number"}>所有者免费入住</div>
                </div>
            </div>
            <div className={"condition"}><AccessTime/><span className={"heavy"}>∞</span>,&nbsp;&nbsp;
                <Autorenew/>✗
            </div>
        </div>
    </div>
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Login from "./login";
import Signup from "./signup";
import { Provider } from 'react-redux';
import store from "./store";


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
        <Router>
            <Routes>
                <Route index element={<App/>}/>
                <Route path={"/game/*"} element={<App/>}/>
                <Route path={"/login"} element={<Login/>}/>
                <Route path={"/signup"} element={<Signup/>}/>
            </Routes>
        </Router>
        </Provider>
    </React.StrictMode>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import "./listItemBuilding.scss"
import {Avatar, Button, ListItemButton, Checkbox} from "@mui/material";
import {Link} from "react-router-dom";
import {
    cityIdToName, TierToStars,
} from "../mappingTable";
import * as React from "react";
import {useSelector} from "react-redux";
import {TimeSlot} from "../timeToNow";
import {TransactionStatusToChs} from "../../Transaction";
import {
    BaseCapacity,
    BPBuildingInfo, BuildingTypeTierToChs,
    districtIdToName,
    FindBuildingSkillLevel,
    FindBuildingSkillName,
    itemInfo
} from "../gadgets";
import {constructionHint, FindBuildingDistrictName} from "../../Buildings/Building/components/WorkCard/WorkCard";
import {BuildingInList, Item} from "../IconImg";


//私人交易列表
export function TransactionListBullet(props: {
    transaction: any
}) {
    const state = useSelector((state: any) => state.state.data)
    return <div className={"list-item-building list-item-building-transaction"}>
        <img
            src={require("../../Transaction/assets/transaction_" + ((props.transaction.status === 0) ? "orange" : "grey") + ".webp").default}
            alt={`trade`}
            className="transaction-icon"/>
        <div className={"two-story-text"}>
            <div className={"up"}>
                {(state.uid === props.transaction.recipient.id) ? ("← 来自" + props.transaction.initiator.name) : ("→ 发往" + props.transaction.recipient.name)}
            </div>
            <div className={"down"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {props.transaction.initiator?.pay.items.length + props.transaction.recipient.pay.items.length
                    + props.transaction.initiator.pay.buildings.length + props.transaction.recipient.pay.buildings.length
                }项物品
            </div>
        </div>
        <div className={"right two-story-text margin-left-auto"}>
            <div className={"up"}>
                {TimeSlot(props.transaction.expire_time - props.transaction.initiate_time)}
            </div>
            <div className={"down"}>
                发布于{TimeSlot(Date.now() * 1000000 - props.transaction.initiate_time)}前
            </div>
        </div>
        <div className={"right two-story-text six-vw"}>
            <div className={"up " + ((props.transaction.status === 0) ? "orange" : "")}>
                {TransactionStatusToChs[props.transaction.status]}
            </div>
            <div className={"down"}>

            </div>
        </div>
    </div>

}

//浏览模式，单个街区里的建筑列表
export function ListItemBuildingPersonal(props: {
    building: any
}) {
    const building = props.building
    const hasWorkerSlots = building.max_work_count
    const workerColor = "#ed6c02"
    const colorWorked = building.today_worked_count === building.worker_count && building.worker_count
    const colorWorker = building.worker_count === building.max_work_count && building.max_work_count
    //拿城市结构，看街区id名字对照
    const districts = useSelector((state: any) => state.cityStructure.data.districts)
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

    function stageDecorativeText(stage: number) {
        switch (stage) {
            case 0:
                return " - 开垦中";
            case 1:
                return " - 建设中";
            case 2:
                return "";
            case 3:
                return " - 已损毁";
        }
    }

    return <div className={"list-item-building"}>
        <Avatar alt={props.building.name} src={props.building.avatar}
                sx={{width: "2.5vw", height: "2.5vw"}}
                variant="rounded"
                className={"avt"}
        >{props.building.name[0]}</Avatar>
        <div className={"two-story-text"}>
            <div className={"up"}>
                {props.building.name}
            </div>
            <div className={"down"}>
                {BuildingTypeTierToChs(props.building) + stageDecorativeText(props.building.stage) + " - " + districtIdToName(props.building.belong_districts, cityStructure)}
            </div>
        </div>
        {hasWorkerSlots ?
            <div className={"two-story-text right margin-left-auto"}>
                <div className={"up"}>
                    <span style={colorWorked ? {
                        color: workerColor,
                        fontWeight: 700
                    } : {}}>{building.today_worked_count ?? 0}/</span>
                    <span style={colorWorker ? {
                        color: workerColor,
                        fontWeight: 700
                    } : {}}>{building.worker_count ?? 0}/</span>
                    <span style={{}}>{building.max_work_count ?? 0}</span>
                </div>
                <div className={"down"}>
                    上工/在职/工位
                </div>
            </div> : null}
        {/*<div className={"two-story-text four-vw right " + (hasWorkerSlots ? "" : "margin-left-auto")}>*/}
        {/*    <div className={"up"}>*/}
        {/*        {props.building.percent.toFixed(0)}%*/}
        {/*    </div>*/}
        {/*    <div className={"down"}>*/}
        {/*        完整度*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className={(hasWorkerSlots ? "" : "margin-left-auto")}>
            <BuildingInList id={props.building.type} t={props.building.tier} width={6} ml={1}/>
        </div>
    </div>
}


//建筑招聘已满？
const hireFull = (building: any) => {
    const full = (building.max_work_count - (building.worker_count ?? 0)) === 0
    if (full) {
        return " disabled"
    } else {
        return ""
    }
}

//建筑招聘列表
export function RecruitBullet(props: {
    building: any,
}) {
    const building = props.building
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const status = useSelector((state: any) => state.status.data)
    return <div className={"list-item-building list-item-building-Recruit"}>
        <Avatar alt="RecruitAvt" src={building.avatar}
                sx={{width: "2.5vw", height: "2.5vw", marginRight: "1vw"}}
                variant="rounded" style={{alignSelf: "center"}}>{props.building.name[0]}</Avatar>
        <div className={"two-story-text"}>
            <div className={"up" + hireFull(building)}>
                {props.building.name}
            </div>
            <div className={"down"}>
                {BPBuildingInfo(props.building.type)?.name + TierToStars[props.building.tier] + constructionHint(props.building) + " - " + FindBuildingDistrictName(cityStructure, props.building)}
            </div>
        </div>
        <div className={"five-vw two-story-text margin-left-auto"}>
            <div className={"up" + hireFull(building)}>
                {FindBuildingSkillLevel(status, props.building, 1).toFixed(2)}
            </div>
            <div className={"down"}>
                {FindBuildingSkillName(props.building, 1)}
            </div>
        </div>
        <div className={"five-vw two-story-text" + hireFull(building)}>
            <div className={"up orange" + hireFull(building)}>
                {props.building.salary_number}
            </div>
            <div className={"down"}>
                {itemInfo(props.building.salary_type)?.Chs + TierToStars[props.building.salary_tier]}
            </div>
        </div>
        <Item id={props.building.salary_type} t={props.building.salary_tier} mr={0.5}/>
        <div className={"five-vw two-story-text"}>
            <div className={"up orange" + hireFull(building)}>
                {(props.building.salary_number * BaseCapacity(FindBuildingSkillLevel(status, props.building, 0), FindBuildingSkillLevel(status, props.building, 1))).toFixed(2)}
            </div>
            <div className={"down"}>
                {itemInfo(props.building.salary_type)?.Chs + TierToStars[props.building.salary_tier]}
            </div>
        </div>
        <Button variant="text" className={"apply-job-btn"} color={"happiness"}
                disabled={hireFull(building) === " disabled"}
                style={{
                    margin: 0,
                    padding: 0,
                    maxHeight: '2vw',
                    minHeight: '2vw',
                    maxWidth: "4vw",
                    minWidth: "4vw",
                    fontSize: '0.8vw',
                    alignSelf: "center"
                }}>
            {(hireFull(building) === " disabled") ? "满员" : "应聘"}
        </Button>
    </div>

}

//建筑招聘列表预览版（少预计薪资和应聘按钮）
export function RecruitBulletP() {
    const building = useSelector((state: any) => state.buildingSummary.data)
    const city = useSelector((state: any) => state.cityStructure.data)
    return <div
        className={"list-item-building list-item-building-Recruit-preview" + (building.is_hiring ? "" : " disabled")}>
        <Avatar alt={building.name} src="/static/images/avatar/1.jpg"
                sx={{width: "2.5vw", height: "2.5vw", marginRight: "1vw"}}
                variant="rounded" style={{alignSelf: "center"}}/>
        <div className={"two-story-text"}>
            <div className={"up"}>
                {building.name}
            </div>
            <div className={"down"}>
                {BPBuildingInfo(building.type)?.name + TierToStars[building.tier] + constructionHint(building) + " - " + FindBuildingDistrictName(city, building)}
            </div>
        </div>
        <div className={"five-vw two-story-text right margin-left-auto"}>
            <div className={"up"}>
                {FindBuildingSkillName(building, 1)}
            </div>
            <div className={"down"}>
                使用技能
            </div>
        </div>
        <div className={"five-vw two-story-text right"}>
            <div className={"up orange"}>
                {building.salary_number}
            </div>
            <div className={"down"}>
                单位薪水
            </div>
        </div>
        <div className={"five-vw two-story-text type right"}>
            <div className={"up orange"}>
                {itemInfo(building.salary_type)?.Chs + TierToStars[building.salary_tier]}
            </div>
            <div className={"down"}>
                薪水种类
            </div>
        </div>
    </div>

}

import "./districtAndBazaarTabs.scss"
import {Link, useMatch} from "react-router-dom";

function TabButton(props: {
    className: string,
    to: string,
}) {
    let match = useMatch(props.to+"/*");
    return <Link to={props.to} className="district-and-bazaar-tab">
        <div
            // className带tab的名字，这样自动匹配到css里自己的背景图和微调的height
            className={"tab-button " + props.className +" "+ (match ? (props.className + "-active active") : "")}>
        </div>
    </Link>
}

export function BazaarTabButtons(props: {}) {

    return <div className="bazaar-tab-buttons">
        <TabButton to={"/game/bazaar/items"} className={"goods"}/>
        <TabButton to={"/game/bazaar/buildings"} className={"buildings"}/>
    </div>
}

export function DistrictTabButtons(props: {}) {

    return <div className="district-tab-buttons">
        <TabButton to={"/game/district/overview"} className={"overview"}/>
        <TabButton to={"/game/district/recruit"} className={"recruit"}/>
        {/*<TabButton to={"/game/district/apply"} className={"apply"}/>*/}
        <TabButton to={"/game/district/housing"} className={"housing"}/>
        <TabButton to={"/game/district/restaurant"} className={"restaurant"}/>
    </div>
}

import * as React from 'react'
import "./District.scss"
import {Link, Navigate, Route, Routes, useMatch, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {DistrictTabButtons} from "../../components/districtAndBazaarTabs"
import Recruit from "./Recruit";
import DistrictOverview from "./Overview";
import DistrictRestaurants from "./Restaurant";
import DistrictHousing from "./Housing";

export default function District() {
    //自己

    return (
        <div className="districtRoot">
            <div className={"left-container"}>
                <div className={"left-menu-container"}>
                    <DistrictTabButtons/>
                </div>
            </div>
            <div className={"right-container"}>
                <Routes>
                    <Route index element={<Navigate
                        to={`/game/district/overview`}/>}/>
                    <Route path={`/overview/*`} element={<DistrictOverview/>}/>
                    <Route path={`/recruit/*`} element={<Recruit/>}/>
                    <Route path={`/apply/*`} element={<Recruit/>}/>
                    <Route path={`/restaurant/*`} element={<DistrictRestaurants/>}/>
                    <Route path={`/housing/*`} element={<DistrictHousing/>}/>
                </Routes>

            </div>

        </div>
    )
}


import "./AddPhotoAvt.scss"
import {AddPhotoAlternate} from "@mui/icons-material";
import {Avatar} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {api_host, api_with_auth} from "../../utils/api";
import {openSnackbar} from "../../appSnackbarSlice";
import {useParams} from "react-router-dom";
import {getLoginToken} from "../../utils/storage";
import {useDispatch} from "react-redux";
import {fetchBuildingSummaryNow} from "../../Buildings/Building/buildingSlice";


export default function AddPhotoAvt(props: {
    type: "player" | "building" | "recipe"
    src: string,
    onClick: () => void,
    onSuccess?: () => void,
    size?: number,
    letter?: string,
    recipeId?: number,
}) {
    const size = props.size ? props.size : 6
    const letter = props.letter ? props.letter : ""
    const {buildingId} = useParams()
    const dispatch = useDispatch()

    const set = (image: any) => {
        const fd = new FormData()
        fd.append('upload', image)
        fetch(api_host() +
            {
                "player": "/api/user/set_avatar",
                "building": "/api/building/" + buildingId + "/set_avatar",
                "recipe": "/api/recipe/" + props.recipeId + "/set_avatar",
            }[props.type]
            , {
                method: "POST",
                credentials: 'include',
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'Authorization': 'Basic ' + getLoginToken(),
                },
                body: fd
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = "/#/login"
                }
                return res.json()
            })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "成功更新图片",
                        }))
                        props.onSuccess?.()
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );

    }

    const [photo, setPhoto] = useState<any>();

    //打开隐形的input窗口。注释不能删
    const onClick = () => {
        // @ts-ignore: Object is possibly 'null'.
        document.getElementById("selectImage").click()
        props.onClick?.()
    }
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setPhoto(URL.createObjectURL(e.target.files?.[0] as any))
        set(e.target.files?.[0])
        // handle the input...
    }
    return <div className={"add-photo-avt-root"}
                style={{borderRadius: props.type === "player" ? `${0.5 * size}vw` : `${0.07 * size}vw`}}
                onClick={onClick}>
        <input type="file" accept="image/*" name={"上传图片"} id={"selectImage"} hidden onChange={handleChange}/>
        <div className={"backdrop"} style={{borderRadius: `${0.07 * size}vw`}}>
            <AddPhotoAlternate/>
            <div className={"add-photo"}>上传图片</div>
        </div>
        <Avatar className={"add-photo-pfp-avt"} alt={props.src}
                variant="rounded"
                src={photo || props.src}
                style={{width: `${size}vw`, height: `${size}vw`}}
        >{letter}
        </Avatar>
    </div>
}
import {createSlice} from '@reduxjs/toolkit';
import {getLoginToken} from "../utils/storage";
import {api_host} from "../utils/api";

const slice = createSlice({
    name: 'myTransactions',
    initialState: {
        "data": {
            "count": 1,
            "transactions":
                [{
                    "id": 1,
                    //交易状态：0未决1已完成2已取消3被拒4已超时
                    "status": 1,
                    "city": 0,
                    "initiate_time": 1652515476872692000,
                    "expire_time": 1652601876872692000,
                    "text_message": "",
                    "initiator": {
                        "id": 4,
                        "name": "加载中",
                        "export_building": 6,
                        "import_building": 6,
                        "fresh_only": false,
                        "pay": {
                            "items": [
                                {
                                    "type": 5,
                                    "tier": 1,
                                    "quantity": 0
                                }
                            ],
                            "buildings": []
                        }
                    },
                    "recipient": {
                        "id": 4,
                        "name": "加载中",
                        "recommended_export_building": 6,
                        "fresh_only": false,
                        "pay": {
                            "items": [
                                {
                                    "type": 5,
                                    "tier": 1,
                                    "quantity": 0
                                }
                            ],
                            "buildings": []
                        }
                    }
                },

                ]
        },
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateMyTransactionsWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchMyTransactionsNow: (state) => {
            state.fetchNow += 1;
        },
    },
});
export const {updateMyTransactionsWPayload,fetchMyTransactionsNow} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import "./buildings.scss"
import {Avatar, Button, Grid, IconButton, SvgIcon, Tooltip} from "@mui/material";
import {api_host, api_with_auth} from "../../utils/api";
import {Link, Navigate, Route, Routes, useParams, useNavigate, useMatch, useLocation} from "react-router-dom";
import {Flowchart} from "./components/FlowChart";
import Facility from "./Facility";
import Work from "./Work";
import Storage from "./Storage";
import {
    TierToStars,
    BuildingDefaultRoute,
} from "../../components/mappingTable";
import {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {updateEmployeesWPayload} from "./Work/workSlice";
import {updateBuildingSummaryWPayload} from "./buildingSlice"
import Supervisor from "./Supervisor";
import {BPBTierInfo, BPBuildingInfo, districtInfo,} from "../../components/gadgets";
import * as React from "react";
import Residence from "./Residence";
import Recipe from "./Recipe";
import GuestRooms from "./GuestRooms";
import EditRecipe from "./Recipe/EditRecipe";
import {updateEmployeeSkillsWPayload} from "./Work/employeeSkillsSlice";
import {KeyboardBackspaceRounded, Sell} from "@mui/icons-material";
import Agent from "./Agent";
import BazaarBuildingPopover from "../../Bazaar/BazaarBuildings/component/Popover";
import RestaurantMenu from "./Recipe/Menu";
import RestaurantEditMenu from "./Recipe/EditMenu";


export function Buildings() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const {buildingId} = useParams();
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const isSelling = buildingSummary.is_selling
    const fetchBuildingSummaryNow = useSelector((state: any) => state.buildingSummary.fetchNow)
    const fetchEmployeesNow = useSelector((state: any) => state.employees.fetchNow)
    let urlLocation = useLocation()
    //正在建设的提示
    const constructionHint = (buildingSummary.stage === 0) ? "（开垦中）" : ((buildingSummary.stage === 1) ? "（建造中）" : "")
    ;

    //拿员工和员工技能。因为工作和监工都要用到
    useEffect(() => {
            api_with_auth("/api/building/" + buildingId + "/employee", "GET")
                .then(res => {
                    if (res.status === "success") {
                        dispatch(updateEmployeesWPayload(res.data))
                    }
                })

            //所有員工技能
            api_with_auth("/api/building/" + buildingId + "/employee/skills", "GET")
                .then(res => {
                    if (res.status === "success") {
                        dispatch(updateEmployeeSkillsWPayload(res.data))
                    }
                })
        }
        , [urlLocation, fetchEmployeesNow])
    //根据url id获取建筑信息
    useEffect(() => {
            api_with_auth("/api/building/" + buildingId, "GET")
                .then(res => {
                    if (res.status === "success") {
                        dispatch(updateBuildingSummaryWPayload(res.data))
                    } else {
                    }
                })
        }
        , [buildingId, fetchBuildingSummaryNow])


    //buildingSummary更新后，自动重定向
    const match1 = useMatch(`/game/building/:buildingId/facilities`)
    const match2 = useMatch(`/game/building/:buildingId/supervisor`)
    const match3 = useMatch(`/game/building/:buildingId/storage`)
    const bDefaultRoute = BuildingDefaultRoute(buildingSummary)
    useEffect(() => {
            //     //刷新后，没fetch的redux默认建筑id为-1。防止跳到redux默认建筑的work tab的那一秒钟。不能防止刷新后强行跳转到特色tab
            //     if (buildingSummary.id !== -1 && !isMount) {
            if (buildingSummary.id !== -1 && !match1 && !match2 && !match3) {
                navigate("" + bDefaultRoute, {replace: true})
            }
        }
        , [buildingSummary.id])

    //建筑出售的popover
    const [buildingSellPopoverOpen, setBuildingSellPopoverOpen] = useState(false)

    return <div className={"building-main-root"}>
        <div className={"top-container"}>
            <div className={"building-top-container-back-icon"}>
                <IconButton onClick={() => navigate(-1)} className="back"
                            aria-label="goBack">
                    <KeyboardBackspaceRounded/>
                </IconButton>
            </div>
            <div className={"building-top-container-avatar"}>
                <Avatar sx={{width: '3.7vw', height: '3.7vw'}} src={buildingSummary.avatar}
                        variant="rounded">{buildingSummary.name[0]}</Avatar>

                {isSelling ? <Tooltip title={"该建筑在售！"}><Button variant={"outlined"} color={"happiness"}
                        // onClick={() => setBuildingSellPopoverOpen(true)}
                                                               onClick={() => navigate(`/game/bazaar/buildings/view`)}
                    ><Sell/></Button></Tooltip>
                    : null}
                <BazaarBuildingPopover sell={{}} open={buildingSellPopoverOpen}
                                       onClose={() => setBuildingSellPopoverOpen(false)}/>

            </div>
            <div className={"building-top-container-building-topic-infos"}>
                <div className={"location-text"}>京兆郡 {' > '} 长安县 {' > '}
                    <span className={"region-text"}>
                        {districtInfo(cityStructure, buildingSummary.belong_districts)}</span>
                    {/*<span className={"region-status-text"}>*/}
                    {/*                <span style={{color: "#75ba38"}}> 卫生100%</span> <span*/}
                    {/*    style={{color: "#e84653"}}>繁荣100%</span> <span style={{color: "#46b5e8"}}>产业100%</span>*/}
                    {/*            </span>*/}
                </div>
                <div className={"building-name"}>{buildingSummary.name}
                    {/*<span className={"building-dupes"}>x20联</span>*/}
                </div>
                <div className={"type-owner-manager"}>{BPBuildingInfo(buildingSummary.type)?.name + constructionHint}
                    <span
                        style={{color: "#ff8742"}}>
                        {TierToStars[buildingSummary.tier]}
                    </span> - <Link
                        to={"/game/personal/" + buildingSummary.owner.uid}
                        className={"link"}>{buildingSummary.owner.nick_name}</Link>所有 - <Link
                        to={"/game/personal/" + buildingSummary.manager.uid}
                        className={"link"}>{buildingSummary.manager.nick_name}</Link>管理
                </div>
                <div className={"status-table"}>
                    <div className={"status-table-status"}>
                        <div>{BPBTierInfo(buildingSummary).designed_area}</div>
                        <div>{(buildingSummary.stage === 1 || buildingSummary.stage === 2) ?
                            BPBTierInfo(buildingSummary).designed_area :
                            (BPBTierInfo(buildingSummary).designed_area * buildingSummary.percent / 100).toFixed(1)
                        }</div>
                        <div>100%</div>
                        <div>{buildingSummary.max_work_count ?? 0}</div>
                        <div>{buildingSummary.worker_count ?? 0}</div>
                    </div>
                    <div className={"status-table-category"}>
                        <div>规划面积</div>
                        <div>实际面积</div>
                        <div>生产效率</div>
                        <div>规划工人</div>
                        <div>在职工人</div>
                    </div>
                </div>

            </div>
        </div>
        <div className={"detail-page"}>
            <div className={"flowchart-container"}>
                <Flowchart buildingType={buildingSummary.type}
                           worker_count={buildingSummary.worker_count === undefined ? 0 : buildingSummary.worker_count}
                           today_worked_count={buildingSummary.today_worked_count === undefined ? 0 : buildingSummary.today_worked_count}
                           max_storage_capacity={buildingSummary.max_storage_capacity === undefined ? 1 : buildingSummary.max_storage_capacity}
                           storage_capacity={buildingSummary.storage_capacity === undefined ? 0 : buildingSummary.storage_capacity}
                           stage={buildingSummary.stage === undefined ? 2 : buildingSummary.stage}
                />
            </div>
            <div className={"detail-page-left"}>
            </div>
            <div className={"detail-page-mid"}>
                <Routes>
                    {/*建筑种类的默认导航位置在mapping table，未完成建筑(开垦、建设)的默认导航在下面*/}
                    <Route index element={<Navigate
                        to={BuildingDefaultRoute(buildingSummary)} replace/>}/>
                    <Route path={`/facilities/*`} element={<Facility buildingId={buildingSummary.id}
                                                                     buildingType={buildingSummary.type}
                    />}/>
                    {/*<Route path={`/${buildingSummary.id}/works/*`}*/}
                    <Route path={`/works/*`}
                           element={<Work/>}/>
                    <Route path={`/Storage/*`} element={<Storage/>}/>
                    {/*<Route path={`/OrderFood/*`} element={<OrderFood/>}/>*/}
                    {/*新版餐馆，做好了但是后端不通先不实装*/}
                    <Route path={`/OrderFood/*`} element={<RestaurantMenu/>}/>
                    <Route path={`/OrderFood/EditMenu/*`} element={<RestaurantEditMenu/>}/>
                    <Route path={`/supervisor/*`} element={<Supervisor/>}/>
                    <Route path={`/residence/*`} element={<Residence/>}/>
                    <Route path={`/recipe/*`} element={<Recipe/>}/>
                    <Route path={`/recipe/edit/:recipeId/*`} element={<EditRecipe/>}/>
                    <Route path={`/GuestRooms/*`} element={<GuestRooms/>}/>
                    <Route path={`/agent/*`} element={<Agent/>}/>
                </Routes>
            </div>
            <div className={"detail-page-right"}>
                {buildingSummary.architect.uid === 0 ? null :
                    <div className={"architect"}>
                        建筑师：<Link to={"/game/personal/" + buildingSummary.architect.uid} className={"link"}>
                        {buildingSummary.architect.nick_name}</Link>

                    </div>
                }
                {/*{buildingSummary.remarks === "" ? null :*/}
                <div className={"remarks"}>建筑附言：{buildingSummary.remarks}</div>
                {/*}*/}
            </div>
        </div>
        {/*右上方建筑插画*/}
        <img
            src={process.env.PUBLIC_URL + "./img/buildings/" + BPBuildingInfo(buildingSummary.type)?.Eng + "_" + ((buildingSummary.culture === 0) ? "east" : "west") + ".webp"}
            onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
                event.currentTarget.src = (process.env.PUBLIC_URL + "/img/undefined.webp");
                event.currentTarget.onerror = null;
            }}
            className={"right-up-image"} alt={""}/>
    </div>;
}

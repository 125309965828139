import "./GuestRooms.scss"
import * as React from "react";
import {Delete} from "@mui/icons-material";
import {Divider} from "@mui/material";
import {RoomContractCard, RoomContractEmployeeFreeCard, RoomContractOwnerFreeCard, RoomGuestCard} from "./RoomCard";
import {getBPResidence} from "../../../utils/storage";
import {useSelector} from "react-redux";
import {BuildingTypeTierToChs} from "../../../components/gadgets";

export default function GuestRooms() {
    const state = useSelector((state: any) => state.state.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const residence = buildingSummary
    const residenceInfo = getBPResidence().find((b: any) => b.buildingId === residence?.type)
    const residenceTierInfo = residenceInfo?.tiers.find((t: any) => t.tier === residence?.tier)
    const leasingContracts = buildingSummary.residence?.leasingContracts
    const guests = buildingSummary.residence?.guests

    //我是管理员/所有者
    const IGovern = ((state.uid === buildingSummary.owner.uid) || (state.uid === buildingSummary.manager.uid));


    return <div className={"guest-rooms-root"}>

        <div className={"guest-rooms-title"}>客房
        </div>
        <div className={"guest-rooms-content"}>
            <div className={"room-info"}>
                <div className={"description"}>
                    <div className={"selector"}>（
                        <span className={residence?.type === 5 ? "black" : ""}>藏身陋室</span>/
                        <span className={residence?.type === 39 ? "black" : ""}>棚屋</span>/
                        <span className={residence?.type === 37 ? "black" : ""}>楼房</span>/
                        <span className={residence?.type === 38 ? "black" : ""}>宅院</span>）
                    </div>
                    <div>自带围墙和院子的体面之家。</div>
                    <div>舒适的环境。</div>
                    <br/>
                    <div className={"selector"}>作为房客入住后，即可：</div>
                    <div className={"selector"}>1享受 <b>{BuildingTypeTierToChs(residence)}</b> 的换日属性基础回复和私人库存容量。</div>
                    <div className={"selector"}>2将你的藏身处搬迁到这座住房所处的位置。</div>
                </div>
                <Divider orientation="vertical"/>
                <div className={""}>
                    <div className={"status"}>
                        <div className={"sub-title"}>-<b> {BuildingTypeTierToChs(residence)} </b>换日属性基础回复：</div>
                        <div className={"black-text"}>
                            <img src={process.env.PUBLIC_URL + `/img/status/statusEnergy.svg`}
                                 className={"type-icon"} alt={"type-icon"}/><span className={"energy"}>
                            精力+{residenceTierInfo?.recover.energy}</span>直到{residenceTierInfo?.recoverCap.energy}
                        </div>
                        <div className={"black-text"}>
                            <img src={process.env.PUBLIC_URL + `/img/status/statusHappiness.svg`}
                                 className={"type-icon"} alt={"type-icon"}/><span className={"happiness"}>
                            快乐+{residenceTierInfo?.recover.happiness}</span>直到{residenceTierInfo?.recoverCap.happiness}
                        </div>
                        <div className={"black-text"}>
                            <img src={process.env.PUBLIC_URL + `/img/status/statusHealth.svg`}
                                 className={"type-icon"} alt={"type-icon"}/><span className={"health"}>
                            健康+{residenceTierInfo?.recover.health}</span>直到{residenceTierInfo?.recoverCap.health}
                        </div>
                    </div>

                    <div className={"status"}>
                        <div className={"sub-title"}>- <b>{BuildingTypeTierToChs(residence)}</b>提供的私人库存：</div>
                        <div className={"black-text"}><Delete className={"type-icon"}/>容量{residenceTierInfo?.storage}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"rooms"}>
                {IGovern ? <RoomContractOwnerFreeCard/> : null}
                {residence.residence?.freeForEmployees ? <RoomContractEmployeeFreeCard/> : null}
                {leasingContracts?.map((contract: any) =>
                    <RoomContractCard contract={contract} key={contract.id}/>
                )}
                {guests?.map((guest: any) =>
                    <RoomGuestCard player={guest} key={guest.id} />
                )}
            </div>
        </div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
import "./BazaarItemSeller.scss"
import {Avatar, Tooltip} from "@mui/material";
import {BPBuildingInfo, districtIdToName, itemInfo} from "../../../../components/gadgets";
import {TierToStars} from "../../../../components/mappingTable";
import {shippingLossTip} from "../../../../components/ToolTips";
import {Item} from "../../../../components/IconImg";
import * as React from "react";
import {useSelector} from "react-redux";

export default function BazaarItemSeller(props:{
    sell:any
}){
    const sell = props.sell
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    //卖货卖家的基础条目结构
    return <div className={"list-item-building bazaar-item-seller"}>
            <Avatar alt={sell.from.name} src={sell.from.avatar}
                    sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                    variant="rounded"
            >{sell.from.name?.[0]}</Avatar>

            <div className={"two-story-text"}>
                <div className={"up"}>
                    {sell.from.name}
                </div>
                <div className={"down"}>
                    {BPBuildingInfo(sell.from.type)?.name}{TierToStars[sell.from.tier]}{" - "}
                    {sell.agent.id === 0 ? (districtIdToName(sell.from.belong_districts, cityStructure) +
                        "：距离我0里（损耗3%") : "使用贸易站位置（损耗0%"}

                    <Tooltip title={shippingLossTip} arrow
                             disableInteractive><u>🛈</u></Tooltip>）
                </div>
            </div>
            <div className={"two-story-text right margin-left-auto"}>
                <div className={"up"}>
                    &nbsp;
                </div>
                <div className={"down"}>
                    供应
                </div>
            </div>
            <Item id={sell.selling.id} t={sell.selling.tier} ml={0.3} mr={0.3}/>
            <div className={"two-story-text four-vw"}>
                <div className={"up"}>
                    {(sell.selling.quantity).toFixed(2)}
                </div>
                <div className={"down"}>
                    {itemInfo(sell.selling.id)?.Chs}{TierToStars[sell.selling.tier]}
                </div>
            </div>
            <div className={"two-story-text right"}>
                <div className={"up"}>
                    &nbsp;
                </div>
                <div className={"down"}>
                    交换
                </div>
            </div>
            <Item id={sell.buying.id} t={sell.buying.tier} ml={0.3} mr={0.3}/>
            <div className={"two-story-text five-vw"}>
                <div className={"up happiness"}>
                    {(sell.buying.quantityPerItem).toFixed(2)}/个
                </div>
                <div className={"down"}>
                    {itemInfo(sell.buying.id).Chs}{TierToStars[sell.buying.tier]}🛈
                </div>
            </div>
        </div>
}
import "./DistrictBuildingsPreview.scss"
import {Avatar, ListItemButton} from "@mui/material";
import {Link} from "react-router-dom";
import {BPBuildingInfo} from "../../../components/gadgets";
import {TierToStars} from "../../../components/mappingTable";
import {constructionHint, FindBuildingCurrentArea} from "../../Building/components/WorkCard/WorkCard";
import * as React from "react";
import {BuildingInList} from "../../../components/IconImg";


//浏览模式，单个街区里的建筑列表
export function OverviewBuildingsRender(props: {
    buildings: []
}) {
    return <div className={"overview-buildings-render"}>
        {
            (props.buildings.length === 0) ?
                (<ListItemButton onClick={() => {
                }} className={"list-item list-footnote no-building-note"}>
                    没有建筑，尚未开发的街区。
                </ListItemButton>) : (
                    props.buildings.map((building: any, index: any, array: any) =>
                        <ListItemButton key={"districtOverview_" + building.id} disableRipple
                                        alignItems="flex-start"
                                        onClick={() => {
                                        }}
                                        component={Link}
                                        className={"list-item list-item-building"}
                                        to={`/game/building/${building.id}/`}
                        >
                            <div className={"form-maker"}>{(array.length - 1 === index) ? "└" : "├"}</div>
                            <Avatar alt={building.name} src=" "
                                    sx={{width: "2.5vw", height: "2.5vw"}}
                                    variant="rounded"
                                    className={"avt"}
                            >{building.name[0]}</Avatar>
                            <div className={"two-story-text"}>
                                <div className={"up"}>
                                    {building.name}
                                </div>
                                <div className={"down"}>
                                    {BPBuildingInfo(building.type)?.name + TierToStars[building.tier] + constructionHint(building)}
                                </div>
                            </div>
                            <div className={"two-story-text owner-name margin-left-auto right"}>
                                <div className={"up"}>
                                    {building.owner.nick_name}
                                </div>
                                <div className={"down"}>
                                    地产主人
                                </div>
                            </div>
                            <div className={"two-story-text area-taken four-vw right"}>
                                <div className={"up"}>
                                    {FindBuildingCurrentArea(building).toFixed(1)}
                                </div>
                                <div className={"down"}>
                                    占地面积
                                </div>
                            </div>
                            <BuildingInList id={building.type}/>
                        </ListItemButton>)
                )}</div>
}
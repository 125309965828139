import "./ItemSelector.scss"
import "./PickItemList.scss"
import {Item} from "../IconImg";
import {useRef, useState} from "react";
import {AddPhotoAlternate, PanToolAlt} from "@mui/icons-material";
import * as React from "react";
import {Divider, Popover, TextField, Typography} from "@mui/material";
import {BPFoodMaterials, BPFuels, BPItems} from "../gadgets";


//道具池改变按钮说明文字
const text = (poolText: string | undefined) => {
    switch (poolText) {
        case"foodMaterial":
            return "食材"
        case "fuel":
            return "燃料"
        default:
            return "物品"
    }
}

function PickItemList(props: {
    pickItem: ([id, tier]: number[]) => void
    pool?: "foodMaterial" | "fuel",
}) {
    //道具池
    const items = () => {
        switch (props.pool) {
            case"foodMaterial":
                return BPFoodMaterials
            case "fuel":
                return BPFuels
            default:
                return BPItems
        }
    }
    const pick = (id: number, tier: number) => {
        props.pickItem([id, tier])
    }

    //搜索栏
    const [searchName, setSearchName] = useState("")
    const [itemsSearched, setItemsSearched] = useState(items())
    const updateSearchName = (name: string) => {
        setSearchName(name)
        setItemsSearched(items()?.filter((item: any) =>
            item.Chs.includes(name) || item.Eng.includes(name)
        ))
    }


    return <div className={"pick-item-list-root"}>
        <div className={"title"}>选择一件{text(props.pool)}</div>
        <Divider/>
        <TextField size="small" value={searchName} autoFocus placeholder={"按名称搜索"}
                   onChange={(event) => {
                       updateSearchName(event.currentTarget.value)
                   }}
        />
        <div className={"list"}>
            <div className={"row"}>
                <div className={"name"}>取消选择</div>
                <div className={"pointer"} onClick={() => pick(-1, 1)}>
                    <Item id={-1} t={1} Eng={"null"} title={"取消选择"} mr={0.5}/>
                </div>
            </div>
            {itemsSearched.map((item: any, index: number) =>
                <div className={"row"} key={item.id}>
                    <div className={"name"}>{item.Chs}</div>
                    {[1, 2, 3, 4].map((tier: number, index2: number) =>
                        <div className={"pointer"} onClick={() => pick(item.id, tier)} key={tier}>
                            <Item id={item.id} t={tier} key={tier} mr={0.5}/>
                        </div>
                    )}
                </div>
            )}
        </div>
    </div>

}

export default function ItemSelector(props: {
    pickItem: (id: number, tier: number) => void
    size?: number,
    ml?: number,
    mr?: number,
    //道具池。全道具留空，食材foodMaterial，燃料fuel
    pool?: "foodMaterial" | "fuel",
    //覆盖按钮显示文字
    title?: string,
    default?: [id: number, tier: number],
}) {
    const [selectedId, setSelectedId] = useState(props.default?.[0] ?? -1)
    const [selectedTier, setSelectedTier] = useState(props.default?.[1] ?? 1)

    //按钮显示文字
    const title = props.title ?? ("选择" + text(props.pool))

    //popover的相关。太tm多了
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'item-selector-popover' : undefined;
    //列表点选物品
    const setItem = ([id, tier]: number[]) => {
        setSelectedId(id)
        setSelectedTier(tier)
        props.pickItem(id, tier)
        handleClose()
    }

    return <div className={"item-selector-root"}
                style={{marginRight: ((props.mr ?? 0) + "vw"), marginLeft: ((props.ml ?? 0) + "vw")}}>
        {(selectedId === -1) ?
            <Item id={-1} t={1} Eng={"blank"} title={"选择物品"} size={props.size}/> :
            <div className={"flex-box"} onClick={handleClick}>
                <Item id={selectedId} t={selectedTier} size={props.size}/>
            </div>
        }
        <div className={"backdrop"} style={(selectedId < 0) ? {} : {opacity: 0, pointerEvents: "none"}}
             ref={anchorEl}
             onClick={handleClick}>
            <PanToolAlt/>
            <div className={"description-text"}>{title}</div>
        </div>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <PickItemList pickItem={setItem} pool={props.pool}/>
        </Popover>
    </div>
}
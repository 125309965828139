import "./Agent.scss"
import {Button, Divider, ListItemButton, TextField, Tooltip} from "@mui/material";
import BazaarItemSeller from "../../../Bazaar/BazaarItems/component/BazaarItemSeller";
import BazaarItemAgent from "../../../Bazaar/BazaarItems/component/BaazaarItemAgent";
import * as React from "react";
import {useState} from "react";
import {openSnackbar} from "../../../appSnackbarSlice";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AgentItemsView from "./View";
import {Floor2} from "../../../components/gadgets";

export default function Agent(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const agent = buildingSummary.agent


//购买成功的snackbar
    const buySuccessSnackbar = () => {
        dispatch(openSnackbar({
            "severity": "success",
            "snackbarMessage": "购买成功，在藏身处和卖家建筑之间交换了货物。",
        }))
    }

    return <div className={"building-agent-root"}>

        <div className={"building-function-title"}>
            寄售
        </div>
        <div className={"agent-infos"}>
            <div className={"tax-container"}>
                <div className={"tax"}>{Floor2(agent?.tax * 100)}%</div>
                <div className={"description"}>寄售税率</div>
            </div>
            <div className={"history-data"}>
                <div className={""}>贸易站为寄售的货物订单大量减免运输损耗</div>
                <div className={""}>历史完售货物订单数量：{agent?.total_sale}</div>
                <div className={""}>昨日完售货物订单数量：{agent?.yesterday_sale}</div>
                <Tooltip title={"从你拥有/管理的建筑出售物品时选择通过贸易站寄售"}>
                    <div className={""}>想要寄售？</div>
                </Tooltip>
            </div>
        </div>
        <div className={"filters-container"}>
            <div className={"title"}>此处寄售的货物订单</div>
            <div
                className={"description"}>可用寄售货物重量{Math.floor(agent?.capacity - agent?.capacity_taken)}/{agent?.capacity}</div>
        </div>
        <Divider variant={"middle"}/>
        <AgentItemsView/>
        <div className={""}></div>
        <div className={""}></div>
        <div className={""}></div>
    </div>
}
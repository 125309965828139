import "./CookingMethodButton.scss"
import * as React from "react";
import {getBPCookingMethods} from "../../../../utils/storage";

export default function CookingMethodButton(props: {
    method: number,
    selected: boolean,
    disabled?: boolean,
    pickMethod: (id: number) => void,
}) {
    return <div
        className={"cooking-method-button-root " + (props.selected ? "selected " : " ") + (props.disabled ? "disabled " : " ")}
        onClick={props.disabled ? (() => {
        }) : (() => props.pickMethod(props.method))}
    >
        <img src={require("./assets/cooker/cooker"+ (props.method + 1) +".webp").default} alt="cooker" className="cooker-img"/>
        <div className={"chs"}>{getBPCookingMethods().find((c: any) => c.id === props.method)?.Chs}</div>
    </div>
}
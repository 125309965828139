import "./view.scss"
import {useEffect, useState} from "react";
import {openSnackbar} from "../../../appSnackbarSlice";
import {Avatar, Button, ListItemButton, TextField, Tooltip} from "@mui/material";
import {BPBuildingInfo, districtIdToName, itemInfo} from "../../../components/gadgets";
import {TierToStars} from "../../../components/mappingTable";
import {shippingLossTip} from "../../../components/ToolTips";
import {Item} from "../../../components/IconImg";
import {BalanceRounded} from "@mui/icons-material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import BazaarItemSeller from "../component/BazaarItemSeller";
import BazaarItemAgent from "../component/BaazaarItemAgent";
import {api_with_auth} from "../../../utils/api";
import {fetchStatusNow} from "../../../appSlice";
import Filler from "../../../components/Filler";


export default function BazaarItemsView(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)

    const [bazaarItems, setBazaarItems] = useState<any>({
            "page": 0,
            "pageSize": 20,
            "totalPages": 20,
            "totalResults": 300,
            "content": [
                {
                    //订单id
                    "id": 1,
                    //卖方建筑
                    "from": {
                        //建筑id
                        "id": 1,
                        //建筑名字
                        "name": "寻找订单中",
                        //建筑头像
                        "avatar": "",
                        //种类
                        "type": 2,
                        //星级
                        "tier": 1,
                        //所在城市
                        "belong_city": 0,
                        //所在街区
                        "belong_districts": 0,
                        //所在地形
                        "belong_terrain": 0,
                    },
                    //寄售的贸易站建筑。如果没有通过贸易站，不返回或者返回id=-1都可以
                    "agent": {
                        "id": 0,
                        "name": "中介1",
                        "avatar": "",
                        "type": 41,
                        "tier": 1,
                        "belong_city": 0,
                        "belong_districts": 0,
                        "belong_terrain": 0,
                    },
                    //卖方玩家
                    "seller": {
                        "uid": 1,
                        "nick_name": "",
                        "avatar": ""
                    },
                    //出售的货物
                    "selling": {
                        "id": 1,
                        "tier": 1,
                        //出售货物的数量，有人购买时实时更新
                        "quantity": 1,
                        //创建订单时候的总量
                        "quantityWhenCreated": 1,
                    },
                    //交换的货物
                    "buying": {
                        "id": 1,
                        "tier": 1,
                        //单价
                        "quantityPerItem": 1,
                    },
                    //订单创建时间戳
                    "create_time": 1,
                    //最后编辑时间戳
                    "edit_time": 1,
                    //订单截止日期。使用游戏日期game_day
                    "available_until": 1,
                    //订单状态。0进行中1已完售2已取消3已超时
                    "status": 0,
                },
            ]
        }
    )
    const emptyBazaarItems = !bazaarItems.content


    //买的数量
    const [buyQuantity, setBuyQuantity] = useState(Array(200).fill(0))
    const changeQuantity = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let newArr = [...buyQuantity]
        newArr[index] = parseFloat(e.target.value)
        setBuyQuantity([...newArr])
    }

    //买
    const buy = (index: number) => {
        //买负数判定
        if (buyQuantity[index] <= 0) {
            dispatch(openSnackbar({
                "severity": "error",
                "snackbarMessage": "不能购买负数货物",
            }))
        } else {
            api_with_auth("/api/bazaar/items/" + bazaarItems.content[index].id + "/buy", "POST", {
                "orderId": bazaarItems.content[index].id,
                "quantity": Math.max((
                        //残货小于0.01时直接购买全部
                        (bazaarItems.content[index].selling.quantity - buyQuantity[index] >= 0 &&
                            bazaarItems.content[index].selling.quantity - buyQuantity[index] < 0.01) ?
                            bazaarItems.content[index].selling.quantity : buyQuantity[index])
                    , 0),
                "buying": bazaarItems.content[index].buying
            })
                .then(res => {
                        if (res.status === "success") {
                            dispatch(openSnackbar({
                                "severity": "success",
                                "snackbarMessage": "购买成功",
                            }))
                        } else {
                            dispatch(openSnackbar({
                                "severity": "error",
                                "snackbarMessage": res.message,
                            }))
                        }
                    },
                );
        }
    }


    //fetch市场物品
    const fetch = () => {
        api_with_auth("/api/bazaar/items", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                    if (res.status === "success") {
                        setBazaarItems(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    useEffect(() => {
        fetch()
    }, [])


//货物列表
    if (emptyBazaarItems) {
        return <div className={"bazaar-items-view-root"}><Filler title={"没有物品正在城市市场出售"}/></div>
    } else {
        return <div className={"bazaar-items-view-root"}>
            {bazaarItems.content?.map((sell: any, index: any) =>
                <ListItemButton className={"bazaar-item-bullet-root"} key={"bazaarItem_" + sell.id} disableRipple>
                    <div className={"bazaar-item-seller-and-others"}>
                        <BazaarItemSeller sell={sell}/>
                        <TextField size={"small"} value={buyQuantity[index]}
                                   onChange={changeQuantity(index)}
                                   onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                   type={"number"} disabled={false}/>
                        <Button variant="text" className={"modify-order"} color={"happiness"}
                                onClick={() => buy(index)}
                                style={{
                                    margin: "0.8vw 0.5vw",
                                    maxHeight: '1.8vw',
                                    minHeight: '2vw',
                                    maxWidth: "4vw",
                                    minWidth: "4vw",
                                    fontSize: '0.8vw',
                                    padding: 0,
                                }}>
                            交易
                        </Button>
                    </div>
                    {sell.agent.id !== 0 ? <BazaarItemAgent sell={sell}/> : null}
                </ListItemButton>
            )}
        </div>
    }
}

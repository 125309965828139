import "./Split.scss"
import "../../../../components/GenericModalStyle/GenericModalStyle.scss"
import * as React from "react";
import {SPVSCheckMark} from "../component/CheckMark";
import {Backdrop, Button, Divider, Fade, Modal} from "@mui/material";
import {Insights} from "@mui/icons-material";
import {ProgressDots} from "../../../../components/ProgressDots";
import {useState} from "react";
import {StatusBar2x2} from "../../../../components/statusBar";
import {useSelector} from "react-redux";
import {BPBuildings, itemInfo} from "../../../../components/gadgets";
import {Item} from "../../../../components/IconImg";
import {BuildingSpecialType, TierToStars} from "../../../../components/mappingTable";

export default function SplitBuilding(props: {}) {
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    const state = useSelector((state: any) => state.state.data)
    const [open, setOpen] = useState(false)
    const ImOwner = buildingSummary.owner.uid === state.uid

    //建筑为正常运作阶段
    const BFunctioning = buildingSummary.stage === 2
    //建筑不是合并后的
    const multiplied = !(!buildingSummary.multiply) || buildingSummary.multiply === 1
    //建筑没有工人
    const noWorkers = !buildingSummary.worker_count
    //建筑没有房客
    const noGuests = BuildingSpecialType(buildingSummary) !== "housing" ||
        buildingSummary.residence?.guests?.length === 0
    //更高一级的建筑信息（如有）
    const upgradedBuilding = BPBuildings.find((b: any) => b.id === buildingSummary.type)?.tiers.find((t: any) => t.tier === (buildingSummary.tier + 1))
    //满足的条件数量
    const fulfilledCount = [multiplied, noWorkers, noGuests, upgradedBuilding].filter(Boolean).length
    //条件总数
    const fulfillNeeded = 4
    //全部满足
    const fulfilledAll = fulfilledCount === fulfillNeeded


    return <div className={"split-building-root"}>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={BFunctioning ? "check" : "cross"}/>建筑处在正常运作阶段。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={multiplied ? "check" : "cross"}/>建筑是一座合并后的建筑。
        </div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noWorkers ? "check" : "cross"}/>建筑没有工人。</div>
        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={noGuests ? "check" : "cross"}/>如果建筑是一座住房，没有房客。
        </div>
        <div className={"execute-btn-container"}>
            <Button variant={"contained"} color={"happiness"}
                    disabled={true}
                // disabled={!ImOwner}
                    onClick={() => {
                        setOpen(true)
                    }}
            >
                {"暂不开放"}</Button>
            {/*{ImOwner ? "详情" : "仅限所有者"}</Button>*/}
        </div>
        <Modal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={"generic-modal-root"}
        >
            <Fade in={open}>
                <div className={"modal-content-root"}>
                    <div className={"title-image title-image1"}/>
                    <div className={"title-image title-image2"}/>
                    <div className={"title"}>拆分建筑</div>
                    <div className={"content-container"}>
                        <Divider variant={"middle"} flexItem>条件（{fulfilledCount}/{fulfillNeeded}）</Divider>
                        <div className={"conditions-container " + (fulfilledAll ? "yes" : "no")}>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={BFunctioning ? "check" : "cross"}/>建筑处在正常运作阶段。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={multiplied ? "check" : "cross"}/>建筑是一座合并后的建筑。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noWorkers ? "check" : "cross"}/>建筑没有工人。
                            </div>
                            <div className={"condition"}><SPVSCheckMark mr={0.5}
                                                                        variant={noGuests ? "check" : "cross"}/>如果建筑是一座住房，没有房客。
                            </div>
                        </div>
                        <Divider variant={"middle"} flexItem>结果</Divider>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>从当前建筑中拆分出一座单独的建筑。</div>
                        <Divider variant={"middle"} flexItem>消耗</Divider>
                        <div className={"condition"}><SPVSCheckMark mr={0.5} variant={"dot"}/>消耗属性来开始建筑升级。</div>
                        <StatusBar2x2 changingValueEnergy={-9} changingValueHappiness={-1.5} changingValueHealth={-1.5}
                                      changingValueHungry={-0}/>
                        <Button variant={"contained"} color={"happiness"} disabled={!fulfilledAll}
                                onClick={() => {
                                }}
                        >
                            {fulfilledAll ? "拆分建筑" : "条件未满足"}</Button>
                    </div>
                    <div className={""}></div>
                </div>
            </Fade>
        </Modal>
        <div className={""}></div>
    </div>
}
import "./Actions.scss"
import {Button} from "@mui/material";
import {getHrefFromStringifyJson} from "./index";
import {api_with_auth} from "../utils/api";
import {openSnackbar} from "../appSnackbarSlice";
import {useDispatch} from "react-redux";


export default function NotifActions(props: { notif: any }) {
    const dispatch = useDispatch()
    const notif = props.notif
    //目标建筑id，或者玩家id
    const targetBuildingId = getHrefFromStringifyJson(props.notif.content)?.id
    const getJsonFromNotif = () => {
        const jsonStart = notif.content.indexOf("[")
        const jsonEnd = notif.content.indexOf("]")
        const json = notif.content.substring(jsonStart + 1, jsonEnd)
        return JSON.parse(json)
    }

    //回应管理员邀请
    const respondManagerInvite = (response1: boolean) => {
        api_with_auth("/api/building/" + targetBuildingId + "/supervisor/invite_manager_response", "POST", {
            "inviteId": getJsonFromNotif().inviteId,
            "response": response1
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": response1 ? "接受了邀请，成为了该建筑管理员" : "回决了管理员邀请",
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }

    //邀请入住
    if (props.notif.type === "residence" && props.notif.keyword === "invited") {
        return <div className={"notif-actions-root"}>
            <Button onClick={() => {
            }} variant={"contained"} color={"happiness"}>接受</Button>
            <Button onClick={() => {
            }} variant={"outlined"} color={"happiness"}>回决</Button>
        </div>
        //邀请成为管理员
    } else if (props.notif.type === "sueprvisor" && props.notif.keyword === "invited") {
        return <div className={"notif-actions-root"}>
            <Button onClick={() => {
                respondManagerInvite(true)
            }} variant={"contained"} color={"happiness"}>接受</Button>
            <Button onClick={() => {
                respondManagerInvite(false)
            }} variant={"outlined"} color={"happiness"}>回决</Button>
        </div>
    } else return <div className={"notif-actions-root"}/>


}
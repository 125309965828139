import "./MethodBullet.scss"
import {Item} from "../../../../components/IconImg";
import {Add, DoubleArrow} from '@mui/icons-material'
import {BPBTierInfo, Floor1, Floor2, no, skillInfo} from "../../../../components/gadgets";
import {PBar} from "../../../../components/statusBar";

export default function MethodBullet(props: {
    method: any,
    n: number,
    noTitle?: boolean
}) {
    const method = props.method
    const n = props.n
    const noTTStyle = {display: props.noTitle ? "none" : "block"}
    return <div className={"method-bullet-component"} style={{width: props.noTitle ? "16vw" : "18.1vw"}}>
        <div className={"sub"} style={noTTStyle}>方案</div>
        <div className={"no"} style={noTTStyle}>{no(n)}</div>
        <div className={"m"}>
            {method?.input ? "" : <Item id={-1} t={1} title={"无需原料"} mr={0.2}/>}
            {method?.input?.map((input: number, index: number) =>
                <div key={input} style={{display: "flex"}}>
                    {(index === 0) ? null : <Add/>}
                    <Item id={input} t={method?.tier_input[index]} mr={0.2}/>
                </div>)}
            <div className={"to"}>
                <div className={"arrow"}><DoubleArrow className={"arrow-icon"}/></div>
                <div className={"skill"}>{skillInfo(method?.skill)?.Chs}</div>
            </div>
            <Item id={method?.output} t={method?.tier_output} mr={0.2}/>
        </div>
    </div>
}

//开垦和建设的建筑的 工作页面，生产方式栏位
export function ConstructionMethodBullet(props: { buildingSummary: any }) {
    const buildingSummary = props.buildingSummary
    const reclamation = Floor1((buildingSummary.stage === 0) ? buildingSummary.percent : 100)
    const construction = Floor1((buildingSummary.stage === 1) ? buildingSummary.percent : ((buildingSummary.stage === 0) ? 0 : 100))
    const reclamationCost = BPBTierInfo(buildingSummary).cost.reclamation
    const constructionCost = BPBTierInfo(buildingSummary).cost.building
    return <div className={"method-bullet-component"}>
        <div className={"sub"}>建设</div>
        <div className={"no"}>{no(buildingSummary.stage + 1)}</div>
        <div className={"m-cons"}>
            <div className={"cons-line"}>
                <div
                    className={"text-me " + ((buildingSummary.stage === 0) ? "active" : "")}>开垦:{reclamation * reclamationCost / 100}/{reclamationCost}
                </div>
                <PBar value={reclamation} color={"#29b6f6"} text={Floor2(reclamation).toString() + "%"}
                      hoverText={"开垦进度"} length={8.5}/>
            </div>
            <div className={"cons-line"}>
                <div
                    className={"text-me " + ((buildingSummary.stage === 1) ? "active" : "")}>建造:{construction * constructionCost / 100}/{constructionCost}
                </div>
                <PBar value={construction} color={"#29b6f6"} text={Floor2(construction).toString() + "%"}
                      hoverText={"建造进度"} length={8.5}/>
            </div>
        </div>
    </div>
}
import "./agent.scss"
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Avatar, Button, ListItemButton} from "@mui/material";
import {BPBuildingInfo, Floor2} from "../../../components/gadgets";
import {TierToStars} from "../../../components/mappingTable";
import {constructionHint, FindBuildingCurrentArea} from "../../../Buildings/Building/components/WorkCard/WorkCard";
import {BuildingInList} from "../../../components/IconImg";
import * as React from "react";
import {openSnackbar} from "../../../appSnackbarSlice";
import {api_with_auth} from "../../../utils/api";

export default function BazaarItemsAgents(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const [bazaarItemsAgents, setBazaarItemsAgents] = useState<any>([
        {
            "id": 170,
            "owner": {
                "uid": 28,
                "nick_name": "玩家",
                "avatar": "",
                "born_city": 0
            },
            "owner_id": 28,
            "manager": {
                "uid": 28,
                "nick_name": "",
                "avatar": "",
                "born_city": 0
            },
            "manager_id": 28,
            "name": "正在寻找贸易站",
            "avatar": "",
            "type": 41,
            "tier": 1,
            "storage_capacity": 600,
            "max_storage_capacity": 2500,
            "belong_city": 0,
            "belong_districts": 2,
            "belong_terrain": 7,
            "culture": 0,
            "stage": 2,
            "percent": 100,
            //以上都是已经有的内容，以下agent是贸易站相关的内容
            "agent": {
                //贸易站接收新货物订单寄售
                "open": true,
                //仅限相同街区的建筑从此处寄售
                "same_district_only": false,
                //总寄售货物总重量上限
                "capacity": 2,
                //已占用的寄售货物总重量
                "capacity_taken": 1,
                //昨日完售货物订单数量
                "yesterday_sale": 0,
                //历史总完售货物订单数量
                "total_sale": 0,
                "tax": 0.02,
            }
        },
    ])


//fetch全城贸易站
    useEffect(() => {
        api_with_auth("/api/bazaar/agents", "GET")
            .then(res => {
                    if (res.status === "success") {
                        setBazaarItemsAgents(res.data)
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }, [])


    // //假装跳转到贸易站建筑
    // const navigateSuccessSnackbar = () => {
    //     dispatch(openSnackbar({
    //         "severity": "success",
    //         "snackbarMessage": "点击之后跳转到了对应的贸易站建筑。",
    //     }))
    // }
    return <div className={"bazaar-items-agents-root"}>
        {bazaarItemsAgents.map((building: any, index: number) =>
            <ListItemButton className={"bazaar-item-agents-bullet-root"} key={"bazaarAgent_" + building.id}
                            disableRipple onClick={() => navigate(`/game/building/` + building.id)}>
                <div className={"list-item-building"}>
                    <Avatar alt={building.name} src={building.avatar}
                            sx={{width: "2.5vw", height: "2.5vw"}}
                            variant="rounded"
                            className={"avt"}
                    >{building.name[0]}</Avatar>
                    <div className={"two-story-text"}>
                        <div className={"up"}>
                            {building.name}
                        </div>
                        <div className={"down"}>
                            {BPBuildingInfo(building.type)?.name}{TierToStars[building.tier]}{constructionHint(building)}
                        </div>
                    </div>
                    <div className={"two-story-text right four-vw margin-left-auto"}>
                        <div className={"up"}>
                            {Floor2(building.agent?.tax * 100)}%
                        </div>
                        <div className={"down"}>
                            税率
                        </div>
                    </div>
                    <div className={"two-story-text right eight-vw"}>
                        <div className={"up"}>
                            {Math.floor((building.agent?.capacity - building.agent?.capacity_taken) || 1)}可用
                        </div>
                        <div className={"down"}>
                            /{Math.floor(building.agent?.capacity || 1)}寄售重量上限
                        </div>
                    </div>
                    <div className={"two-story-text right seven-vw"}>
                        <div className={"up"}>
                            {building.agent?.yesterday_sale}昨日
                        </div>
                        <div className={"down"}>
                            /{building.agent?.total_sale}历史经手
                        </div>
                    </div>
                    <div className={"two-story-text right seven-vw"}>
                        <div className={"up"}>
                            {building.agent?.open ? "营业中" : "歇业"}
                        </div>
                        <div className={"down"}>
                            {building.agent?.same_district_only ? "仅限同街区" : "来者不拒"}
                        </div>
                    </div>
                    {/*<BuildingInList id={building.type}/>*/}
                    <Button variant="text" className={""} color={"happiness"}
                            onClick={() => {
                            }}
                            style={{
                                margin: "0.8vw 0.5vw",
                                maxHeight: '1.8vw',
                                minHeight: '2vw',
                                maxWidth: "4vw",
                                minWidth: "4vw",
                                fontSize: '0.8vw',
                                padding: 0,
                            }}>
                        造访
                    </Button>
                </div>
            </ListItemButton>
        )}
    </div>

}
import "./speechBullet.scss"
import {api_host, api_with_auth} from "../../../utils/api";
import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Avatar, Button, ButtonGroup, Divider, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import {id2color} from "../../../utils/id2color";
import CelebrationIcon from "@mui/icons-material/Celebration";
import OutletIcon from "@mui/icons-material/Outlet";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import TimeToNow from "../../../components/timeToNow";
import {getLoginToken} from "../../../utils/storage";
import {useNavigate} from "react-router-dom";

export default function SpeechBullet(props: {
    speech:any,
    updateSpeech: () => void,
}) {
    const navigate = useNavigate()
    //演讲互动
    const patchSpeech = (id: any, MyReaction: any) => {
        api_with_auth("/api/speech/" + id + "?myReaction=" + MyReaction, "PATCH")
                .then(res => {
                        props.updateSpeech()
                    },
                );
        }
    ;

    return <div className={"speech-bullet"}>
        <ListItem alignItems="flex-start" sx={{padding: "0.5vw 1.2vw 0 1.2vw"}}>
            <ListItemAvatar sx={{minWidth: 0, minHeight: 0, mt: "0.45vw"}}>
                <Avatar alt="speech-pfp" className={"avatar"} src={props.speech.Uavatar}
                        onClick={() =>navigate("/game/personal/"+props.speech.Uid)}
                        style={{cursor: "pointer"}}
                        sx={{
                            width: "3vw",
                            height: "3vw",
                            marginRight: "0vw",
                            bgcolor: id2color(props.speech.Uid)
                        }}>{props.speech.Uname ? props.speech.Uname[0] : ""}</Avatar>
            </ListItemAvatar>
            <ListItemText
                sx={{m: "0.5vw 0 0.3vw 1.2vw", fontWeight: '900'}}
                primary={
                    <div className={"speechtextmidiumblock"}>
                        <Typography
                            sx={{
                                display: 'inline',
                                fontWeight: 900,
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                            fontSize={"1vw"}
                            letterSpacing={"0.05em"}
                        >
                            {props.speech.Uname}
                        </Typography>
                        <Typography
                            sx={{
                                display: 'inline',
                                fontWeight: "500"
                            }}
                            component="span"
                            variant="body2"
                            color="#5a6a77"
                            fontSize={"0.7vw"}
                        >
                            &nbsp;&nbsp;{TimeToNow((props.speech.Time))}
                        </Typography>
                    </div>
                }
                secondary={
                    <div>
                        <Typography
                            sx={{display: 'inline'}}
                            component="span"
                            variant="body2"
                            color="#5a6a77"
                            fontSize={"0.7vw"}
                            fontWeight={500}
                        >
                            📍&nbsp;位于&nbsp;&nbsp;
                        </Typography>
                        <Typography
                            sx={{display: 'inline'}}
                            component="span"
                            variant="body2"
                            color="#5a6a77"
                            fontSize={"0.7vw"}
                            fontWeight={500}
                        >
                            演说位置
                        </Typography>
                        <Typography
                            sx={{lineHeight: "1.8em", mt: "0.4vw"}}
                            style={{wordBreak: "break-word"}}
                            component="div"
                            variant="body2"
                            color="text.primary"
                            fontSize={"0.88vw"}
                        >
                            {props.speech.Content}
                        </Typography>

                        <ButtonGroup
                            variant="text"
                            aria-label="text button group"
                            fullWidth={true}
                            sx={{p: "0 0", minWidth: "100%", maxWidth: "100%"}}
                        >
                            <Button style={props.speech.MyReaction === 1 ? {
                                color: "red",
                                fontWeight: "900",
                                fontSize: "0.8vw",
                                height: "1.5vw",
                            } : {color: "#5a6a77", fontWeight: "500", fontSize: "0.8vw", height: "1.5vw",}}
                                    sx={{padding: 0, minWidth: 0}}
                                    onClick={() => {
                                        if (props.speech.MyReaction === 1) {
                                            patchSpeech(props.speech.ID, 0)
                                        } else {
                                            patchSpeech(props.speech.ID, 1)
                                        }
                                    }}>
                                <CelebrationIcon sx={{fontSize: "1vw"}}/>&nbsp;{props.speech.NumReaction1} </Button>
                            <Button style={props.speech.MyReaction === 2 ? {
                                color: "red",
                                fontWeight: "900",
                                fontSize: "0.8vw",
                                height: "1.5vw",
                            } : {color: "#5a6a77", fontWeight: "500", fontSize: "0.8vw", height: "1.5vw",}}
                                    sx={{padding: 0,}}
                                    onClick={() => {
                                        if (props.speech.MyReaction === 2) {
                                            patchSpeech(props.speech.ID, 0)
                                        } else {
                                            patchSpeech(props.speech.ID, 2)
                                        }
                                    }}>
                                <OutletIcon sx={{fontSize: "1vw"}}/>&nbsp;{props.speech.NumReaction2} </Button>
                            <Button style={props.speech.MyReaction === 3 ? {
                                color: "red",
                                fontWeight: "900",
                                fontSize: "0.8vw",
                                height: "1.5vw",
                            } : {color: "#5a6a77", fontWeight: "500", fontSize: "0.8vw", height: "1.5vw",}}
                                    sx={{padding: 0,}}
                                    onClick={() => {
                                        if (props.speech.MyReaction === 3) {
                                            patchSpeech(props.speech.ID, 0)
                                        } else {
                                            patchSpeech(props.speech.ID, 3)
                                        }
                                    }}>
                                <ThumbDownIcon sx={{fontSize: "1vw"}}/>&nbsp;{props.speech.NumReaction3} </Button>
                        </ButtonGroup>
                    </div>

                }
            />

        </ListItem>
    </div>
}
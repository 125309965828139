import "./sideJob.scss"
import {Button, Divider, ListItemButton} from "@mui/material";
import {Add, Bolt, Category, CategoryOutlined, CompareArrows, RadioButtonUnchecked} from "@mui/icons-material";
import * as React from "react";
import InLineSVGCollection from "../components/inLineSVG";
import {terrainIdToTerrainChs, terrainIdToTerrainEng} from "../components/mappingTable";
import {Item, Skill} from "../components/IconImg";
import {StatusBar2x2} from "../components/statusBar";
import BuildingTab from "../Buildings/Building/components/FlowChart/assets/building_tab_1.webp"
import {getBPSideJobLoots, getLoginToken} from "../utils/storage";
import {useEffect, useState} from "react";
import {CityTerrainArea, FindSkillLevel, Floor1, majorSkillId, skillInfo} from "../components/gadgets";
import {useDispatch, useSelector} from "react-redux";
import {api_host, api_with_auth} from "../utils/api";
import {fetchStatusNow, updateStatusWPayload} from "../appSlice";
import {openSnackbar} from "../appSnackbarSlice";
import WorkingResults from "../components/WorkingResults";

export default function SideJob() {
    const dispatch = useDispatch()
    const status = useSelector((state: any) => state.status.data)
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    //昨天的数据
    const yesterdayGameDayData = useSelector((state: any) => state.twoPreviousGameDays.data[0])
    const BPSideJobLoots = getBPSideJobLoots()
    //暂时资源耗尽判断bool(昨天这个地形上的采集副业人数>城市这个地形总面积/200,95%)
    const drained = (terrain: number) => {
        return ((yesterdayGameDayData.LootTimesByAreaType[terrain] ?? 0) > CityTerrainArea(cityStructure, terrain) / 200)
    }

    //文明和城市特产的css类名
    const specialtyClassName = (loot: any) => {
        if (loot.specialtyRestricted === true) {
            if (loot.civilizationSpecialty === cityStructure.main_land) {
                return " civilization-specialty specialty"
            } else {
                if (loot.citySpecialty.includes(cityStructure.id)) {
                    return " city-specialty specialty"
                } else {
                    return " null specialty"
                }
            }
        } else {
            return ""
        }
    }

    const [selectedType, setSelectedType] = useState("gathering")
    //不用array的index，用id，这样可以直接api传走
    const [selectedId, setSelectedId] = useState(0)
    //找到选择的副业方式
    const selectedSideJob = BPSideJobLoots[selectedType].find((e: any) => e.id === selectedId)

    // //万一城市没有平原，重新设置默认选择项目
    //不写了，赌所有城市都有平原
    // useEffect(() => {
    //     //这逻辑不对啊，BPSideJob是不变的，应该查城市的平原总面积
    //     if (CityTerrainArea(cityStructure, 0) === 0 )
    //     setSelectedId(BPSideJobLoots.gathering[0].id)
    // }, [])

    //副业结果
    const [workingResultsOpen, setWorkingResultsOpen] = useState(false)
    const defaultResult = {
        "input": [],
        "output": [
            {
                "id": 2,
                "tier": 1,
                "quantity": 1
            }
        ],
        "buffs": {
            "random": 1,
        },
        "salary": {
            "id": 0,
            "tier": 0,
            "quantity": 0
        },
        "skills": [
            {
                "id": 216,
                "before": 0,
                "after": 1,
                "thresholdMet": false,
                "breakThrough": false
            },
            {
                "id": 200,
                "before": 0,
                "after": 1,
                "thresholdMet": true,
                "breakThrough": true
            }
        ],
        "characters": null
    }
    const [result, setResult] = useState(defaultResult)

    //执行副业
    const SideJob = () => {
        api_with_auth("/api/sideJob/" + selectedType, "POST", {
            "id": selectedId,
        })
            .then(res => {
                    if (res.status === "success") {
                        setResult(res.data)
                        setWorkingResultsOpen(true)
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "成功进行副业",
                        }))
                        dispatch(fetchStatusNow())
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))

                    }
                },
            );
    }


    return <div className={"side-job-root"}>
        <div className={"l-root"}>
            {/*标题栏*/}
            <div className={"title-line"}>
                <div className={"title"}>
                    我的副业
                </div>
                <div className={"sub-text"}>
                    副业是一种不需要应聘与建筑，不受每日工作次数限制的活动。<br/>花费较少属性，提升较少技能，产出随机物资。
                </div>
            </div>
            <Divider orientation="horizontal" variant="middle" component="div"/>
            {/*副业选项*/}
            <div className={"option-bullets"}>
                <Divider variant={"middle"} className={"category-divider"}>副业采集</Divider>
                {BPSideJobLoots.gathering?.map((gathering: any, index: number) =>
                        // (CityTerrainArea(cityStructure, gathering.id) === 0 ? null :
                        <ListItemButton
                            className={"option-bullet " + (selectedType === "gathering" && selectedId === gathering.id ? "selected" : null)}
                            key={gathering.Eng} disableRipple
                            onClick={() => {
                                if (CityTerrainArea(cityStructure, gathering.id) === 0) {
                                    dispatch(openSnackbar({
                                        "severity": "info",
                                        "snackbarMessage": "本地城市没有" + terrainIdToTerrainChs[gathering.id] + "地形。",
                                    }))
                                } else {
                                    setSelectedType("gathering");
                                    setSelectedId(gathering.id)
                                }
                            }}
                        >
                            <div className={"l"}>
                                <div className={"side-job-option-img"}>
                                    <InLineSVGCollection SVGName={"tera_" + gathering.Eng} size={4}
                                                         color={CityTerrainArea(cityStructure, gathering.id) === 0 ? `grey` : `#46b4e6`}/>
                                    {/*<InLineSVGCollection SVGName={"tera_plain"} size={4} color={`#fd7700`}/>*/}
                                    <img src={require("./assets/grab.webp").default} alt="grab" className={"grab"}/>
                                </div>
                                <div className={"info"}>
                                    <div className={"title"}
                                         style={{color: (CityTerrainArea(cityStructure, gathering.id) === 0 ? `grey` : `#46b4e6`)}}
                                    >{gathering.Chs}</div>
                                    <div
                                        className={"text"}>{(CityTerrainArea(cityStructure, gathering.id) === 0 ? `走遍${cityStructure.name}你也找不到${terrainIdToTerrainChs[gathering.id]}。因此无法进行${gathering.Chs}。` : gathering.description)}</div>
                                    <div className={"detail"}>
                                        {gathering.loots.guarantee?.[0] ? "必定获得：" : null}
                                        {gathering.loots.guarantee?.map((loot: any, index: number) =>
                                            <div className={"loot" + specialtyClassName(loot)}
                                                 key={loot.id + " " + loot.tier}>
                                                <Item id={loot.id} t={loot.tier} size={1.5} mr={0.6}
                                                      key={loot.id + "_" + loot.tier}/>
                                            </div>
                                        )}
                                        {gathering.loots.chance?.[0] ? "随机获得：" : null}
                                        {gathering.loots.chance?.map((loot: any, index: number) =>
                                            <div className={"loot" + specialtyClassName(loot)}
                                                 key={loot.id + "" + loot.tier}>
                                                <Item id={loot.id} t={loot.tier} size={1.5} mr={0.6}
                                                      key={loot.id + "_" + loot.tier}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {(CityTerrainArea(cityStructure, gathering.id) === 0 ?
                                    <div className={"r"}></div>
                                    :
                                    <div className={"r"}>
                                        {(CityTerrainArea(cityStructure, gathering.id) > 2500 ? (
                                            <div>{terrainIdToTerrainChs[gathering.id]}面积{CityTerrainArea(cityStructure, gathering.id)}(&gt;2500)。<span
                                                className={"green"}>产能+10%</span></div>
                                        ) : null)}
                                        {(CityTerrainArea(cityStructure, gathering.id) < 1000 ? (
                                            <div>{terrainIdToTerrainChs[gathering.id]}面积{CityTerrainArea(cityStructure, gathering.id)}(&lt;1000)。<span
                                                className={"red"}>产能-10%</span></div>
                                        ) : null)}
                                        昨日{yesterdayGameDayData.LootTimesByAreaType[gathering.id] ?? 0}人于{terrainIdToTerrainChs[gathering.id]}采集资源<br/>
                                        {(drained(gathering.id)) ? (
                                            <div>采集人数&gt;地形面积/200，{terrainIdToTerrainChs[gathering.id]}上的资源暂时性枯竭了。<span
                                                className={"red"}>产能-5%</span></div>
                                        ) : null}
                                        {(status.firstSideJob ?
                                            <div>今天第一次探索你充满干劲，<span className={"green"}>产能+10%</span></div> : null)}
                                    </div>
                            )}
                        </ListItemButton>
                    // )
                )}
                <Divider variant={"middle"} className={"category-divider"}>副业制造</Divider>
                {BPSideJobLoots.crafting?.map((crafting: any, index: number) =>
                    <ListItemButton
                        className={"option-bullet " + (selectedType === "crafting" && selectedId === crafting.id ? "selected" : null)}
                        key={crafting.Eng} disableRipple
                        onClick={() => {
                            setSelectedType("crafting");
                            setSelectedId(crafting.id)
                        }}
                    >
                        <div className={"l"}>
                            <div className={"side-job-option-img"}>
                                <Item
                                    id={crafting.output.id} t={crafting.output.tier} size={4} mr={0}/>
                            </div>
                            <div className={"info"}>
                                <div className={"title"}>{crafting.Chs}</div>
                                <div className={"text"}>{crafting.description}</div>
                                <div className={"detail"}>使用原料：{crafting.input?.length !== 0 ?
                                    (crafting.input?.map((input: any, index: number) =>
                                        <Item id={input.id} t={input.tier} size={1.5} mr={0.3} key={input.id}/>
                                    ))
                                    : <Item id={-1} t={1} size={1.5} mr={0.3} title={"无需原料"}/>}固定产出：
                                    <Item id={crafting.output.id} t={crafting.output.tier} size={1.5} mr={0.3}/></div>
                            </div>
                        </div>
                        <div className={"r"}>
                        </div>
                    </ListItemButton>
                )}
            </div>
        </div>
        <Divider orientation="vertical" variant="middle" component="div"/>

        {/*右侧详情面板*/}
        <div className={"r-root"}>
            <div className={"title-illustration"}
                 style={{
                     backgroundImage: {
                         "gathering": `url("/img/tera/illustrations/${selectedSideJob.Eng}.webp")`,
                         "crafting": `url("/img/tera/illustrations/plain.webp")`
                     }[selectedType]
                 }}>
                {{
                    "gathering":
                        <div className={"side-job-option-img"}>
                            <InLineSVGCollection
                                SVGName={"tera_" + (selectedSideJob.Eng)}
                                size={4} color={`#46b4e6`}/>
                            <img src={require("./assets/grab.webp").default} alt="grab" className={"grab"}/>
                        </div>,
                    "crafting":
                        <div className={"side-job-option-img"}>
                            <Item id={selectedSideJob?.output?.id}
                                  t={selectedSideJob?.output?.tier} size={4}
                                  mr={0}/>
                        </div>
                }[selectedType]}
            </div>
            <div className={"side-job-title"}>{selectedSideJob.Chs}</div>
            <div className={"detail-container"}>
                <div className={"info"}>{selectedSideJob.description}</div>
                <div className={"info"}>{selectedSideJob.description2}</div>
                <Divider orientation="horizontal" component="div"/>
                <div className={"r-subtitle"}><RadioButtonUnchecked/>相关技能</div>
                <div className={"related-skills"}>
                    <div className={"related-skill img-with-double-info"}>
                        <Skill id={(Math.floor(selectedSideJob.skill / 100) * 100)} mr={0.8}
                               playerProgress={status?.skills?.[majorSkillId(selectedSideJob.skill)]}/>
                        <div className={"double-info"}>
                            <div
                                className={"up"}>{Floor1(FindSkillLevel(status, majorSkillId(selectedSideJob.skill)))}</div>
                            <div
                                className={"down"}>{skillInfo(majorSkillId(selectedSideJob.skill))?.Chs}</div>
                        </div>
                    </div>
                    <div className={"related-skill img-with-double-info"}>
                        <Skill id={selectedSideJob.skill} mr={0.8}
                               playerProgress={status?.skills?.[selectedSideJob.skill]}/>
                        <div className={"double-info"}>
                            <div className={"up"}>{Floor1(FindSkillLevel(status, selectedSideJob.skill))}</div>
                            <div className={"down"}>{skillInfo(selectedSideJob.skill)?.Chs}</div>
                        </div>
                    </div>
                </div>
                <Divider orientation="horizontal" component="div"/>
                {
                    {
                        "gathering": <div>
                            <div className={"r-subtitle"}><CategoryOutlined/>回报物资</div>
                            <div className={"items-line"}>
                                <div className={"front"}>必定获得：</div>
                                {selectedSideJob.loots?.guarantee?.map((item: any, index: number) =>
                                    <div className={"loot right-panel" + specialtyClassName(item)}
                                         key={item.id + " " + item.tier}>
                                        <Item id={item.id} t={item.tier} mr={0}/>
                                    </div>
                                )}
                            </div>
                            <div className={"items-line"}>
                                <div className={"front"}>随机获得：</div>
                                {selectedSideJob.loots?.chance?.map((item: any, index: number) =>
                                    <div className={"loot right-panel" + specialtyClassName(item)}
                                         key={item.id + " " + item.tier}>
                                        <Item id={item.id} t={item.tier} mr={0}/>
                                    </div>
                                )}
                            </div>
                        </div>,
                        "crafting": <div>
                            <div className={"r-subtitle"}><CategoryOutlined/>使用原料</div>
                            {(selectedSideJob.input && selectedSideJob.input.length !== 0) ?
                                <div className={"items-line"}>
                                    <div className={"front"}>使用原料：</div>
                                    {selectedSideJob.input.map((item: any, index: number) =>
                                        <Item id={item.id} t={item.tier} mr={0.3} key={item.id}/>
                                    )}
                                </div>
                                :
                                <div className={"items-line"}>
                                    <div className={"info"}>{selectedSideJob.Chs}不需要原料。</div>
                                </div>
                            }
                            <Divider orientation="horizontal" component="div"/>
                            <div className={"r-subtitle"}><CategoryOutlined/>固定产出</div>
                            <div className={"items-line"}>
                                <div className={"front"}>固定产出：</div>
                                <Item id={selectedSideJob?.output?.id} mr={0.3} t={selectedSideJob?.output?.tier}/>
                            </div>
                        </div>
                    }[selectedType]
                }
                <Divider orientation="horizontal" component="div"/>
                {/*右侧面板修改器环节*/}
                <div className={"r-subtitle"}><CompareArrows style={{transform: "rotate(90deg)"}}/>修改器</div>
                <div className={"buff-container"}>
                    <div className={"buff"}>副业：属性<span className={"green"}>消耗降低</span>，物资<span
                        className={"red"}>产出降低</span>，技能<span className={"red"}>增长降低</span></div>
                    {(selectedType === "gathering" ?
                            <div>
                                {(selectedSideJob.loots?.chance?.length > 0) ?
                                    <div>随机获得：50%概率以65%产能取得额外资源。可以叠加。</div>
                                    : null
                                }
                                {(CityTerrainArea(cityStructure, selectedId) > 2500 ? (
                                    <div>{terrainIdToTerrainChs[selectedId]}面积{CityTerrainArea(cityStructure, selectedId)}(&gt;2500)。<span
                                        className={"green"}>产能+10%</span></div>
                                ) : null)}
                                {(CityTerrainArea(cityStructure, selectedId) < 1000 ? (
                                    <div>{terrainIdToTerrainChs[selectedId]}面积{CityTerrainArea(cityStructure, selectedId)}(&lt;1000)。<span
                                        className={"red"}>产能-10%</span></div>
                                ) : null)}
                                {(drained(selectedId)) ? (
                                    <div>采集人数&gt;地形面积/200，{terrainIdToTerrainChs[selectedId]}上的资源暂时性枯竭了。<span
                                        className={"red"}>产能-5%</span></div>
                                ) : null}
                                {(status.firstSideJob ?
                                    <div>今天第一次探索你充满干劲，<span className={"green"}>产能+10%</span></div> : null)}
                            </div> : null
                    )
                    }

                </div>

                <Divider orientation="horizontal" component="div"/>
                <div className={"r-subtitle"}><Bolt/>属性消耗</div>

                <div className={"status-change"}>
                    <StatusBar2x2 changingValueEnergy={-5} changingValueHappiness={-2} changingValueHealth={-2}/>
                </div>
                <Button variant="contained" className={"execute"}
                        onClick={SideJob}
                >
                    执行
                </Button>
                <WorkingResults type={"sideJob"}
                                open={workingResultsOpen}
                                close={() => {
                                    setWorkingResultsOpen(false)
                                }}
                                result={result}
                />
            </div>
        </div>
        <div className={""}></div>
    </div>
}
import "./ProgressDots.scss"

export function ProgressDots(props: {
    totalSteps: number,
    currentStep: number,
}) {
    //currentStep从0开始数
    return <div className={"progress-dots-root"}>
        <div className={"progress-dots-dash"}/>
        <div className={"progress-dots-dots-container"}>
            {Array(props.totalSteps).fill(0).map((step: any, index: number) =>
                <div className={"progress-dot " + (props.currentStep === index ? "current" : null)} key={step + index}/>
            )}
        </div>
        <div className={"progress-dots-dash"}/>
    </div>
}
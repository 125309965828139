import "./edit.scss"
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {openSnackbar} from "../../../appSnackbarSlice";
import {Button, ListItemButton} from "@mui/material";
import BazaarItemSeller from "../../BazaarItems/component/BazaarItemSeller";
import BazaarItemAgent from "../../BazaarItems/component/BaazaarItemAgent";
import * as React from "react";
import BazaarBuildingSeller from "../component/BazaarBuildingSeller";
import {api_with_auth} from "../../../utils/api";
import Filler from "../../../components/Filler";

export default function BazaarBuildingsEdit(props: {}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cityStructure = useSelector((state: any) => state.cityStructure.data)
    const [bazaarItemsEdit, setBazaarItemsHistory] = useState<any>({
        "page": 0,
        "pageSize": 20,
        "totalPages": 20,
        "totalResults": 300,
        "content": [
            {
                //建筑出售id
                "id": 1,
                //卖方玩家
                "seller": {
                    "uid": 1,
                    "nick_name": "寻找卖家",
                    "avatar": "",
                    //卖家本城市藏身处的位置,要读取时候实时的，不是建筑出售创建瞬间的状态
                    "belong_city": 0,
                    "belong_districts": 2,
                    "belong_terrain": 7,
                },
                //出售的建筑物，建筑物状态要读取时候实时的，不是建筑出售创建瞬间的状态
                "selling": {
                    //建筑id
                    "id": 1,
                    //建筑名字
                    "name": "获取订单中",
                    //建筑头像
                    "avatar": "",
                    //种类
                    "type": 2,
                    //星级
                    "tier": 1,
                    //所在城市
                    "belong_city": 0,
                    //所在街区
                    "belong_districts": 0,
                    //所在地形
                    "belong_terrain": 0,
                    //建筑阶段
                    "stage": 2,
                    //维护度
                    "percent": 100,
                },
                //交换的货物
                "buying": {
                    "id": 1,
                    "tier": 1,
                    "quantity": 1,
                },
                //建筑出售创建时间戳
                "create_time": 1,
                //建筑出售出售最后编辑时间戳
                "edit_time": 1,
                //建筑出售截止日期。使用游戏日期game_day
                "available_until": 1,
                //建筑出售状态。0进行中1已完售2已取消3已超时
                "status": 0,
            },
        ]
    })
    const emptyBazaarItemsEdit = !bazaarItemsEdit.content

    const btnStyle = {
        margin: "0.8vw 0.2vw",
        maxHeight: '1.8vw',
        minHeight: '2vw',
        maxWidth: "4vw",
        minWidth: "4vw",
        fontSize: '0.8vw',
        padding: 0,
    }

//fetch我的出售
    const fetchMySell = () => {
        api_with_auth("/api/bazaar/houses/my", "POST", {
            "page": 1,
            "pageSize": 20
        })
            .then(res => {
                if (res.status === "success") {
                    setBazaarItemsHistory(res.data)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            },);
    }
    useEffect(() => {
        fetchMySell()
    }, [])

    //下架
    const cancel = (sellId: number) => {
        api_with_auth("/api/bazaar/houses/" + sellId + "/cancel", "POST")
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "下架了订单。",
                    }))
                    fetchMySell()
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            },);
    }


    if (emptyBazaarItemsEdit) {
        return <div className={"bazaar-buildings-edit-root"}><Filler title={"你没有建筑在售"}/></div>
    } else {
        return <div className={"bazaar-buildings-edit-root"}>
            {bazaarItemsEdit.content.map((sell: any, index: any) =>
                <ListItemButton className={"bazaar-building-bullet-root"} key={"bazaarItem_" + sell.id} disableRipple>
                    <div className={"bazaar-building-seller-and-others"}>
                        <BazaarBuildingSeller sell={sell}/>
                        {/*<Button variant="text" className={"modify-order"} color={"happiness"}*/}
                        {/*        onClick={() => {*/}
                        {/*            editSuccessSnackbar()*/}
                        {/*        }}*/}
                        {/*        style={btnStyle}>*/}
                        {/*    修改*/}
                        {/*</Button>*/}
                        <Button variant="text" className={"modify-order"} color={"happiness"}
                                onClick={() => {
                                    cancel(sell.id)
                                }}
                                style={btnStyle}>
                            下架
                        </Button>
                    </div>
                </ListItemButton>
            )}
        </div>
    }
}
import MethodBullet from "../../components/MethodBullet";
import * as React from "react";
import {api_host, api_with_auth} from "../../../../utils/api";
import {getLoginToken} from "../../../../utils/storage";
import {BPBuildingInfo, itemInfo, skillInfo} from "../../../../components/gadgets";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {TierToStars} from "../../../../components/mappingTable";
import {useDispatch, useSelector} from "react-redux";
import "./WorkingMethod.scss"

export default function WorkingMethod(props: {}) {
    const dispatch = useDispatch();
    // const buildingSummary = props.building
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)
    //生产方案
    //本地建筑方案列表
    function methodsList() {
        return BPBuildingInfo(buildingSummary.type)?.tiers.find((t: any) => t.tier === buildingSummary.tier)?.working_method
        // return BPBuildingInfo(buildingSummary.type)?.tiers[buildingSummary.tier - 1]?.working_method
    }

    const changeMethod = (methodId: number) => {
        api_with_auth("/api/building/" + buildingSummary.id + "/change_working_method?working_method=" + methodId, "POST",)
            .then(res => {
                if (res.status === "success") {
                    dispatch(fetchBuildingSummaryNow())
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": `成功切换到生产方案${methodId}。` + res.message,
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }
    //因为有buildingSummary,所以不能export只能复制，真的蛋疼
    // 工作方案的文字描述
    const methodDescription = (method: any) => {
        const input2 = method?.input?.map((input: number, index: number) => {
            return ((index === 0) ? "消耗" : "和") + method?.input_factor[index] + itemInfo(input)?.Chs + TierToStars[method.tier_input[index]]
        })
        const input3 = input2 ? (input2?.join('') + ",") : ""
        const output2 = "产出" + itemInfo(method?.output)?.factor * (method?.n ?? 1) + itemInfo(method?.output)?.Chs + TierToStars[method?.tier_output] + "。"
        return "每1产能" + input3 + output2
    }
    //方案使用小技能id
    const methodMinorSkill = (method: any) => {
        return method.skill
    }
    //大技能id。用floor方法
    const methodMajorSkill = (method: any) => {
        return Math.floor(methodMinorSkill(method) / 100) * 100
    }
    //本地建筑的方案文字描述2
    const thisBuildingMD2 = (method: any) => {
        return "工人使用技能" + skillInfo(methodMinorSkill(method))?.Chs + "和" + skillInfo(methodMajorSkill(method))?.Chs + "。"
    }

    return <div className={"working-method"}>
        {methodsList()?.map((method: any, index: number) =>
            <div className={"method-container-container"}>
                <div className={"method-container " + ((buildingSummary.working_method === index) ? "chosen" : "")}
                     onClick={() => changeMethod(index)}>
                    <MethodBullet method={method} n={index + 1}/>
                </div>
                <div className={"m-text"}>
                    <div className={"text1"}>
                        {methodDescription(method)}<br/>
                        {thisBuildingMD2(method)}
                    </div>
                    {buildingSummary.working_method === index ?
                        <div className={"tag"}>当前方案</div> : buildingSummary.in_day_working_method === index ?
                            <div className={"red tag"}>明日方案</div> : <div/>}
                </div>
            </div>
        )}
    </div>
}
import { createSlice } from '@reduxjs/toolkit';
import {getLoginToken} from "./utils/storage";
import {api_host} from "./utils/api";

const slice = createSlice({
    name: 'status',
    initialState: {
        "data": {
            "user_id": 4,
            "hunger": 0,
            "happiness": 0,
            "health": 0,
            "energy": 0,
            "now_city":0,
            "skills": {
                "100": {"type": 1, "level": 1.613463028439279, "saved_level": 0, "breakthrough": 14},
            },
            "firstSideJob":true,
            "employee": [
                {
                    "buildingID": 1,
                    "employee": null,
                    "employeeID": 4,
                    "city": 0,
                    "is_worked": true,
                    "quitting": false
                }
            ],
            "luxury": 0,
            "notificationLastReadTime": 1463675642227554597,
            "lastNotificationTime": 1364771966817983274,
            "lastImportantNotificationTime": 1364771966817983174
        },
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateStatusWPayload: (state, action) => {
            state.data = action.payload;
        },
        fetchStatusNow: (state) => {
            state.fetchNow += 1;
        },
    },
});
export const { updateStatusWPayload, fetchStatusNow } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import * as React from 'react'
import {api_host, api_with_auth} from "../utils/api";
import "./bazaar.scss"
import {
    Avatar,
    Button,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import {useEffect, useState} from "react";
import {getLoginToken} from "../utils/storage";
import {TierToStars} from "../components/mappingTable";
import {BPBuildingInfo, districtIdToName, itemInfo} from "../components/gadgets";
import {BuildingInList} from "../components/IconImg";
import {useSelector} from "react-redux";
import BazaarItems from "./BazaarItems";
import {BazaarTabButtons} from "../components/districtAndBazaarTabs";
import {Navigate, Route, Routes} from "react-router-dom";
import DistrictOverview from "../Buildings/District/Overview";
import Recruit from "../Buildings/District/Recruit";
import BazaarBuildings from "./BazaarBuildings";


export function Bazaar() {
    return (
        <div className="bazaar-root">
            <div className={"left-container"}>
                <div className={"left-menu-container"}>
                    <BazaarTabButtons/>
                </div>
            </div>
            <div className={"right-container"}>
                <Routes>
                    <Route index element={<Navigate
                        to={`/game/bazaar/items`} replace/>}/>
                    <Route path={`/items/*`} element={<BazaarItems/>}/>
                    <Route path={`/buildings/*`} element={<BazaarBuildings/>}/>
                </Routes>


            </div>

        </div>
    )
}


import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {api_host, api_with_auth} from "../../utils/api";
import {
    getBPResidence,
    getLoginToken,
    setBPBuildings, setBPFoodMaterials,
    setBPItems,
    setBPResidence,
    setBPSideJobLoots,
    setBPSkills
} from "../../utils/storage";
import {updateStateWPayload} from "../../appStateSlice";
import {updateMyHideOutStorageWPayload} from "../../myHideOutStorageSlice";
import {useNavigate} from "react-router-dom";
import {updateStatusWPayload} from "../../appSlice";
import {updateCityStructureWPayload} from "../../appCitytStructureSlice";
import {updateMyBuildingsWPayload} from "../../myBuildingsSlice";
import {updateGameDayWPayload} from "./gameDaySlice";
import {updateTwoPreviousGameDaysWPayload} from "./twoPreviousGameDaysSlice";
import * as path from "path";


import {library} from '@fortawesome/fontawesome-svg-core'
import {faBolt, faCheckSquare, faCoffee, faFaceSmile, faHeart} from '@fortawesome/free-solid-svg-icons'
import {fetchMyTransactionsNow} from "../../Transaction/myTransactionsSlice";

library.add(faCheckSquare, faCoffee, faBolt, faFaceSmile, faHeart,)
export default function FirstFetch() {


    const dispatch = useDispatch();
    const fetchStateNow = useSelector((state: any) => state.state.fetchNow)
    const navigate = useNavigate()


    //测试服专用，手动换日测试
    const testInDay = () => {
        api_with_auth("/api/in_day", "GET")
            .then(res => {
                console.log(res)
            })
    }
    // testInDay()


    //游戏日统计数据（本地城市）
    const fetchGameDayNow = useSelector((state: any) => state.gameDay.fetchNow)
    useEffect(() => {
        api_with_auth("/api/game_day", "GET")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(updateGameDayWPayload(res.data))
                    }
                },
            );

    }, [fetchGameDayNow])


    //昨天和前天游戏日统计数据（本地城市）
    const fetchTwoPreviousGameDaysNow = useSelector((state: any) => state.gameDay.fetchNow)
    useEffect(() => {
        api_with_auth("/api/game_day/yesterday_and_the_day_before_yesterday", "GET")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(updateTwoPreviousGameDaysWPayload(res.data))
                    }
                },
            );
    }, [fetchTwoPreviousGameDaysNow])


    useEffect(() => {
        //自己
        api_with_auth("/api/user", "GET")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(updateStateWPayload(res.data))
                    }
                },
            );


        //自己本城市的藏身处
        api_with_auth("/api/hideout", "GET")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(updateMyHideOutStorageWPayload(res.data))
                    }
                },
            );


    }, [fetchStateNow])
    const fetchStatusNow = useSelector((state: any) => state.status.fetchNow)
    const fetchCityStructureNow = useSelector((state: any) => state.cityStructure.fetchNow)

    useEffect(() => {
        //自己的相关status
        api_with_auth("/api/status", "GET")
            .then(res => {
                if (res.status === "success") {
                    dispatch(updateStatusWPayload(res.data))
                    //我所在的城市
                    api_with_auth(`/api/city/${res.data.now_city}`, "GET")
                        .then(res => {
                                if (res.status === "success") {
                                    dispatch(updateCityStructureWPayload(res.data))
                                }
                            },
                        );
                }
            })

    }, [fetchStatusNow, fetchCityStructureNow])


    const updateStateDesign = (url: string, method: (res: any) => void) => {

        api_with_auth("/api/" + url, "GET")
            .then(res => {
                    if (res.status === "success") {
                        method(res.data)
                    }
                },
            );
    }

    //单次获取
    //所有数值设计。街区，总览，蓝图模式可选建筑列表。
    useEffect(() => {
        //建筑
        updateStateDesign("BPModeBuildingsList", setBPBuildings)
        //技能配置
        updateStateDesign("BPModeSkillsList", setBPSkills)
        //物品
        updateStateDesign("BPModeItemsList", setBPItems)
        //副业
        updateStateDesign("BPModeSideJobsList", setBPSideJobLoots)
        //住房
        updateStateDesign("BPResidence", setBPResidence)
        //食材
        updateStateDesign("BPFoodMaterials", setBPFoodMaterials)

        //建筑
        // api_with_auth("/api/BPModeBuildingsList", "GET")
        //     .then(res => {
        //             if (res.status === "success") {
        //                 setBPBuildings(res.data)
        //             }
        //         },
        //     );

        //技能配置
        // api_with_auth("/api/BPModeSkillsList", "GET")
        //     .then(res => {
        //             if (res.status === "success") {
        //                 setBPSkills(res.data)
        //             }
        //         },
        //     );

        //物品
        // api_with_auth("/api/BPModeItemsList", "GET")
        //     .then(res => {
        //             if (res.status === "success") {
        //                 setBPItems(res.data)
        //             }
        //         },
        //     );

        //副业
        // api_with_auth("/api/BPModeSideJobsList", "GET")
        //     .then(res => {
        //             if (res.status === "success") {
        //                 setBPSideJobLoots(res.data)
        //             }
        //         },
        //     );

        //住房
        // api_with_auth("/api/BPResidence", "GET")
        //     .then(res => {
        //             if (res.status === "success") {
        //                 setBPResidence(res.data)
        //             }
        //         },
        //     );
        //
        // //食材
        // api_with_auth("/api/BPFoodMaterials", "GET")
        //     .then(res => {
        //             if (res.status === "success") {
        //                 setBPFoodMaterials(res.data)
        //             }
        //         },
        //     );
        //我的私人交易
        dispatch(fetchMyTransactionsNow())

    }, [])


    //我拥有的建筑列表，我的建筑
    const fetchMyBuildingsNow = useSelector((state: any) => state.myBuildings.fetchNow)
    useEffect(() => {
        api_with_auth("/api/buildings/my", "GET")
            .then(res => {
                    if (res.status === "success") {
                        dispatch(updateMyBuildingsWPayload(res.data))
                    }
                },
            );

    }, [fetchMyBuildingsNow])
}
//指定的某个游戏日统计数据（本地城市）
export const fetchGivenGameDay = (gameDay: number) => {
    api_with_auth("/api/game_day/" + gameDay, "GET")
        .then(res => {
                if (res.status === "success") {
                }
            },
        );
}

//获取某玩家拥有的建筑
export function GetPlayerOwnedBuildings(id: number, finish: (res: any) => void) {
    api_with_auth("/api/buildings/user/" + id, "GET")
        .then(res => {
            finish(res)
        })
}

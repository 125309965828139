import { createSlice } from '@reduxjs/toolkit';
import {getLoginToken} from "./utils/storage";
import {api_host} from "./utils/api";

const slice = createSlice({
    name: 'MyHideOutStorage',
    initialState: {
        "data":
            {
                "id": -1,
                "storages": [
                    {
                        "id": 11,
                        "building_id": 6,
                        "city": 0,
                        "item_type": 2,
                        "item_tier": 1,
                        "item_amount": 1564.3028569938122,
                        "total_things_weight": 938.5817141962873
                    },
                    {
                        "id": 5,
                        "building_id": 6,
                        "city": 0,
                        "item_type": 5,
                        "item_tier": 1,
                        "item_amount": 99238.07053158486,
                        "total_things_weight": 39695.22821263395
                    },
                ]
            }
        ,
        "status": "success",
        "fetchNow": 0,
    },
    reducers: {
        updateMyHideOutStorageWPayload: (MyHideOutStorage, action) => {
            MyHideOutStorage.data = action.payload;
        },
        fetchMyHideOutStorageNow: (MyHideOutStorage) => {
            MyHideOutStorage.fetchNow += 1;
        },
    },
});
export const { updateMyHideOutStorageWPayload, fetchMyHideOutStorageNow } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the MyHideOutStorage. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((MyHideOutStorage) => MyHideOutStorage.counter.value)`
// export const selectStatus = (MyHideOutStorage:any) => MyHideOutStorage.status;
// export const selectStatusFetchNow = (MyHideOutStorage:any) => MyHideOutStorage.status.fetchNow;


export default slice.reducer;

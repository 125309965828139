import "./playerSelector.scss"
import {Avatar, Divider, InputAdornment, TextField} from "@mui/material";
import {Item} from "../IconImg";
import * as React from "react";
import {useState} from "react";
import {api_with_auth} from "../../utils/api";
import {updateMyTransactionsWPayload} from "../../Transaction/myTransactionsSlice";

//玩家选择器弹出列表，包含搜索栏
export function PickPlayerList(props: {
    pickPlayer: (player: any) => void
    pool?: any[],
}) {
    //玩家池（玩家详细信息，不光id）
    const pool = props.pool
    const pick = (player: any) => {
        props.pickPlayer(player)
    }
    const placeHolderPlayer = {
        "uid": -1,
        "nick_name": "占位符玩家",
        "avatar": "",
        "born_city": 0
    }
    const [list, setList] = useState([])
    let timer: any = null
    const findPlayerByName = (name: string) => {
        api_with_auth("/api/user/find/" + name, "GET",)
            .then(res => {
                    if (res.status === "success") {
                        setList(res.data)
                    }
                },
            );
    }
    const [searchName, setSearchName] = useState("")
    const updateSearchName = (name: string) => {
        clearTimeout(timer)
        setSearchName(name)
        timer = setTimeout(() => {
            findPlayerByName(name)
        }, 500)
    }

    return <div className={"pick-player-list-root"}>
        <div className={"title"}>选择目标玩家</div>
        <Divider/>
        <div className={"list"}>
            <div className="row">
                <TextField size="small" value={searchName} autoFocus placeholder={"搜索玩家名"}
                           onChange={(event) => {
                               updateSearchName(event.currentTarget.value)
                           }}
                /></div>
            <div className={"row"}>
                <div className={"name"}>取消选择</div>
                <div className={"pointer"} onClick={() => pick(placeHolderPlayer)}>
                    <Item id={-1} t={1} Eng={"null"} title={"取消选择"} mr={0.5}/>
                </div>
            </div>
            {(list.length) ? list.map((player: any, index: number) =>
                <div className={"row"} key={player.uid} onClick={() => pick(player)}>
                    <div className="player-list-player">
                        <Avatar alt={player.nick_name}
                                src={player.avatar}
                                sx={{width: "2.5vw", height: "2.5vw", marginRight: "0.5vw"}}
                        >{player.nick_name?.[0]}</Avatar>
                        <div className={"two-story-text left-align"}>
                            <div className="up">{player.nick_name}</div>
                            <div className="down">玩家</div>
                        </div>
                    </div>
                </div>) : null
            }
        </div>
    </div>
}

import {createSlice} from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'snackbar',
    initialState: {
        "data": {
            "severity":"info",
            "snackbarMessage": "",
            "open": false,
        }
    },
    reducers: {
        openSnackbar: (state, action) => {
            state.data.severity = action.payload.severity;
            state.data.snackbarMessage = action.payload.snackbarMessage;
            state.data.open = true;
        },
        closeSnackbar: (state) => {
            state.data.open = false;
        }
    },
});
export const {openSnackbar, closeSnackbar} = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectStatus = (state:any) => state.status;
// export const selectStatusFetchNow = (state:any) => state.status.fetchNow;


export default slice.reducer;

import "./EditMenu.scss"
import "../../../../components/ListItemBuilding/listItemBuilding.scss"
import {api_with_auth} from "../../../../utils/api";
import {openSnackbar} from "../../../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../buildingSlice";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Floor1, itemInfo} from "../../../../components/gadgets";
import {Checkbox, Divider, Switch, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import Filler from "../../../../components/Filler";
import {RecipeRender} from "../index";
import ItemSelector from "../../../../components/ItemSelector";
import {TierToStars} from "../../../../components/mappingTable";

export default function RestaurantEditMenu(props: {}) {
    const {buildingId} = useParams()
    const dispatch = useDispatch()
    const status = useSelector((state: any) => state.status.data)
    const navigate = useNavigate()
    const buildingSummary = useSelector((state: any) => state.buildingSummary.data)


    //删除食谱
    const [deleteRecipeWaiting, setDeleteRecipeWaiting] = useState(-1)
    const deleteRecipe = (recipeId: number) => {
        setDeleteRecipeWaiting(recipeId)
        api_with_auth("/api/recipe/" + recipeId + "/delete", "POST")
            .then(res => {
                setDeleteRecipeWaiting(-1)
                if (res.status === "success") {
                    dispatch(fetchBuildingSummaryNow())
                    fetchMenu()
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "删除了食谱",
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }

    //编辑食谱，编辑直接跳转url
    const editRecipe = (recipeId: number) => {
        navigate(`/game/building/` + buildingId + "/recipe/edit/" + recipeId)
    }

    //提交设定
    const [updateRecipeWaiting, setUpdateRecipeWaiting] = useState(-1)
    const updateRecipe = (recipeId: number, index: number) => {
        setUpdateRecipeWaiting(recipeId)
        api_with_auth("/api/restaurant/" + buildingId + "/menu_settings/update", "POST", {
            "id": recipeId,
            "price": {
                "id": menu[index].price?.id,
                "tier": menu[index].price?.tier,
                "quantity": menu[index].price?.quantity
            },
            "freeForWorkers": menu[index].freeForWorkers,
            "freeForGuests": menu[index].freeForGuests,
            "dailyLimit": menu[index].dailyLimit,
            "oneForAll": menu[index].oneForAll
        })
            .then(res => {
                setUpdateRecipeWaiting(-1)
                if (res.status === "success") {
                    fetchMenu()
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": "更新了餐食售卖设定",
                    }))
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            })
    }


    const [menu, setMenu] = useState([
        {
            "id": -1,
            "building_id": -1,
            "name": "获取食谱中",
            "avatar": "",
            "cookingMethod": 0,
            "cookingId": 4,
            "cookingTier": 1,
            "cooking_quantity": 0.1,
            "fuelId": 33,
            "fuelTier": 1,
            "fuelQuantity": 0,
            "materials": [
                {
                    "id": 1,
                    "tier": 1,
                    "quantity": 0
                }
            ],
            "energy": 0,
            "happiness": 0.05,
            "health": 0.2,
            "hunger": 1.3,
            "luxury": 30,
            "selling": false,
            "price": {
                "id": -1,
                "tier": 1,
                "quantity": 0
            },
            "freeForWorkers": false,
            "freeForGuests": false,
            "dailyLimit": 0,
            "oneForAll": false
        }])
    const fetchMenu = () => {
        api_with_auth("/api/restaurant/" + buildingId + "/menu_settings", "GET")
            .then(res => {
                if (res.status === "success") {
                    let m = JSON.parse(JSON.stringify(res.data))
                    m.sort((a: any, b: any) => b.id - a.id)
                    setMenu(m)
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            },);
    }
    useEffect(() => {
        fetchMenu()
    }, [])

    //开关售卖
    const toggleSale = (event: any, index: number) => {
        api_with_auth("/api/restaurant/" + buildingId + "/menu_settings/toogle_sale", "POST", {
            "id": menu[index].id,
            "selling": event.target.checked
        })
            .then(res => {
                if (res.status === "success") {
                    dispatch(openSnackbar({
                        "severity": "success",
                        "snackbarMessage": event.target.value ? "餐食开始出售" : "餐食暂停出售",
                    }))
                    fetchMenu()
                } else {
                    dispatch(openSnackbar({
                        "severity": "error",
                        "snackbarMessage": res.message,
                    }))
                }
            },);
    }

    //设置价格种类
    const setBuyingItem = (id: number, tier: number, index: number) => {
        let b = JSON.parse(JSON.stringify(menu))
        b[index].price.id = id
        b[index].price.tier = tier
        setMenu(b)
    }
    //设置价格数量
    const setBuyingQuantity = (event: any, index: number) => {
        let b = JSON.parse(JSON.stringify(menu))
        b[index].price.quantity = parseFloat(event.target.value)
        setMenu(b)
    }

    //价格未设置
    const noPrice = (recipe: any) => {
        return !(recipe.price.id >0  && recipe.price.quantity >= 0)
    }
    //员工免费，租客免费，每日每人限购一份 三选项
    const setOptions = (event: any, index: number, type: "worker" | "guest" | "limit") => {
        let b = JSON.parse(JSON.stringify(menu))
        switch (type) {
            case "worker":
                b[index].freeForWorkers = event.target.checked
                break
            case "guest":
                b[index].freeForGuests = event.target.checked
                break
            case "limit":
                b[index].oneForAll = event.target.checked
                break
        }
        setMenu(b)
    }
    //每日总限购
    const toggleLimit = (event: any, index: number) => {
        let b = JSON.parse(JSON.stringify(menu))
        b[index].dailyLimit = event.target.checked ? 1 : 0
        setMenu(b)
    }
    const setLimit = (event: any, index: number) => {
        let b = JSON.parse(JSON.stringify(menu))
        b[index].dailyLimit = parseInt(event.target.value)
        setMenu(b)
    }

    return <div className={"restaurant-edit-menu-root"}>
        <div className={"restaurant-edit-menu-title"}>点餐（后厨）
            <div className={"white-button button8"}
                 onClick={() => {
                     navigate("/game/building/" + buildingId)
                 }}>前往前台
            </div>
        </div>
        <div className={"restaurant-edit-menu-content"}>
            {menu?.map((recipe: any, index: number) =>
                <div>
                    {/*餐食*/}
                    <RecipeRender recipe={recipe} key={recipe.id}/>
                    {/*设定*/}
                    <div className={"list-item-building"}>
                        <Switch checked={recipe.selling} onChange={(e) => {
                            toggleSale(e, index)
                        }} disabled={false}/>
                        <div className={"selling " + (recipe.selling ? "active" : "")}>
                            {recipe.selling ? "售卖中" : "暂停出售"}
                        </div>
                        <div className={"right side margin-left-auto"}>
                            <div className={"starter-text"}>餐食价格</div>
                            <ItemSelector mr={0.5} default={[recipe.price.id || -1, recipe.price.tier || 1]}
                                          pickItem={(id, tier) => setBuyingItem(id, tier, index)}/>
                            <div className={"two-story-text required-item"}>
                                {(recipe.price.id > 0) ?
                                    <TextField size="small" className={"up price"} label={"售价"} type={"number"}
                                               value={recipe.price.quantity}
                                               onChange={(e) => setBuyingQuantity(e, index)}
                                    /> :
                                    <div
                                        className={"up " + (recipe.price.id !== -1 ? "" : "emphasis")}>
                                        {(itemInfo(recipe.price.id)?.Chs ?? "选择物品") + TierToStars[recipe.price.tier ?? 1]}
                                    </div>
                                }
                                <div className={"down"}>
                                    {itemInfo(recipe.price.id)?.Chs ? (itemInfo(recipe.price.id)?.Chs + TierToStars[recipe.price.tier]) : ""} -
                                    运输损耗 0%
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*多选选项*/}
                    <div className={"options"}>
                        <div className={"option " + (recipe.freeForWorkers ? "" : "disabled")}><Checkbox
                            checked={recipe.freeForWorkers} onChange={(e) => setOptions(e, index, "worker")}/>免费供应给员工
                        </div>
                        <div className={"option " + (recipe.dailyLimit !== 0 ? "" : "disabled")}><Checkbox
                            checked={recipe.dailyLimit !== 0} onChange={(e) => {
                            toggleLimit(e, index)
                        }}/>每日总计限量供应
                            <TextField size={"small"} value={recipe.dailyLimit}
                                       onChange={(e) => {
                                           setLimit(e, index)
                                       }}
                                       onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                       type={"number"} disabled={recipe.dailyLimit === 0}/>
                            份
                        </div>
                        <div className={"option " + (recipe.freeForGuests ? "" : "disabled")}><Checkbox
                            checked={recipe.freeForGuests} onChange={(e) => setOptions(e, index, "guest")}/>免费供应给租客
                        </div>
                        <div className={"option " + (recipe.oneForAll ? "" : "disabled")}><Checkbox
                            checked={recipe.oneForAll} onChange={(e) => setOptions(e, index, "limit")}/>每人每日限购一份
                        </div>
                    </div>
                    {/*提交的三按钮*/}
                    <div className={"actions"}>
                        <div className={""}
                             onClick={() => deleteRecipe(recipe.id)}>{deleteRecipeWaiting === recipe.id ? "删除中…" : "删除食谱"}</div>
                        <div className={""} onClick={() => editRecipe(recipe.id)}>编辑食谱
                        </div>
                        <div className={"orange-button button8 " + (noPrice(recipe) ? "disabled" : "")}
                             onClick={() => {
                                 updateRecipe(recipe.id, index)
                             }}>{noPrice(recipe) ? "请设定价格" : (updateRecipeWaiting === recipe.id ? "提交中…" : "提交设定")}</div>
                    </div>
                    <Divider variant={"middle"}/>
                </div>
            )}
            {!menu.length ? <Filler title={"没有在售餐食"}/> : null}
            <Filler/>
        </div>
    </div>
}
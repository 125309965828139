import "./AdminSettings.scss"
import {Button, ListItemButton, TextField} from "@mui/material";
import AddPhotoAvt from "../../components/AddPhotoAvt";
import {fetchStateNow} from "../../appStateSlice";
import React, {useState} from "react";
import {api_host, api_with_auth} from "../../utils/api";
import {openSnackbar} from "../../appSnackbarSlice";
import {fetchBuildingSummaryNow} from "../../Buildings/Building/buildingSlice";
import {useDispatch} from "react-redux";
import {getLoginToken} from "../../utils/storage";

export default function AdminSettings() {
    const dispatch = useDispatch()
    const pw = "0451"
    const [pwInput, setPwInput] = useState("")

    const updateStateDesign = (url: string, body: any) => {
        console.log(body)
        // One-liner comment stripper
        const body2 = body.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m: any, g: any) => g ? "" : m);
        const body3 = body2.replace(/\,(?!\s*?[\{\[\"\'\w])/g, '')
        fetch(api_host() + "/api/admin/" + url, {
            method: "PATCH",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + getLoginToken(),
            },
            body: JSON.parse(body3)
        })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = "/#/login"
                }
                return res.json()
            })
            .then(res => {
                    if (res.status === "success") {
                        dispatch(openSnackbar({
                            "severity": "success",
                            "snackbarMessage": "更新成功",
                        }))
                    } else {
                        dispatch(openSnackbar({
                            "severity": "error",
                            "snackbarMessage": res.message,
                        }))
                    }
                },
            );
    }

    //f12手动发送的示例
    const type = ["BPModeBuildingList", "BPModeSkillsList", "BPModeItemsList", "BPModeSideJobsList"]
    const hostTest = "https://testapi.civitasrise.com"
    const host = "https://api.civitasrise.com"
    const tokenTest = "NDpzdm5sTGwxWFBJR3p0ZmdDRk9ITm9pTGVjZWR4ZUlqMQ=="
    const token = "NDpkNTVmQ0NPeE1BMkExMlJPczQwcGEzb3RHWG9Ed0M0WQ=="
    const manualUpdate = (url: string, body: any) => {
        fetch("https://api.civitasrise.com/api/admin/BPModeBuildingList" , {
            method: "PATCH",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic NDpkNTVmQ0NPeE1BMkExMlJPczQwcGEzb3RHWG9Ed0M0WQ==',
            },
            body: JSON.stringify(
                []
            )
        })
            .then(res => res.json())
            .then(console.log)

    }
    //建筑
    const [buildings, setBuildings] = useState("")
    //技能
    const [skills, setSkills] = useState("")
    //物品
    const [items, setItems] = useState("")
    //副业
    const [sideJobs, setSideJobs] = useState("")


    return <div className="right-content">
        <ListItemButton className="content-bullet" disableRipple>
            <div className="set-ttl">管理员密码验证</div>
            <div className="set-content">
                <TextField size={"small"} value={pwInput}
                           onChange={(e) => {
                               setPwInput(e.target.value)
                           }}
                           type={"number"}/>
            </div>
        </ListItemButton>
        <div style={{display: ((pw === pwInput) ? "block" : "none")}}>
            <ListItemButton className="content-bullet" disableRipple>
                <div className="set-ttl">更新建筑</div>
                <div className="set-content">
                    <TextField size={"small"} value={skills}
                               onChange={(e) => {
                                   setBuildings(e.target.value)
                               }}/>
                    <Button variant="outlined" onClick={() => {
                        updateStateDesign("BPModeBuildingList", buildings)
                    }}>提交建筑</Button>
                </div>
            </ListItemButton>
            <ListItemButton className="content-bullet" disableRipple>
                <div className="set-ttl">更新技能</div>
                <div className="set-content">
                    <TextField size={"small"} value={skills}
                               onChange={(e) => {
                                   setSkills(e.target.value)
                               }}/>
                    <Button variant="outlined" onClick={() => {
                        updateStateDesign("BPModeSkillsList", skills)
                    }}>提交技能</Button>
                </div>
            </ListItemButton>
            <ListItemButton className="content-bullet" disableRipple>
                <div className="set-ttl">更新物品</div>
                <div className="set-content">
                    <TextField size={"small"} value={items}
                               onChange={(e) => {
                                   setItems(e.target.value)
                               }}/>
                    <Button variant="outlined" onClick={() => {
                        updateStateDesign("BPModeItemsList", items)
                    }}>提交物品</Button>
                </div>
            </ListItemButton>
            <ListItemButton className="content-bullet" disableRipple>
                <div className="set-ttl">更新副业</div>
                <div className="set-content">
                    <TextField size={"small"} value={sideJobs}
                               onChange={(e) => {
                                   setSideJobs(e.target.value)
                               }}/>
                    <Button variant="outlined" onClick={() => {
                        updateStateDesign("BPModeSideJobsList", sideJobs)
                    }}>提交副业</Button>
                </div>
            </ListItemButton>

        </div>
    </div>
}
import { deepPurple,deepOrange,red,purple,amber,blue,blueGrey,cyan,green,grey,indigo,lightBlue,lightGreen,lime,yellow,teal,orange,brown,pink } from '@mui/material/colors';

const i2c = [
    pink[400],
    deepPurple[400],
    deepOrange[400],
    red[400],
    purple[400],
    amber[400],
    blue[400],
    blueGrey[400],
    yellow[400],
    cyan[400],
    green[400],
    grey[400],
    indigo[400],
    lightBlue[400],
    lightGreen[400],
    lime[400],
    teal[400],
    orange[400],
    brown[400],
]

export function id2color(id:number) {
    return i2c[id%(i2c.length)]
}

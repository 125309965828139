import "./traits.scss"
import {Floor1} from "../../components/gadgets";

export default function Traits(props: {
    statusDisplayed: any
}) {
    const status = props.statusDisplayed
    const traits = ["luxury"]
    return <div className={"traits-root"}>
        <div className={"trait-root"}>
            <div className="trait_content">
                <img src={process.env.PUBLIC_URL + "/img/traits/luxury_empty.webp"}
                     className={'trait_icon'} alt={"trait_icon"}/>
                <div className="trait_progress_container">
                    <div className="progress_text">
                        <div>0</div>
                        <div>50</div>
                        <div>100</div>
                    </div>
                    <div className="bar">
                        <div className="inner_bar" style={{right: `${100 - status.luxury}%`}}/>
                        <div className="text" style={
                            (status.luxury > 20) ?
                                {right: `${100 - status.luxury}%`, color: "white"} :
                                {left: `${status.luxury}%`, color: "#9c7732"}
                        }>{Floor1(status.luxury)}</div>
                    </div>
                    <div className="dashed"/>
                    <div className="indicator" style={{left: `${status.luxury}%`}}/>
                    <div className="progress_text">
                        <div>节俭</div>
                        <div>中庸</div>
                        <div>奢侈</div>
                    </div>
                </div>
                <img src={process.env.PUBLIC_URL + "/img/traits/luxury_full.webp"}
                     className={'trait_icon'} alt={"trait_icon"}/>
            </div>
            <div className="trait_description">

            </div>
        </div>
    </div>
}
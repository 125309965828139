import "./inLineSVG.scss"

const tera_forest = () => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px"
                viewBox="0 0 50 50" style={{background: 'new 0 0 50 50'}} xmlSpace="preserve">
        <style type="text/css">
            {`.st3{fill:none;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st4{fill:none;stroke-width:6;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1">
            <g>
                <path d="M44.4,4C45.3,4,46,4.7,46,5.6v38.8c0,0.9-0.7,1.6-1.6,1.6H5.6C4.7,46,4,45.3,4,44.4V5.6C4,4.7,4.7,4,5.6,4H44.4 M44.4,0
			H5.6C2.5,0,0,2.5,0,5.6v38.8C0,47.5,2.5,50,5.6,50h38.8c3.1,0,5.6-2.5,5.6-5.6V5.6C50,2.5,47.5,0,44.4,0L44.4,0z"/>
            </g>
        </g>
        <g id="Layer_6">
            <path className="st3"
                  d="M19,9l7,14h-4.3c-0.2,0-0.3,0.2-0.2,0.3L29,36H18.6H8.2l7.6-12.7c0.1-0.1,0-0.3-0.2-0.3H11L19,9z"/>
            <line className="st4" x1="18.6" y1="49" x2="18.6" y2="36"/>
            <path className="st3" d="M28.7,21.2l3-6l7.7,14.5h-3.9c-0.2,0-0.3,0.2-0.2,0.3L42,41.4h-9.4H25l1-1.4"/>
            <line className="st4" x1="32" y1="48" x2="32" y2="41.4"/>
        </g>
    </svg>
}
const tera_hill = () => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px"
                viewBox="0 0 50 50" style={{background: 'new 0 0 50 50'}} xmlSpace="preserve">
        <style type="text/css">
            {`.st1{display:inline;fill:none;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st2{display:inline;fill:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st3{display:inline;fill:none;stroke-width:6;stroke-miterlimit:10;}`}
            {`.st4{fill:none;stroke-width:4;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1">
            <g>
                <path d="M44.4,4C45.3,4,46,4.7,46,5.6v38.8c0,0.9-0.7,1.6-1.6,1.6H5.6C4.7,46,4,45.3,4,44.4V5.6C4,4.7,4.7,4,5.6,4H44.4 M44.4,0
			H5.6C2.5,0,0,2.5,0,5.6v38.8C0,47.5,2.5,50,5.6,50h38.8c3.1,0,5.6-2.5,5.6-5.6V5.6C50,2.5,47.5,0,44.4,0L44.4,0z"/>
            </g>
        </g>
        <g id="Layer_5">
            <path className="st4" d="M2,39l4.1-4.1c4.2-4.2,11.2-3.9,14.9,0.8L31,48"/>
            <path className="st4" d="M20,33l7.9-6.8c3.5-3,8.7-3.1,12.3-0.3L48,32"/>
        </g>
    </svg>

}
const tera_mountain = () => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px"
                viewBox="0 0 50 50" style={{background: 'new 0 0 50 50'}} xmlSpace="preserve">
        <style type="text/css">
            {`.st1{display:inline;fill:none;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st2{display:inline;fill:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st3{display:inline;fill:none;stroke-width:6;stroke-miterlimit:10;}`}
            {`.st4{fill:none;stroke-width:4;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1">
            <g>
                <path d="M44.4,4C45.3,4,46,4.7,46,5.6v38.8c0,0.9-0.7,1.6-1.6,1.6H5.6C4.7,46,4,45.3,4,44.4V5.6C4,4.7,4.7,4,5.6,4H44.4 M44.4,0
			H5.6C2.5,0,0,2.5,0,5.6v38.8C0,47.5,2.5,50,5.6,50h38.8c3.1,0,5.6-2.5,5.6-5.6V5.6C50,2.5,47.5,0,44.4,0L44.4,0z"/>
            </g>
        </g>
        <g id="Layer_4">
            <polyline className="st4" points="2,39 14,22 31,48 	"/>
            <polyline className="st4" points="20,33 35,10 48,32 	"/>
        </g>
    </svg>
}
const tera_plain = () => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px"
                viewBox="0 0 50 50" style={{background: 'new 0 0 50 50'}} xmlSpace="preserve">
        <style type="text/css">
            {`.st0{fill:none; stroke-width:4;stroke-miterlimit:10;}`}
            {`.st1{fill:#FFFFFF; stroke-width:4;stroke-miterlimit:10;}`}
            {`.st2{display:none;}`}
            {`.st3{display:inline;fill:none; stroke-width:4;stroke-miterlimit:10;}`}
            {`.st4{display:inline;fill:none; stroke-width:6;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1">
            <g>
                <path d="M44.4,4C45.3,4,46,4.7,46,5.6v38.8c0,0.9-0.7,1.6-1.6,1.6H5.6C4.7,46,4,45.3,4,44.4V5.6C4,4.7,4.7,4,5.6,4H44.4 M44.4,0
			H5.6C2.5,0,0,2.5,0,5.6v38.8C0,47.5,2.5,50,5.6,50h38.8c3.1,0,5.6-2.5,5.6-5.6V5.6C50,2.5,47.5,0,44.4,0L44.4,0z"/>
            </g>
        </g>
        <g id="Layer_3">
            <line className="st0" x1="1.5" y1="38.5" x2="48.5" y2="38.5"/>
        </g>
        <g id="Layer_2">
            <polyline className="st0" points="39.2,24.2 25,38.5 10.8,24.3 	"/>
            <line className="st1" x1="25" y1="20.5" x2="25" y2="38.5"/>
        </g>
    </svg>
}

const tera_sea = () => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px"
                viewBox="0 0 50 50" style={{background: 'new 0 0 50 50'}} xmlSpace="preserve">
        <style type="text/css">
            {`.st0{display:none;}`}
            {`.st1{display:inline;fill:none ;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st2{display:inline;fill:#FFFFFF ;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st3{display:inline;fill:none ;stroke-width:6;stroke-miterlimit:10;}`}
            {`.st4{fill:none; stroke-width:4;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1">
            <g>
                <path d="M44.4,4C45.3,4,46,4.7,46,5.6v38.8c0,0.9-0.7,1.6-1.6,1.6H5.6C4.7,46,4,45.3,4,44.4V5.6C4,4.7,4.7,4,5.6,4H44.4 M44.4,0
			H5.6C2.5,0,0,2.5,0,5.6v38.8C0,47.5,2.5,50,5.6,50h38.8c3.1,0,5.6-2.5,5.6-5.6V5.6C50,2.5,47.5,0,44.4,0L44.4,0z"/>
            </g>
        </g>

        <g id="Layer_8">
            <path className="st4" d="M1.3,39c1.7,0.4,3.6,0.3,5.1-0.5l6.2-3.3c2-1.1,4.8-1,6.7,0.1l5.4,3.1c1.9,1.1,4.7,1.1,6.7,0.1l5.8-3.1
		c2.1-1.1,5.2-1,7.1,0.3l3.9,2.7"/>
            <path className="st4" d="M1.6,27.8C3.3,27.5,5,26.7,7,25c0,0,4-3,9-8c5.1-5.1,9.2-8,16.5-8h0c1.8,0,3.9,1.2,5.6,2.8
		c2.2,2.1,2.9,5.3,1.9,8.2v0c0,0-1-1-2-2c-2.4-2.4-5-2.8-8-1l0,0c-2.7,1.6-3.8,5.5-2,8l0,0c2.7,3.8,6,6,10.4,4.6l0.1,0
		c2.1-0.7,4.3-0.9,6.5-0.6l3.2,0.4"/>
        </g>

    </svg>
}
const tera_water = () => {
    return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px"
                viewBox="0 0 50 50" style={{background: 'new 0 0 50 50'}} xmlSpace="preserve">
        <style type="text/css">
            {`.st0{display:none;}`}
            {`.st1{display:inline;fill:none;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st2{display:inline;fill:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}`}
            {`.st3{display:inline;fill:none;stroke-width:6;stroke-miterlimit:10;}`}
            {`.st4{fill:none;stroke-width:4;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1">
            <g>
                <path d="M44.4,4C45.3,4,46,4.7,46,5.6v38.8c0,0.9-0.7,1.6-1.6,1.6H5.6C4.7,46,4,45.3,4,44.4V5.6C4,4.7,4.7,4,5.6,4H44.4 M44.4,0
			H5.6C2.5,0,0,2.5,0,5.6v38.8C0,47.5,2.5,50,5.6,50h38.8c3.1,0,5.6-2.5,5.6-5.6V5.6C50,2.5,47.5,0,44.4,0L44.4,0z"/>
            </g>
        </g>
        <g id="Layer_7">
            <path className="st4" d="M1.9,16.7c1.5,0.2,3.2,0,4.5-0.7l6.2-3.3c2-1.1,4.8-1,6.7,0.1l5.4,3.1c1.9,1.1,4.7,1.1,6.7,0.1l5.8-3.1
		c2.1-1.1,5.2-1,7.1,0.3l3.4,2.4"/>
            <path className="st4" d="M1.9,28.3c1.5,0.2,3.2,0,4.5-0.7l6.2-3.3c2-1.1,4.8-1,6.7,0.1l5.4,3.1c1.9,1.1,4.7,1.1,6.7,0.1l5.8-3.1
		c2.1-1.1,5.2-1,7.1,0.3l3.4,2.4"/>
            <path className="st4" d="M1.9,39.2c1.5,0.2,3.2,0,4.5-0.7l6.2-3.3c2-1.1,4.8-1,6.7,0.1l5.4,3.1c1.9,1.1,4.7,1.1,6.7,0.1l5.8-3.1
		c2.1-1.1,5.2-1,7.1,0.3l3.4,2.4"/>
        </g>
    </svg>


}
const mapping: { [key: string]: any } = {
    "tera_forest": tera_forest(),
    "tera_hill": tera_hill(),
    "tera_mountain": tera_mountain(),
    "tera_plain": tera_plain(),
    "tera_sea": tera_sea(),
    "tera_water": tera_water(),
}
export default function InLineSVGCollection(props: {
    SVGName: string,
    //color不在用
    color?: string,
    opacity?: string,
    size?: number,
}) {
    const color = (props.color === undefined) ? `currentColor` : props.color
    const opacity = (props.opacity === undefined) ? 1 : props.opacity
    const size = (props.size === undefined) ? '1em' : (props.size + `vw`)
    const margin = (props.size === undefined) ? 0 : (props.size * 0.2 + `vw`)


    return <div style={{
        opacity: opacity,
        width: size,
        height: size,
        // marginRight: margin,
        // top: margin,
        stroke: color,
        fill: color,
    }}
                className={"inLineSVG-root " + props.SVGName}
    >{mapping[props.SVGName]}</div>

    // <img src={require(`./assets/${props.SVGName}.svg`).default}
    //             style={{
    //                 opacity: opacity,
    //                 width: size,
    //                 height: size,
    //                 marginRight: margin,
    //                 top: margin,
    //             }}
    //             className={"inLineSVG " + props.SVGName}
    //             alt={props.SVGName}/>
    //
    // return <img src={require(`./assets/${props.SVGName}.svg`).default}
    //             style={{
    //                 opacity: opacity,
    //                 width: size,
    //                 height: size,
    //                 marginRight: margin,
    //                 top: margin,
    //             }}
    //             className={"inLineSVG " + props.SVGName}
    //             alt={props.SVGName}/>
}

import {HiringCard} from "../components/WorkCard/WorkCard";
import WorkCard from "../components/WorkCard/WorkCard";
import Grid from '@mui/material/Grid';
import {Link, Navigate, Route, Routes, useParams} from "react-router-dom";
import "./Facility.scss"
import {useEffect, useState} from "react";
import {getLoginToken} from "../../../utils/storage";
import {buildingTypeToIntroduction} from "../../../components/mappingTable";

//可视为啥都还没写
export default function Facility(props: {
    buildingType: number,
    buildingId: number,
}) {

    //获取自己，用作比对
    const [user_state,set_user_state] = useState({
        "uid": 0,
        "user": {
            "uid": 0,
            "email": null,
            "activated_at": null,
            "created_at": null,
            "updated_at": null
        },
        "nick_name": "",
        "avatar": ""
    })




    const {buildingId} = useParams();


    return <div className={"facility"} style={{width: "100%", height: "100%"}}>
        <div className={"work-title"}>
            设施
        </div>
        <div className={"grid-container"}>
            {buildingTypeToIntroduction[props.buildingType]}
        </div>
    </div>;
}

